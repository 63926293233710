<style scoped>
.input-err-border {
  border-color: var(--ft-error) !important;
  border: 1.5px solid;
}
</style>

<template>
  <div>
    <VueFormElement
      :HeaderText="Options.HeaderText"
      :ErrorMessage="ErrorMessage"
      :ShowErrorMessage="Options.ShowErrorMessage"
      :required="Options.required"
    >
      <template #custom-content>
        <select
          v-model="parentValue"
          :class="[{ 'input-err-border': Options.ShowErrorMessage }]"
          :disabled="Disabled"
          class="w-100 h-48px"
          @change="$emit('change')"
        >
          <slot></slot>
          <option v-for="i in selectOption" :value="i.id" :key="i.id" :disabled="i.disabled">
            {{ i.label }}
          </option>
        </select>
      </template>
    </VueFormElement>
  </div>
</template>

<script>
import VueFormElement from "./Form-element.vue";

export default {
  components: {
    VueFormElement,
  },
  props: {
    value: { require: true },
    Options: Object,
    selectOption: Array,
  },
  computed: {
    parentValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    ErrorMessage: {
      get() {
        if (this.Options.ErrorMessage === undefined) {
          return "未選擇";
        } else {
          return this.Options.ErrorMessage;
        }
      },
    },
    Placeholder: {
      get() {
        if (this.Options.Placeholder === undefined) {
          return "請選擇" + this.Options.HeaderText;
        } else {
          return this.Options.Placeholder;
        }
      },
    },
    Disabled: {
      get() {
        if (this.Options.disabled === undefined) {
          return false;
        } else {
          return this.Options.disabled;
        }
      },
    },
  },
};
</script>
