<template>
    <div class="page-wrapper">
        <div class="row row-large row-divided">
            <div class="large-12 col">
                <div class="entry-header">
                    <h1 class="entry-title">【粽夏YA!】DIY史萊姆 粽粽怪獸+冰心粽 包出夏日的趣味!<br />
                    </h1>
                    <h1 class="entry-title">≡活動期間┊6/9-6/25≡</h1>
                </div>
            </div>
            <div class="post-time">
                <span><img src="../../../assets/img/mobile/news/time.png" alt="">2023-06-01</span>
            </div>
            <div class="content col-12">
                <div class="img-group">
                    <img v-lazy="Img1" alt="">
                    <p>端午節到GAGA monster包史萊姆粽子啦<br />
                        ➡ #粽粽怪獸 + 浸涼冰心 #冰心粽 雙重滿足~<br />
                        ➡ 上課即贈6月主題活動粽夏YA! #限定徽章</p>
                </div>
                <div class="img-group">
                    <img v-lazy="Img2" alt="">
                </div>
                <p>
                    魔法藥水是個非常神奇的魔法，也是製作史萊姆時一個很重要的步驟喔！<br />
                    只要加魔法藥水進去怪獸膠水裡面後，用魔法棒攪攪攪~~~<br />
                    就能夠變出史萊姆了!!!　魔法藥水也俗稱：史萊姆成型水<br />
                    那這次的粽粽怪獸是GAGA特調的比例與手感~等你們來櫃上體驗唷!!!
                </p>
                <div class="img-group">
                    <img v-lazy="Img3" alt="">
                </div>
                <p>再來想當小小藝術家就是這個時候！超多顏色可以調配＞＜<br /> 小小藝術家準備將史萊姆上色啦💛🧡一次加兩種不同顏色才有趣~</p>

                <div class="img-group">
                    <img v-lazy="Img4" alt="">
                    <p>將史萊姆挖呀挖呀挖~像麻糬般捏呀捏呀捏~ <br />
                        史萊姆怪獸就會黏在你手上啦~~~(快逃)
                    </p>
                    <img v-lazy="Img5" alt="">
                    <p>
                        將史萊姆怪獸裹上滿滿的餡料，變身 #粽粽怪獸 ! <br />
                        被灑滿餡料的粽粽怪獸，手感摸起來好扎實呀~
                    </p>
                    <img v-lazy="Img6" alt="">
                </div>
                <div style="text-align: center;font-size: 16px;">
                    <p><b>來體驗不一樣端午節? GAGA monster 要讓大家來包史萊姆粽子囉!</b></p>
                </div>
                <div class="img-group">
                    <img v-lazy="Img7" alt="">
                </div>
                <p>
                    透明史萊姆+粽粽怪獸 <br />
                    變身沁涼Q彈的 冰心粽 😍 <br />
                    小小一個冰心粽拿在手上好可愛!!! <br />
                    還可以當小裝飾品的史萊姆小點心
                </p>
                <div class="img-group">
                    <img v-lazy="Img8" alt="">
                </div>
                <p>另外，這是GAGA意外的發現? 把冰心粽拿去冷凍!!!<br />
                    沒想到~冷凍過後的史萊姆冰心粽竟然結晶了!!!<br />
                    看起來就是個藝術品呢!!!超級漂亮😍
                </p>
                <div class="img-group">
                    <img v-lazy="Img9" alt="">
                </div>
                <p>6月份主題活動粽夏YA! <br />
                    滿滿的系列活動等你們來GAGA monster玩啦❤️ <br />
                    史萊姆無限變化，準備釋放孩子無限創造力
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'newsdetail20230601',
    data() {
        return {
            Img1: require('../../../assets/img/mobile/news/20230601/官網1.jpg'),
            Img2: require('../../../assets/img/mobile/news/20230601/官網2.jpg'),
            Img3: require('../../../assets/img/mobile/news/20230601/官網3.jpg'),
            Img4: require('../../../assets/img/mobile/news/20230601/官網4.jpg'),
            Img5: require('../../../assets/img/mobile/news/20230601/官網5.jpg'),
            Img6: require('../../../assets/img/mobile/news/20230601/官網6.jpg'),
            Img7: require('../../../assets/img/mobile/news/20230601/官網7.jpg'),
            Img8: require('../../../assets/img/mobile/news/20230601/官網8.jpg'),
            Img9: require('../../../assets/img/mobile/news/20230601/官網9.jpg'),
        }
    },
}
</script>