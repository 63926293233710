<template>
    <div>
        <!-- BEGIN login -->
        <div class="login login-box">
            <!-- BEGIN login-container -->
            <div class="login-container">
                <!-- BEGIN login-header -->
                <div class="login-header">
                    <div class="bg-white-shadow">
                        <img :src="logo_img_path" class="header-logo-img" />
                        <span class="title-pink">忘記密碼</span>
                        <div class="row input-box">
                            <!-- 手機 -->
                            <label v-bind:class="{ 'input-error': errclass_account }">
                                <!-- <span class="input-icon"
                    ><img class="icon-Phone filter-orange"
                  /></span> -->
                                <input type="text" class="col-12 form-controller" v-model="user_account" placeholder="請輸入帳號" />
                                <small class="error-text">{{ errMsg_account }}</small>
                            </label>

                            <!-- 手機 -->
                            <label v-bind:class="{ 'input-error': errclass_id }">
                                <!-- <span class="input-icon"
                    ><img class="icon-Phone filter-orange"
                  /></span> -->
                                <input type="text" class="col-12 form-controller" v-model="user_id" @input="onlyNum_id"
                                    @change="err_id" placeholder="請輸入手機號碼" maxlength="10" />
                                <small class="error-text">{{ errMsg_id }}</small>
                            </label>

                            <!-- 驗證碼 -->
                            <label v-if="Sms_YN" v-bind:class="{ 'input-error': errclass_captcha }">
                                <!-- <span class="input-icon"
                    ><img class="icon-Shield filter-orange"
                  /></span> -->
                                <div class="btn btn-confirm btn-verify" :class="{ 'btn-disabled': btn_disabled_captcha }"
                                    @click="btn_disabled_captcha ? '' : getCaptcha()">
                                    <span class="btn-text" v-show="show">{{ text_captcha }}</span>
                                    <span class="btn-text count" v-show="!show">{{ count }}s後重送</span>
                                </div>
                                <div class="position-relative input-verify">
                                    <span class="input-icon-bk text-green" v-show="!show_bk"><i
                                            class="fa fa-solid fa-check"></i></span>
                                    <input type="text" class="col-12 form-controller" v-model="user_captcha"
                                        @input="err_captcha" placeholder="請輸入驗證碼" maxlength="6" />
                                    <small class="error-text">{{ errMsg_captcha }}</small>
                                </div>
                            </label>

                            <!-- 生日 -->
                            <label class="col-12" v-bind:class="{ 'input-error': errclass_birth }">
                                <span class="span-pink">請選擇您的生日</span>
                            </label>
                            <label class="col-12 d-block d-sm-none" v-bind:class="{ 'input-error': errclass_birth }">
                                <input type="date" class="col-12 form-controller" v-model="user_birth"
                                    :max="getNowdate()" />
                            </label>

                            <label class="col-4 d-none d-sm-block" v-bind:class="{ 'input-error': errclass_birth }">
                                <select class="col-12" v-model="user_birth1" @change="onChangeBirthday()">
                                    <option class="d-none">請選擇西元年</option>
                                    <option v-for="year in Years" v-bind:value="year" v-bind:key="year">
                                        {{ year }} 年
                                    </option>
                                </select>
                            </label>
                            <label class="col-4 d-none d-sm-block" v-bind:class="{ 'input-error': errclass_birth }">
                                <select class="col-12" v-model="user_birth2" @change="onChangeBirthday()">
                                    <option class="d-none">請選擇月份</option>
                                    <option v-for="month in Months" v-bind:value="month" v-bind:key="month">
                                        {{ month }} 月
                                    </option>
                                </select>
                            </label>
                            <label class="col-4 d-none d-sm-block" v-bind:class="{ 'input-error': errclass_birth }">
                                <select class="col-12" v-model="user_birth3">
                                    <option class="d-none">請選擇日期</option>
                                    <option v-for="day in Dates" v-bind:value="day" v-bind:key="day">
                                        {{ day }} 日
                                    </option>
                                </select>
                            </label>
                            <label class="col-12 p-0" v-bind:class="{ 'input-error': errclass_birth }">
                                <small class="error-text">帳號、生日與註冊資料比對不符，請重新確認資料或洽詢<span class="text-decoration-underline"
                                        style="cursor: pointer" @click="openStoreDetail()">門市協助</span>。</small>
                            </label>

                            <label class="col-12 label-btn">
                                <!-- <div class="btn-long">
                    <span class="btn-text">下一步</span>
                    <img :src="btn_img" />
                  </div> -->
                                <button class="border-0 btn btn-long btn-confirm" @click="nextpage">
                                    <span class="btn-text">下一步</span>
                                </button>
                            </label>
                        </div>
                    </div>
                </div>
                <!-- END login-header -->
            </div>
            <!-- END login-container -->
        </div>
    </div>
</template>

<script>
import axios from "../api/axios.js";
export default {
    async created() {
        this.getNowdate();
        this.initYear();
        this.initMonth();
    },
    data() {
        return {

            logo_img_path: require("../assets/img/mobile/home/BG-10(2)-logo.png"),
            screenWidth: document.body.clientWidth,
            //帳號
            user_account: "",
            errclass_account: false,
            errMsg_account: "帳號錯誤，請輸入正確帳號。",
            //手機
            user_id: "",
            errclass_id: false,
            errMsg_id: "手機號碼格式錯誤，請輸入正確手機號碼。",
            //驗證碼
            user_captcha: "",
            errclass_captcha: false,
            errMsg_captcha: "驗證碼錯誤，請輸入正確手機驗證碼。",
            text_captcha: "發送驗證碼",
            //生日
            user_birth: "",
            user_birth1: "請選擇西元年",
            user_birth2: "請選擇月份",
            user_birth3: "請選擇日期",
            errclass_birth: false,
            mm: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            //驗證碼倒數
            btn_disabled_captcha: false,
            show: true,
            show_bk: true,
            count: "",
            timer: null,
            Years: [],
            Months: [],
            Dates: [],
            store1: [],
            store2: [],
            //是否有開啟簡訊推播
            Sms_YN: false,
        }
    },
    methods: {
        //限制只能輸入數字
        onlyNum_id() {
            this.user_id = this.user_id.replace(/[^0-9.]/g, "");
        },
        //判斷格式是否正確
        err_id() {
            this.errclass_id = !/^09\d{8}$/.test(this.user_id) ? true : false;
            if (this.user_id.length == 0) this.errclass_id = false;
        },
        //取得今天日期(格式：yyyy-mm-dd)
        getNowdate() {
            let Today = new Date();
            let yyyy = Today.getFullYear();
            let mm = String(Today.getMonth() + 1).padStart(2, "0");
            let dd = String(Today.getDate()).padStart(2, "0");
            let nowdate = yyyy + "-" + mm + "-" + dd;
            return nowdate;
        },
        onChangeBirthday: function() {
        if (
            this.user_birth1 != "請選擇西元年" &&
            this.user_birth2 != "請選擇月份"
        ) {
            var bodyData = { year: this.user_birth1, month: this.user_birth2 };

            axios.post("User/GetOptionsDate",{ year : bodyData.year,month : bodyData.month }).then(resp => {
                if (resp.succ) {
                    this.Dates = resp.result;
                } else {
                    this.$swal(resp.msg);
                    //alert(resp.msg);
                }
            })
        }
        },
        getdate() {
            let date = null;
            // if (this.screenWidth > 576) {
            let yyyy = this.user_birth1;
            let mm = String(this.user_birth2).padStart(2, "0");
            let dd = String(this.user_birth3).padStart(2, "0");
            date = yyyy + "-" + mm + "-" + dd;
            // } else {
            // date = this.user_birth;
            // }
            this.user_birth = date;
        },
        initYear: function () {
        for (let i = 1900; i < new Date().getFullYear() + 1; i++) {
            this.Years.push(i.toString());
            }
        },
        initMonth: function () {
        for (let i = 1; i < 13; i++) {
            this.Months.push(i.toString());
            }
        },
        nextpage() {
            let ok =
                this.user_birth.length > 0 ||
                (this.user_birth1 != "請選擇西元年" &&
                this.user_birth2 != "請選擇月份" &&
                this.user_birth3 != "請選擇日期")
                ? true
                : false;
            if (ok) {
                if(this.user_birth.length <= 0)
                {
                    this.getdate();
                }
                axios.post("User/Verify_Forgetpwd",{ mobile : this.user_id,birth : this.user_birth ,account : this.user_account}).then(resp => {
                    if (resp.succ) {
                        this.sno_user
                        this.$router.push({ name: "setPwd",            
                        query: {
                            sno_user: resp.result.map(x=>{return x.sno_user}),
                            token: resp.result.map(x=>{return x.token}),
                            } 
                        });
                    } else {
                        this.$swal(resp.msg);
                        //alert(resp.msg);
                    }
                })
            }
        }
    }
}
</script>

<style scoped>
.login-box {
    min-height: calc(100vh - 105px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    margin-bottom: 0;
}

.login-container {
    padding: 0px;
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
.bg-white-shadow {
    padding: 14px 32px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.header-logo-img {
    width: 350px;
}
.title-pink {
    color: var(--main-text-color);
    font-size: 28px;
    font-weight: 700;
    display: block;
    text-align: center;
    padding: 20px 0 20px;
}
.error-text {
    visibility: hidden;
    color: #f20505;
    font-weight: 900;
    padding: 4px 0 0 4px;
    letter-spacing: -1px;
    font-size: 10px;
}
input{
    padding: 7px;
}
.btn-long {
    width: 100%;
    height: 50px;
    cursor: pointer;
    border-radius: 8px;
    font-weight: 600;
}
.btn-confirm {
    background: var(--main-text-color)!important;
    color: #ffffff!important;
}

@media (min-width: 767.98px) {
    .login-container {
        max-width: 600px;
    }
}
</style>