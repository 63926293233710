<template>
    <div class="page-wrapper">
        <div class="row row-large row-divided">
            <div class="large-12 col" style="padding-left:0;padding-right:0;">
                <div class="entry-header">
                    <h1 class="entry-title">GAGA monster 堅持力挺創客教育，參與Maker Party釋放孩子無限創造力</h1>
                </div>
            </div>
            <div class="post-time">
                <span><img src="../../../assets/img/mobile/news/time.png" alt="">2022-12-07</span>
            </div>
            <div class="content col-12">
                <p>2022親子天下Maker Party亞洲最大親子創客嘉年華已來到第8屆，提供孩子發揮「動手做、玩中學、勇敢嘗試」的創客精神，連結孩子從「想」到「做」
                    的過程。培養創新、獨立思考、自主學習、與解決問題的能力。
                </p>
                <p>首次參與的GAGA monster將創客教育導入maker精神，秉持開發孩子創造力，並認為創客精神是讓孩子探索與實踐的教育，從過程中誘發孩子培養 「獨立、好奇、專注」的能力，激發孩子無限的創客潛能。</p>
                <div class="img-group">
                    <img v-lazy="Img1" alt="">
                    <p>2022親子天下 Maker Party</p>
                </div>
                <p>GAGA monster 將「動手做史萊姆」這件事所帶來的學習意義視為一種創造力的展現，在本次親子天下Maker
                    Party，透過課程教學與沉浸式五感互動體驗，讓孩子們將史萊姆從零到有獨立創作。製作史萊姆過程中，運用不同的道具與教材，讓孩子在實作中投入全心全意的專注力，在動手做的過程中讓孩子產生好奇心。</p>
                <p>GAGA monster訓練專注力與獨立，也發現了孩子看到自己的創客作品時，擁有滿滿的成就感！ GAGA
                    monster認為「動手做」不是一個可有可無的遊戲或動作，而是孩子在學習成長、面對世界時，不可少的關鍵要素。</p>
                <div class="img-group">
                    <img v-lazy="Img2" alt="">
                    <img v-lazy="Img3" alt="">
                    <p>GAGA monster 課程教學與沉浸式五感互動體驗</p>
                    <img v-lazy="Img4" alt="">
                    <p>知名部落客 ⌜林叨囝仔⌟ 也來到了活動現場</p>
                </div>
                <div style="text-align: center;font-size: 16px;">
                    <p><b>女兒的maker精神開啟了品牌故事</b></p>
                </div>
                <p>GAGA monster的創辦初衷竟然為了女兒?
                    女兒自從開始接觸史萊姆後，無限的熱情與創造力，從她小小的體內不斷湧出。她可以展現驚人的專注力，在還不知道史萊姆需要什麼原物料的情況下，曾經發揮創客精神!強大的好奇心使她將家中想像得到的材料，例如拿走爸爸的刮鬍泡、家裡的湯匙等，全都成為她的實驗器具，甚至可以獨自好幾個小時專注地研究史萊姆。
                </p>
                <div class="img-group">
                    <p>GAGA monster初始創辦人-史萊姆校長</p>
                </div>
                <p>因此，GAGA monster史萊姆怪獸星球誕生了，身為GAGA
                    monster的創辦人身兼爸媽的角色，最大的初衷只為給予女兒最安心的遊玩體驗，在曾經的經驗中發掘，如果購買文書專用的膠水、專門設計給大人使用的刮鬍泡，或是高濃度的化學香精等，皆是小朋友不該輕易接觸的物品。身為家長不想澆熄女兒的熱情，便開始在全球各地尋找最優質的材料，最後採用台灣製造的史萊姆專用膠水、成型水等材料，並皆通過
                    SGS、歐盟、玩具中心等安全檢驗合格。GAGA monster期望給予孩子們最安全的創造空間，才能持續展現無限創造力。</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'newsdetail20221207',
    data() {
        return {
            Img1: require('../../../assets/img/mobile/news/20221207/1.jpg'),
            Img2: require('../../../assets/img/mobile/news/20221207/2.jpg'),
            Img3: require('../../../assets/img/mobile/news/20221207/3.jpg'),
            Img4: require('../../../assets/img/mobile/news/20221207/4.jpg'),
        }
    },
}
</script>