<template>
    <div id="body-bg">
        <b-card no-body class="card">
            <div class="text-center" style="margin-top: 20px;font-size: 16px;">
                <span v-if="isMobile">我的帳戶</span>
            </div>
            <!-- #region 未登入 -->
            <div v-if="!loggedInUser" class="row">
                <div class="col-lg-6 col-xl-6 col-12">
                    <div class="login row">
                        <span class="col-12 title">登入</span>
                        <span class="col-12">帳號(手機號碼)*</span>
                        <input type="text" name="login-userId" id="login-userId" class="col-12" v-model="form.login.userId">
                        <span class="col-12">密碼*</span>
                        <div class="col-12 input-with-icon p-0">
                            <input type="password" name="login-password" id="login-password" class="col-12"
                                style="padding:0  calc(var(--bs-gutter-x) * 0.5);" v-model="form.login.password"
                                :type="loginPwdInputType">
                            <span class="icon-container" @click=" isShowLoginPwd = !isShowLoginPwd">
                                <b-icon :icon="isShowLoginPwd ? 'eye' : 'eye-slash'" font-scale="1.05"></b-icon>
                            </span>
                        </div>
                        <input type="checkbox" name="keep-login" id="keep-login" class="col-1"
                            v-model="form.login.isRemeber">
                        <span class="col-4" style="margin: 4px 0px 20px 5px;">保持登入</span><span class="col-6"></span>
                        <input type="button" value="登入" class="col-3" id="login-btn" @click="handelLogin()">
                        <div style="padding: 0;"><router-link :to="{ name: 'forgetPwd' }">忘記密碼?</router-link></div>

                        <span v-if="isMobile" :class="collapseVisible ? null : 'collapsed'"
                            :aria-expanded="collapseVisible ? 'true' : 'false'" aria-controls="collapse"
                            @click="collapseVisible = !collapseVisible">
                            <span class="collope">沒有帳號嗎?立即註冊</span>
                        </span>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-6 col-12">
                    <b-collapse id="collapse" v-model="collapseVisible">
                        <div class="register row">
                            <span class="col-12 title">註冊</span>
                            <span class="col-12">手機號碼*</span>
                            <input type="text" name="register-phone" id="register-phone" class="col-12" @input="onlyNum_id"
                                v-model="form.register.phone">
                            <span class="col-12">密碼*</span>
                            <div class="col-12 input-with-icon p-0">
                                <input type="password" name="register-password" id="register-password" class="col-12"
                                    style="padding:0  calc(var(--bs-gutter-x) * 0.5);" v-model="form.register.password"
                                    :type="registerPwdInputType">
                                <span class="icon-container" @click=" isShowRegisterPwd = !isShowRegisterPwd">
                                    <b-icon :icon="isShowRegisterPwd ? 'eye' : 'eye-slash'" font-scale="1.05"></b-icon>
                                </span>
                            </div>
                            <span class="col-12">帳號(手機號碼)* <input type="checkbox" name="isSamePhone" id="isSamePhone"
                                    v-model="isSamePhone">同手機號</span>
                            <input type="text" name="register-userId" id="register-userId" class="col-12"
                                v-model="form.register.userId">
                            <span class="col-12">姓名*</span>
                            <input type="text" name="register-name" id="register-name" class="col-12"
                                v-model="form.register.name">
                            <span class="col-12">電子郵件*</span>
                            <input type="text" name="register-email" id="register-email" class="col-12"
                                v-model="form.register.email">
                            <span class="col-12">生日*</span>
                            <div class="input-group datetimepicker-box col-12 p-0">
                                <span class="row">
                                    <input type="text" name="register-year" id="register-year"
                                        @change="updateDays('register')" @input="onlyNum_id"
                                        v-model="form.register.b_arr[0]" placeholder="西元年" class="col-4">
                                    <select id="register-month" v-model="form.register.b_arr[1]"
                                        @change="updateDays('register')" class="register-select col-4">
                                        <option value="">請選擇月</option>
                                        <option v-for="month in months" :key="month" :value="month">{{ month }}
                                        </option>
                                    </select>
                                    <select id="register-day" v-model="form.register.b_arr[2]"
                                        class="register-select col-4">
                                        <option value="">請選擇日</option>
                                        <option v-for="day in days" :key="day" :value="day">{{ day }}</option>
                                    </select>
                                </span>
                            </div>
                            <span class="col-12">性別*</span>
                            <select class="form-select col-12" v-model="form.register.sex">
                                <option selected value="0">男</option>
                                <option value="1">女</option>
                            </select>
                            <p>您的個人數據將用於您在整個網站上的體驗，管理對帳戶的訪問以及用於我們的其他目的<router-link
                                    :to="{ name: 'privacyPolicy' }">隱私權政策</router-link></p>
                            <input type="button" value="註冊" class="col-3" id="register-btn" @click="register()">

                            <div v-if="showCaptcha" class="captcha">
                                <div style="height: 70px;">
                                    <label v-bind:class="{ 'input-error': errclass_captcha }">
                                        <div class="btn btn-confirm btn-verify"
                                            :class="{ 'btn-disabled': btn_disabled_captcha }"
                                            @click="btn_disabled_captcha ? '' : getCaptcha()">
                                            <span class="btn-text" v-show="show">{{ text_captcha }}</span>
                                            <span class="btn-text count" v-show="!show">{{ count }}s後重送</span>
                                        </div>
                                        <div class="position-relative input-verify">
                                            <span class="input-icon-bk text-green" v-show="!show_bk"><i
                                                    class="fa fa-solid fa-check"></i></span>
                                            <input type="text" class="col-12 input-captcha" v-model="user_captcha"
                                                @input="err_captcha" placeholder="請輸入驗證碼" maxlength="6" />
                                            <small class="error-text">{{ errMsg_captcha }}</small>
                                        </div>
                                    </label>
                                </div>
                                <b-button class="col-12 btn-confirm" style="border: 0;"
                                    :disabled="comfirmCheckDisable">確認驗證</b-button>
                            </div>
                        </div>
                    </b-collapse>
                </div>
            </div>
            <!-- #endregion -->
            <!-- #region 已登入 -->
            <div v-if="loggedInUser" class="row">
                <div class="col-lg-3 col-xl-3 col-12 my-0">
                    <div class="personal-info row">
                        <img v-lazy="memberIconImg" alt="" class="col-2">
                        <span class="col-8" style="padding: 0;color: #2a9ecb;" @click="handleGetUser">{{
                            loggedInUser.userName
                        }}
                            <br>
                            <span style="color: black;">點數: {{ userPoint }}</span>
                        </span>
                        <span class="col-3" style="padding: 0;color: #9ca8ad;font-style: italic;"></span>
                        <ul>
                            <li v-for="item in options" :key="item.id" @click=" selectItem(item.id)"
                                :style="{ color: 'black', 'border-right': item.active ? '#b42751 3px solid' : 'none' }">
                                {{ item.name }}
                            </li>
                        </ul>
                        <div v-if="isMobile" style="padding: 10px">
                            <!-- 預約資訊 -->
                            <div v-if="activeInfo(2)">
                                <span class="pers-title">預約資訊</span>
                                <div class="row py-40px" >
                                    <b-table :items="mappedItems" :fields="fields">
                                        <template v-slot:cell(BOOKING_DATE)="row">
                                            {{ row.item.BOOKING_DATE }} <br>{{ row.item.BOOKING_TIME }}
                                        </template>
                                        <template v-slot:cell(CANCEL)="row">
                                            <template v-if="row.item.BOOKING_STATUS === '即將到來'">
                                                <b-button variant="danger" size="sm" @click="cancelGroupClass(row.item)">
                                                    取消
                                                </b-button>
                                            </template>
                                        </template>
                                    </b-table>
                                </div>
                            </div>
                            <!-- 消費紀錄 -->
                            <div v-if="activeInfo(3)">
                                <span class="pers-title">消費紀錄</span>
                                <ConsumptionHistory />
                            </div>
                            <!-- 帳戶詳細資料 -->
                            <div v-if="activeInfo(6)">
                                <span class="pers-title">帳戶詳細資料</span>
                                <div class="row py-40px" style="margin-top: 40px;">
                                    <div class="user">
                                        <header>姓名</header>
                                        <span v-show="!showEdit_username">{{ user_list.user_name }}
                                            <div @click="handleEdit('userName')" class="btn-storeChange mx-5px">
                                                變更
                                            </div>
                                        </span>
                                        <span v-show="showEdit_username">
                                            <input type="text" class="col-12 form-controller"
                                                v-model="formData.user_name.value" placeholder="請輸入姓名" maxlength="16" />
                                        </span>
                                    </div>
                                    <div class="user">
                                        <header>性別</header>
                                        <span v-show="!showEdit_gender">{{ user_list.gender == "0" ? "男" : "女" }}
                                            <div @click="handleEdit('gender')" class="btn-storeChange mx-5px">
                                                變更
                                            </div>
                                        </span>
                                        <span v-show="showEdit_gender">
                                            <select class="form-select" v-model="formData.gender.value">
                                                <option selected value="0">男</option>
                                                <option value="1">女</option>
                                            </select>
                                        </span>
                                    </div>
                                    <div class="user">
                                        <header>手機號碼</header>
                                        <span>{{ user_list.phone }}
                                        </span>
                                    </div>
                                    <div class="user">
                                        <header>出生年月日</header>
                                        <span v-show="!showEdit_birth">{{ user_list.birthYear }}年{{ user_list.birthMonth
                                        }}月{{ user_list.birthDay }}日
                                            <div @click="handleEdit('birth')" class="btn-storeChange mx-5px">
                                                變更
                                            </div>
                                        </span>
                                        <span v-show="showEdit_birth">
                                            <label for="year">年：</label>
                                            <!-- <select id="year" v-model="formData.user_birth.value[0]"
                                                @change="updateDays('')">
                                                <option value="">請選擇</option>
                                                <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
                                            </select> -->
                                            <input type="text" name="year" id="year" @change="updateDays('isLogin')"
                                                @input="onlyNum_id" v-model="formData.user_birth.value[0]"
                                                placeholder="請輸入西元年" style="height: 25px;width: 120px;">

                                            <label for="month">月：</label>
                                            <select id="month" v-model="formData.user_birth.value[1]"
                                                @change="updateDays('isLogin')">
                                                <option value="">請選擇</option>
                                                <option v-for="month in months" :key="month" :value="month">{{ month }}
                                                </option>
                                            </select>

                                            <label for="day">日：</label>
                                            <select id="day" v-model="formData.user_birth.value[2]">
                                                <option value="">請選擇</option>
                                                <option v-for="day in days" :key="day" :value="day">{{ day }}</option>
                                            </select>
                                        </span>
                                    </div>
                                    <!-- 密碼設定 -->
                                    <div class="user">
                                        <header>
                                            密碼設定
                                        </header>
                                        <span v-show="!showEdit_pwd">{{ user_list.pwd }}
                                            <div @click="handleEdit('pwd')" class="btn-storeChange mx-5px">
                                                變更
                                            </div>
                                        </span>
                                        <span v-show="showEdit_pwd">
                                            <div class="mb-4px mb-12px position-relative width-500"
                                                v-bind:class="{ 'input-error': formData.user_pwd.errclass }">
                                                <input type="password" class="col-12 form-controller"
                                                    v-model="formData.user_pwd.value" placeholder="請輸入舊密碼" maxlength="16" />
                                                <small :class="{ hide: !formData.user_pwd.errclass }" class="error-text">{{
                                                    formData.user_pwd.errmsg }}</small>
                                            </div>
                                            <div class="mb-4px mb-12px position-relative width-500"
                                                v-bind:class="{ 'input-error': formData.user_pwd1.errclass }">
                                                <input type="password" class="col-12 form-controller"
                                                    v-model="formData.user_pwd1.value" placeholder="請輸入密碼" maxlength="16" />
                                                <small class="tip-text"
                                                    :class="{ hide: formData.user_pwd1.errclass }">密碼長度限制{{ GetPwdMinLen
                                                    }}至16個字元，必須包含至少一個英文字母。</small>
                                                <small class="error-text" :class="{ hide: !formData.user_pwd1.errclass }">{{
                                                    formData.user_pwd1.errmsg }}</small>
                                            </div>
                                            <div class="mb-0 position-relative width-500"
                                                v-bind:class="{ 'input-error': formData.user_pwd2.errclass }">
                                                <input type="password" class="col-12 form-controller"
                                                    v-model="formData.user_pwd2.value" placeholder="請再次輸入密碼" maxlength="16"
                                                    @input="checkPassword" />
                                                <small :class="{ hide: !formData.user_pwd2.errclass }" class="error-text">{{
                                                    formData.user_pwd2.errmsg }}</small>
                                            </div>
                                        </span>
                                    </div>
                                    <div class="user">
                                        <header>地址</header>
                                        <span v-show="!showEdit_address">{{ user_list.addr1 }}&nbsp;{{
                                            user_list.addr2 }}&nbsp;{{ user_list.addr3 }}
                                            <div @click="handleEdit('address')" class="btn-storeChange mx-5px">
                                                變更
                                            </div>
                                        </span>
                                        <span v-show="showEdit_address">
                                            <span class="addr width-500">
                                                <select v-model="formData.address.value[0]"
                                                    @change="onChange_add1($event, '')" class="col-12 col-xl-3 mb-12px">
                                                    <option class="d-none" value="null">請選擇縣市</option>
                                                    <option v-for="list in this.Countys" :key="list.p_key"
                                                        :value="list.p_name">
                                                        {{ list.p_name }}
                                                    </option>
                                                </select>
                                                <select v-model="formData.address.value[1]" class="col-12 col-xl-3 mb-12px">
                                                    <option class="d-none" value="null">請選擇鄉鎮市區</option>
                                                    <option v-for="list in this.Citys" :key="list.p_key"
                                                        :value="list.p_name">
                                                        {{ list.p_name }}
                                                    </option>
                                                </select>
                                                <input type="text" class="col-12 col-xl-6"
                                                    v-model="formData.address.value[2]" placeholder="請輸入地址" />
                                            </span>
                                        </span>
                                    </div>
                                    <div class="user" :class="{ 'mb-0': showEdit_email }">
                                        <header>電子信箱</header>
                                        <span v-show="!showEdit_email">{{ user_list.email == null ? "未填寫" : user_list.email
                                        }}<span @click="handleEdit('email')"
                                                class="btn-storeChange mx-5px">變更</span></span>
                                        <span v-show="showEdit_email">
                                            <div class="position-relative width-500">
                                                <div v-bind:class="{ 'input-error': formData.email.errclass }">
                                                    <input type="email" class="col-12 form-controller"
                                                        v-model="formData.email.value" @input="checkEmail"
                                                        placeholder="請輸入電子信箱" style="padding-right: 40px" />
                                                    <small class="error-text" :class="{ hide: !formData.email.errclass }">{{
                                                        formData.email.errmsg }}</small>
                                                </div>
                                            </div>
                                        </span>
                                    </div>
                                </div>

                                <div :style="{ display: showEdit_btn ? 'flex' : 'none' }" class="justify-content-center">
                                    <button class="border-0 btn-cancel m-3" @click="handleCancel">
                                        取消
                                    </button>
                                    <button class="border-0 btn-primary m-3" @click="handleUpdate">
                                        儲存
                                    </button>
                                </div>
                            </div>
                            <!--登出 -->
                            <div v-if="activeInfo(8)">
                                <span style="margin-right: 20px;">確認要登出嗎?</span>
                                <input type="button" value="確認並登出" class="logout-btn" @click="handelLogout()">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-9 col-xl-9 col-12" v-if="!isMobile"
                    style="border-left: #d9dcdd 1px solid;margin-bottom: 10px;">
                    <!-- 預約資訊 -->
                    <div v-if="activeInfo(2)">
                        <span class="pers-title">預約資訊</span>
                        <div class="row py-40px">
                            <b-table :items="mappedItems" :fields="fields">
                                <template v-slot:cell(BOOKING_DATE)="row">
                                    {{ row.item.BOOKING_DATE }} <br>{{ row.item.BOOKING_TIME }}
                                </template>
                                <template v-slot:cell(CANCEL)="row">
                                    <template v-if="row.item.BOOKING_STATUS === '即將到來'">
                                        <b-button variant="danger" size="sm" @click="cancelGroupClass(row.item)">
                                            取消
                                        </b-button>
                                    </template>
                                </template>
                            </b-table>
                        </div>
                    </div>
                    <!-- 消費紀錄 -->
                    <div v-if="activeInfo(3)">
                        <span class="pers-title">消費紀錄</span>
                        <ConsumptionHistory />
                    </div>
                    <!-- 帳戶詳細資料 -->
                    <div v-if="activeInfo(6)">
                        <span class="pers-title">帳戶詳細資料</span>
                        <div class="row py-40px">
                            <div class="user">
                                <header>姓名</header>
                                <span v-show="!showEdit_username">{{ user_list.user_name }}
                                    <div @click="handleEdit('userName')" class="btn-storeChange mx-5px">
                                        變更
                                    </div>
                                </span>
                                <span v-show="showEdit_username">
                                    <input type="text" class="col-12 form-controller" v-model="formData.user_name.value"
                                        placeholder="請輸入姓名" maxlength="16" />
                                </span>
                            </div>
                            <div class="user">
                                <header>性別</header>
                                <span v-show="!showEdit_gender">{{ user_list.gender == "0" ? "男" : "女" }}
                                    <div @click="handleEdit('gender')" class="btn-storeChange mx-5px">
                                        變更
                                    </div>
                                </span>
                                <span v-show="showEdit_gender">
                                    <select class="form-select" v-model="formData.gender.value">
                                        <option selected value="0">男</option>
                                        <option value="1">女</option>
                                    </select>
                                </span>
                            </div>
                            <div class="user">
                                <header>手機號碼</header>
                                <span>{{ user_list.phone }}
                                </span>
                            </div>
                            <div class="user">
                                <header>出生年月日</header>
                                <span v-show="!showEdit_birth">{{ user_list.birthYear }}年{{ user_list.birthMonth }}月{{
                                    user_list.birthDay }}日
                                    <div @click="handleEdit('birth')" class="btn-storeChange mx-5px">
                                        變更
                                    </div>
                                </span>
                                <span v-show="showEdit_birth">
                                    <label for="year">年：</label>
                                    <!-- <select id="year" v-model="formData.user_birth.value[0]"
                                        @change="updateDays('isLogin')">
                                        <option value="">請選擇</option>
                                        <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
                                    </select> -->
                                    <input type="text" name="year" id="year" @change="updateDays('isLogin')"
                                        @input="onlyNum_id" v-model="formData.user_birth.value[0]" placeholder="請輸入西元年"
                                        style="height: 25px;width: 120px;">

                                    <label for="month">月：</label>
                                    <select id="month" v-model="formData.user_birth.value[1]"
                                        @change="updateDays('isLogin')">
                                        <option value="">請選擇</option>
                                        <option v-for="month in months" :key="month" :value="month">{{ month }}</option>
                                    </select>

                                    <label for="day">日：</label>
                                    <select id="day" v-model="formData.user_birth.value[2]">
                                        <option value="">請選擇</option>
                                        <option v-for="day in days" :key="day" :value="day">{{ day }}</option>
                                    </select>
                                </span>
                            </div>
                            <!-- 密碼設定 -->
                            <div class="user">
                                <header>
                                    密碼設定
                                </header>
                                <span v-show="!showEdit_pwd">{{ user_list.pwd }}
                                    <div @click="handleEdit('pwd')" class="btn-storeChange mx-5px">
                                        變更
                                    </div>
                                </span>
                                <span v-show="showEdit_pwd">
                                    <div class="mb-4px mb-12px position-relative width-500"
                                        v-bind:class="{ 'input-error': formData.user_pwd.errclass }">
                                        <input type="password" class="col-12 form-controller"
                                            v-model="formData.user_pwd.value" placeholder="請輸入舊密碼" maxlength="16" />
                                        <small :class="{ hide: !formData.user_pwd.errclass }" class="error-text">{{
                                            formData.user_pwd.errmsg }}</small>
                                    </div>
                                    <div class="mb-4px mb-12px position-relative width-500"
                                        v-bind:class="{ 'input-error': formData.user_pwd1.errclass }">
                                        <input type="password" class="col-12 form-controller"
                                            v-model="formData.user_pwd1.value" placeholder="請輸入密碼" maxlength="16" />
                                        <small class="tip-text" :class="{ hide: formData.user_pwd1.errclass }">密碼長度限制{{
                                            GetPwdMinLen
                                        }}至16個字元，必須包含至少一個英文字母。</small>
                                        <small class="error-text" :class="{ hide: !formData.user_pwd1.errclass }">{{
                                            formData.user_pwd1.errmsg }}</small>
                                    </div>
                                    <div class="mb-0 position-relative width-500"
                                        v-bind:class="{ 'input-error': formData.user_pwd2.errclass }">
                                        <input type="password" class="col-12 form-controller"
                                            v-model="formData.user_pwd2.value" placeholder="請再次輸入密碼" maxlength="16"
                                            @input="checkPassword" />
                                        <small :class="{ hide: !formData.user_pwd2.errclass }" class="error-text">{{
                                            formData.user_pwd2.errmsg }}</small>
                                    </div>
                                </span>
                            </div>
                            <div class="user">
                                <header>地址</header>
                                <span v-show="!showEdit_address">{{ user_list.addr1 }}&nbsp;{{
                                    user_list.addr2 }}&nbsp;{{ user_list.addr3 }}
                                    <div @click="handleEdit('address')" class="btn-storeChange mx-5px">
                                        變更
                                    </div>
                                </span>
                                <span v-show="showEdit_address">
                                    <span class="addr width-500">
                                        <select v-model="formData.address.value[0]" @change="onChange_add1($event, '')"
                                            class="col-12 col-xl-3 mb-12px">
                                            <option class="d-none" value="null">請選擇縣市</option>
                                            <option v-for="list in this.Countys" :key="list.p_key" :value="list.p_name">
                                                {{ list.p_name }}
                                            </option>
                                        </select>
                                        <select v-model="formData.address.value[1]" class="col-12 col-xl-3 mb-12px">
                                            <option class="d-none" value="null">請選擇鄉鎮市區</option>
                                            <option v-for="list in this.Citys" :key="list.p_key" :value="list.p_name">
                                                {{ list.p_name }}
                                            </option>
                                        </select>
                                        <input type="text" class="col-12 col-xl-6" v-model="formData.address.value[2]"
                                            placeholder="請輸入地址" />
                                    </span>
                                </span>
                            </div>
                            <div class="user" :class="{ 'mb-0': showEdit_email }">
                                <header>電子信箱</header>
                                <span v-show="!showEdit_email">{{ user_list.email == null ? "未填寫" : user_list.email
                                }}<span @click="handleEdit('email')" class="btn-storeChange mx-5px">變更</span></span>
                                <span v-show="showEdit_email">
                                    <div class="position-relative width-500">
                                        <div v-bind:class="{ 'input-error': formData.email.errclass }">
                                            <input type="email" class="col-12 form-controller"
                                                v-model="formData.email.value" @input="checkEmail" placeholder="請輸入電子信箱"
                                                style="padding-right: 40px" />
                                            <small class="error-text" :class="{ hide: !formData.email.errclass }">{{
                                                formData.email.errmsg }}</small>
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div :style="{ display: showEdit_btn ? 'flex' : 'none' }" class="justify-content-center">
                            <button class="border-0 btn-cancel m-3" @click="handleCancel">
                                取消
                            </button>
                            <button class="border-0 btn-primary m-3" @click="handleUpdate">
                                儲存
                            </button>
                        </div>
                    </div>
                    <!--登出 -->
                    <div v-if="activeInfo(8)">
                        <span style="margin-right: 20px;">確認要登出嗎?</span>
                        <input type="button" value="確認並登出" class="logout-btn" @click="handelLogout()">
                    </div>
                </div>
            </div>
            <!-- #endregion -->
        </b-card>
    </div>
</template>

<script>
import UserModels from "../model/UserModels.vue";
import API_ROUTE from "../api/API_ROUTE.vue";
import API_CORE from "../api/API_CORE.vue";
import AppOptions from "../config/AppOptions.vue";
import axios from "../api/axios.js";
import { mapState, mapMutations, mapActions } from 'vuex'
import { zh } from 'vuejs-datepicker/dist/locale'
import ConsumptionHistory from "../components/ConsumptionHistory.vue";
export default {
    //mixins: [API_CORE],
    inject: ["callCartCount"],
    components:{
        ConsumptionHistory,
    },
    computed: {
        ...mapState(['loggedInUser', 'isMobile']),
        mappedItems() {
            return this.items.map(item => {
                var booking_date = new Date(item.BOOKING_DATE + ' ' + item.BOOKING_TIME)
                var now_date = new Date();
                if (item.BOOKING_STATUS === 'OK') {
                    if (now_date > booking_date) {
                        return { ...item, BOOKING_STATUS: '已過期' };
                    }
                    else {
                        return { ...item, BOOKING_STATUS: '即將到來' };
                    }
                } else if (item.BOOKING_STATUS === 'DONE') {
                    return { ...item, BOOKING_STATUS: '已完成' };
                } else if (item.BOOKING_STATUS === 'CANCEL') {
                    return { ...item, BOOKING_STATUS: '已取消' };
                } else {
                    return item;
                }
            });
        },
        loginPwdInputType() {
            return this.isShowLoginPwd ? 'text' : 'password';
        },
        registerPwdInputType() {
            return this.isShowRegisterPwd ? 'text' : 'password';
        },
    },
    created() {
        if (!this.isMobile) this.collapseVisible = true
        let user = this.$cookies.get("user");
        if (user) {
            this.login(user);
            this.form.login.userId = user.userId;

            this.getUserInfo(user.userId);
            this.getGroupClass(user.sno);
            this.GetUserPoint(user.sno);
            this.getAddress();
        }
        const currentYear = new Date().getFullYear();
        for (let year = currentYear; year >= 1900; year--) {
            this.years.push(year + "");
        }

        // 初始化月份選項
        for (let month = 1; month <= 12; month++) {
            this.months.push(this.padZero(month));
        }
    },
    beforeRouteEnter(to, from, next) {
        const routerFrom = from.name;
        next(vm => {
            vm.routerFrom = routerFrom;
        });
    },

    data() {
        return {
            zh: zh,
            isLogin: false,
            memberIconImg: require('../assets/img/mobile/account/memberIcon.png'),
            keep_login: false,
            register_account: '',
            options: [
                {
                    id: 2,
                    name: '預約資訊',
                    active: true
                },
                {
                    id: 3,
                    name: '消費紀錄',
                    active: false
                },
                {
                    id: 6,
                    name: '帳戶詳細資料',
                    active: false
                },
                {
                    id: 8,
                    name: '登出',
                    active: false
                }
            ],
            selectedItem: null,
            AppOptions,
            form: {
                login: {
                    password: '',
                    userId: ''
                },
                register: {
                    userId: '',
                    password: '',
                    name: '',
                    phone: '',
                    email: '',
                    birth: '',
                    sex: '',
                    birthFormat: '',
                    b_arr: ['', '', '']
                },
            },
            isShowLoginPwd: false,
            isShowRegisterPwd: false,
            user_captcha: '',
            show_bk: true,
            btn_disabled_captcha: false,
            errMsg_captcha: "驗證碼錯誤，請輸入正確手機驗證碼。",
            errclass_captcha: false,
            text_captcha: "發送驗證碼",
            show: true,
            errclass_id: false,
            count: "",
            showCaptcha: false,
            comfirmCheckDisable: true,
            user_list: {},
            showEdit_btn: false,
            showEdit_username: false,
            showEdit_pwd: false,
            showEdit_birth: false,
            showEdit_gender: false,
            showEdit_address: false,
            showEdit_email: false,
            years: [],
            months: [],
            days: [],
            formData: {
                user_name: {
                    value: "",
                    errclass: false,
                    isEdit: false,
                },
                gender: {
                    value: "",
                    errclass: false,
                    isEdit: false,
                },
                user_pwd: {
                    value: "",
                    errclass: false,
                    errmsg: "舊密碼錯誤，請重新輸入。",
                    isEdit: false,
                },
                user_pwd1: {
                    value: "",
                    errclass: false,
                    errmsg: "必須包含至少一個英文字母。",
                },
                user_pwd2: {
                    value: "",
                    errclass: false,
                    errmsg: "該密碼與您輸入的確認密碼不一致",
                },
                user_birth: {
                    value: [null, null, ""],
                    isEdit: false,
                },
                address: {
                    value: [null, null, ""],
                    isEdit: false,
                },
                email: {
                    value: "",
                    errclass: false,
                    errmsg: "電子信箱格式錯誤，請輸入正確的電子信箱。",
                    disableButton: true,
                    isEdit: false,
                },
            },
            GetPwdMinLen: "8",
            Countys: [{}],
            Citys: [{}],
            fields: [{
                key: 'BOOKING_DATE', label: '日期/時間'
            }, {
                key: 'AMOUNT', label: '金額'
            }, {
                key: 'NUM', label: '數量'
            }, {
                key: 'STORE_NAME', label: '門市'
            }, {
                key: 'CLASS_NAME', label: '課程'
            }, {
                key: 'BOOKING_STATUS', label: '預約狀態'
            }, {
                key: 'CANCEL', label: '取消'
            }],
            items: [],
            userPoint: '0',
            collapseVisible: false,
            isSamePhone: false,
            routerFrom: ''
        }
    },
    watch: {
        isSamePhone() {
            if (this.isSamePhone == true) this.form.register.userId = this.form.register.phone
        },
    },
    methods: {
        ...mapMutations(['setLoggedInUser', 'clearLoggedInUser']),
        ...mapActions(['login', 'logout']),
        //取得使用者詳細資訊
        getUserInfo(userId) {


            // 監聽年份和月份的變化，更新日期選項
            this.updateDays('isLogin');
            let loader = this.$loading.show();
            axios.post("User/GetUserInfo", { USER_ACCOUNT: userId }).then(resp => {
                loader.hide()
                if (resp.succ) {
                    this.user_list = resp.result;
                    this.formData.gender.value = resp.result.gender;
                    this.formData.user_birth.value[0] = resp.result.birthYear;
                    this.formData.user_birth.value[1] = resp.result.birthMonth;
                    this.updateDays('isLogin');
                    this.formData.user_birth.value[2] = resp.result.birthDay;
                    this.formData.address.value[0] = resp.result.addr1;
                    this.formData.address.value[1] = resp.result.addr2;
                    this.formData.address.value[2] = resp.result.addr3;
                    this.formData.user_name.value = resp.result.user_name;
                    this.formData.email.value = resp.result.email;
                    this.loggedInUser.userName = resp.result.user_name;
                    //alert(resp.result);
                } else {
                    this.$swal(resp.msg);
                    //alert(resp.msg);
                }
            });
        },
        updateDays(type) {
            let daysInMonth
            if (type === 'isLogin') {
                daysInMonth = new Date(this.formData.user_birth.value[0], this.formData.user_birth.value[1], 0).getDate();
            } else {
                daysInMonth = new Date(this.form.register.b_arr[0], this.form.register.b_arr[1], 0).getDate();
            }

            this.days = [];
            for (let day = 1; day <= daysInMonth; day++) {
                this.days.push(this.padZero(day));
            }
        },
        padZero(value) {
            return String(value).padStart(2, '0');
        },
        initialData() {
            this.showEdit_btn = false;
            this.showEdit_username = false;
            this.showEdit_pwd = false;
            this.showEdit_birth = false;
            this.showEdit_gender = false;
            this.showEdit_address = false;
            this.showEdit_email = false;
            this.formData.user_name.isEdit = false;
            this.formData.gender.isEdit = false;
            this.formData.user_pwd.isEdit = false;
            this.formData.address.isEdit = false;
            this.formData.email.isEdit = false;
            this.formData.user_birth.isEdit = false;
        },
        handleGetUser() {
            axios.get("Account/GetUser").then(a => console.log(a));
        },
        getAddress() {
            axios.post("System/SysparamList", { p_group: "City" }).then(resp => {
                if (resp.succ) {
                    this.Countys = resp.result.map(x => {
                        return {
                            p_key: x.p_key,
                            p_name: x.p_name
                        }
                    });
                    this.onChange_add1("", this.formData.address.value[0]);
                    //alert(resp.result)
                } else {
                    this.$swal(resp.msg);
                    //alert(resp.msg);
                }
            });
        },
        handelLogin() {
            //let userName = this.getCookie("loginIn")
            const user = this.form.login
            for (const key in user) {
                if (!user[key] && key != "isRemeber") {
                    this.$swal('錯誤', '請確認必填欄位', 'error')
                    return
                }
            }
            if (this.form.login?.isRemeber == "") {
                this.form.login.isRemeber = null;
            }
            let loader = this.$loading.show();
            axios.post("Account/LoginCookie", this.form.login).then(resp => {
                loader.hide();
                if (resp.succ) {
                    //alert("登錄成功");
                    this.$swal("登錄成功");

                    this.setCookie(resp.result, AppOptions.param.cookieTime);
                    this.login(resp.result);
                    let user = this.$cookies.get("user");
                    if (user) {
                        this.login(user);
                        this.form.login.userId = user.userId;
                        this.getUserInfo(user.userId);
                        this.getAddress();
                        this.getGroupClass(user.sno);
                        this.GetUserPoint(user.sno);
                    }
                    
                    this.callCartCount();
                    if (this.routerFrom == "productDetail") this.$router.push({ name: 'allProduct' })
                    else this.$router.go(-1)
                } else {
                    this.$swal(resp.msg);
                    //alert(resp.msg);
                }
            });

            //清空input
            for (const key in user) {
                user[key] = ""
            }
            //this.setCookie(user, AppOptions.param.cookieTime);
            //this.login(user);
        },
        handelLogout() {
            axios.post("Account/Logout").then(resp => {
                if (resp.succ) {
                    this.$swal(resp.result);
                } else {
                    this.$swal(resp.msg);
                }
            })
            this.initialData();
            this.clearCookie()
            this.logout();            
            this.callCartCount();
        },
        getPhoneCaptcha() {
            const register = this.form.register
            console.log(register);
            register.birth = `${register.b_arr[0]}/${register.b_arr[1]}/${register.b_arr[2]}`
            if (register.birth) {
                // let dateFormat = register.birth?.toLocaleDateString('zh-TW');
                register.birthFormat = register.birth?.replaceAll("/", "-");
            }
            for (const key in register) {
                if (!register[key]) {
                    this.$swal('錯誤', '請確認必填欄位', 'error')
                    return false;
                }
            }

            this.showCaptcha = true
            return true;
        },
        register() {
            //先不開啟簡訊驗證  先用帳號註冊
            var chk = this.getPhoneCaptcha();
            this.showCaptcha = false;
            if (!chk) {
                return;
            }
            const register = this.form.register
            let vm = this;
            let userId = this.form.register.userId;
            let pwd = this.form.register.password;
            let loader = this.$loading.show();
            axios.post("User/register", this.form.register).then(resp => {
                loader.hide();
                if (resp.succ) {
                    this.$swal(resp.result);

                    vm.form.login.userId = userId;
                    vm.form.login.password = pwd;
                    vm.handelLogin();
                } else {
                    vm.$swal(resp.msg);
                }
            });

            //清空input
            for (const key in register) {
                register[key] = ""
            }
        },
        getCookie(cookieName) {
            var name = cookieName + "=";
            var decodedCookie = document.cookie;
            var cookies = decodedCookie.split(';');
            for (var i = 0; i < cookies.length; i++) {
                var cookie = cookies[i].trim();
                if (cookie.indexOf(name) === 0) {
                    var letcookie = cookie.substring(name.length, cookie.length);
                    var decodeCookie = decodeURIComponent(decodeURIComponent(letcookie));
                    return JSON.parse(decodeCookie);
                }
            }
            return '';
        },
        //判斷目前tab
        activeInfo(type) {
            const isActive = this.options.find(x => x.id === type)
            return isActive.active
        },
        //切換tab
        selectItem(id) {
            for (const iterator of this.options) {
                if (iterator.id === id) iterator.active = true
                else iterator.active = false
            }
            this.selectedItem = id;
            this.handleCancel()
        },
        setCookie(user, exdays) {
            var exdate = 0;
            if (user.isRemeber) {
                exdate = 60 * 60 * 24 * exdays;
            }
            this.$cookies.set("user", user, exdate);
        },
        clearCookie() {
            this.$cookies.remove("user");
        },
        //請求驗證碼
        getCaptcha() {
            if (this.errclass_id == false) {
                alert("請求驗證碼：getCaptcha() click!!!"); //暫時
                // this.reqModel_pwd.mobile = this.user_id;
                // this.callApi(
                //     API_ROUTE.Account.Forgetpwd,
                //     this.reqModel_pwd,
                //     this.respModel_pwd
                // );
            }
        },
        //限制只能輸入數字
        onlyNum_id() {
            const year = new Date().getFullYear();
            if (this.form.register.phone != '') {
                this.form.register.phone = this.form.register.phone.replace(/[^0-9.]/g, "");
            }
            if (this.formData.user_birth.value[0] != '') {
                if (this.formData.user_birth.value[0] > year) this.formData.user_birth.value[0] = `${year}`;
                this.form.register.b_arr[0] = this.form.register.b_arr[0].replace(/[^0-9.]/g, "");
            }
            if (this.form.register.b_arr[0] != '') {
                if (this.form.register.b_arr[0] > year) this.form.register.b_arr[0] = `${year}`;
                this.form.register.b_arr[0] = this.form.register.b_arr[0].replace(/[^0-9.]/g, "");
            }
        },
        err_captcha() {
            this.show_bk = this.user_captcha.length == 6 ? false : true;
        },
        handleEdit(item) {
            this.showEdit_btn = true;
            switch (item) {
                case "userName":
                    this.showEdit_username = !this.showEdit_username
                    this.formData.user_name.isEdit = true;
                    break;
                case "gender":
                    this.showEdit_gender = !this.showEdit_gender
                    this.formData.gender.isEdit = true;
                    break;
                case "pwd":
                    this.showEdit_pwd = !this.showEdit_pwd;
                    this.formData.user_pwd.isEdit = true;
                    break;
                case "address":
                    this.showEdit_address = !this.showEdit_address;
                    this.formData.address.isEdit = true;
                    break;
                case "email":
                    this.showEdit_email = !this.showEdit_email;
                    this.formData.email.isEdit = true;
                    break;
                case "birth":
                    this.showEdit_birth = !this.showEdit_birth;
                    this.formData.user_birth.isEdit = true;
                    break;
            }
        },
        checkPassword() {
            this.formData.user_pwd2.errclass =
                this.formData.user_pwd1.value === this.formData.user_pwd2.value
                    ? false
                    : true;
        },
        //判斷格式是否正確
        checkEmail() {
            if (
                !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
                    this.formData.email.value
                )
            ) {
                this.formData.email.errclass = true;
            } else {
                this.formData.email.errclass = false;
            }

            if (this.formData.email.value.length == 0)
                this.formData.email.errclass = false;
        },
        checkUpdate() {
            let bolErr = true;
            this.checkPassword();
            this.checkEmail();
            if (this.formData.email.isEdit && this.formData.email.errclass) {
                bolErr = false;
            }
            // if(this.formData.gender.isEdit && this.formData.gender.value)
            // {
            //     this.formData.gender.errclass=true;
            //     bolErr=false;
            // }
            // if(this.formData.user_name.isEdit&& this.formData.user_name.value!="")
            // {
            //     this.formData.user_name.errclass=true;
            //     bolErr=false;
            // }
            if (this.formData.user_pwd.isEdit && this.formData.user_pwd2.errclass) {
                bolErr = false;
            }

            return bolErr;
        },
        handleUpdate() {
            if (this.checkUpdate()) {
                let req = { ...this.formData, userId: this.form.login.userId };
                let loader = this.$loading.show();
                axios.post("User/UpdateUserInfo", req).then(resp => {
                    loader.hide()
                    if (resp.succ) {
                        this.$swal({
                            text: resp.result,
                            icon: 'success',
                            button: 'OK',
                        }).then(() => {
                            window.location.reload();
                        });
                    } else {
                        this.$swal(resp.msg);
                        //alert(resp.msg);
                    }
                });
            }


        },
        cancelItem(item) {
            // 在這裡處理取消按鈕的點擊事件
            alert(`取消項目:${item}`)
        },
        cancelGroupClass(item) {
            axios.post("/Group/CancelGroupClassBooking", { Sno_booking: item.SNO_BOOKING }).then(resp => {
                if (resp.succ) {
                    this.$swal(resp.result).then(() => {
                        let snoUser = this.$cookies.get("user").sno;
                        this.getGroupClass(snoUser);
                        this.GetUserPoint(snoUser);
                    });
                } else {
                    this.$swal(resp.msg);
                }
            })
        },
        handleCancel() {
            this.showEdit_btn = false;
            this.showEdit_address = false;
            this.showEdit_birth = false;
            this.showEdit_btn = false;
            this.showEdit_email = false;
            this.showEdit_gender = false;
            this.showEdit_pwd = false;
            this.showEdit_username = false;
        },
        getGroupClass(sno) {
            axios.post("/Group/GetGroupClassBooking", { Sno_user: sno }).then(resp => {
                if (resp.succ) {
                    this.items = resp.result;
                }
            });
        },
        GetUserPoint(sno) {
            axios.post("/User/GetUserPoint", { Sno_user: sno }).then(resp => {
                if (resp.succ) {
                    this.userPoint = resp.result;
                }
            });
        },

        onChange_add1: function (event, name) {
            let temp_name = "";

            if (name === "") {
                temp_name = event.target.value;
                this.formData.address.value[1] = null;
            } else {
                temp_name = name;
            }
            var a = this.Countys.filter((item) => {
                return item.p_name == temp_name;
            });

            let p_key = a[0]?.p_key;
            if (p_key != undefined) {
                axios.post("System/SysparamList", { p_group: p_key }).then(resp => {
                    if (resp.succ) {
                        this.Citys = resp.result.map(x => {
                            return {
                                p_key: x.p_key,
                                p_name: x.p_name
                            }
                        });
                    } else {
                        this.$swal(resp.msg);
                        //alert(resp.msg);
                    }
                });
            }

        },
    },


}
</script>

<style scoped>
* {
    font-size: 16px;
}

#isSamePhone {
    margin-bottom: 0;
    height: auto;
}

#body-bg {
    background-color: #f5f5f5;
    padding: 0px
}

.card {
    margin: 10px;
}

.login {
    text-align: left;
    margin: 0;
    padding: 15px;
}

.login .title,
.register .title {
    color: var(--main-text-color);
    font-size: 18px;
}

.login span {
    padding: 0;
}

.login .row {
    margin: 0;
    padding: 0;
}


.register {
    text-align: left;
    margin: 0;
    padding: 15px;
}

.register span {
    padding: 0;
}

.register .row,
.register .col-6 {
    margin: 0;
    padding: 0;
}


.register-select,
input {
    margin-bottom: 20px;
    height: 40px;
}

div {
    font-size: 12px;
}

a {
    padding: 0;
}

#login-btn {
    background-color: var(--main-text-color);
    border: none;
    color: white;
    padding: 3px;
    margin-bottom: 5px;
}

p {
    padding: 0;
}

#register-btn {
    background-color: var(--main-text-color);
    border: none;
    color: white;
    padding: 3px;
    margin-bottom: 5px;
}

.personal-info {
    text-align: left;
    /* margin: 0; */
    padding: 15px;
    display: flex;
    align-items: center;
    letter-spacing: 0.7px;
}

.personal-info img {
    width: 25%;
}

.personal-info ul {
    margin-top: 15px;
    list-style-type: none;
    padding-left: 0;
}

.personal-info li {
    border-left: none;
    border-right: none;
    padding: 5px;
    color: gray;
}

.personal-info li:not(:last-child) {
    border-bottom: #d9dcdd 1px solid;
}

.logout-btn,
.browse-products-btn {
    background-color: var(--main-text-color);
    border: none;
    color: white;
    padding: 3px;
    margin-bottom: 5px;
}

.login p,
.register p {
    font-size: 12px;
}

/* -------------datetimepicker------------- */

.datetimepicker-box .dateTimeInput {
    position: absolute;
    right: 9px;
    top: 6px;
    height: 25px;
    z-index: 999;
    cursor: pointer;
}

.datetimepicker-box .dropdown-menu {
    border-radius: 12px;
    box-shadow: 0 0.3rem 1rem rgb(0 0 0 / 15%);
}


.vdp-datepicker {
    margin-bottom: 20px;
}

.vdp-datepicker * {
    width: 100% !important;
}

.vdp-datepicker input {
    height: 35px;
}

.input-group {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;
}

.form-select {
    border: 1px solid rgb(118, 118, 118);
    border-radius: 0rem;
    margin-bottom: 20px;
    padding: 0.2rem 2.25rem 0.2rem 0.75rem;
    font-size: 15px;
}


.btn-verify {
    position: absolute;
    top: -1px;
    right: 0px;
    width: 41%;
    height: 38px;
    cursor: pointer;
}

.btn-verify .btn-text {
    top: 12.5px;
    font-size: 13px;
}

.input-verify {
    width: 50%;
}

.input-error .error-text {
    font-size: 19px;
}

.error-text {
    color: red;
    font-weight: 900;
    padding: 4px 0 0 4px;
    letter-spacing: -1px;
    font-size: 10px;
}


.error-text,
.errmsg,
.tip-text {
    font-size: 19px;
}

.hide {
    visibility: hidden;
}

.btn-confirm {
    background: var(--main-text-color) !important;
    color: #ffffff !important;
}

.captcha {
    padding: 0;
}

.captcha label {
    position: relative;
    margin: 18px 0 0 0;
    width: 100%;
}

.input-icon-bk {
    position: absolute;
    top: 3px;
    right: 15px;
    font-size: 20px;
    color: #32a932;
}

.btn-disabled {
    opacity: 0.7 !important;
    pointer-events: auto;
    cursor: not-allowed !important;
}

.input-captcha {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
}

.py-40px {
    padding: 0px !important;
}

.user {
    display: inline-flex;
    margin-bottom: 16px;
}

.btn-storeChange {
    background-color: #ffffff;
    color: #df2059;
    font-weight: normal;
    font-size: 13px;
    border: 2px solid #df2059;
    border-radius: 8px;
    padding: 0 4px;
    font-weight: 600;
    line-height: 16px;
    cursor: pointer;
}

.user header+span {
    font-size: 16px;
    color: #4b4b4b;
    font-weight: 500;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.user header {
    width: 80px;
    text-align: right;
    margin-right: 32px;
    font-size: 16px;
    color: #909090;
}

.tip-text {
    letter-spacing: -0.7px;
    color: #909090;
}

small {
    font-size: .875em !important
}

.pers-title {
    font-size: 20px;
    border-left: 2px solid #df2059;
    padding-left: 10px;
}

.btn-primary {
    width: 150px;
    height: 48px;
    border-radius: 10000000000000px;
    font-weight: 700;
    margin: 0;
    color: #ffffff !important;
    border: none !important;
    background: #df2059 !important;
}

.btn-cancel {
    width: 150px;
    height: 48px;
    border-radius: 10000000000000px;
    font-weight: 700;
    color: #ffffff !important;
    border: none !important;
    background: #9ca8ad !important;
}

.input-with-icon {
    position: relative;
}

.icon-container {
    position: absolute;
    top: 35%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 24px;
}

#keep-login {
    height: 30px;
}

.collope {
    color: var(--bs-link-color);
    ;
    cursor: pointer;
    font-size: 20px !important;
}


@media (min-width: 992px) {
    #body-bg {
        padding: 30px
    }

    .py-40px {
        padding: 40px !important;
    }

    .login,
    .register {
        padding: 30px;
    }

    .logout-btn,
    .browse-products-btn {
        width: 150px;
    }

    div {
        font-size: 16px;
    }

    .login .title,
    .register .title {
        font-size: 18px;
    }

    .login p,
    .register p {
        font-size: 16px;
    }

    .input-icon-bk {
        position: absolute;
        top: 3px;
        right: 16px;
    }

    .personal-info {
        padding: 25px;
    }

    .personal-info img {
        width: 30%;
    }

}
</style>