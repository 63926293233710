<template>
    <div>
        <div class="offcanvas offcanvas-end" :class="showCart ? 'show' : ''" tabindex="-1"
            :style="{ visibility: showCart ? 'visible' : 'hidden', width: isMobile ? '90vw' : '40vw', backgroundColor: 'rgba(255, 255, 255, .95)' }">
            <div class="offcanvas-body" style="margin-top: 0;">
                <div class="cart-popup-inner inner-padding">
                    <div class="cart-popup-title text-center">
                        <h4 class="uppercase">購物車</h4>
                        <div class="is-divider" style="margin-left: auto;margin-right: auto;"></div>
                    </div>
                    <section class="cart-wrap">
                        <section class="list">
                            <figure v-for="item in cart_list" :key="item.id" class="cart-item">
                                <img :src="item.image" class="cart-img" />

                                <div class="d-flex align-items-center justify-content-between w-100">
                                    <div class="d-flex flex-column justify-content-between w-100">
                                        <div class="name m-text-28">{{ item.name }}</div>
                                        <div class="tag-wrap m-text-24">
                                            <!-- <div v-for="tag in item.position" :key="tag.id">
                                                {{ tag.tag_name }}
                                            </div> -->
                                        </div>

                                        <div class="m-text-24 mb-2">${{ item.amount }}</div>

                                        <InputNumber :qty.sync="item.qty" :min="1" :resize="false"
                                            @AddClick="handleUpdate(item)" @MinusClick="handleUpdate(item)">
                                        </InputNumber>
                                    </div>

                                    <div class="amoun m-text-35">${{ item.amount * item.qty }}</div>
                                </div>

                                <img class="icon-delete" @click="handleDelete(item)" />
                            </figure>
                        </section>
                        <input type="button" value="結帳" id="ShoppingCartCheck" style="background-color: #af2473;"
                            @click="ShoppingCart()">
                    </section>
                </div>
            </div>
        </div>
        <div class="modal-backdrop fade" :class="showCart ? 'show' : ''" @click="toggleShoppingCart"
            :style="{ visibility: showCart ? 'visible' : 'hidden' }">
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import InputNumber from "../components/common/InputNumber.vue"
import axios from "../api/axios.js";
export default {
    inject: ["callCartCount"],
    components: { InputNumber },
    data() {
        return {
            cart_list: []
        }
    },
    created() {
        // axios.post("/Course/GetShoppingCart", { uid : this.$cookies.get("user").sno}).then(resp => {
        // if (resp.succ) {
        //     this.$swal("已成功加入購物車!");
        //     }
        // });
    },
    computed: {
        ...mapGetters(["showCart"]),
        ...mapState(['isMobile']),
    },
    watch: {
        showCart(val) {
            if (val) {
                this.getCartList();
            }
        },
    },
    methods: {
        ...mapActions(["toggleShoppingCart"]),
        ...mapMutations(['SHOPPING_CART_BTN_COUNT']),
        handleDelete(item) {
            // this.callApi(
            //     API_ROUTE.ShoppingCart.ShoppingCartDelete,
            //     { ROW_ID: item.row_id },
            //     {}
            // );
            axios.post("/Course/ShoppingCartDelete", { ROW_ID: item.row_id }).then(resp => {
                if (resp.succ) {
                    this.getCartList();
                    this.callCartCount();
                }
            });
        },
        handleUpdate(item) {
            // this.callApi(
            //     API_ROUTE.ShoppingCart.ShoppingCartUpdate,
            //     { ROW_ID: item.row_id, QTY: item.qty },
            //     {}
            // );
            axios.post("/Course/ShoppingCartUpdate", { ROW_ID: item.row_id, QTY: item.qty }).then(resp => {
                if (resp.succ) {
                }
            });
        },
        ShoppingCart() {
            if (this.cart_list.length != 0) {
                //關閉購物車，觸發vuex重新取資料
                this.toggleShoppingCart();
                this.SHOPPING_CART_BTN_COUNT()
                if (this.$router.path !== "/shopping-cart/pay") this.$router.push({ name: 'shoppingCartPay' });
            } else {
                this.$swal
                    .fire({
                        // title: title,
                        text: "購物車無內容可以結帳!",
                        confirmButtonText: "確認",
                        allowOutsideClick: true,
                    })
            }
        },
        getCartList() {
            const user = this.$cookies.get("user")
            if (user) {
                axios.post("/Course/GetShoppingCart", { uid: user.sno }).then(resp => {
                    if (resp.succ) {
                        this.cart_list = resp.result.cart_list;
                    }
                });
            } else {
                this.$swal("請先登入!")
                this.toggleShoppingCart(false)
            }

        },
    }

}
</script>

<style scoped>
.offcanvas-header {
    padding: 30px 0 0 20px;
}

.offcanvas-body {
    padding: 0;
    margin-top: 30px;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100%;
    height: 100vh;
}

.inner-padding {
    padding: 20px;
}

.text-center {
    text-align: center;
}

.is-divider {
    margin-left: auto;
    margin-right: auto;
    height: 3px;
    display: block;
    background-color: rgba(0, 0, 0, .1);
    margin: 1em 0 1em;
    width: 100%;
    max-width: 30px;
}

ul.product_list_widget li img {
    top: 10px;
    position: absolute;
    left: 0;
    width: 60px;
    height: 60px;
    margin-bottom: 5px;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: 50% 50%;
    object-position: 50% 50%;
}

ul {
    margin-bottom: 1.3em;
    margin-top: 0;
    padding: 0;
}

.widget_shopping_cart ul.product_list_widget li {
    padding-right: 30px;
}

ul.product_list_widget li {
    list-style: none;
    padding: 10px 0 5px 75px;
    min-height: 80px;
    position: relative;
    overflow: hidden;
    vertical-align: top;
    line-height: 1.33;
    margin-bottom: 0.6em;
}

ul.product_list_widget li .quantity {
    display: block;
    margin-top: 3px;
    font-size: .85em;
    opacity: .6;
    margin-right: 1em;
    white-space: nowrap;
    vertical-align: top;
}

ul.product_list_widget li a.remove {
    position: absolute;
    right: 0px;
    z-index: 9;
}

a.remove,
a.icon-remove {
    display: block;
    width: 24px;
    height: 24px;
    font-size: 15px !important;
    line-height: 19px !important;
    border-radius: 100%;
    color: #ccc;
    font-weight: bold;
    text-align: center;
    border: 2px solid currentColor;
}

p.total {
    text-align: center;
    padding: 10px 0;
    border-top: 1px solid #ececec;
    border-bottom: 2px solid #ececec;
    margin-bottom: 0.5em;
}

p {
    margin-top: 0;
}

span.amount {
    white-space: nowrap;
    color: #111;
    font-weight: bold;
}

#ShoppingCartCheck {
    width: 100%;
    margin: 0.5em 0 0;
    color: #fff;
    border-color: rgba(0, 0, 0, .05);
    padding: 5px;
}

.icon-delete {
    position: absolute;
    right: 10px;
    top: 10px;
}

.cart-item {
    border-bottom: 2px solid #e9ecef;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
}

.cart-img {
    width: 100px;
    height: 100px;
    margin-right: 10px;
}
</style>