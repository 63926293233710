import axios from 'axios';
import AppOptions from "../config/AppOptions.vue";
import Vue from "vue";

const instance = axios.create({
  baseURL: AppOptions.param.apiUrl, // 根據你的 API 地址設置
  withCredentials: true,
});
instance.interceptors.response.use(function (response) {
  // 在发送请求之前做些什么
  // if(!response.data?.succ){
  //   alert("test " + response.data.msg); 
  //   return;
  // }
  return response.data;
}, function (error) {
  // 对请求错误做些什么
  let errResp  = error.response;
  if(errResp.request.responseURL.indexOf("/Account/Login")>0)
  {
    Vue.$cookies.remove("user");
    window.location = "/sign-in";
  }
  return Promise.reject(error);
});



export default instance;