<script>
const api_path = {
  Account: {
    login: "/api/USER/Account/Login",
    checklogin: "/api/USER/Account/CheckLogin",
    mobile: "/api/USER/Account/Mobile",
    verifyMobile: "/api/USER/Account/verifyMobile",
    email: "/api/USER/Account/Email",
    verifyEmail: "/api/USER/Account/VerifyEmail",
    register: "/api/USER/Account/Register",
    Register_data: "/api/USER/Account/Register_data",
    Register_City: "/api/USER/Account/Register_City",
    GetPwdMinLen: "/api/USER/Account/GetPwdMinLen",
    Identity: "/api/USER/Account/Identity",
    Forgetpwd: "/api/USER/Account/Forgetpwd",
    Verify_Forgetpwd: "/api/USER/Account/Verify_Forgetpwd",
    Setpwd: "/api/USER/Account/Setpwd",
    GetStoreList: "/api/USER/Account/GetStoreList",
    GetImgPath: "/api/USER/Account/GetImgPath",
    OtherLogin: "/api/USER/Account/otherLogin",
    BindOther: "/api/USER/Account/Bind_Community",
    UploadSign: "/api/USER/Account/UploadSign",
  },
  Home: {
    MemberStroeInfo: "/api/main/Home/MemberStroeInfo",
    MyReserveCourse: "/api/main/Home/MyReserveCourse",
    GetEMPPortfolio: "/api/main/home/GetEMPProtfolio",
  },
  System: {
    sysparam: "/api/SYS/System/Sysparam",
    SysparamList: "/api/SYS/System/SysparamList",
    OptionsDate: "/api/SYS/System/GetOptionsDate",
    CreateWebApiLog: "/api/SYS/System/CreateWebApiLog",
    ECMenu2: "/api/SYS/System/ECMenu2",
  },
  Course: {
    courseSearch: "/api/main/Course/CourseSearch",
    getFinishCourse: "/api/main/Course/GetFinishCourse",
    GetCommodityCourse: "/api/main/Course/GetCommodityCourse",
    GetCommodityCourseDetail: "/api/main/Course/GetCommodityCourseDetail",
    TransCourse: "/api/main/Course/TransCourse",
    CheckCourse: "/api/main/Course/CheckCourse",
    GetCommodityCourse2: "/api/main/Course/GetCommodityCourse2",
    GetCommodityCourseDetail2: "/api/main/Course/GetCommodityCourseDetail2",
  },
  Commodity: {
    GetCommodity: "/api/main/Commodity/GetCommodity",
    GetCommodityDetail: "/api/main/Commodity/GetCommodityDetail",
    GetCommodity2: "/api/main/Commodity/GetCommodity2",
    GetCommodityDetail2: "/api/main/Commodity/GetCommodityDetail2",
  },
  Store: {
    GetStoreList: "/api/main/Store/GetStoreList",
    GetPointPlanList: "/api/main/Store/GetPointPlanList",
    UpdateUserPoint: "/api/main/Store/UpdateUserPoint",
    GetPointInfo: "/api/main/Store/GetPointInfo",
    GetStoreInfo: "/api/main/Store/GetStoreInfo",
    GetStoreInfo2: "/api/main/Store/GetStoreInfo2",
    GetStoreList2: "/api/main/Store/GetStoreList2",
  },
  ConsumptionHistory: {
    GetTransHistoryList: "/api/main/TransHistory/GetTransHistoryList",
  },
  Booking: {
    GetBooking: "/api/Booking/GetBooking",
    //團課預約
    GetGroupClassBooking: "/api/Booking/GetGroupClassBooking",
    //取得時間
    //GetBookingTime: "/api/Booking/GetBookingTime",
    GetBookingTime2: "/api/Booking/GetBookingTime2",
    //取得使用者資訊
    GetUser: "/api/Booking/GetUser",
    //取得使用者課程資訊
    GetUserClass: "/api/Booking/GetUserClass",
    //預約調整調整11
    BookingEdit: "/api/Booking/BookingEdit",
    BookingClean: "/api/Booking/BookingClean",
    BookingCreate: "/api/Booking/BookingCreate",
    //取得專業人員清單
    GetBookingEmp: "/api/Booking/GetBookingEmp",
    //取得排班專業人員清單
    GetClassEmp: "/api/Booking/GetClassEmp",
  },
  Shop: {
    GetBannerList: "/api/main/Shop/GetBannerList",
    GetBannerDetail: "/api/main/Shop/GetBannerDetail",
    GetBannerList2: "/api/main/Shop/GetBannerList2",
  },
  ShoppingCart: {
    ShoppingCartCreate: "/api/EC/ShoppingCart/ShoppingCartCreate",
    ShoppingCartDelete: "/api/EC/ShoppingCart/ShoppingCartDelete",
    ShoppingCartUpdate: "/api/EC/ShoppingCart/ShoppingCartUpdate",
    GetShoppingCart: "/api/EC/ShoppingCart/GetShoppingCart",
    GetShoppingCartCount: "/api/EC/ShoppingCart/GetShoppingCartAddCount",
    GetShoppingCartSuccess: "/api/EC/ShoppingCart/GetShoppingCartSuccess",
  },
  User: {
    GetUserPoint: "/api/main/User/GetUserPoint",
    GetUserInfo: "/api/main/User/GetUserInfo",
    UpdateUserInfo: "/api/main/User/UpdateUserInfo",
    SendEmail: "/api/main/User/SendEmail",
    VerifyEmail: "/api/main/User/VerifyEmail",
    InsertUserEmail: "/api/main/User/InsertUserEmail",
    CheckAccountData: "/api/USER/Account/CheckAccountData",
    GetUserLevel: "/api/main/User/GetUserLevel",
  },
  Trans: {
    TransCreate: "/api/EC/Trans/TransCreate",
    GetRetryTransID: "/api/EC/Trans/GetRetryTransID",
  },
  ESAFE: {
    GetClass: "/api/EC/Esafe/GetClass",
  },
  Disc: {
    RedeemDisc: "/api/main/Disc/RedeemDisc",
    RedeemDiscForShop: "/api/main/Disc/RedeemDiscForShop",
    GetUserDiscList: "/api/main/Disc/GetUserDiscList",
  },
  Message: {
    GetMessageList: "/api/EC/Message/GetMessageList",
    UpdateReadStatus: "/api/EC/Message/UpdateReadStatus",
    GetMessageAddCount: "/api/EC/Message/GetMessageAddCount",
  },
  Version: {
    Get: "/api/Util/Version/GetEC",
  },
  Consent: {
    GetConsent: "/api/Util/ConsentUtil/GetConsent",
  },
  //租賃商品
  RentalGoods: {
    GetRentalGoodsCommodity: "/api/main/RentalGoods/GetRentalGoodsCommodity",
    GetRentalGoodsDetail2: "/api/main/RentalGoods/GetRentalGoodsDetail2",
    GetRentalGoodsType: "/api/main/RentalGoods/GetRentalGoodsType",
  },
  GroupClass:{
    GetGroupClass: "/api/main/GroupClass/GetGroupClass",
    GetGroupClassDetail2: "/api/main/GroupClass/GetGroupClassDetail2",
    VerifyGroupClassNumberPeople: "/api/main/GroupClass/VerifyGroupClassNumberPeople",
  },

};

export default api_path;
</script>
