<script>
const sidebarMenu = [
  {
    text: "首頁",
    pathName: 'home',
    show: true
  },
  {
    text: "線上預約",
    pathName: 'onlineAppointment',
    show: true
  }, {
    text: "線上預約",
    pathName: 'appointmentClass',
    show: false
  }, {
    text: "線上預約",
    pathName: 'appointmentClassPay',
    show: false
  }, {
    text: "線上預約",
    pathName: 'appointmentClassCheckOut',
    show: false
  },
  {
    text: "全部商品",
    pathName: 'allProduct',
    show: true
  },
  {
    text: "全部商品",
    pathName: 'productDetail',
    show: false
  },
  {
    text: "最新消息",
    pathName: 'news',
    show: true
  },
  {
    text: "最新消息",
    pathName: 'newsDetail',
    show: false
  },
  {
    text: "最新消息",
    pathName: 'shareByUser',
    show: false
  },
  {
    text: "史萊姆教學",
    pathName: 'course',
    show: true
  },
  {
    text: "常見問題",
    pathName: 'commonProblem',
    show: true
  },
  {
    text: "星球據點",
    pathName: 'allPlace',
    show: true
  },
  {
    text: "關於我們",
    pathName: 'aboutUs',
    show: true
  },
  {
    text: "登入",
    pathName: 'signIn',
    show: true
  },
  {
    text: "購物車",
    pathName: 'shoppingCart',
    show: false
  },
  {
    text: "結帳",
    pathName: 'shoppingCartPay',
    show: false
  },
  {
    text: "結帳",
    pathName: 'shoppingCartPaySuccess',
    show: false
  },
  {
    text: "忘記密碼",
    pathName: 'forgetPwd',
    show: false
  },
  {
    text: "重設密碼",
    pathName: 'setPwd',
    show: false
  },
  {
    text: "退換貨政策",
    pathName: 'returnPolicy',
    show: false
  },
  {
    text: "隱私權政策",
    pathName: 'privacyPolicy',
    show: false
  },
  {
    text: "會員制度",
    pathName: 'memberPolicy',
    show: false
  },
  {
    text: "404",
    pathName: 'error404',
    show: false
  },
];

export default sidebarMenu;
</script>
