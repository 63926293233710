<template>
    <swiper ref="classBannerSwiper" :options="classPicSwiperOption">
        <swiper-slide v-for="i in classPic_list" :key="i.id">
            <img :src="i.url" />
        </swiper-slide>
    </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import { mapState } from 'vuex'
export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    computed: {
        ...mapState({
            isMobile: state => state.isMobile,
        }),
    },
    data() {
        return {
            classPic_list: [
                {
                    id: 1,
                    url: require("../../assets/img/mobile/home/(P)BG-04(P01).png"),
                },
                {
                    id: 2,
                    url: require("../../assets/img/mobile/home/(P)BG-04(P02).png"),
                },
                {
                    id: 3,
                    url: require("../../assets/img/mobile/home/(P)BG-04(P03).png"),
                },
            ],
            classPicSwiperOption: {},
        }
    },
    created() {
        if (this.isMobile) this.classPicSwiperOption = {
            loop: true,
            slidesPerView: 2,
            spaceBetween: -30,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            autoplay: {
                // delay: 5 * 1000,
                disableOnInteraction: false,
            },
        }
        else this.classPicSwiperOption = {
            loop: true,
            slidesPerView: 3,
            spaceBetween: 30,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            autoplay: {
                disableOnInteraction: false,
            },
        }
    }
}
</script>

<style scoped>
.swiper-container {
    width: 100vw;
    height: 80vh;
}

.swiper-slide {
    text-align: center;
}

.swiper-slide img {
    width: 40vw;
}

@media (min-width: 992px) {
    .swiper-slide img {
        width: 20vw;
    }

    .swiper-container {
        width: 61.5vw;
    }
}
</style>