<template>
  <div>
    <notifications group="custom-style" position="bottom center" :speed="500" />
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import BASE_REQ from "../api/BASE_REQ";
import BASE_RESP from "../api/BASE_RESP";
import VueCookies from "vue-cookies";
import UserModels from "../model/UserModels.vue";
import AppOptions from "../config/AppOptions.vue";
import API_ROUTE from "../api/API_ROUTE.vue";

var baseUrl = AppOptions.param.apiUrl;

export default {
  created: function() {
    Vue.use(VueAxios, axios);
    Vue.use(VueCookies);
  },
  methods: {
    callApi: function(routePath, reqDataModel, page, funSucc, apiMode = 0) {
      //檢查版本, 強制更新
      //this.ApiCheckWebVer();

      var reqModel = BASE_REQ.data();
      reqModel.data = reqDataModel;

      var respModel = BASE_RESP.data();
      if (page != null) {
        if (page.currentPage != null) {
          reqModel.currentPage = page.currentPage;
        }
      }

      var user = this.getUserCookie();
      var contentType = "application/json";
      if (apiMode == AppOptions.param.apiMode.UploadFile) {
        reqModel = reqDataModel;
        contentType = "multipart/form-data";
      }

      var token = "", user_sno = "123";

      if (user != null) {
        token = user.acc_token;
        reqModel.header.uid = user.sno_user;
        if (apiMode == AppOptions.param.apiMode.UploadFile) {
          reqModel.append("header.schema_no", user.sno_store);
          reqModel.append("header.uid", user_sno);
        }
        user_sno = user.sno_user+"";
      }

      axios({
        method: "post",
        url: baseUrl + routePath,
        data: reqModel,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": contentType,
        },
      })
        .then((response) => {
          try {
            respModel = response.data;
            if (respModel.stat.succ) {
              if (funSucc) {
                funSucc(respModel);
              } else {
                this.apiSuccess(routePath, respModel);
              }
            } else {
              //this.showApiMsg('custom-style', 'error',respModel.stat.err_msg);
              this.apiError(routePath, respModel);
            }
          } catch (ex) {
            console.log(ex);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            var user = this.getUserCookie();
            var refModel = BASE_REQ.data();
            if (user == null) {
              Vue.$cookies.remove("user");
              this.goToLoginPage();
            }
            refModel.data = {
              refresh: user.ref_token,
              sno_user: user.sno_user.toString(),
            };
            axios({
              method: "post",
              url: baseUrl + "/api/USER/Account/NewToken",
              data: refModel,
            })
              .then((response) => {
                var resp = response.data;
                if (resp.stat.succ) {
                  var cookieModel = UserModels.data();
                  var user = this.getUserCookie();
                  var cookieTime = 0;

                  cookieModel.acc_token = resp.data.acc_token;
                  cookieModel.ref_token = resp.data.ref_token;
                  cookieModel.sno_user = resp.data.sno_user.toString();
                  cookieModel.user_name = resp.data.user_name;
                  cookieModel.entertain = resp.data.entertain;
                  cookieModel.mobile = resp.data.mobile;
                  cookieModel.full_mobile = response.data.full_mobile;
                  if (user != null) {
                    cookieModel.remeberFlag = user.remeberFlag;
                    cookieTime = AppOptions.param.cookieTime;
                  }

                  this.setCookie(cookieModel, cookieTime);

                  this.retryApi(routePath, reqModel, respModel);
                } else {
                  Vue.$cookies.remove("user");
                  this.goToLoginPage();
                }
              })
              .catch((ex) => {
                console.log(ex);
              });
          } else {
            this.apiError(routePath, respModel);
            console.log(error);
          }
        });
    },
    showApiMsg(text = "", title = "") {
      this.$swal
        .fire({
          title: title,
          text: text,
          confirmButtonText: "確認",
          allowOutsideClick: true,
        })
        .then((result) => {
          result;
        });
    },
    showSwalForInput: function(text = "", title = "", tag = "") {
      this.$swal
        .fire({
          title: title,
          input: "text",
          inputPlaceholder: text,
          showCancelButton: true,
          confirmButtonText: "確認",
          cancelButtonText: "取消",
          allowOutsideClick: false,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.swalForInputConfirm(result.value, tag);
          }
        });
    },
    retryApi: function(routePath, reqModel, respModel, funSucc) {
      var user = this.getUserCookie();
      var token = user.acc_token;
      axios({
        method: "post",
        url: baseUrl + routePath,
        data: reqModel,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          respModel = response.data;
          if (respModel.stat.succ) {
            if (funSucc) {
              funSucc(respModel);
            } else {
              this.apiSuccess(routePath, respModel);
            }
          } else {
            //this.showApiMsg('custom-style', 'error',respModel.stat.err_msg);
            this.apiError(routePath, respModel);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            Vue.$cookies.remove("user");
            this.goToLoginPage();
          }
        });
    },
    getUserCookie: function() {
      return Vue.$cookies.get("user");
    },
    setCookie(user, exdays) {
      var exdate = 0;
      if (user.remeberFlag) {
        exdate = 60 * 60 * 24 * exdays;
      }

      Vue.$cookies.set("user", user, exdate);
    },
    goToLoginPage: function() {
      if (this.$router.name != "R.1.0.0") {
        this.$router.push({ name: "R.1.0.0" });
      }
    },
    //檢查版本, 強制更新
    ApiCheckWebVer: function() {
      axios({
        method: "get",
        url: baseUrl + API_ROUTE.Version.Get,
      }).then((resp) => {
        try {
          let v = resp.data.v || "default";
          let lv = localStorage.getItem("vers") || v;
          localStorage.setItem("vers", v);
          if (v !== lv) {
            window.location.reload(); // For new version, simply reload on any get
          }
        } catch {
          console.log("get version error!");
        }
      });
    },
    //取得專案模式
    async getProjectMode() {
      let mode = "1";
      let reqModel = BASE_REQ.data();
      reqModel.data = {
        p_key: "Project_Mode",
      };
      await axios({
        method: "post",
        url: baseUrl + API_ROUTE.System.sysparam,
        data: reqModel,
      }).then((resp) => {
        try {
          if (resp.data.stat.succ) {
            mode = resp.data.data.p_value;
          }
        } catch {
          console.log("get Project_Mode error!");
        }
      });
      return mode;
    },
    //取得參數檔圖片路徑
    async GetImgPath(key) {
      let imaPath = "";
      let reqModel = BASE_REQ.data();
      reqModel.data = {
        p_key: key,
      };
      await axios({
        method: "post",
        url: baseUrl + API_ROUTE.Account.GetImgPath,
        data: reqModel,
      }).then((resp) => {
        try {
          if (resp.data.stat.succ) {
            imaPath = resp.data.data.img_path;
          }
        } catch {
          console.log("get GetImgPath error!");
        }
      });
      return imaPath;
    },
  },

  // swalNotification(swalType) {
  //     var btnClass = (swalType == 'error') ? 'danger' : swalType;
  // 		btnClass = (swalType == 'question') ? 'primary' : btnClass;
  // 	this.$swal({
  // 		title: '尚未註冊',
  // 		text: '查無資料，請重新註冊',
  // 		type: 'primary',
  // 		showCancelButton: false,
  // 		buttonsStyling: false,
  // 		confirmButtonText: '註冊',
  // 		cancelButtonText: 'Cancel',
  // 		confirmButtonClass: 'btn me-5px btn-'+ btnClass +'',
  // 		cancelButtonClass: 'btn btn-default',
  // 		})
  // 	},
};

export var ApiBase = {
  typeUser: "user",
  ReGetToken: async function() {
    var user = Vue.$cookies.get(this.typeUser);
    if (!user) {
      window.location.href = ".";
      return;
    }

    try {
      var reqModel = { data: {} };
      reqModel.data.refresh = user.ref_token;
      if (this.typeUser == "ADM_USER") {
        reqModel.data.sno_emp = user.sno_emp;
      } else {
        reqModel.data.sno_user = user.sno_user;
      }

      var tokenResp = await axios({
        method: "post",
        url: baseUrl + "/api/USER/Account/NewToken",
        data: reqModel,
      });

      if (tokenResp.data.stat.succ) {
        var cookieModel = {};
        cookieModel.acc_token = tokenResp.data.data.acc_token;
        cookieModel.ref_token = tokenResp.data.data.ref_token;
        if (this.typeUser == "ADM_USER") {
          cookieModel.sno_emp = tokenResp.data.data.sno_emp;
        } else {
          cookieModel.sno_user = tokenResp.data.data.sno_user;
          cookieModel.user_name = tokenResp.data.data.user_name;
          cookieModel.entertain = tokenResp.data.data.entertain;
          cookieModel.mobile = tokenResp.data.data.mobile;
          cookieModel.full_mobile = tokenResp.data.full_mobile;
        }

        var cookieTime = AppOptions.param.cookieTime;
        Vue.$cookies.set(this.typeUser, cookieModel, cookieTime);

        //return await this.callApi(routePath, reqDataModel);
      } else {
        //Vue.$cookies.remove(this.typeUser);
        //window.location.href = ".";
        throw "";
      }
    } catch (error) {
      Vue.$cookies.remove(this.typeUser);
      window.location.href = ".";
      throw "";
    }
  },
  callApi2: async function(routePath, reqDataModel) {
    var user = Vue.$cookies.get(this.typeUser);
    if (!user) {
      window.location.href = ".";
      return;
    }
    var token = user.acc_token;
    let resp = null;
    try {
      resp = await axios({
        method: "post",
        url: baseUrl + routePath,
        data: reqDataModel,
        headers: { Authorization: `Bearer ${token}` },
      }).then((a) => a.data);

      return resp;
    } catch (errMsg) {
      if (errMsg.response?.status !== 401) {
        console.error(routePath, reqDataModel, errMsg);
        throw errMsg;
      } else {
        try {
          this.ReGetToken();
          //讀完token重跑API
          return await this.callApi2(routePath, reqDataModel);
        } catch (error) {
          Vue.$cookies.remove(this.typeUser);
          window.location.href = ".";
          throw error;
        }
      }
    }
  },
  callApi: async function(routePath, reqDataModel, page = null) {
    var user = Vue.$cookies.get(this.typeUser);
    // if (!user) {
    //   window.location.href = ".";
    //   //this.$router.push({ name: "R.1.0.0" });
    //   return;
    // }

    let token = "";
    let reqModel = BASE_REQ.data();
    if (user != null) {
      token = user.acc_token;

      if (this.typeUser == "ADM_USER") {
        reqModel.header.uid = user.sno_emp + "";
      } else {
        reqModel.header.uid = user.sno_user;
      }
    }
    reqModel.data = reqDataModel;

    if (page != null) {
      if (page.currentPage != null) {
        reqModel.currentPage = page.currentPage;
      }
    }

    let resp = null;
    try {
      resp = await axios({
        method: "post",
        url: baseUrl + routePath,
        data: reqModel,
        headers: { Authorization: `Bearer ${token}` },
      }).then((a) => a.data);

      return resp;
    } catch (errMsg) {
      //已讀取過tokenResp 讀直接跳出
      if (tokenResp) throw errMsg;

      if (errMsg.response?.status === 401) {
        //驗証問題重新讀取新的token 再重新呼叫
        //reqModel = BASE_REQ.data();
        reqModel.data = {};
        reqModel.data.refresh = user.ref_token;
        if (this.typeUser == "ADM_USER") {
          reqModel.data.sno_emp = user.sno_emp;
        } else {
          reqModel.data.sno_user = user.sno_user;
        }

        var tokenResp = await axios({
          method: "post",
          url: baseUrl + "/api/USER/Account/NewToken",
          data: reqModel,
        });

        if (tokenResp.data.stat.succ) {
          var cookieModel = {};
          cookieModel.acc_token = tokenResp.data.data.acc_token;
          cookieModel.ref_token = tokenResp.data.data.ref_token;
          if (this.typeUser == "ADM_USER") {
            cookieModel.sno_emp = tokenResp.data.data.sno_emp;
          } else {
            cookieModel.sno_user = tokenResp.data.data.sno_user;
            cookieModel.user_name = tokenResp.data.data.user_name;
            cookieModel.entertain = tokenResp.data.data.entertain;
            cookieModel.mobile = tokenResp.data.data.mobile;
            cookieModel.full_mobile = tokenResp.data.full_mobile;
          }

          var cookieTime = AppOptions.param.cookieTime;
          Vue.$cookies.set(this.typeUser, cookieModel, cookieTime);

          return await this.callApi(routePath, reqDataModel);
          //return resp;
        } else {
          Vue.$cookies.remove(this.typeUser);
          window.location.href = ".";
        }
      } else {
        console.error(routePath, reqDataModel, errMsg);
        throw errMsg;
      }
    }
  },
};
</script>
