var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-wrapper"},[_c('div',{staticClass:"row row-large row-divided"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"content col-12"},[_c('div',{staticClass:"img-group"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.Img1),expression:"Img1"}],attrs:{"alt":""}}),_vm._m(2)]),_c('div',{staticClass:"img-group"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.Img2),expression:"Img2"}],attrs:{"alt":""}})]),_vm._m(3),_c('div',{staticClass:"img-group"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.Img3),expression:"Img3"}],attrs:{"alt":""}})]),_vm._m(4),_c('div',{staticClass:"img-group"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.Img4),expression:"Img4"}],attrs:{"alt":""}}),_vm._m(5),_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.Img5),expression:"Img5"}],attrs:{"alt":""}}),_vm._m(6),_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.Img6),expression:"Img6"}],attrs:{"alt":""}})]),_vm._m(7),_c('div',{staticClass:"img-group"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.Img7),expression:"Img7"}],attrs:{"alt":""}})]),_vm._m(8),_c('div',{staticClass:"img-group"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.Img8),expression:"Img8"}],attrs:{"alt":""}})]),_vm._m(9),_c('div',{staticClass:"img-group"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.Img9),expression:"Img9"}],attrs:{"alt":""}})]),_vm._m(10)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"large-12 col"},[_c('div',{staticClass:"entry-header"},[_c('h1',{staticClass:"entry-title"},[_vm._v("【粽夏YA!】DIY史萊姆 粽粽怪獸+冰心粽 包出夏日的趣味!"),_c('br')]),_c('h1',{staticClass:"entry-title"},[_vm._v("≡活動期間┊6/9-6/25≡")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"post-time"},[_c('span',[_c('img',{attrs:{"src":require("../../../assets/img/mobile/news/time.png"),"alt":""}}),_vm._v("2023-06-01")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("端午節到GAGA monster包史萊姆粽子啦"),_c('br'),_vm._v(" ➡ #粽粽怪獸 + 浸涼冰心 #冰心粽 雙重滿足~"),_c('br'),_vm._v(" ➡ 上課即贈6月主題活動粽夏YA! #限定徽章")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" 魔法藥水是個非常神奇的魔法，也是製作史萊姆時一個很重要的步驟喔！"),_c('br'),_vm._v(" 只要加魔法藥水進去怪獸膠水裡面後，用魔法棒攪攪攪~~~"),_c('br'),_vm._v(" 就能夠變出史萊姆了!!!　魔法藥水也俗稱：史萊姆成型水"),_c('br'),_vm._v(" 那這次的粽粽怪獸是GAGA特調的比例與手感~等你們來櫃上體驗唷!!! ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("再來想當小小藝術家就是這個時候！超多顏色可以調配＞＜"),_c('br'),_vm._v(" 小小藝術家準備將史萊姆上色啦💛🧡一次加兩種不同顏色才有趣~")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("將史萊姆挖呀挖呀挖~像麻糬般捏呀捏呀捏~ "),_c('br'),_vm._v(" 史萊姆怪獸就會黏在你手上啦~~~(快逃) ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" 將史萊姆怪獸裹上滿滿的餡料，變身 #粽粽怪獸 ! "),_c('br'),_vm._v(" 被灑滿餡料的粽粽怪獸，手感摸起來好扎實呀~ ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"text-align":"center","font-size":"16px"}},[_c('p',[_c('b',[_vm._v("來體驗不一樣端午節? GAGA monster 要讓大家來包史萊姆粽子囉!")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" 透明史萊姆+粽粽怪獸 "),_c('br'),_vm._v(" 變身沁涼Q彈的 冰心粽 😍 "),_c('br'),_vm._v(" 小小一個冰心粽拿在手上好可愛!!! "),_c('br'),_vm._v(" 還可以當小裝飾品的史萊姆小點心 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("另外，這是GAGA意外的發現? 把冰心粽拿去冷凍!!!"),_c('br'),_vm._v(" 沒想到~冷凍過後的史萊姆冰心粽竟然結晶了!!!"),_c('br'),_vm._v(" 看起來就是個藝術品呢!!!超級漂亮😍 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("6月份主題活動粽夏YA! "),_c('br'),_vm._v(" 滿滿的系列活動等你們來GAGA monster玩啦❤️ "),_c('br'),_vm._v(" 史萊姆無限變化，準備釋放孩子無限創造力 ")])
}]

export { render, staticRenderFns }