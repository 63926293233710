<style lang="scss" scoped>
.form-element-section {
  margin-bottom: 24px;
  position: relative;
}
.form-element-group .form-element-section {
  margin-bottom: 8px;
}
.form-element-header {
  line-height: 16.8px;
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: bold;
}
.input-header {
  color: #6f6f6f;
}
.input-remark {
  width: auto;
  margin-left: 5px;
  color: #a9a9a9;
}
.input-err-msg {
  color: var(--ft-error);
}

@media (max-width: 1200px) {
  .form-element-section {
    header,
    input,
    select,
    textarea {
      zoom: 1.1;
    }
  }
}
</style>

<template>
  <div>
    <section class="form-element-section" :style="SectionStyle">
      <header class="form-element-header">
        <span style="color:red" :class="[{ hide: !required }]">*</span>
        <span class="input-header">{{ HeaderText }}</span>
        <span class="input-remark" v-show="RemarkText !== undefined">{{
          RemarkText
        }}</span>
        <span
          class="input-err-msg float-end"
          :class="[{ hide: !ShowErrorMessage }]"
          >{{ ErrorMessage }}</span
        >
      </header>
      <slot name="custom-content"></slot>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    HeaderText: String,
    RemarkText: String,
    ErrorMessage: String,
    ShowErrorMessage: { type: Boolean, default: false },
    SectionStyle: String,
    required: { type: Boolean, default: false },
  },
};
</script>
