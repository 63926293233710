<template>
  <div>
    <Header :cartCount="cartCount" />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Header from "./components/header/header.vue";
import Footer from "./components/footer/footer.vue";
import axios from "./api/axios.js";

export default {
  name: "App",
  components: {
    Header,
    Footer
  },  
  provide() {
    return {
      callCartCount: this.getShoppingCartCount,
    };
  },
  data() {
    return {
      cartCount: 0,
    }
  },
  async created() {

    this.getShoppingCartCount();
    // this.ApiCheckWebVer();
    // setInterval(() => {
    //   this.ApiCheckWebVer();
    // }, 1000 * 60 * 30);
  },

  watch: {
    $route(to, from) {
      this.getShoppingCartCount();
    }
  },
  methods: {
    // ApiCheckWebVer() {
    //   axios.get('/System/Version/GetEC').then((resp) => {
    //     try {
    //       let v = resp.data.v || "default";
    //       let lv = localStorage.getItem("vers") || v;
    //       localStorage.setItem("vers", v);
    //       if (v !== lv) {
    //         window.location.reload(); // For new version, simply reload on any get
    //       }
    //     } catch {
    //       console.log("get version error!");
    //     }
    //   });
    // }
    getShoppingCartCount() {
      let user = this.$cookies.get("user");
      if (user) {
        axios.post("/Course/GetShoppingCartAddCount", { uid: user.sno }).then(resp => {
          if (resp.succ) {
            this.cartCount = resp.result;
          }
        });
      } else {
        this.cartCount = 0;
      }
    },
  }
};
</script>

<style >
body {
  background-size: cover;
  margin: 0;
}

* {
  font-family: Font YuPearl-Light
}
</style>