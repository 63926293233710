<template>
    <div class="topLoginOkMobile">
        <!-- #region banner -->
        <div class="banner-area">
            <img v-lazy="BG01Img" alt="">
            <!-- <router-link :to="{ name: 'appointmentClass' }"> -->
            <input class="btn-banner-appointment" type="button" value="立即預約" @click="toAppointment"
                :style="{ 'position': 'absolute', 'top': top + '%', 'left': left + '%' }" />
            <!-- </router-link> -->
        </div>
        <!-- #endregion -->
        <div class="about-area">
            <img v-lazy="BG02Img" alt="來史萊姆怪獸星球探險吧">
            <!-- <h5 class="about-title-appointment">來史萊姆怪獸星球探險吧!</h5> -->
            <p id="about-info-appointment">在史萊姆怪獸星球中，我們提供現場體驗手作課程，親切又專業的老師，引導小朋友們透過五感啟動創造與想像力，展開一場星際間與怪獸共同探險的旅程……</p>
            <div class="about-footer-appointment">
                <img v-lazy="bannerAImg" alt="創造一個前所未有的感官冒險旅程" id="about-footer-appointment-img">
                <!-- <span id="about-footer-appointment-text">創造一個前所未有的感官冒險旅程</span> -->
            </div>
        </div>
        <div class="class-area">
            <img v-lazy="BG03Img" alt="史萊姆課程">
            <!-- <h5 class="class-title-appointment">史萊姆課程</h5> -->
            <div class="class-info-appointment">
                <span id="class-info-appointment-text">小小探險家，今天想要來點什麼史萊姆怪獸？課程可當日上課任選喔~</span><br />
                <div style=" margin: 10px 0;">
                    <span class="class-info-appointment-list">⭐<span
                            style="color:var( --main-text-color);;">「慕斯怪獸」</span>獨家魔法慕斯遇上史萊姆</span><br />
                    <span class="class-info-appointment-list">⭐<span
                            style="color:var( --main-text-color);;">「凍凍怪獸」</span>又Q又Juice</span><br />
                    <span class="class-info-appointment-list">⭐<span
                            style="color:var( --main-text-color);;">「水水怪獸」</span>軟軟涼涼的觸感</span><br />
                    <span class="class-info-appointment-list">⭐<span
                            style="color:var( --main-text-color);;">「奶油怪獸」</span>牽絲滑嫩觸感</span><br />
                    <span class="class-info-appointment-list">⭐<span
                            style="color:var( --main-text-color);;">「綿綿怪獸」</span>綿密手感超好玩</span><br />
                    <span class="class-info-appointment-list">⭐<span
                            style="color:var( --main-text-color);;">「軟軟怪獸」</span>麻糬般軟嫩手感</span>
                </div>
                <span>給小朋友最歡樂的體驗，一起來玩史萊姆吧！</span>
            </div>
            <div class="class-footer-appointment">
                <img v-lazy="bannerBImg" alt="立即預約 手作史萊姆課程" id="class-footer-appointment-img">
                <!-- <span id="class-footer-appointment-text">立即預約 手作史萊姆課程</span> -->
            </div>
        </div>
        <div class="appointment-area">
            <!-- <img v-lazy="BG04Img" alt=""> -->
            <div class="appointment-class-group row" id="appointment">
                <div v-for="(item, index) in class_list" class="appointment-class-list col-md-3 col-lg-3 col-xl-3 col-12"
                    :key="index">
                    <div class="appointment-class-card">
                        <img v-lazy=item.picture[0].url alt="" class="appointment-class">
                        <div class="appointment-class-info">
                            <span style="font-size: 13px;color:var( --main-text-color);white-space: nowrap;">{{ item.address
                            }}</span><br>
                            <span style="font-size: 13px;color: #f57850;white-space: nowrap;">{{ item.time }}</span><br>
                            <span v-if="item.tele" style="font-size: 13px;">{{ item.tele }}<br></span>
                            <span style="font-size: 12px;">{{ item.info }}</span>
                            <div class="text-center"> <router-link
                                    :to="{ name: 'appointmentClass', params: { name: item.name, id: item.id } }">
                                    <input class="btn-banner-appointment btn-appointment" type="button" value="立即預約" />
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="appointment-class-warning">
                <ul>
                    <li v-for="info in warning_list">{{ info }}</li>
                </ul>
            </div>
        </div>

    </div>
</template>

<script>
import { mapState } from 'vuex'
import storeDetail from '../data/storeDetail.vue'
export default {
    computed: {
        ...mapState({
            isMobile: state => state.isMobile,
        }),
    },
    data() {
        return {
            storeDetail: {},
            class_list: [],
            warning_list: [
                "課程適合年齡3歲以上兒童。",
                "課程中7歲以下兒童請家長全程陪同參與，切勿獨留兒童參與課程。",
                "開始上課後5分鐘未到場，將直接取消當日課程參與權利，若是還要上課請重新預約下次課程。",
                "單獨上課之兒童，請於下課後自行安排接送，恕不負託管責任。",
                "個人物品、錢財及貴重物品，請收拾好並妥善保管，本店恕不負保管責任。",
                "預約一名僅限一名顧客使用，恕不接受兩人共用一份材料。",
                "課程中如有突發狀況，老師擁有終止課程權利。",
            ],
            BG01Img: '',
            BG02Img: '',
            BG03Img: '',
            bannerAImg: '',
            bannerBImg: '',
            BG04Img: '',
            top: 0,
            left: 0,
        }
    },
    methods: {
        toAppointment() {
            const target = document.getElementsByClassName("appointment-area")
            target[0].scrollIntoView();
        }
    },
    created() {
        if (this.isMobile) {
            this.BG01Img = require('../assets/img/mobile/onlineAppointment/(2)(p)(p)BG-01.jpg'),
                this.BG02Img = require('../assets/img/mobile/onlineAppointment/(2)(p)(p)BG-02.png'),
                this.BG03Img = require('../assets/img/mobile/onlineAppointment/(2)(p)(p)BG-03.png'),
                this.bannerAImg = require('../assets/img/mobile/onlineAppointment/(2)(P)bannerA.png'),
                this.bannerBImg = require('../assets/img/mobile/onlineAppointment/(2)(P)bannerB.png'),
                this.BG04Img = require('../assets/img/mobile/onlineAppointment/(2)(p)(p)BG-042.png'),
                this.top = 75,
                this.left = 55
        } else {
            this.BG01Img = require('../assets/img/pc/onlineAppointment/(2)BG-01.jpg'),
                this.BG02Img = require('../assets/img/pc/onlineAppointment/(2)BG-02.png'),
                this.BG03Img = require('../assets/img/pc/onlineAppointment/(2)BG-03.png'),
                this.bannerAImg = require('../assets/img/pc/onlineAppointment/(2)bannerA.png'),
                this.bannerBImg = require('../assets/img/pc/onlineAppointment/(2)bannerB.png'),
                this.BG04Img = require('../assets/img/pc/onlineAppointment/(2)BG-04.png'),
                this.top = 76,
                this.left = 74
        }
        this.class_list = storeDetail
    }
}
</script>

<style scoped>
.topLoginOkMobile {
    width: 100%;
}

.banner-area {
    position: relative;
}

.banner-area img {
    width: 100%;
}

.btn-banner-appointment {
    background-color: #ffcf00;
    border: none;
    color: black;
    padding: 4px 45px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    border-radius: 50px;
}

.about-area {
    position: relative;
}

.about-area img {
    width: 100%;
}

.about-title-appointment {
    position: absolute;
    top: 51%;
    left: 50%;
    transform: translateX(-50%);
    width: 230px;
    font-weight: bolder;
    white-space: nowrap;
}

#about-info-appointment {
    position: absolute;
    font-size: 13px;
    top: 60%;
    left: 19%;
    width: 250px;
    text-indent: 2em;
    line-height: 2;
}

.about-footer-appointment {
    position: relative;
}

#about-footer-appointment-img {
    position: absolute;
    top: -34px;
    left: 50%;
    transform: translateX(-50%);
}

#about-footer-appointment-text {
    color: #ffffff;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 250px;
}

.class-area {
    position: relative;
}

.class-area img {
    width: 100%;
}

.class-title-appointment {
    position: absolute;
    top: 60%;
    left: 40%;
    width: auto;
    font-weight: bolder;
}

.class-info-appointment {
    position: absolute;
    font-size: 13px;
    top: 66%;
    left: 54%;
    width: 270px;
    line-height: 2;
    transform: translateX(-50%);
}


.class-info-appointment-list {
    white-space: nowrap;
}

.class-footer-appointment {
    position: relative;
}

#class-footer-appointment-img {
    position: absolute;
    top: -8px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
}

#class-footer-appointment-text {
    color: #ffffff;
    position: absolute;
    left: 25%;
    width: 250px;
    top: 24px;
    font-size: 20px;
    z-index: 3;
}

.appointment-area {
    /* position: relative; */
    background-color: antiquewhite;
    padding: 25% 0 5%;
}

.appointment-area img {
    width: 100%;
    position: relative;
}

.appointment-class-group {
    text-align: center;
    display: flex;
    justify-content: center;
    margin: 0 0 10%;
}

.btn-appointment {
    margin-top: 20px;
}

.appointment-class-list {
    margin-bottom: 1em;
    width: 250px;
    height: 400px;
    background-color: white;
    border-radius: 20px;
    padding: 0;
}

.appointment-class-group img {
    width: 100%;
    border-radius: 20px;
}

.appointment-class-info {
    font-size: 12px;
    width: 250px;
    text-align: left;
    line-height: 1rem;
    padding: 15px 20px;
}

.appointment-class-warning {
    margin-top: 10px;
}

ul {
    width: 100%;
    padding-left: 10%;
}

li {
    font-size: 16px;
    transform: scale(0.9);
    margin-bottom: 5px;
}

.appointment-class-card {
    padding: 0;
}

@media screen and (max-width: 380px)and (max-height:700px) {
    .appointment-class-list {
        margin-bottom: 5em;
    }

    li {
        font-size: 16px;
    }
}


@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appointment-class-group {
        display: block;
    }

    #about-info-appointment {
        font-size: 22px;
        top: 58%;
        left: 25%;
        width: 400px;
        line-height: 2.5;
    }

    .btn-appointment {
        margin-left: 15px;
    }

    .class-info-appointment {
        font-size: 22px;
        top: 67%;
        left: 50%;
        width: 450px;
    }

    .appointment-class-list {
        margin-bottom: 9em;
        width: 400px;
        height: 700px;
        border-radius: 30px;
    }

    .appointment-class-group {
        top: 19%;
        left: 50%;
        display: flex;
        flex-wrap: nowrap;
        transform: translateX(-50%);
    }

    .appointment-class-info {
        font-size: 14px;
        width: 250px;
        padding: 15px 20px;
    }

    .appointment-class-info {
        text-align: left;
        line-height: 2rem;
    }

    .btn-appointment {
        margin-left: 100px;
    }
}
</style>

<style scoped>
@media (min-width: 992px) {
    .btn-banner-appointment {
        padding: 5px 60px;
    }

    #about-info-appointment {
        font-size: 18px;
        top: 33%;
        left: 18%;
        width: 400px;
        line-height: 3rem;
    }

    .class-info-appointment {
        font-size: 18px;
        top: 34%;
        left: 31%;
        width: 370px;
        line-height: 2.5rem;
    }

    #class-footer-appointment-img {
        top: -70px;
    }

    .appointment-class-group {
        display: flex;
        justify-content: center;
    }

    .appointment-class-group img {
        width: 100%;
    }

    .appointment-class-info {
        font-size: 14px;
        width: 300px;
        padding: 15px 20px;
    }

    .appointment-class-warning {
        top: 65%;
        left: 16%;
    }

    li {
        font-size: 20px;
        white-space: nowrap;
        transform: none;
        margin-bottom: 15px;
    }

    .btn-appointment {
        margin-top: 30px;
    }

    .appointment-class-list {
        width: 300px;
        height: 475px;
        padding: 0;
        margin: 20px;
    }
}
</style>