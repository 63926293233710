<template>
    <div>
        <b-tabs pills card>
            <b-tab title="星課程據點" active>
                <div class="place-body">
                    <div class="place-group row">
                        <div v-for="(item, index) in storeDetail" class="place-list col-lg-4 col-xl-4 col-12" :key="index">
                            <div class="place-card">
                                <img v-lazy=item.picture[0].url alt="" class="place">
                                <div class="place-info">
                                    <span style="font-size: 13px;color:var( --main-text-color);white-space: nowrap;">{{
                                        item.address
                                    }}</span><br>
                                    <span style="font-size: 13px;color: #f57850;white-space: nowrap;">{{ item.time
                                    }}</span><br>
                                    <span v-if="item.tele" style="font-size: 13px;">{{ item.tele }}<br></span>
                                    <span style="font-size: 12px;">{{ item.info }}</span>
                                    <router-link
                                        :to="{ name: 'appointmentClass', params: { name: item.name, id: item.id } }">
                                        <input class="btn-banner-appointment btn-appointment" type="button" value="立即預約" />
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="class-warning">
                        <ul>
                            <li v-for="info in warning_list">{{ info }}</li>
                        </ul>
                    </div>
                </div>
            </b-tab>
            <b-tab title="星商店據點">
                <div class="place-body">
                <!-- <h3 style="padding-top: 45px;">上課地點</h3>
                    <div class="place-group row">
                        <div v-for="(item, index) in storeDetail.filter(x => x.type == 'normal' && x.classPlaceImg)"
                            class="product-list col-lg-4 col-xl-4 col-12" :key="index">
                            <div class="place-card">
                                <img v-lazy=item.classPlaceImg alt="" class="class">
                                <div class="place-info">
                                    <span style="font-size: 13px;">{{ item.name }}<br></span>
                                    <span v-if="item.tele" style="font-size: 13px;">{{ item.tele }}<br></span>
                                    <span style="font-size: 13px;color:var( --main-text-color);white-space: nowrap;">{{
                                        item.address
                                    }}</span>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    
                <h3 style="padding-top: 45px;">商品販售</h3>
                    <div class="place-group row">
                        <div v-for="(item, index) in place_product_list"
                            class="product-list-2 col-lg-4 col-xl-4 col-md-4 col-12" :key="index">
                            <div class="place-card-2">
                                <img v-lazy=item.classPlaceImg alt="" class="class">
                                <div class="place-info">
                                    <span style="font-size: 13px;">{{ item.name }}<br></span>
                                    <span v-if="item.tele" style="font-size: 13px;">{{ item.tele }}<br></span>
                                    <span style="font-size: 13px;color:var( --main-text-color);white-space: nowrap;">{{
                                        item.address
                                    }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import storeDetail from '../data/storeDetail.vue'
export default {
    data() {
        return {
            BG04Img: require('../assets/img/mobile/place/bg.png'),
            warning_list: [
                "課程適合年齡3歲以上兒童。",
                "課程中7歲以下兒童請家長全程陪同參與，切勿獨留兒童參與課程。",
                "開始上課後5分鐘未到場，將直接取消當日課程參與權利，若是還要上課請重新預約下次課程。",
                "單獨上課之兒童，請於下課後自行安排接送，恕不負託管責任。",
                "個人物品、錢財及貴重物品，請收拾好並妥善保管，本店恕不負保管責任。",
                "預約一名僅限一名顧客使用，恕不接受兩人共用一份材料。",
                "課程中如有突發狀況，老師擁有終止課程權利。",
            ],
            storeDetail: {},
            place_product_list: [
                // {
                //     name: '誠品信義店5F',
                //     tele: '(02) 7756-3935 #11',
                //     address: '台北市信義區松高路11號5F',
                //     type: '台北',
                //     classPlaceImg: require('../assets/img/mobile/place/product/誠品信義店.jpg')
                // },
                {
                    name: '信義A8店5F',
                    tele: '',
                    address: '台北市信義區松高路12號5F',
                    type: '台北',
                    classPlaceImg: require('../assets/img/mobile/place/product/信義A8店.jpg')
                },
                {
                    name: 'Sogo天母店7F',
                    tele: '(02) 7756-3934',
                    address: '台北市士林區中山北路六段77號7F',
                    type: '台北',
                    classPlaceImg: require('../assets/img/mobile/place/product/Sogo天母店.jpg')
                },
                {
                    name: '新店裕隆城3F',
                    tele: '0902037018',
                    address: '新北市新店區中興路三段70號3樓',
                    type: '台北',
                    classPlaceImg: require('../assets/img/mobile/place/product/新店裕隆誠.jpg')
                },
                {
                    name: '新竹巨城店5F',
                    tele: '(02) 7756 – 3931',
                    address: '新竹市東區中央路229號 5F',
                    type: '新竹',
                    classPlaceImg: require('../assets/img/mobile/place/product/新竹巨城.jpg')
                },
                {
                    name: '台中廣三sogo店8F',
                    tele: '(04) 3700-539',
                    address: '台中市西區台灣大道二段459號8F',
                    type: '台中',
                    classPlaceImg: require('../assets/img/mobile/place/product/台中.jpg')
                },
                {
                    name: '漢神巨蛋店6F',
                    tele: '0902037022',
                    address: '高雄市左營區博愛二路777號6樓',
                    type: '高雄',
                    classPlaceImg: require('../assets/img/mobile/place/product/漢神巨蛋店.jpg')
                },
                {
                    name: '信義威秀店1F',
                    tele: '0983253668',
                    address: '台北市信義區松壽路20號1樓',
                    type: '台北',
                    classPlaceImg:require('../assets/img/mobile/place/product/信義威秀.jpg')
                }
            ]
        }
    },
    created() {
        this.storeDetail = storeDetail.filter(x => x.type == 'normal');
    }
}
</script>
<style scoped>
h3 {
    color: var(--btn-main-hover);
}

.row {
    margin: 0;
    padding: 0;
}

.title {
    text-align: center;
    display: flex;
    align-items: center;
}

.title span {
    color: var(--main-text-color);
    width: 100%;
}

.title img {
    width: 6%;
    margin-right: 5px;
}

.header-wrap img {
    width: 100%;
}

.place-body {
    /* background-color: #F7F0E0; */
    text-align: center;
    background-image: url('../assets/img/mobile/place/bg.png');
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: left top, right bottom, center center;
    background-size: cover;
    position: relative;
    top: 0;
    left: 0;
}

.place-group {
    padding-top: 20px;
    display: flex;
    justify-content: center;
    /* margin-bottom: 20px; */
}

.place-product-group {
    padding-bottom: 15px;
    display: flex;
    justify-content: center;
}

.place-list {
    margin-bottom: 1em;
    width: 250px;
    height: 420px;
    background-color: white;
    border-radius: 20px;
    padding: 0;    
    margin-top: 20px;
}

.product-list {
    margin-bottom: 1em;
    width: 250px;
    height: 250px;
    background-color: white;
    border-radius: 20px;
    padding: 0;
}

.place-group img {
    width: 250px;
    height: 250px;
    border-radius: 8%;
}

.place-info {
    font-size: 12px;
    width: 250px;
    text-align: left;
    line-height: 1rem;
    padding: 15px 20px;
}

.place-product-info {
    font-size: 16px;
    width: 250px;
    text-align: left;
    line-height: 1rem;
    padding: 15px 20px;
}

.btn-banner-appointment {
    background-color: #ffcf00;
    border: none;
    color: black;
    padding: 4px 45px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    border-radius: 50px;
}

.btn-appointment {
    margin-top: 20px;
    margin-left: 35px;
}

.class-warning {
    text-align: left;
    padding: 0 0 40px 40px;
}

ul {
    width: 80%;
    padding-left: 0rem;
}

li {
    font-size: 12px;
    margin-bottom: 5px;
}

.place-card-2 {
    margin-bottom: 1em;
    width: 250px;
    height: 350px;
    background-color: white;
    border-radius: 20px;
    padding: 0;
}

.product-list-2 {
    display: flex;
    justify-content: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .place-list {
        width: 51% !important;
        height: auto !important;
    }

    .place-info {
        line-height: 2rem;
        width: auto
    }

    .btn-appointment {
        margin-left: 100px;
    }
}
</style>

<style scoped>
@media (min-width: 992px) {

    .appointment-class-group {
        top: 19%;
        display: flex;
        flex-wrap: nowrap;
    }

    .appointment-class-group img {
        width: 100%;
    }

    .appointment-class-info {
        font-size: 14px;
        width: 300px;
        padding: 15px 20px;
    }

    .appointment-class-warning {
        top: 65%;
        left: 16%;
    }

    .btn-appointment {
        margin-top: 30px;
        margin-left: 35px;
    }

    .appointment-class-list {
        width: 300px;
        height: 475px;
        padding: 0;
        margin: 30px;
    }

    .place-body {
        background-image: url('../assets/img/pc/place/bg.png');
        padding: 80px;
    }

    .place-group {
        justify-content: space-evenly;
    }

    .class-warning {
        padding: 40px 0 40px 200px;
    }

    li {
        font-size: 16px;
    }
}
</style>