<template>
    <div class="page-wrapper">
        <div class="row row-large row-divided">
            <div class="large-12 col" style="padding-left:0;padding-right:0;">
                <div class="entry-header">
                    <h1 class="entry-title">用愛手作史萊姆-獻給媽咪史萊姆 ⌜康乃馨🌹⌟
                    </h1>
                    <h1 class="entry-title">限定徽章與隱藏怪獸出沒!!!</h1>
                </div>
            </div>
            <div class="post-time">
                <span><img src="../../../assets/img/mobile/news/time.png" alt="">2023-05-11</span>
            </div>
            <div class="content col-12">
                <div class="img-group">
                    <img v-lazy="Img1" alt="">
                    <p>≣ 活動期間：5/13、5/14 ≣<br>
                        只有兩天，趕快報名卡位唷!
                    </p>
                </div>
                <div style="text-align: center;font-size: 16px;">
                    <p><b>❮康乃馨手作教學❯</b></p>
                </div>
                <p>
                    於活動2天預約報名課程，含史萊姆康乃馨手作教學1朵<br>
                    快拿來送給媽咪吧~~</p>
                <div class="img-group">
                    <img v-lazy="Img2" alt="">
                    <p>❮母親節限定徽章❯<br>
                        活動期間預約上課即贈【GAGA 母親節限定徽章】乙個<br>
                        數量有限，售完為止唷!!!

                    </p>
                    <img v-lazy="Img3" alt="">
                    <p>❮隱藏怪獸出沒❯<br>
                        新怪獸將於母親節期間現身~<br>
                        療癒觸覺與嗅覺和視覺的三重享受❣<br>
                        搶先在這兩天體驗吧!!!!
                    </p>
                    <img v-lazy="Img4" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'newsdetail20230511',
    data() {
        return {
            Img1: require('../../../assets/img/mobile/news/20230511/1.jpg'),
            Img2: require('../../../assets/img/mobile/news/20230511/2.jpg'),
            Img3: require('../../../assets/img/mobile/news/20230511/3.jpg'),
            Img4: require('../../../assets/img/mobile/news/20230511/4.jpg'),
        }
    },
}
</script>