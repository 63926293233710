<script>

var STAT={
			//處理是否成功
			succ: Boolean,
			//處理狀態碼
			code: String,
			//處理狀態訊息
			msg: String,
			//處理狀態異常訊息
			err_msg: String,
			//處理狀態異常細項
			err_msg_d: String
		}

export default {	
	data()
	{
		return {
            stat:STAT,
            data:Object,            
		}
	}
 
}

</script>>