<style lang="scss">
.pagination {
  justify-content: center;
  margin: 20px 0;

  .page-items {
    width: 24px;
    height: 24px;
    border-radius: 99999px;
    color: #6f6f6f;
    font-size: 16px;
    margin: 0 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .page-items.num:hover {
    background: var(--btn-main-disabled);
  }

  .page-items.num.active {
    color: #ffffff;
    background: var(--btn-page);
  }
}

@media screen and (max-width: 1200px) {
  .pagination {
    justify-content: center;
    margin: 40px 0;

    .page-items {
      width: 25px;
      height: 25px;
      border-radius: 99999px;
      color: #6f6f6f;
      font-size: 21px;
      margin: 0 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      img {
        width: 30px;
      }
    }

  }
}
</style>
<template>
  <div>
    <ul class="pagination">
      <li @click="handlePageArrow('first')" class="page-items">
        <img class="img-first-arrow" />
      </li>
      <li @click="handlePageArrow('prev')" class="page-items">
        <img class="img-prev-arrow" />
      </li>
      <li v-for="(item, index) in arr_page" :key="index" class="page-items num"
        :class="{ active: item === currentPage || currentPage === 0 }" @click="handlePageNumber(item)">
        {{ item }}
      </li>
      <li @click="handlePageArrow('next')" class="page-items">
        <img class="img-next-arrow" />
      </li>
      <li @click="handlePageArrow('last')" class="page-items">
        <img class="img-last-arrow" />
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "PaginationElement",
  props: {
    // 總頁數
    totalPage: {
      type: Number,
      required: true,
    },
    // 當前頁數
    currentPage: {
      type: Number,
      required: true,
    },
  },
  created() {
    this.changeList();
  },
  data() {
    return {
      arr_page: [1],
      active: 1,
    };
  },
  watch: {
    totalPage() {
      this.changeList();
    },
    currentPage() {
      this.changeList();
    },
    active(val) {
      this.$emit("change", val);
    },
  },
  methods: {
    handlePageNumber(item) {
      this.active = item;
    },
    handlePageArrow(x) {
      let page = this.active;
      let last = this.arr_page[this.arr_page.length - 1];
      switch (x) {
        case "first":
          page = 1;
          break;
        case "prev":
          page -= 1;
          break;
        case "next":
          page = page + 1 > this.totalPage ? this.totalPage : page + 1;
          break;
        case "last":
          page = last + 1 > this.totalPage ? this.totalPage : last + 1;
          break;
      }
      page = page <= 1 ? 1 : page;
      if (this.arr_page.length < 5) {
        page = page >= last ? last : page;
      }
      this.active = page;
    },
    changeList() {
      this.arr_page = [];
      if (this.currentPage == 0 || this.totalPage == 0) {
        this.arr_page = [1];
        this.active = 1;
      } else {
        let x = parseInt((this.currentPage - 1) / 5) * 5 + 1;
        let y = x + 4 > this.totalPage ? this.totalPage : x + 4;
        for (let i = x; i <= y; i++) this.arr_page.push(i);
      }
    },
  },
};
</script>
