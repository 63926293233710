<template>
    <div>
        <div class="row" style="margin: 0;">
            <div class="col-xl-5 col-12">
                <!-- 大圖 -->
                <swiper ref="picture" :options="swiperOptions">
                    <swiper-slide v-for="i in storeDetail.picture" :key="i.id">
                        <img :src="i.url" class="img-size" />
                    </swiper-slide>
                </swiper>
                <!-- 小圖 -->
                <div v-show="storeDetail.picture?.length > 1" class="thumbnail">
                    <img :src="i.url" v-for="i in storeDetail.picture" :key="i.id" class="img-size-min"
                        @click="handleImage(i)" />
                </div>
            </div>
            <div style="padding: 20px 50px;" class="col-xl-7 col-12">
                <div class="col-xs-5 col-12">
                    <h3>{{ storeDetail.title }}</h3>
                    <span style="color: #f57850;white-space: nowrap;">{{ storeDetail.time }}</span>
                    <br>
                    <span style="font-size: 13px;">{{ storeDetail.info }}立即透過網路預約簡單方便，付款後立即保留座位。定金每位200元將直接抵扣課程費用。</span>
                </div>
                <div class="row" style="margin: 0;">
                    <CustomDateTimePicker :reserve_date="param.reserve_date" :reserve_time="param.reserve_time"
                        :time.sync="form.reserve_time" :timeIsCandidate.sync="form.time_is_candidate"
                        :iRemainingNumber.sync="form.RemainingNumber" :isCandidate.sync="form.is_candidate"
                        :date.sync="form.reserve_date" :SnoGroupClass="storeDetail.bas_id" :availableDates="availableDates">
                    </CustomDateTimePicker>
                </div>
                <div class="row item-row">
                    <div class="item-wrap col-xs-4 col-4">
                        <InputNumber :typeName="'人數:'" :qty.sync="qty" :min="1" :resize="false" @AddClick="handleUpdate()"
                            @MinusClick="handleUpdate()">
                        </InputNumber>
                    </div>
                    <div class="col-xs-6 col-6">
                        <input type="button" value="立即預約" class="reservation-now" @click="reservation" />
                    </div>
                </div>
            </div>
        </div>

        <div class="booking-infoMsg">

            <img :src="infoImg" alt="" v-if="storeDetail.type =='normal'" style="width: 100%;">
            <!-- <img :src="infoImg2" alt="" v-if="storeDetail.type == 'groupClass2'" style="width: 100%;"> -->
            <span>為維護各位貴賓訂位權益，2023/7/17起預訂訂位採預付定金制，每人上課預收定金NT$200。付款成功才完成訂位。</span>
            <ul>
                <li>【訂單資訊】請至「官網－我的帳號－訂單資訊」，可查詢及修改訂單狀態。</li>
                <li>【付款方式】官網預訂訂位付款方式可使用信用卡、ATM轉帳、儲值點數預扣定金。</li>
                <li>【定金折抵】定金將直接抵扣預約該堂課程。</li>
                <li>【取消訂位】請於該堂上課前3小時(含3小時)自行至「官網－我的帳號－訂單資訊取消」進行取消作業。於上課之日起前2小時內取消，定金則全額沒收不得要求退還。取消訂位僅限定官網線上自行操作，客服恕不接受協助取消訂位。
                </li>
                <li>【定金退還方式】定金退還至帳戶點數。恕不直接退款。</li>
                <li>【訂位未到】訂位將保留5分鐘，遲到或未到將取消訂位且不退款。</li>
            </ul>
        </div>
        <div class="booking-warning">
            <ul>
                <!-- <li>每人預收100訂金，可全額扣抵課程金額。</li>
                <li>會員可使用購物金支付。</li>
                <li>於課程48小時前可免費取消，款項依原支付方式退回。</li> -->
                <li>課程適合年齡3歲以上兒童。</li>
                <li>課程中7歲以下兒童請家長全程陪同參與，切勿獨留兒童參與課程。</li>
                <li>開始上課後5分鐘未到場，將直接取消當日課程參與權利，若是還要上課請重新預約下次課程。</li>
                <li>單獨上課之兒童，請於下課後自行安排接送，恕不負託管責任。</li>
                <li>個人物品、錢財及貴重物品，請收拾好並妥善保管，本店恕不負保管責任。</li>
                <li>預約一名僅限一名顧客使用，恕不接受兩人共用一份材料。</li>
                <li>課程中如有突發狀況，老師擁有終止課程權利。</li>
            </ul>
        </div>
        <div style="text-align: center;margin-bottom: 50px;">
            <router-link class="routerLink bannerBtn" :to="{ name: 'onlineAppointment', hash: '#appointment' }">
                <input type="button" value="返回選擇分店" id="gobacktoshops" />
            </router-link>
        </div>
    </div>
</template>

<script>
import CustomDateTimePicker from "../components/common/CustomDateTimePicker.vue";
import InputNumber from "../components/common/InputNumber";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import axios from "../api/axios.js";
import "swiper/css/swiper.css";
import storeDetail from '../data/storeDetail.vue'
import { mapState, mapMutations, mapActions } from 'vuex'
export default {
    components: {
        Swiper,
        SwiperSlide,
        CustomDateTimePicker,
        InputNumber,
    },
    computed: {
        ...mapState(['loggedInUser'])
    },
    data() {
        return {
            storeDetail: {},
            swiperOptions: {
                width: window.innerWidth,
                slidesPerView: 1,
            },
            user_reserve_data: {
                date: this.getNowdate(),
                day: "(五)",
                time: "", //"14:00",
                time_info: "預計1小時",
                time_is_candidate: false,
                is_candidate: false,
                show_error_msg: false,
                address: "台北市中正區新生南路一段14號2F",
                desc: "",
                detail: [],
                course: [],
                show_candidate_dialog: false,
                emp_sno: 0,
            },
            item: 0,
            qty: 1,
            warning_list: [
                "課程適合年齡3歲以上兒童。",
                "課程中7歲以下兒童請家長全程陪同參與，切勿獨留兒童參與課程。",
                "開始上課後10分鐘未到場，將直接取消當日課程參與權利，若是還要上課請重新預約下次課程。",
                "單獨上課之兒童，請於下課後自行安排接送，恕不負託管責任。",
                "個人物品、錢財及貴重物品，請收拾好並妥善保管，本店恕不負保管責任。",
                "預約一名僅限一名顧客使用，恕不接受兩人共用一份材料。",
                "課程中如有突發狀況，老師擁有終止課程權利。",
            ],
            param: {
                reserveList: {},
                reserve_date: [],
                reserve_time: [

                ],
            },
            form: {
                reserve_date: this.getNowdate(),
                reserve_time: "",
                time_is_candidate: false,
                is_candidate: false,
                RemainingNumber: "0"
            },
            infoImg: '',
            // infoImg2: require('../assets/img/mobile/class/groupclass/明細.jpg'),
            current_storeName: '',
            availableDates: []
        }
    },
    created() {
        const storeName = this.$route.params.name
        const groupClassId = this.$route.params.id //團課編號
        this.current_storeName = storeName;
        this.clearBooking();
        this.storeDetail = storeDetail.find(x => x.name === storeName && x.id === groupClassId);
        this.infoImg=this.storeDetail.infoImg;
        let loader = this.$loading.show();
        //取得預約日期時間
        axios.post("/Group/GetBookingDate", { id: this.storeDetail.bas_id }).then(resp => {
            if (resp.succ) {
                // this.param.reserveList = resp.result;
                this.param.reserveList = resp.result?.map(x => {
                    return {
                        ...x,
                        reserve_time: x.reserve_time.map(o => {
                            return {
                                time: o.time,
                                is_candidate: o.is_candidate === "true" ? true : false
                            }
                        })
                    }

                })

                this.availableDates = resp.result.map(a => a.reserve_date);
                this.form.reserve_date = this.availableDates ? this.availableDates[0] : new Date();
                this.getResrveTime();
            }
            loader.hide()
        });

    },
    watch: {
        "form.reserve_date"() {
            this.getResrveTime();
        }
    },
    methods: {
        ...mapMutations(['setbookingDetail', 'clearbookingDetail']),
        ...mapActions(['setBooking', 'clearBooking']),
        getResrveTime() {
            this.param.reserve_time = [];
            this.form.reserve_time = '';
            let letResrveDate = Date.parse(this.form.reserve_date);
            let findData = this.param.reserveList.find(a => Date.parse(a.reserve_date) == letResrveDate);
            if (findData) {
                this.param.reserve_time = findData.reserve_time;
            }
        },
        handleImage(img) {
            let index = this.storeDetail.picture.findIndex((i) => i.id === img.id);
            this.$refs.picture.$swiper.slideTo(index);
        },
        getNowdate() {
            let Today = new Date();
            let yyyy = Today.getFullYear();
            let mm = String(Today.getMonth() + 1).padStart(2, "0");
            let dd = String(Today.getDate()).padStart(2, "0");
            let nowdate = yyyy + "/" + mm + "/" + dd;
            return nowdate;
        },
        handleUpdate() {
        },
        reservation() {
            if (!this.loggedInUser) {
                this.$swal("請先登入帳號。");
                this.$router.push({
                    name: 'signIn'
                });
                return
            }
            if (this.qty > this.form.RemainingNumber) return this.$swal("人數超過上限!");
            if (!this.form.reserve_time) return this.$swal("請選擇時間");
            this.setBooking({
                reserve_date: this.form.reserve_date,
                reserve_time: this.form.reserve_time,
                qty: this.qty,
                sno: this.storeDetail.sno,
                amount: 200,
                id: this.storeDetail.id,
                bas_id: this.storeDetail.bas_id,
                store: this.storeDetail.name
            })
            this.$router.push({ name: 'appointmentClassPay' });
            //取得預約日期時間
            // axios.post("/Group/GroupClassBookingCreate", {
            //     Sno_group_class_up: this.Sno_group_class_up, Sno_user: this.Sno_user, Sno_store: this.Sno_store
            //     , Booking_date: this.form.reserve_date, Booking_time: this.form.reserve_time
            // }).then(resp => {
            //     if (resp.succ) {
            //         this.$swal(resp.result);
            //         this.$router.push({
            //             name: 'appointmentClassCheckOut',
            //             query: {
            //                 reserve_date: this.form.reserve_date,
            //                 reserve_time: this.form.reserve_time,
            //                 qty: this.qty
            //             }
            //         });
            //     }
            //     else {
            //         this.$swal(resp.msg);
            //     }
            // });
        }
    }
}
</script>

<style scoped>
.swiper-container {
    max-width: 420px;
}

.swiper-container .img-size {
    width: 370px;
    height: 370px
}

.thumbnail {
    margin: 20px;
    display: grid;
    gap: 12.5px;
    grid-template-columns: repeat(4, 1fr);
}

.thumbnail img {
    cursor: pointer;
}

.thumbnail .img-size-min {
    width: 74px;
    height: 74px;
}

.item-row {
    display: flex;
    align-items: center;
    margin: 0;
    justify-content: space-between;
}

.item-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: -8px 20px 0px -8px;
    text-align: center;
}

.item-wrap>* {
    margin: 10px 0 0 8px;
}

.reservation-now {
    background-color: #e67737;
    border: none;
    color: white;
    padding: 4px 30px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 5px;
}

.booking-infoMsg {
    padding: 20px 20px 0px;
    font-size: 15px;
}

.booking-infoMsg ul {
    list-style: none;
    padding-left: 1rem;
    margin-top: 1rem;
}

.booking-infoMsg li {
    margin-bottom: 1rem;
}

.booking-warning {
    font-size: 15px;
    padding: 20px 20px;
}

#gobacktoshops {
    background-color: var(--main-text-color);
    color: white;
    border: none;
    padding: 6px 90px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 5px;
}
</style>

<style scoped>
@media (min-width: 992px) {

    .item-wrap {
        margin: -8px 20px 0px -8px;
    }

    .item-row {
        margin: 20px 0;
    }

    .thumbnail {
        margin: 20px 50px;
    }

    .booking-warning {
        font-size: 14px;
        padding: 20px 70px;
    }
}
</style>