<template>
    <div class="page-wrapper">
        <div class="row row-large row-divided">
            <div class="large-12 col" style="padding-left:0;padding-right:0;">
                <div class="entry-header">
                    <h1 class="entry-title">隱藏怪獸【爆米花怪獸】出沒✨<br />
                    </h1>
                    <h1 class="entry-title">≡期間限定┊5/13-5/31≡</h1>
                </div>
            </div>
            <div class="post-time">
                <span><img src="../../../assets/img/mobile/news/time.png" alt="">2023-05-13</span>
            </div>
            <div class="content col-12">
                <div class="img-group">
                    <img v-lazy="Img1" alt="">
                </div>

                <p>棉花糖星球的彗星公主，最喜歡下午茶了，<br>
                    吃過各式各樣甜點的彗星公主說了：<br>
                    「啊～真想嘗試更多不一樣的特別點心！」<br>
                    希望受邀出席的貴賓能帶不一樣的下午茶甜點過來!<br>
                    收到彗星公主下午茶邀請函的GAGA小小探險家<br>
                    決定帶上【爆米花怪獸】找彗星公主~
                </p>
                <div style="text-align: center;font-size: 16px;">
                    <p><b>【爆米花怪獸】製作步驟： </b></p>
                </div>
                <div class="img-group">
                    <p>
                        使用GAGA怪獸膠基底+魔法藥水調配💧</p>
                    <img v-lazy="Img2" alt="">
                    <p>用魔法棒攪一攪就變出史萊姆啦~~~</p>
                    <img v-lazy="Img3" alt="">
                    <p>再來變身調色盤大師~調配出專屬的顏色❤️🧡💛</p>
                    <img v-lazy="Img4" alt="">
                    <p>登登登~史萊姆基底完成啦~~~~<br>
                        可以拉的好長好長!!!
                    </p>
                    <img v-lazy="Img5" alt="">
                    <p>一樣可以蓋大泡泡喔~~~</p>
                    <img v-lazy="Img6" alt="">
                    <p>再來淋上GAGA特製的甜滋滋果醬史萊姆~~</p>
                    <img v-lazy="Img7" alt="">
                    <p>準備扮在一起啦~~~還有香香的味道~~</p>
                    <img v-lazy="Img8" alt="">
                    <p>最後再配上七彩的爆米花球球來當裝飾品<br>
                        五顏六色的爆米花球球拌在史萊姆裡會變出意想不到的顏色變化~
                    </p>
                    <img v-lazy="Img9" alt="">
                </div>
                <p>
                    撲鼻而來的甜滋滋果醬再配上七彩的爆米花完成! <br>
                    快讓彗星公主揭發觸覺與嗅覺和視覺的三重享受❣ <br>
                    爆米花怪獸小點心的有效期限：5/13-5/31 <br>
                    獨家秘方需到GAGAmonster櫃上體驗喔!!!

                </p>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'newsdetail20230513',
    data() {
        return {
            Img1: require('../../../assets/img/mobile/news/20230513/1.jpg'),
            Img2: require('../../../assets/img/mobile/news/20230513/2.jpg'),
            Img3: require('../../../assets/img/mobile/news/20230513/3.jpg'),
            Img4: require('../../../assets/img/mobile/news/20230513/4.jpg'),
            Img5: require('../../../assets/img/mobile/news/20230513/5.jpg'),
            Img6: require('../../../assets/img/mobile/news/20230513/6.jpg'),
            Img7: require('../../../assets/img/mobile/news/20230513/7.jpg'),
            Img8: require('../../../assets/img/mobile/news/20230513/8.jpg'),
            Img9: require('../../../assets/img/mobile/news/20230513/9.jpg'),
        }
    },
}
</script>