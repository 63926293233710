<template>
    <div class="page-wrapper">
        <div class="row row-large row-divided">
            <div class="large-12 col" style="padding-left:0;padding-right:0;">
                <div class="entry-header">
                    <h1 class="entry-title">歡慶台中廣三SOGO店開幕-史萊姆星球派對</h1>
                </div>
            </div>
            <div class="post-time">
                <span><img src="../../../assets/img/mobile/news/time.png" alt="">2023-03-02</span>
            </div>
            <div class="content col-12">
                <div class="img-group">
                    <img v-lazy="Img1" alt="">
                </div>
                <!-- <div style="text-align: center;font-size: 16px;">
                    <p><b>❮新課程上市❯</b></p>
                </div> -->
                <p>GAGA monster 的星球探險環島旅行進駐於台中！GAGA monster立志將在全台各地傳遞 ⌜釋放孩子無限創造力⌟ 的品牌精神，讓全國孩子們發揮無限創造力與想像力，變身小小創造家!
                    培養他們【獨立】、【專注】、【好奇】的能力，將史萊姆變身，從零到有的製作出獨一無二的專屬作品。</p>

                <div class="img-group">
                    <img v-lazy="Img2" alt="">
                </div>
                <p>台中廣三SOGO店擁有全國唯一的「史萊姆網美牆」整個環境與氛圍以GAGA monster獨有的亮麗粉色為風格，打造出繽紛的史萊姆國度，GAGA monster就是小朋友的快樂天堂。</p>

                <div class="img-group">
                    <img v-lazy="Img3" alt="">
                    <img v-lazy="Img4" alt="">
                    <img v-lazy="Img5" alt="">
                </div>
                <p>GAGA
                    monster老師們皆受過專業的訓練且親切的對待每位孩子，開始上課前先以說故事的方式，讓孩子們身歷其境進入史萊姆星球的世界，並且讓孩子們透過玩中學來認識各個史萊姆怪獸，當天受邀於星球派對的部落客：法蘭媽的小星球、Kid恩來了、小皮球看世界、有一個兒子超爽的等，各個對史萊姆好奇心爆棚!對於史萊姆的變化每位都充滿熾熱的眼神，史萊姆多種的變化除了激發孩子的好奇心外，更能讓所有孩子們發揮創造力。GAGA
                    monster不僅讓每位孩子釋放無限創造力之外，還讓所有孩子沉浸屬於孩子他們的歡樂童年，在玩的過程中學習獨立與自主並且專注地當一個小小創造家，將史萊姆變出各式各樣型態，創造出無限的可能。</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'newsdetail20230302',
    data() {
        return {
            Img1: require('../../../assets/img/mobile/news/20230302/1.jpg'),
            Img2: require('../../../assets/img/mobile/news/20230302/2.jpg'),
            Img3: require('../../../assets/img/mobile/news/20230302/3.jpg'),
            Img4: require('../../../assets/img/mobile/news/20230302/4.jpg'),
            Img5: require('../../../assets/img/mobile/news/20230302/5.jpg'),
        }
    },
}
</script>