import Vue from "vue";
import VueX from "vuex";
import App from "./App.vue";
import router from "./router";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import VueDatepicker from "vuejs-datepicker/dist/vuejs-datepicker.esm.js";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "./assets/css/style.css";
import "./assets/fonts/font.css";
import "./assets/icons/fontawesome-free-6.0.0-web/css/all.css";
import VueLazyload from "vue-lazyload";
import store from "./store/index.js";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import VueCookies from "vue-cookies";
import LoadingPlugin from "vue-loading-overlay";
import "vue-loading-overlay/src/css/index.css";
import VCalendar from 'v-calendar';

Vue.use(VueLazyload);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueSweetalert2);
Vue.use(VueX);
Vue.use(VueCookies);
Vue.use(VCalendar);
Vue.use(LoadingPlugin, {
  color: "#F83F80",
});
Vue.component("datepicker", VueDatepicker);

Vue.config.productionTip = false;

import $cf from "./common/CommonFunc.vue";
Vue.prototype.$cf = $cf;
new Vue({
  router,
  store,
  created() {
    this.$store.dispatch("checkIsMobile");
  },
  render: (h) => h(App),
}).$mount("#app");

import { sha256 } from "js-sha256";
Vue.prototype.$sha256 = sha256;


router.beforeEach((to, from, next) => {
  store.dispatch("checkIsMobile");
  next();
});
