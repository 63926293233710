<style lang="scss" scoped>
table {
  position: relative;
}

tbody tr {
  position: relative;
  top: 16px;
}

.wrap {
  display: grid;
  grid-template-columns: 8fr 2fr;
  gap: 18px;
}

.wrap-one {
  grid-template-columns: 1fr;
}

.card-round {
  background-color: #fff;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.3);
  border-radius: 16px;
}

.pers-title {
  margin: 0 40px 24px 40px !important;
}

hr {
  background: var(--color-gray1);
  height: 1.5px;
  margin: 0;
}

.amount {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  margin-top: 4px;
  justify-items: end;
  white-space: nowrap;
}

.main-content {
  margin-top: 40px;
  min-width: 1200px;
}

.m-checkout {
  display: grid;
  grid-template-columns: 144px 1fr;
  align-items: center;
}

.pay-btn {
  background-color: var(--ft-main) !important;
}

.pers-one-page {
  grid-template-columns: 1fr;
  min-height: calc(100vh - 225px);
}

.pers-container {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: grid;
  grid-template-columns: 286px minmax(896px, 1fr);
  grid-gap: 0 18px;
}

.pers-title,
span {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}

.btn-white {
  background-color: var(--main-text-color);
  color: #fff;
  box-shadow: none !important;
  border-radius: 50px !important;
  -webkit-box-shadow: none;
  min-width: 6.5em;
  font-size: 19px !important;
  padding: 0.4375rem 0.5rem 0.4375rem 0.7rem !important;
}

.btn:hover,
.btn:active,
.btn:focus {
  opacity: 0.85;
  background-color: var(--main-text-color);
  color: #fff;
}

.card-info {
  padding: 24px 40px;
}

@media screen and (min-width: 1200px) {
  .mobile-only {
    display: none !important;
  }
}

@media (max-width: 1200px) {
  .desktop-only {
    display: none !important;
  }

  .m-btn {
    height: 50px;
    width: 40%;
    font-size: 22px !important;
  }

  .card-info {
    padding: 24px 20px;
  }

  .pers-container {
    grid-template-columns: 1fr;
    padding: 0;
  }

  .main-content {
    min-width: unset;
  }

  .wrap {
    grid-template-columns: 1fr;
    margin: 0 -26px;
  }

  .pay-btn {
    min-height: 90px;
  }

  .border-btn {
    border: 2px solid var(--btn-main);
    border-radius: 16px;
    box-sizing: border-box;
    padding: 40px;
  }

  .m-flex-column {
    flex-direction: column !important;
  }

  .amount {
    display: flex-end;
  }
}
</style>
<template>
  <div>
    <div ref="storeCheckOut" :class="{ 'wrap-one': true }">
      <section class="card-round">
        <div style="padding: 24px 0; margin: 0">
          <div class="pers-title">
            <span class="pers-title-line"></span>
            <span class="pers-title-name m-text-35">
              訂購完成</span>
          </div>

          <div class="card-info">
            <!-- step 01 內容 -->
            <!-- 結帳 -->
            <section style="display: grid; gap: 24px; grid-template-columns: 1fr">
              <!-- DESKTOP -->
              <table class="desktop-only">
                <thead>
                  <tr>
                    <th style="min-width: 246px">
                      品項 / 訂單編號： {{ cart.id }}
                    </th>
                    <th>原價</th>
                    <th>優惠價</th>
                    <th>數量</th>
                    <th style="min-width: 100px">總價</th>
                    <th v-if="pay_type === 'REGULAR'">期數</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(i, index) in cart.item_list" :key="index">
                    <td class="d-flex flex-row align-items-center">
                      <img :src="i.image" width="60" height="60" />
                      <div style="
                        margin: 0 24px;
                        font-weight: 700;
                        color: #4b4b4b;
                        font-size: 18px;
                      ">
                        {{ i.item_name }}
                      </div>

                      <figure class="d-flex flex-column">
                        <div style="font-size: 14px" v-for="t in i.tag_list" :key="t.row_id">
                          {{ t.position }} : {{ t.tag }}
                        </div>
                      </figure>
                    </td>
                    <td>
                      <div style="text-decoration: line-through">
                        ${{ $cf.numProc.numberWithCommas(i.original_price / i.qty) }}
                      </div>
                      <div v-show="cart.type !== 3" style="font-size: 10px">
                        ({{ i.pre_price / i.qty }}/堂)
                      </div>
                    </td>
                    <td width="60px">
                      ${{ $cf.numProc.numberWithCommas(i.discount_price / i.qty) }}
                    </td>
                    <td>
                      <div>{{ i.qty }}</div>
                    </td>
                    <td>
                      ${{
                        $cf.numProc.numberWithCommas(i.discount_price)
                      }}
                    </td>
                    <td>
                      {{ cart.rental_goods_type_name }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <!-- MOBILE -->
              <section class="mobile-only">
                <div class="m-text-24">
                  品項 / 訂單編號： {{ cart.id }}
                </div>
                <div v-for="(i, index) in cart.item_list" :key="index" class="m-checkout">
                  <img :src="i.image" width="120" height="120" />
                  <div style="margin-top: 16px">
                    <div class="m-text-28" style="font-weight: 700; color: #4b4b4b; font-size: 18px">
                      {{ i.item_name }}
                    </div>
                    <figure class="d-flex flex-column">
                      <div class="m-text-24" style="font-size: 14px" v-for="t in i.tag_list" :key="t.row_id">
                        {{ t.position }} : {{ t.tag }}
                      </div>
                    </figure>

                    <figure class="d-flex align-items-center">
                      <div class="m-text-24" style="min-width: 100px">原價</div>
                      <div class="m-text-24" style="text-decoration: line-through">
                        ${{ $cf.numProc.numberWithCommas(i.original_price / i.qty) }}
                      </div>
                      <div v-show="cart.type !== 3" style="font-size: 19px; margin-left: 8px">
                        ({{ i.pre_price / i.qty }}/堂)
                      </div>
                    </figure>

                    <figure class="d-flex align-items-center">
                      <div class="m-text-24" style="min-width: 100px">優惠價</div>
                      <div class="m-text-24">
                        ${{ $cf.numProc.numberWithCommas(i.discount_price / i.qty) }}
                      </div>
                    </figure>
                    <figure class="d-flex align-items-center">
                      <div class="m-text-24" style="min-width: 100px">數量</div>
                      <div class="m-text-24">
                        {{ $cf.numProc.numberWithCommas(i.qty) }}
                      </div>
                    </figure>
                    <figure class="d-flex align-items-center">
                      <div class="m-text-24" style="min-width: 100px">總價</div>
                      <div class="m-text-24" style="width: 140px">
                        ${{
                          $cf.numProc.numberWithCommas(i.discount_price)
                        }}
                      </div>
                    </figure>
                  </div>
                </div>
              </section>

              <figure style="font-weight: 700" class="m-text-24">
                門市：<span>{{ cart.brand }} {{ cart.store_name }}</span>
              </figure>

              <!-- <hr />

              <figure>
                <div class="m-text-24" style="color: #909090; font-size: 14px; margin-bottom: 8px">
                  已享用之優惠
                </div>
                <div class="m-text-24" v-show="cart.event">
                  {{ cart.event.length > 0 ? cart.event[cart.event.length - 1].content : "" }}
                </div>
              </figure> -->

              <!-- 訂購完成 -->
              <figure class="main-content">
                <hr />

                <div class="
                  d-flex
                  flex-row
                  justify-content-between
                  m-flex-column m-text-24
                ">
                  提醒您：訂單完成後，將於3~5個工作日以電子郵件寄至您註冊的E-MAIL信箱，不會再另寄送紙本發票。
                  <div>
                    <div class="amount">
                      優惠碼<span>-{{ codeamt }}</span>
                    </div>
                    <div v-show="cash_use" class="amount">
                      優惠金<span>-{{ cash }}</span>
                    </div>
                    <div class="amount">
                      購物金<span>-{{ point }}</span>
                    </div>
                    <div class="amount">
                      運費<span> {{ shipping_fee }}</span>
                    </div>
                    <div class="amount">
                      合計<span>{{ totalamt }}</span>
                    </div>
                    <div class="amount" style="margin-top: 24px; font-weight: 700">
                      消費總金額<span>{{ sellamt }}</span>
                    </div>
                  </div>
                </div>

                <div class="d-flex justify-content-center col-12 my-40px mt-5">
                  <button class="btn btn-white m-btn" style="margin-right: 16px"
                    @click="$router.push({ name: 'allProduct' })">
                    繼續逛逛
                  </button>
                </div>
              </figure>
            </section>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import axios from "../api/axios.js";
export default {
  name: "StoreCheckOut",
  components: {
  },

  data() {
    return {
      pay_type: "",
      cash_use: false,
      booking: false, //前台開放預約
      id: "",
      codeamt: 0,
      cash: 0,
      point: 0,
      shipping_fee: 0,
      totalamt: 0,
      sellamt: 0,
      cart: {
        id: '',
        item_list: [
          {
            item_name: "",
            image: "",
            tag_list: [
              {
                classification: '',
                position: "",
                row_id: 1,
                tag: "",
              },
            ],
            original_price: 0,
            pre_price: 0,
            discount_price: 0,
            qty: 1,
          }
        ],
        trans_pay: [
        ],
        pay_type: "CREDIT",
        balance: 0,
        coupon_code: "",
        coupon_amount: 0,
        coupon_errmsg: "無此優惠碼",
        useable_cash: 0,
        cash: 0,
        useable_point: 0,
        point: 0,
        brand: "",
        store_name: "",
        store_id: 0,
        type: 1,
        event: [],
        sno: 0,
        rental_goods_type_name: "",
      },
      pay_btn_disable: false,
      coupon_show: false,
      point_show: false,
      disc_show: false,
      coupon_calc_available: false,
      cash_calc_available: false,
      point_calc_available: false,
    };
  },
  created() {
    // this.login_user = this.getUserCookie();
    if (this.$route.query.id != undefined) {
      this.id = this.$route.query.id;
    }
    axios.post("/Trans/GetShoppingCartSuccess", {
      id : this.id
    }).then((respData) => {
        if (respData.succ) {
          this.cart.id = respData.result.cart[0].id;
          this.cart.store_id = respData.result.cart[0].store_id;
          this.cart.store_name = respData.result.cart[0].store_name;
          this.cart.brand = respData.result.cart[0].brand;
          this.cart.item_list = [];
          respData.result.cart[0].item_list.forEach((item) => {
            this.cart.item_list.push(item);
          });
          respData.result.cart[0].trans_pay.forEach((item) => {
            this.cart.trans_pay.push(item);
            switch (item.pay_id)
            {
              case 'SHIPPING_FEE': //運費
                this.shipping_fee = item.amt;
                break;
              case 'DISCCODE': //優惠碼
                this.codeamt = item.amt;
                break;
              case 'DISCAMT': //優惠金
                this.cash = item.amt;
                break;
              case 'POINT': //購物金
                this.point = item.amt;
                break;
              case 'CASH': //付款方式 = 現金
                this.sellamt += item.amt;
                break;
              case 'CREDIT': //付款方式 = 信用卡
                this.sellamt += item.amt;
                break;
              case 'ATM': //付款方式 = ATM
                this.sellamt += item.amt;
                break;
              case 'REGULAR': //付款方式 = 定期定額
                this.sellamt += item.amt;
                this.pay_type = 'REGULAR';
                this.cart.rental_goods_type_name = respData.result.cart[0].RENTAL_GOODS_TYPE_NAME;
                break;
              case 'OTHER': //付款方式 = 其他
                this.sellamt += item.amt;
                this.pay_type = 'OTHER';
                break;
            }
          });
          this.totalamt = this.cart.item_list.reduce((acc, curr) => {
              return acc + curr.discount_price;
            }, 0);

          // this.apiGiftSetRemark();
        }
    })
    // this.callApi(API_ROUTE.ShoppingCart.GetShoppingCartSuccess, {id : this.id}, {});
    // this.callApi(API_ROUTE.System.sysparam, { p_key: "Client_Booking" }, {});
    // this.callApi(API_ROUTE.System.sysparam, { p_key: "Client_Cash" }, {});
  },
  computed: {
    // ...mapGetters(["shoppingCart", "shoppingInfo"]),
  },
  methods: {
    // ...mapActions(["setUserReserveDetail"]),
    swalForInputConfirm(val, tag) {
      switch (tag) {
        default:
          if (val != "") {
            this.user_email = val;
          }
          break;
      }
    },
    calDiscount(total) {
      if (this.discount_type === "DISCOUNT") {
        this.cart.coupon_amount =
          total - Math.round(this.$cf.numProc.multiply(total, this.discount));
      } else if (this.discount_type === "CASH") {
        this.cart.coupon_amount = this.discount;
      }

      // console.log(this.cart.coupon_amount);
      return this.cart.coupon_amount;
    },
    apiGiftSetRemark() {
      ApiGiftSet.GetNowUse({
        SNO_STORE: this.cart.store_id,
        TARGET_AMT: this.totalamt,
        SNO_USER: this.login_user.sno_user,
      }).then((resp) => {
        if (resp.stat.succ) {
          let arrContent = [];
          if (resp.data != null) {
            resp.data.forEach((a) => {
              //let giftOne = {};
              if (a.TARGET_TYPE == "SINGLE" && this.user_level != "0") {
                return;
              }

              let letDateSplitB = a.ACTION_DATE_B.split("-");
              let letDateSplitE = a.ACTION_DATE_E.split("-");

              let letDateMMDD_B = letDateSplitB
                ? letDateSplitB[1] + "/" + letDateSplitB[2]
                : "";
              let letDateMMDD_E = letDateSplitB
                ? letDateSplitE[1] + "/" + letDateSplitE[2]
                : "";

              let letContent =
                letDateMMDD_B + " - " + letDateMMDD_E + " | " + a.GIFT_NAME;
              arrContent.push({ content: letContent, url: "" });
            });
          }
          this.cart.event = arrContent;
        }
      });
    },
    // apiSuccess: function(apiRoute, respData) {
    //   apiRoute;respData;
    //   switch (apiRoute) {
    //     case API_ROUTE.System.sysparam:
    //       //前台預約
    //       if (respData.data.p_key === "Client_Booking") {
    //         this.booking = respData.data.p_value === "Y";
    //       }
    //       //前台優惠金
    //       if (respData.data.p_key === "Client_Cash") {
    //         this.cash_use = respData.data.p_value === "Y";
    //       }
    //       break;
    //     case API_ROUTE.ShoppingCart.GetShoppingCartSuccess:
    //       this.cart.id = respData.data.cart[0].id;
    //       this.cart.store_id = respData.data.cart[0].store_id;
    //       this.cart.store_name = respData.data.cart[0].store_name;
    //       this.cart.brand = respData.data.cart[0].brand;
    //       respData.data.cart[0].item_list.forEach((item) => {
    //         this.cart.item_list.push(item);
    //       });
    //       respData.data.cart[0].trans_pay.forEach((item) => {
    //         this.cart.trans_pay.push(item);
    //         switch (item.pay_id)
    //         {
    //           case 'SHIPPING_FEE': //運費
    //             this.shipping_fee = item.amt;
    //             break;
    //           case 'DISCCODE': //優惠碼
    //             this.codeamt = item.amt;
    //             break;
    //           case 'DISCAMT': //優惠金
    //             this.cash = item.amt;
    //             break;
    //           case 'POINT': //購物金
    //             this.point = item.amt;
    //             break;
    //           case 'CASH': //付款方式 = 現金
    //             this.sellamt = item.amt;
    //             break;
    //           case 'CREDIT': //付款方式 = 信用卡
    //             this.sellamt = item.amt;
    //             break;
    //           case 'ATM': //付款方式 = ATM
    //             this.sellamt = item.amt;
    //             break;
    //           case 'REGULAR': //付款方式 = 定期定額
    //             this.sellamt = item.amt;
    //             this.pay_type = 'REGULAR';
    //             this.cart.rental_goods_type_name = respData.data.cart[0].RENTAL_GOODS_TYPE_NAME;
    //             break;
    //           case 'OTHER': //付款方式 = 其他
    //             this.sellamt = item.amt;
    //             this.pay_type = 'OTHER';
    //             break;
    //         }
    //       });
    //       this.totalamt = this.cart.item_list.reduce((acc, curr) => {
    //           return acc + curr.discount_price;
    //         }, 0);

    //       this.apiGiftSetRemark();
    //       break;
    //   }
    // },
    // apiError: function(apiRoute, respData) {
    //   apiRoute;respData;
    //   switch (apiRoute) {
    //     case API_ROUTE.ShoppingCart.GetShoppingCartSuccess:
    //       this.showApiMsg(respData.stat.err_msg);
    //       break;
    //   }
    // },
  },
};
</script>
