<template>
    <div class="page-wrapper">
        <div class="row row-large row-divided">
            <div class="large-12 col" style="padding-left:0;padding-right:0;">
                <div class="entry-header">
                    <h1 class="entry-title">GAGA monster史萊姆多元感官體驗公益活動!
                    </h1>
                    <h1 class="entry-title">與啟明學校透過觸覺與聽覺讓孩子在玩中學!</h1>
                </div>
            </div>
            <div class="post-time">
                <span><img src="../../../assets/img/mobile/news/time.png" alt="">2023-04-28</span>
            </div>
            <div class="content col-12">
                <div class="img-group">
                    <img v-lazy="Img1" alt="">
                </div>
                <p>「臺北市立啟明學校」來到 GAGA
                    monster天母店體驗史萊姆課程，啟明學校的所有老師們也非常用心的陪伴在孩子身邊，所有學生們透過觸覺與聽覺在玩樂中學習並體驗史萊姆的樂趣。除了啟發【觸覺】的好奇心之外，所有學生們也從中學習調配史萊姆的材料比例和顏色，培養他們獨立、自主的能力。
                </p>
                <!-- <div style="text-align: center;font-size: 16px;">
                    <p><b>❮康乃馨手作教學❯</b></p>
                </div> -->
                <div class="img-group">
                    <img v-lazy="Img2" alt="">
                    <p>此外，在課程中啟明學校的學生們與老師同心協力完成各個步驟，透過觸覺與聽覺來提升啟明學校的學生們的想像空間。</p>
                    <img v-lazy="Img3" alt="">
                    <img v-lazy="Img4" alt="">
                </div>
                <p>「: 史萊姆竟然可以無限延展，還可以變出大泡泡耶~」<br>
                    史萊姆透過不同的怪獸膠與魔法藥水還有道具可以變化出多種可能，每個史萊姆怪獸都有他獨特的觸感，有像麻糬般軟嫩嫩的軟軟怪獸，或是冰冰涼涼的水水怪獸，還有戳起來又Q又Juice的凍凍怪獸等，上課前老師都會讓每位學生觸摸看看再自行選擇一隻自己摸起來最喜歡的史萊姆怪獸。
                </p>
                <div class="img-group">
                    <img v-lazy="Img5" alt="">
                    <img v-lazy="Img6" alt="">
                </div>
                <p>「: 哇~~冰冰涼涼的耶!!! 原來這就是史萊姆的觸感~」<br>
                    史萊姆很好地拉長、壓扁和扯斷，甚至可以從手上滑落下來非常有趣，是一個可以帶給孩子們很多樂趣和愉悅的活動，並且啟發孩子無限的創造力和好奇心。對於第一次碰到史萊姆的反應，啟明學校的每位學生都好興奮呢!
                </p>
                <div class="img-group">
                    <img v-lazy="Img7" alt="">
                </div>
                <p>GAGA
                    monster也很開心這次能和啟明學校的學生們一同玩樂!這場公益活動不僅僅是一個有趣史萊姆的體驗，更是一個讓啟明學校的學生們將自己的作品中融入了創意和想像力，從中學習感受不同的觸感發掘更多變化與無限可能，製作出一份獨一無二的專屬作品，也歡迎各位下次再來找GAGA
                    monster玩。
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'newsdetail20230428',
    data() {
        return {
            Img1: require('../../../assets/img/mobile/news/20230428/1.jpg'),
            Img2: require('../../../assets/img/mobile/news/20230428/2.jpg'),
            Img3: require('../../../assets/img/mobile/news/20230428/3.jpg'),
            Img4: require('../../../assets/img/mobile/news/20230428/4.jpg'),
            Img5: require('../../../assets/img/mobile/news/20230428/5.jpg'),
            Img6: require('../../../assets/img/mobile/news/20230428/6.jpg'),
            Img7: require('../../../assets/img/mobile/news/20230428/7.jpg'),
        }
    },
}
</script>