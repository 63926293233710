<template>
    <div>
        <!-- <div class="title">
            <span><img src="../assets/img/mobile/news/information.png" alt="">最新消息</span>
        </div> -->

        <b-tabs pills card>
            <b-tab title="最新消息" active>
                <div class="header-wrap"><img src="../assets/img/mobile/news/04-bg_A01.png" alt=""></div>
                <div class="news-body row">
                    <div v-for="item in news_list" :key="item.id"
                        class="col-xl-4 col-lg-4 col-xs-6 col-6 news-body-card justify-content-center d-flex mb-4">
                        <div style="text-align: center;">
                            <div class="img-bg">
                                <router-link class="routerLink" :to="{ name: 'newsDetail', params: { name: item.title } }">
                                    <img :src="item.url" alt="">
                                    <div class="text-group">
                                        <span class="text-group-date">{{ item.date }} {{ item.type }}</span>
                                        <p class="text-group-title">{{ item.title }}</p>
                                        <p>{{ item.info }}</p>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </b-tab>
            <!-- <b-tab title="部落客分享">
                <div class="header-wrap"><img src="../assets/img/mobile/news/04-bg_A01.png" alt=""></div>
                <div class="news-body row">
                    <div v-for="item in userShare_list" :key="item.id"
                        class=" col-6 news-body-card justify-content-center d-flex mb-4">
                        <div style="text-align: center;">
                            <div class="img-bg">
                                <router-link class="routerLink" :to="{ name: 'shareByUser', params: { name: item.title } }">
                                    <img :src="item.url" alt="">
                                    <div class="text-group">
                                        <p class="text-group-title">{{ item.title }}</p>
                                        <p>{{ item.info }}</p>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </b-tab> -->
        </b-tabs>
    </div>
</template>

<script>
export default {
    data() {
        return {
            news_list: [
                {
                    id: 1,
                    url: require("../assets/img/mobile/news/20230605/官網1.jpg"),
                    title: '偏鄉小學公益活動',
                    info: '照亮偏鄉小學！GAGA monster獨家史萊姆手作教學公益活動！...',
                    date: '2023/06/05',
                    type: '最新活動'
                },
                {
                    id: 2,
                    url: require("../assets/img/mobile/news/20230601/官網1.jpg"),
                    title: '粽夏YA!DIY史萊姆',
                    info: '【粽夏YA!】DIY史萊姆 粽粽怪獸+冰心粽 包出夏日的趣味...',
                    date: '2023/06/01',
                    type: '最新活動'
                },
                {
                    id: 3,
                    url: require("../assets/img/mobile/news/20230513/1.jpg"),
                    title: '爆米花怪獸出沒',
                    info: '隱藏怪獸【爆米花怪獸】出沒！「啊～真想嘗試更多不一樣的特別點心！」...',
                    date: '2023/05/13',
                    type: '最新活動'
                }, {
                    id: 4,
                    url: require("../assets/img/mobile/news/20230511/1.jpg"),
                    title: '母親節活動',
                    info: '用愛手作史萊姆-獻給媽咪史萊姆 ⌜康乃馨🌹⌟ 限定徽章與隱藏怪獸出沒...',
                    date: '2023/05/11',
                    type: '年度活動'
                },
                {
                    id: 5,
                    url: require("../assets/img/mobile/news/20230428/1.jpg"),
                    title: '史萊姆多元感官體驗公益活動',
                    info: 'GAGA monster 史萊姆多元感官體驗公益活動!與啟明學校...',
                    date: '2023/04/28',
                    type: '最新活動'
                },
                {
                    id: 6,
                    url: require("../assets/img/mobile/news/20230329/1.jpg"),
                    title: '台北寒舍艾美「探索童趣」',
                    info: '史萊姆怪獸星球來了！台北寒舍艾美兒童節住房與餐飲活動...',
                    date: '2023/03/29',
                    type: '最新活動'
                },
                {
                    id: 7,
                    url: require("../assets/img/mobile/news/20230324/1.jpg"),
                    title: 'GAGA monster盛大一週年慶典',
                    info: 'GAGA monster盛大一週年慶典🎉歡 / 慶 / 週 / 年 / 慶🎉...',
                    date: '2023/03/24',
                    type: '年度活動'
                },
                {
                    id: 8,
                    url: require("../assets/img/mobile/news/20230302/1.jpg"),
                    title: '歡慶台中廣三SOGO店開幕',
                    info: 'GAGA monster的星球探險環島旅行進駐於台中！...',
                    date: '2023/03/24',
                    type: '最新消息'
                },
                {
                    id: 9,
                    url: require("../assets/img/mobile/news/20230302/1.jpg"),
                    title: 'GAGA monster 堅持力挺創客教育',
                    info: 'GAGA monster 堅持力挺創客教育，參與Maker Party釋放孩子無限創造力...',
                    date: '2022/12/07',
                    type: '最新活動'
                },
                {
                    id: 10,
                    url: require("../assets/img/mobile/news/20221120/1.jpg"),
                    title: '親子家庭一同體驗BMW臺北汎德',
                    info: 'GAGA monster 參與BMW 小小車主周末活動，共同推動 ⌜以寓教於樂⌟ ...',
                    date: '2022/11/20',
                    type: '最新活動'
                },
            ],
            userShare_list: [
                {
                    id: 1,
                    url: require("../assets/img/mobile/news/20230605/官網1.jpg"),
                    title: '偏鄉小學公益活動',
                    info: '照亮偏鄉小學！GAGA monster獨家史萊姆手作教學公益活動！...',
                },
            ]
        }
    }
}
</script>

<style scoped>
.row {
    margin: 0;
}

.title {
    text-align: center;
    display: flex;
    align-items: center;
}

.title span {
    color: var(--main-text-color);
    width: 100%;
}

.title img {
    width: 6%;
    margin-right: 5px;
}

.header-wrap img {
    width: 100%;
}

.news-body {
    background-color: #F7F0E0;
    padding: 30px;
    text-align: center;
}

.news-body img {
    width: 100%;
    height: 115.75px;
    border-radius: 10px;
}

.news-body-card {
    /* padding: 0 20px; */
}

.img-bg {
    margin-bottom: 1em;
    width: 150px;
    /* height: 400px; */
    background-color: white;
    border-radius: 20px;
    padding: 0;
    /* margin: 20px;*/
}

.text-group {
    font-size: 12px;
    text-align: left;
    line-height: 1rem;
    padding: 10px 10px 10px;
}

.text-group-date {
    color: #776e73;
}

.text-group-title {
    font-size: 13px;
    color: var(--main-text-color);
}

.text-group p {
    margin-bottom: 5px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .img-bg {
        width: 350px;
        height: 460px;
    }

    .text-group {
        line-height: 1.5;
        padding: 20px 10px 20px;
    }

    .news-body img {
        height: 100%;
    }
}

@media (min-width: 992px) {

    .img-bg {
        width: 315px;
    }

    .text-group-title {
        font-size: 15px;
        margin: 10px 0 !important;
    }

    .news-body img {
        height: 300px;
        width: 315px;
    }

    .text-group {
        width: 100%;
        font-size: 14px;
        line-height: 1.5em;
    }

    .title span {
        font-size: 22px;
    }

    .title img {
        width: 3%;
    }
}
</style>