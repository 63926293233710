var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-wrapper"},[_c('div',{staticClass:"row row-large row-divided"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"content col-12"},[_c('div',{staticClass:"img-group"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.Img1),expression:"Img1"}],attrs:{"alt":""}}),_vm._m(2)]),_vm._m(3),_vm._m(4),_c('div',{staticClass:"img-group"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.Img2),expression:"Img2"}],attrs:{"alt":""}}),_vm._m(5),_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.Img3),expression:"Img3"}],attrs:{"alt":""}}),_vm._m(6),_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.Img4),expression:"Img4"}],attrs:{"alt":""}})])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"large-12 col",staticStyle:{"padding-left":"0","padding-right":"0"}},[_c('div',{staticClass:"entry-header"},[_c('h1',{staticClass:"entry-title"},[_vm._v("用愛手作史萊姆-獻給媽咪史萊姆 ⌜康乃馨🌹⌟ ")]),_c('h1',{staticClass:"entry-title"},[_vm._v("限定徽章與隱藏怪獸出沒!!!")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"post-time"},[_c('span',[_c('img',{attrs:{"src":require("../../../assets/img/mobile/news/time.png"),"alt":""}}),_vm._v("2023-05-11")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("≣ 活動期間：5/13、5/14 ≣"),_c('br'),_vm._v(" 只有兩天，趕快報名卡位唷! ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"text-align":"center","font-size":"16px"}},[_c('p',[_c('b',[_vm._v("❮康乃馨手作教學❯")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" 於活動2天預約報名課程，含史萊姆康乃馨手作教學1朵"),_c('br'),_vm._v(" 快拿來送給媽咪吧~~")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("❮母親節限定徽章❯"),_c('br'),_vm._v(" 活動期間預約上課即贈【GAGA 母親節限定徽章】乙個"),_c('br'),_vm._v(" 數量有限，售完為止唷!!! ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("❮隱藏怪獸出沒❯"),_c('br'),_vm._v(" 新怪獸將於母親節期間現身~"),_c('br'),_vm._v(" 療癒觸覺與嗅覺和視覺的三重享受❣"),_c('br'),_vm._v(" 搶先在這兩天體驗吧!!!! ")])
}]

export { render, staticRenderFns }