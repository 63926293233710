<template>
    <div>
        <!-- <div class="title">
            <span><img src="../assets/img/mobile/problem/QA.png" alt="">常見問題</span>
        </div> -->
        <div>
            <b-tabs pills card>
                <b-tab title="史萊姆相關問題" active>
                    <div class="header-wrap"><img src="../assets/img/mobile/problem/06-bg02.png" alt=""></div>
                    <div class="news-body row">
                        <div role="tablist">
                            <b-card no-body v-for="item in QA_list" :key="item.id">
                                <span block href="#" v-b-toggle="item.id" class="question">
                                    <span class="left">{{ item.question }}</span>
                                    <span class="when-opened right">
                                        <b-icon icon="chevron-up" />
                                    </span>
                                    <span class="when-closed right">
                                        <b-icon icon="chevron-down" />
                                    </span>
                                </span>
                                <b-collapse :id="item.id" role="tabpanel">
                                    <div class="answer">
                                        <p> {{ item.answer }}</p>
                                    </div>
                                </b-collapse>
                            </b-card>
                        </div>
                    </div>
                </b-tab>
                <!-- <b-tab title="退換貨問題">
                    <div class="header-wrap"><img src="../assets/img/mobile/problem/06-bg02.png" alt=""></div>
                    <div class="news-body row">
                        <div role="tablist">
                            <b-card no-body v-for="item in QA_list" :key="item.id">
                                <span block href="#" v-b-toggle="item.id" class="question">
                                    <span class="left">{{ item.question }}</span>
                                    <span class="when-opened right">
                                        <b-icon icon="chevron-up" />
                                    </span>
                                    <span class="when-closed right">
                                        <b-icon icon="chevron-down" />
                                    </span>
                                </span>
                                <b-collapse :id="item.id" role="tabpanel">
                                    <div class="answer">
                                        <p> {{ item.answer }}</p>
                                    </div>
                                </b-collapse>
                            </b-card>
                        </div>
                    </div>
                </b-tab>
                <b-tab title="會員相關問題">
                    <div class="header-wrap"><img src="../assets/img/mobile/problem/06-bg02.png" alt=""></div>
                    <div class="news-body row">
                        <div role="tablist">
                            <b-card no-body v-for="item in QA_list" :key="item.id">
                                <span block href="#" v-b-toggle="item.id" class="question">
                                    <span class="left">{{ item.question }}</span>
                                    <span class="when-opened right">
                                        <b-icon icon="chevron-up" />
                                    </span>
                                    <span class="when-closed right">
                                        <b-icon icon="chevron-down" />
                                    </span>
                                </span>
                                <b-collapse :id="item.id" role="tabpanel">
                                    <div class="answer">
                                        <p> {{ item.answer }}</p>
                                    </div>
                                </b-collapse>
                            </b-card>
                        </div>
                    </div>
                </b-tab> -->
            </b-tabs>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            QA_list: [
                {
                    id: '1',
                    question: '史萊姆怪獸如何保存呢?',
                    answer: '玩史萊姆怪獸之前要先洗手、消毒。玩完後要將史萊姆怪獸放入密封的罐子中，冰到冰箱保存唷！勿讓史萊姆怪獸過度曝曬於陽光或高溫下唷！'
                },
                {
                    id: '2',
                    question: '如何判斷史萊姆怪獸的壽命？',
                    answer: '史萊姆怪獸無添加防腐劑，因此玩過的史萊姆約可以保存2週。若是史萊姆怪獸若變得太過黏手、出水、液體狀、發臭、發霉，請停止使用並且丟棄喔！'
                },
                {
                    id: '3',
                    question: '若史萊姆怪獸沾到頭髮或是衣服該怎麼辦？',
                    answer: '不小心黏到頭髮、衣服，只要泡溫熱水就可以將史萊姆怪獸融化清洗乾淨~'
                },
                {
                    id: '4',
                    question: '如何調整史萊姆怪獸的手感？',
                    answer: '若是覺得太黏、太軟，可加入適量的小蘇打水，一次約1mL，慢慢混合調整至不黏手即可。若覺得太硬，可加入適量的蔬菜甘油，一次約3滴，慢慢混合調整，若已接近史萊姆怪獸的使用期限並出現異狀，建議停止使用並且丟棄。'
                },
                {
                    id: '5',
                    question: 'GAGA monster史萊姆怪獸星球的產品安全嗎？',
                    answer: 'GAGA monster的產品通過歐盟玩具安全檢測、SGS、玩具中心安全檢測合格認證，原料來自台灣廠商，拒絕使用來路不明原料，不含硼砂，澈底杜絕硼砂致敏的可能性。'
                },
                {
                    id: '6',
                    question: '史萊姆怪獸適合幾歲的孩子玩呢？',
                    answer: '建議三歲以上的孩子較合適唷！史萊姆不可食用。玩耍前後也都要記得洗手，在玩耍時，爸媽在旁陪伴較為安全外，更可一起互動玩耍，增進親子感情與樂趣~'
                },
                {
                    id: '7',
                    question: '不確定是否為過敏體質可以玩嗎?',
                    answer: '可以先做簡單的皮膚測試唷!用棉籤挑出一些史萊姆(約50元硬幣大小)，輕抹在手腕內側，等待約15分鐘，若無異樣即可玩耍;若有紅腫發癢現象，請立即停止玩耍。'
                },
            ]
        }
    }
}
</script>

<style scoped>
.row {
    margin: 0;
}
.title {
    text-align: center;
    display: flex;
    align-items: center;
}

.title span {
    color: var(--main-text-color);
    width: 100%;
}

.title img {
    width: 6%;
    margin-right: 5px;
}

.header-wrap img {
    width: 100%;
}

.news-body {
    background-color: #FEFAEA;
    padding: 30px;
    text-align: center;
    font-size: 15px;
}

.collapsed>.when-opened,
:not(.collapsed)>.when-closed {
    display: none;
}

.left {
    text-align: left;
    padding-left: 10px;
    margin: 10px 0;
}

.right {
    padding-right: 5px;
}

.card {
    --bs-card-border-color: none;
    --bs-card-bg: none;
}

.not-collapsed {
    color: #df2059;
    border-color: #df2059 !important;
    background-color: rgba(255, 255, 255);
}

.question {
    line-height: 1.6;
    /* height: 50px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-top: 1px solid #ddd;
}

.answer {
    text-align: left;
    padding: 10px;
    color: #746b6b;
}
</style>