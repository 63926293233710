<template>
    <div v-if="title == '偏鄉小學公益活動'">
        <newsdetail_20230605 />
    </div>
    <div v-else-if="title == '粽夏YA!DIY史萊姆'">
        <newsdetail_20230601 />
    </div>
    <div v-else-if="title == '爆米花怪獸出沒'">
        <newsdetail_20230513 />
    </div>
    <div v-else-if="title == '母親節活動'">
        <newsdetail_20230511 />
    </div>
    <div v-else-if="title == '史萊姆多元感官體驗公益活動'">
        <newsdetail_20230428 />
    </div>
    <div v-else-if="title == '台北寒舍艾美「探索童趣」'">
        <newsdetail_20230329 />
    </div>
    <div v-else-if="title == 'GAGA monster盛大一週年慶典'">
        <newsdetail_20230324 />
    </div>
    <div v-else-if="title == '歡慶台中廣三SOGO店開幕'">
        <newsdetail_20230302 />
    </div>
    <div v-else-if="title == 'GAGA monster 堅持力挺創客教育'">
        <newsdetail_20221207 />
    </div>
    <div v-else-if="title == '親子家庭一同體驗BMW臺北汎德'">
        <newsdetail_20221120 />
    </div>
</template>

<script>
import newsdetail_20230605 from '../components/common/news/newsdetail20230605.vue'
import newsdetail_20230601 from '../components/common/news/newsdetail20230601.vue'
import newsdetail_20230513 from '../components/common/news/newsdetail20230513.vue'
import newsdetail_20230511 from '../components/common/news/newsdetail20230511.vue'
import newsdetail_20230428 from '../components/common/news/newsdetail20230428.vue'
import newsdetail_20230329 from '../components/common/news/newsdetail20230329.vue'
import newsdetail_20230324 from '../components/common/news/newsdetail20230324.vue'
import newsdetail_20230302 from '../components/common/news/newsdetail20230302.vue'
import newsdetail_20221207 from '../components/common/news/newsdetail20221207.vue'
import newsdetail_20221120 from '../components/common/news/newsdetail20221120.vue'
export default {
    components: {
        newsdetail_20230605,
        newsdetail_20230601,
        newsdetail_20230513,
        newsdetail_20230511,
        newsdetail_20230428,
        newsdetail_20230329,
        newsdetail_20230324,
        newsdetail_20230302,
        newsdetail_20221207,
        newsdetail_20221120
    },
    data() {
        return {
            title: ''
        }
    },
    created() {
        this.title = this.$route.params.name
    }
}
</script>

<style>
h1 {
    color: #df2059;
}
.entry-title{
    text-align: center;
}
.page-wrapper {
    padding: 30px
}

.entry-header {
    width: 100%;
}

.row {
    margin: 0;
}

.col,
.columns {
    position: relative;
    margin: 0;
    padding: 0 15px 30px;
    width: 100%;
}

.post-time {
    padding-left: 1.5em;
    padding-right: 1.5em;
    display: flex;
    align-items: center;
}

.post-time span {
    font-size: 12px;
    color: #776e73;
}

.post-time img {
    width: 8%;
    margin-right: 5px;
}

.content {
    padding: 0 25px;
    font-size: 14px;
    line-height: 2;
}

.content img {
    width: 100%;
    margin-bottom: 0.5rem;
}

.img-group {
    text-align: center;
}

.img-group p {
    color: #776e73;
}

p {
    margin-top: 0;
    margin-bottom: 2rem;
}

@media screen and (max-width: 849px) {
    .col {
        padding-bottom: 30px;
    }
}

@media (max-width: 549px) {
    h1 {
        font-size: 1.2em;
    }
}

@media (min-width: 992px) {
    p {
        margin-bottom: 2rem;
        line-height: 2rem;
    }

    .page-wrapper {
        padding: 30px 20%;
    }

    .content {
        font-size: 16px;
        line-height: 1.5rem;
    }

    .content img {
        margin-bottom: 2rem;
    }
}
</style>