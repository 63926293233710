<template>
  <div>
    <div class="app-header-wrap">
      <!-- #region APP BAR -->
      <div class="row align-items-center app-header" v-show="!isMobile">
        <div class="logo col-1">
          <img src="../../assets/img/pc/home/top logo.png" alt="" />
        </div>
        <div class="menu-bar col-7">
          <div v-for="(item, index) in menuList" :key="index" class="menu-item">
            <router-link class="routerLink" :to="{ name: item.pathName }">
              {{ item.text }}</router-link>
          </div>
        </div>
        <div class="shopping-cart col-2">
          <span class="rounded-pill" @click="toggleShoppingCart">購物車

            <p v-show="cartCount > 0 && cartCount !== undefined" style="margin: inherit;">({{
              cartCount >= 100 ? "99+" : cartCount }})</p>
            <b-icon icon="cart4" class="icons"></b-icon>
          </span>
        </div>
        <div class="member col-2">
          <router-link class="routerLink" :to="{ name: 'signIn' }">
            <img src="../../assets/img/pc/home/member.png" alt="">
            <br>
            {{ loggedInUser ? '我的帳號' : '註冊/登入' }}
          </router-link>
        </div>
      </div>
      <!-- #endregion -->
      <!-- #region MOBILE APP BAR -->
      <div class="row align-items-center mobile app-header" v-show="isMobile">
        <div class="col-3">
          <img src="../../assets/img/mobile/home/(P)topbanner(menu).png" alt="menu" class="mobile-menu-img"
            @click="clickMenu()" />
        </div>
        <div class="col-1"></div>
        <div class="col-4">
          <router-link class="routerLink" :to="{ name: 'home' }">
            <img src="../../assets/img/mobile/home/toplogo(2).png" alt="logo" class="mobile-logo-img" />
          </router-link>
        </div>
        <div class="col-4">
          <div style="position: relative;">
            <p v-show="cartCount > 0 && cartCount !== undefined" class="msg-count-circle">
              {{ cartCount >= 100 ? "99+" : cartCount }}
            </p>
            <img src="../../assets/img/mobile/home/(P)topbanner(buy).png" alt="buy" class="mobile-buy-img"
              @click="toggleShoppingCart" />

            <router-link class="routerLink" :to="{ name: 'signIn' }">
              <img src="../../assets/img/mobile/home/(P)topbanner(member).png" alt="member" class="mobile-member-img" />
            </router-link>
          </div>
        </div>
      </div>
      <!-- #endregion -->
    </div>

    <!-- #region MOBILE MENU-->
    <div class="offcanvas offcanvas-start" :class="showMenu ? 'show' : ''" tabindex="-1"
      :style="{ visibility: showMenu ? 'visible' : 'hidden', width: '60vw' }">
      <div class="offcanvas-header">
        <!-- <div class="row">
          <input type="text" class="menu-search">
          <div class=" text-center px-0 col-2"
            style=" background-color:var( --main-text-color);display:flex;align-items: center; cursor: pointer;">
            <i class="fa-solid fa-magnifying-glass" style="padding-left: 10px;color: #ffffff;" />
          </div>
        </div> -->
      </div>
      <div class="offcanvas-body">
        <ul class="mobile-menu">
          <li v-for="(item, index) in menuList" :key="index" class="menu-item">
            <router-link class="routerLink" :to="{ name: item.pathName }" @click.native="clickMenu()">
              {{ item.text }}</router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="modal-backdrop fade" :class="showMenu ? 'show' : ''" @click="clickMenu()"
      :style="{ visibility: showMenu ? 'visible' : 'hidden' }">
    </div>
    <!-- #endregion -->
    <!-- #region 全部商品頁推薦輪播 -->
    <div v-if="$route.name == 'allProduct'">
      <swiper ref="bannerSwiper" :options="swiperOptions">
        <swiper-slide v-for="i in banner_list" :key="i.id">
          <img :src="i.url" />
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
    <!-- #endregion -->
    <!-- #region 常見問題頁titleImg -->
    <div v-if="$route.name == 'commonProblem'">
      <img :src="commonProblemTitleImg" alt="" style="width: 100%;">
    </div>
    <!-- #endregion -->
    <!-- #region 最新消息頁titleImg -->
    <div v-if="$route.name == 'news'">
      <img :src="newsTitleImg" alt="" style="width: 100%;margin-top: 60px;">
    </div>
    <!-- #endregion -->
    <!-- #region 星球據點頁titleImg -->
    <div v-if="$route.name == 'allPlace'">
      <img :src="placeTitleImg" alt="" style="width: 100%;">
    </div>
    <!-- #endregion -->
    <!-- #region 麵包屑導航 -->
    <BreadCrumb :pageType="pageType" :pageTypeStr="pageTypeStr" :params='params' :secPageTextColor="secPageTextColor"
      :isCheckOut="isCheckOut" v-if="!isHome" :style="{ 'padding-top': isUseTitleImg ? '10px' : '70px' }"
      style="padding-left: 20px;font-size: 14px;"></BreadCrumb>
    <!-- #endregion -->
    <!-- #region 購物車 -->
    <Cart />
    <!-- #endregion -->
  </div>
</template>

<script>
import SidebarMenu from "../sidebar/SidebarMenu.vue";
import BreadCrumb from "../common/BreadCrumb.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import { mapState, mapActions } from 'vuex'
import Cart from "../shoppingCart.vue";
export default {
  components: {
    Swiper,
    SwiperSlide,
    BreadCrumb,
    Cart
  },
  props: {
    //購物車數量
    cartCount: {
      type: Number,
    },
  },
  data() {
    return {
      menuList: [],
      showMobileMenu: false,
      showMenu: false,
      items: [
        {
          text: '首頁',
          disabled: false,
          href: '/'
        },
      ],
      booking: [],
      shopInfo: [{
        id: 'ggm01',
        shopName: '誠品信義店5F'
      }, {
        id: 'ggm02',
        shopName: '廣三SOGO店8F'
      }, {
        id: 'ggm03',
        shopName: 'SOGO天母店7F'
      }],
      banner_list: [
        {
          id: 1,
          url: require("../../assets/img/mobile/home/(P)BG-01.png"),
        },
        {
          id: 2,
          url: require("../../assets/img/mobile/home/(P)BG-01.png"),
        },
        {
          id: 3,
          url: require("../../assets/img/mobile/home/(P)BG-01.png"),
        },
      ],
      swiperOptions: {
        loop: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        autoplay: {
          delay: 5 * 1000,
          disableOnInteraction: false,
        },
      },
      showShoppingCart: false,
      pageType: '',
      pageTypeStr: '',
      params: '',
      secPageTextColor: '',
      isCheckOut: false,
      commonProblemTitleImg: require('../../assets/img/mobile/problem/title.png'),
      newsTitleImg: require('../../assets/img/mobile/news/title.jpg'),
      placeTitleImg: require('../../assets/img/mobile/place/title.png'),
      productList: [
        {
          id: 1,
          name: '史萊姆魔法棒',
          productImg: 'https://gagamonster.co/wp-content/uploads/2022/06/魔法棒-03-300x300.jpg',
          qty: 14,
          amount: 69
        },
        {
          id: 2,
          name: '史萊姆魔法棒222',
          productImg: 'https://gagamonster.co/wp-content/uploads/2022/06/魔法棒-03-300x300.jpg',
          qty: 1,
          amount: 40
        },
      ],
    };
  },
  computed: {
    ...mapState(['loggedInUser', 'isMobile']),
    isHome() {
      return this.$route.name === 'home'
    },
    isUseTitleImg() {
      const titlePage = ['commonProblem', 'allProduct', 'news', 'allPlace']
      const isExist = titlePage.find(x => x === this.$route.name)
      if (isExist) return true
      else return false
    },
    totalAmounts: function () {
      let total = 0;
      for (let i = 0; i < this.productList.length; i++) {
        total += this.productList[i].qty * this.productList[i].amount;
      }
      return total;
    }
  },
  created() {
    let user = this.$cookies.get("user");
    this.menuList = SidebarMenu.filter(x => x.show)
    if (user) {
      this.menuList = this.menuList.filter(x => x.pathName !== "signIn")
    }
  },
  mounted() {
    this.updateBreadcrumb()
  },
  watch: {
    $route() {
      this.updateBreadcrumb()
    }
  },
  methods: {
    ...mapActions(["toggleShoppingCart"]),
    clickMenu() {
      this.showMenu ? this.showMenu = false : this.showMenu = true;
    },
    clickShoppingCart() {
      this.showShoppingCart ? this.showShoppingCart = false : this.showShoppingCart = true;
    },
    updateBreadcrumb() {
      const nowRoute = SidebarMenu.find(x => x.pathName === this.$route.name)
      const routeParam = this.$route.params.name
      const path = this.$route.path.split('/')

      if (this.params != '' && path[5]) this.isCheckOut = true
      else this.isCheckOut = false

      this.pageType = nowRoute.pathName
      this.pageTypeStr = nowRoute.text
      if (this.loggedInUser && nowRoute.text == '登入') this.pageTypeStr = '我的帳號'
      if (routeParam) this.params = routeParam
      else this.params = ''
    },
    removeProduct(productId) {
      const index = this.productList.findIndex(product => product.id === productId);
      if (index !== -1) {
        this.productList.splice(index, 1);
      }
    }
  }
};
</script>

<style scoped>
.app-header-wrap {
  background-color: hsla(342, 64%, 43%, 0.92);
  animation-name: menu-slide;
  animation-fill-mode: both;
  animation-duration: 0.2s;
  flex-direction: column;
  position: fixed;
  width: 100vw;
  height: 60px;
  margin: 0px;
  z-index: 99;
  box-shadow: 0px 0px 0px -20px #b3b3b3;
  display: flex;
  justify-content: center;
}

.logo {
  margin: 10px 0 0 50px;
}

.logo img {
  width: 70px;
}

.menu-bar {
  display: flex;
  justify-content: center;
  color: white;
  position: relative;
}

.menu-item {
  padding: 10px;
  border-top: gray 1px solid;
  border-left: none;
  border-right: none;
}

.row {
  flex-wrap: inherit;
}

.shopping-cart span {
  background-color: white;
  width: 150px;
  display: inherit;
  padding-left: 15px;
}

.mobile {
  text-align: center;
  position: relative;
}

.mobile-header {
  text-align: center;
}

.mobile-menu-img {
  width: 30px;
  cursor: pointer;
}

.mobile-logo-img {
  width: 50px;
}

.mobile-buy-img {
  margin-right: 20px;
  width: 30px;
}

.mobile-member-img {
  margin-right: 20px;
  width: 30px;
}

.menu-search {
  width: 40vw;
  height: 25px;
  padding-right: 0;
  margin-left: 15px;
  outline: none !important;
  font-size: 14px;
  line-height: 1.428571429;
  color: #555555;
  vertical-align: middle;
  background-color: #ffffff;
  border: 1px solid #c1bdb8;
  border-radius: 4px;
  -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  -webkit-transition: all border-color ease-in-out .15s, box-shadow ease-in-out .15s ease-out;
  -moz-transition: all border-color ease-in-out .15s, box-shadow ease-in-out .15s ease-out;
  -o-transition: all border-color ease-in-out .15s, box-shadow ease-in-out .15s ease-out;
  transition: all border-color ease-in-out .15s, box-shadow ease-in-out .15s ease-out;
  border-radius: 0 !important;
  -webkit-appearance: none !important;
}

.mobile-menu {
  text-align: center;
}

.offcanvas-header {
  padding: 30px 0 0 20px;
}

.offcanvas-body {
  padding: 0;
  margin-top: 30px;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100%;
  height: 100vh;
}

ul {
  list-style-type: none;
  padding-left: 0;
}

.routerLink {
  display: inline-block;
  text-decoration: none;
  color: gray;
}

a {
  color: black;
  text-decoration: none;
}

.breadcrumb {
  --bs-breadcrumb-margin-bottom: 10px
}

.swiper-container {
  --swiper-navigation-color: #909090;
  --swiper-navigation-size: 24px;
  width: 100%;
}

.swiper-slide {
  width: 100% !important;
  height: 100%;
  cursor: pointer;

}

.swiper-slide img {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: block;
  object-fit: cover;
  position: center center;
}

.uppercase {
  line-height: 1.2;
  text-transform: uppercase;
  letter-spacing: .05em;
}

h4 {
  color: #df2059;
  font-weight: 700;
  font-family: "Lato", sans-serif;
  font-size: 1.125em;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0.5em;
  text-rendering: optimizeSpeed;
}

.inner-padding {
  padding: 20px;
}

.text-center {
  text-align: center;
}

.is-divider {
  margin-left: auto;
  margin-right: auto;
  height: 3px;
  display: block;
  background-color: rgba(0, 0, 0, .1);
  margin: 1em 0 1em;
  width: 100%;
  max-width: 30px;
}

ul.product_list_widget li img {
  top: 10px;
  position: absolute;
  left: 0;
  width: 60px;
  height: 60px;
  margin-bottom: 5px;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
}

ul {
  margin-bottom: 1.3em;
  margin-top: 0;
  padding: 0;
}

.widget_shopping_cart ul.product_list_widget li {
  padding-right: 30px;
}

ul.product_list_widget li {
  list-style: none;
  padding: 10px 0 5px 75px;
  min-height: 80px;
  position: relative;
  overflow: hidden;
  vertical-align: top;
  line-height: 1.33;
  margin-bottom: 0.6em;
}

ul.product_list_widget li .quantity {
  display: block;
  margin-top: 3px;
  font-size: .85em;
  opacity: .6;
  margin-right: 1em;
  white-space: nowrap;
  vertical-align: top;
}

ul.product_list_widget li a.remove {
  position: absolute;
  right: 0px;
  z-index: 9;
}

a.remove,
a.icon-remove {
  display: block;
  width: 24px;
  height: 24px;
  font-size: 15px !important;
  line-height: 19px !important;
  border-radius: 100%;
  color: #ccc;
  font-weight: bold;
  text-align: center;
  border: 2px solid currentColor;
}

p.total {
  text-align: center;
  padding: 10px 0;
  border-top: 1px solid #ececec;
  border-bottom: 2px solid #ececec;
  margin-bottom: 0.5em;
}

p {
  margin-top: 0;
}

span.amount {
  white-space: nowrap;
  color: #111;
  font-weight: bold;
}

.buttons input[type="button"] {
  width: 100%;
  margin: 0.5em 0 0;
  color: #fff;
  border-color: rgba(0, 0, 0, .05);
  padding: 5px;
}

.msg-count-circle {
  position: absolute;
  top: -10px;
  left: 31px;
  color: var(--main-text-color);
  font-weight: 500;
  background-color: #ffffff;
  font-size: 14px;
  padding: 1px 0px;
  min-width: 23px;
  margin-bottom: 0;
  border-radius: 999px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
<style scoped>
@media (min-width: 992px) {
  * {
    font-size: 15px;
  }

  .routerLink {
    color: white;
  }

  .menu-bar {
    white-space: nowrap;
  }

  .menu-item {
    border: none
  }

  .logo {
    margin: 0 0 0 40px
  }

  .logo img {
    width: 60px;
  }

  .shopping-cart span {
    display: flex;
    justify-content: space-around;
    height: 25px;
    align-items: center;
    font-size: 14px;
  }

  .icons {
    font-size: 1.4em;
    color: var(--main-text-color);
  }

  .member {
    text-align: center;
    color: white;
  }

  .member img {
    width: 30px;
  }
}
</style>