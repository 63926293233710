<template>
    <div>
        <!-- BEGIN login -->
        <div class="login login-box">
            <!-- BEGIN login-container -->
            <div class="login-container">
                <!-- BEGIN login-header -->
                <div class="login-header">
                    <div class="bg-white-shadow">
                        <!-- <img
                :src="logo_img_path"
                @error="imgOnError($event)"
                class="header-logo-img"
              /> -->
                        <span class="title-pink">設定新密碼</span>
                        <div class="row input-box">
                            <!-- 密碼設定 -->
                            <label class="col-12" v-bind:class="{ 'input-error': errclass_pwd }">
                                <span class="span-pink">密碼設定</span><small class="error-text text-right">請輸入密碼</small>
                            </label>
                            <label class="col-12" v-bind:class="{ 'input-error': errclass_pwd1 }">
                                <span class="input-icon"><img class="icon-Lock filter-orange" /></span>
                                <input type="password" v-model="user_pwd1" class="col-12 form-controller"
                                    placeholder="請輸入密碼" maxlength="16" />
                                <small class="tip-text" v-bind:class="{
                                    'tip-text': pwdclass_tip,
                                    'error-text': pwdclass_error,
                                }">{{ errMsg_pwd1 }}</small>
                            </label>
                            <label class="col-12 mt-2" v-bind:class="{ 'input-error': errclass_pwd2 }">
                                <span class="input-icon"><img class="icon-Lock filter-orange" /></span>
                                <input type="password" v-model="user_pwd2" class="col-12 form-controller" @input="err_pwd2"
                                    placeholder="請再次輸入密碼" maxlength="16" />
                                <small class="error-text">{{ errmsg_pwd2 }}</small>
                            </label>

                            <label class="col-12 label-btn mb-30px">
                                <button class="border-0 btn btn-long btn-confirm" @click="nextpage">
                                    <span class="btn-text">修改密碼完成</span>
                                </button>
                            </label>
                        </div>
                    </div>
                </div>
                <!-- END login-header -->
            </div>
            <!-- END login-container -->
        </div>
        <!-- END login -->
    </div>
</template>

<script>
import axios from "../api/axios.js";
export default {
    async created() {
        this.sno_user = this.$route.query.sno_user;
        this.token = this.$route.query.token;
    },
    data() {
        return {
            logo_img_path: "",
            //密碼長度
            GetPwdMinLen: "",
            //密碼(標題)
            errclass_pwd: false,
            //輸入密碼
            user_pwd1: "",
            errclass_pwd1: false,

            errMsg_pwd1: "密碼長度限制8至16個字元，必須包含至少一個英文字母。",
            pwdclass_tip: true,
            pwdclass_error: false,
            //再次輸入密碼
            user_pwd2: "",
            errclass_pwd2: false,
            errmsg_pwd2: "該密碼與您輸入的確認密碼不一致",
            sno_user:"",
            token:"",
        }
    },
    methods: {
        err_pwd2() {
            this.errclass_pwd2 = this.user_pwd2 !== this.user_pwd1 ? true : false;
        }, 
        nextpage: function() {
            if (this.user_pwd1 === this.user_pwd2 && this.user_pwd1 != "") {
                axios.post("User/Setpwd",{ sno_user : this.sno_user[0],token : this.token[0] ,user_pwd : this.user_pwd1,re_user_pwd:this.user_pwd2}).then(resp => {
                    if (resp.succ) {
                        this.$swal(resp.result).then(() => {
                                    this.$router.push({
                                        name: "home",
                                    });
                                });
                    } else {
                        this.$swal(resp.msg);
                        //alert(resp.msg);
                    }
                })
            }
        },
    },
}
</script>

<style scoped>
.login-box {
    min-height: calc(100vh - 105px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    margin-bottom: 0;
}

.login-container {
    padding: 0px;
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
.bg-white-shadow {
    padding: 14px 32px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.header-logo-img {
    width: 350px;
}
.title-pink {
    color: var(--main-text-color);
    font-size: 28px;
    font-weight: 700;
    display: block;
    text-align: center;
    padding: 20px 0 20px;
}
.error-text {
    visibility: hidden;
    color: #f20505;
    font-weight: 900;
    padding: 4px 0 0 4px;
    letter-spacing: -1px;
    font-size: 10px;
}
input{
    padding: 7px;
}
.btn-long {
    width: 100%;
    height: 50px;
    cursor: pointer;
    border-radius: 8px;
    font-weight: 600;
}
.btn-confirm {
    background: var(--main-text-color)!important;
    color: #ffffff!important;
}

.input-box label {
    position: relative;
    margin: 3px 0 0 0;
    padding: 0 3px;
}

.input-icon {
  position: absolute;
  top: 10px;
  left: 15px;
}
.input-icon~input {
    padding-left: 40px;
}
.tip-text {
    color: #6F6F6F;
    letter-spacing: -.7px;
}

@media (min-width: 767.98px) {
    .login-container {
        max-width: 600px;
    }
}
</style>