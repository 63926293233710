<template>
    <div class="page-wrapper">
        <div class="row row-large row-divided">
            <div class="large-12 col" style="padding-left:0;padding-right:0;">
                <div class="entry-header">
                    <h1 class="entry-title">GAGA monster盛大一週年慶典<br>
                        🎉歡 / 慶 / 週 / 年 / 慶🎉<br>
                        活動期間：3/24 - 4/9<br>
                        場場加贈驚喜活動
                    </h1>
                </div>
            </div>
            <div class="post-time">
                <span><img src="../../../assets/img/mobile/news/time.png" alt="">2023-03-24</span>
            </div>
            <div class="content col-12">
                <div class="img-group">
                    <img v-lazy="Img1" alt="">
                    <img v-lazy="Img2" alt="">
                </div>
                <div style="text-align: center;font-size: 16px;">
                    <p><b>❮新課程上市❯</b></p>
                </div>
            </div>
            <p>慶祝GAGAmonster1歲生日!!!以創意料理聞名的廚師長，想要製作出慶祝派對甜點，前往「奶酪星球」探險時，好多牛奶凍散落在星球上，特級廚師長使出看家本領，邀請小小探險家共同研發，製作出Q彈冰涼又綿密的❰奶凍怪獸❱</p>
            <div class="content col-12">
                <div class="img-group">
                    <img v-lazy="Img4" alt="">
                </div>
                <div style="text-align: center;font-size: 16px;">
                    <p><b>❮新品上市❯</b></p>
                    <p>五彩繽紛的史萊姆在宇宙星空中閃耀著彩虹般的繽紛色彩。</p>
                </div>
                <img v-lazy="Img5" alt="">
                <div style="text-align: center;font-size: 16px;">
                    <p><b>❮期間小活動❯</b></p>
                </div>
                <p> 噗噗！是誰在偷放屁？與屁屁怪獸一起展開星球攻擊！<br>
                    於活動期間參與課程，製作史萊姆怪獸後加贈「屁屁怪獸」</p>
                <img v-lazy="Img6" alt="">
                <div style="text-align: center;font-size: 16px;">
                    <p><b>❮全年最划算❯</b></p>
                </div>
                <p>凡是於周年慶期間2023/3/24-4/9儲值會員最低下殺7折起<br>
                    再加贈【品牌帆布袋】會員扣點再贈驚喜小禮!<br>
                    週年慶期間商品組合豪華重量箱 超值優惠價
                </p>
                <img v-lazy="Img7" alt="">
                <div style="text-align: center;font-size: 16px;">
                    <p><b>❮來店裡換來店禮❯</b></p>
                </div>
                <p>上課即贈限定徽章，只送不賣!!!!!<br>
                    追蹤打卡分享，換更多限定徽章+驚喜小禮
                </p>
                <img v-lazy="Img8" alt="">
                <img v-lazy="Img9" alt="">
                <img v-lazy="Img10" alt="">
                <img v-lazy="Img11" alt="">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'newsdetail20230329',
    data() {
        return {
            Img1: require('../../../assets/img/mobile/news/20230324/1.jpg'),
            Img2: require('../../../assets/img/mobile/news/20230324/2.jpg'),
            Img4: require('../../../assets/img/mobile/news/20230324/4.jpg'),
            Img5: require('../../../assets/img/mobile/news/20230324/5.jpg'),
            Img6: require('../../../assets/img/mobile/news/20230324/6.jpg'),
            Img7: require('../../../assets/img/mobile/news/20230324/7.jpg'),
            Img8: require('../../../assets/img/mobile/news/20230324/8.jpg'),
            Img9: require('../../../assets/img/mobile/news/20230324/9.jpg'),
            Img10: require('../../../assets/img/mobile/news/20230324/10.jpg'),
            Img11: require('../../../assets/img/mobile/news/20230324/11.jpg'),
        }
    },
}
</script>