<template>
    <div>
        <div class="bg-card">
            <div class="large-12 col" style="padding-left:0;padding-right:0;">
                <div class="entry-header">
                    <h1 class="entry-title">{{ title }}</h1>
                </div>
            </div>
            <div ref="shareByUserContent" v-html="Portfolio" style="margin: 50px 30px 50px 50px"></div>
        </div>
    </div>
</template>
  
<script>
export default {
    async created() {
        //   AppOptions.appSidebarLight = true;
        //   AppOptions.appHeaderInverse = true;
        //   if (AppOptions.param.demoMode) {
        //     this.projectMode;
        //   } else {
        //     this.callApi(API_ROUTE.Home.GetEMPPortfolio, { SNO_EMP: this.$route.query.emp_sno }, {});
        //   }
        this.title = this.$route.params.name
    },
    data() {
        return {
            Portfolio: "",
            title: "",
        };
    },
    updated() {
        this.$nextTick(() => {
            const shareByUserContent = this.$refs.shareByUserContent;

            if (shareByUserContent) {
                const paragraphs = shareByUserContent.querySelectorAll('p');
                const blockquotes = shareByUserContent.querySelectorAll('blockquote');
                const images = shareByUserContent.querySelectorAll('img');
                const centerAlignedElements = shareByUserContent.getElementsByClassName('ql-align-center');
                const windowWidth = window.innerWidth;
                const fontSize = windowWidth <= 1200 ? '24px' : '16px';

                paragraphs.forEach((p) => {
                    p.style.fontSize = fontSize;
                });

                blockquotes.forEach((bq) => {
                    bq.style.fontSize = fontSize;
                });

                images.forEach((img) => {
                    img.style.maxWidth = '100%';
                });

                centerAlignedElements.forEach((element) => {
                    element.style.textAlign = 'center';
                });
            } else {
                console.error('Portfolio content not found.');
            }
        });
    },

    methods: {

    },
};
</script>
  
  
<style lang="scss" scoped>
.pers-title {
    padding: 24px 40px 14px;
}

.pers-title-name {
    font-size: 20px;
}

.pers-title-more {
    font-size: 14px;
    font-weight: normal;
}

@media (max-width: 1200px) {
    .pers-title-name {
        font-size: 24px;
    }
}
</style>
  