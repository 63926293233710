<template>
    <div>
        <div>
            <img src="../assets/img/element/error/404頁面.jpg" alt="404 error" style="width: 100%;">
        </div>
        <div class="text-center row justify-content-center d-flex btn-area">
            <div class="col-6">
                <button @click="goHome">返回首頁</button>
            </div>
            <div class="col-6">
                <button @click="goOnlineAppointment">前往預約課程</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        goHome() {
            this.$router.push({ name: 'home' })
        },
        goOnlineAppointment() {
            this.$router.push({ name: 'onlineAppointment' })
        }
    }
}
</script>

<style scoped>
.row {
    margin: 0;
}

button {
    background-color: #e91e63;
    border: none;
    color: white;
    padding: 5px 30px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 20px !important;
}

.btn-area {
    margin-bottom: 10%;
}

@media screen and (min-width: 850px) {
    button {
        padding: 10px 45px;
    }

}
</style>