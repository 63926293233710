<style scoped>
.input-err-border {
  border-color: var(--ft-error) !important;
  border: 1.5px solid;
}

textarea {
  padding: 12px 8px;
}
</style>

<template>
  <div>
    <VueFormElement :HeaderText="Options.HeaderText" :RemarkText="Options.RemarkText" :ErrorMessage="ErrorMessage"
      :ShowErrorMessage="Options.ShowErrorMessage" :SectionStyle="SectionStyle" :required="Options.required">
      <template #custom-content>
        <textarea v-model="parentValue" :placeholder="Placeholder" :disabled="Disabled" :maxlength="Maxlength"
          :class="[{ 'input-err-border': Options.ShowErrorMessage }]" :style="[{ height: Height }]"
          class="w-100"></textarea>
      </template>
    </VueFormElement>
  </div>
</template>

<script>
import VueFormElement from "./Form-element.vue";

export default {
  components: {
    VueFormElement,
  },
  props: {
    value: { require: true },
    Options: Object,
    SectionStyle: String,
  },
  computed: {
    parentValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    ErrorMessage: {
      get() {
        if (this.Options.ErrorMessage === undefined) {
          return "未輸入";
        } else {
          return this.Options.ErrorMessage;
        }
      },
    },
    Placeholder: {
      get() {
        if (this.Options.Placeholder === undefined) {
          return "請輸入" + this.Options.HeaderText;
        } else {
          return this.Options.Placeholder;
        }
      },
    },
    Disabled: {
      get() {
        if (this.Options.disabled === undefined) {
          return false;
        } else {
          return this.Options.disabled;
        }
      },
    },
    Maxlength: {
      get() {
        if (this.Options.maxlength === undefined) {
          return "";
        } else {
          return this.Options.maxlength;
        }
      },
    },
    Height: {
      get() {
        if (this.Options.height === undefined) {
          return "100px";
        } else {
          return this.Options.height;
        }
      },
    },
  },
};
</script>
