<script>


export default {	
  data()
	{
		return {
			acc_token:String,
			ref_token:String,
			sno_user:String,
			remeberFlag:false
		}
	}
}
</script>>