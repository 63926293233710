<template>
  <div class="topLoginOkMobile">
    <!-- #region 輪播 -->
    <div style="padding-top: 60px;">
      <bannerSwiper />
    </div>
    <!-- #endregion -->
    <!-- #region 介紹 -->
    <div class="about">
      <img v-lazy="BG02Img" alt="">
      <img v-lazy="BG03Img" alt="為何選擇 GAGA monste">
      <!-- <h5 class="about-title"></h5> -->
      <div class="about-info-group">
        <p class="about-info-title"><b>GAGA monster史萊姆怪獸星球從思考到創造，開發潛藏的創意力！</b></p>
        <p class="about-info-sub">思考＋創造＝創意力</p>
        <p class="about-info">隨著科技的進步、AI人工智慧的突破，有「想法」，變得前所未有的重要。孩子們的未來，將由創意力來主導！如何一邊磨練邏輯思考，又能解放想像、盡情創造呢?</p>
        <p class="about-info">GAGA monster史萊姆怪獸星球和你的孩子一起，從思考到創造，開發潛藏的創意力</p>
      </div>
      <div class="about-footer">
        <img v-lazy="bannerA3Img" alt="展開一場與史萊姆怪獸的探險旅程" id="about-footer-bannerA">
        <!-- <span id="about-footer-bannerA-text"></span> -->
      </div>
    </div>
    <!-- #endregion -->
    <!-- #region 課程 -->
    <div class="class">
      <img v-lazy="BG04Img" alt="最有趣的課程">
      <!-- <span id="class-title"></span> -->
      <p id="class-info">在星際探險之旅當中，小朋友們將化身史萊姆探險家，伴隨著故事引導，運用現場的各式材料與工具，發揮創意與創造力，進化出不同的史萊姆怪獸。</p>
      <span class="rounded-pill" id="register-now"><router-link class="routerLink" :to="{ name: 'onlineAppointment' }">
          立即報名</router-link></span>
      <!-- #region 課程照片輪播 -->
      <div class="classPic">
        <classPicSwiper />
        <div style="position: relative;">
          <img :src="girlImg" alt="" style="width: 39%;top: -555px;left: 100%;position: absolute;z-index: 2;">
        </div>
      </div>
      <!-- #endregion -->
      <div class="class-footer">
        <img v-lazy="bannerBImg" alt="創造一個前所未有的感官冒險旅程" id="bannerB">
        <!-- <span id="bannerB-text"></span> -->
      </div>
    </div>
    <!-- #endregion -->
    <!-- #region 商品 -->
    <div class="product">
      <img v-lazy="BG05Img" alt="解鎖孩子的創造力">
      <!-- <span id="product-title">解鎖孩子的創造力</span> -->
      <p id="product-info">好奇心是孩子與生俱來的寶物，我們引導孩子維持、延續好奇心，從創作中培養專注度。
        創造的過程中，有著無限變化的史萊姆，讓孩子從獨立的創造思維到邏輯的建構技巧。
      </p>
      <span id="product-slogan">⭐ 獨立學習 ⭐ 培養專注 ⭐ 激發好奇</span>
      <span class="rounded-pill" id="buy-product">購買星商品</span>
      <!-- #region 課程照片輪播 -->
      <div class="productPic">
        <productSwiper />
      </div>
      <!-- #endregion -->
    </div>
    <!-- #endregion -->
    <!-- #region 使用者體驗 -->
    <div class="userExperience">
      <img v-lazy="BG06Img" alt="" id="userExperience-banner">
      <img v-lazy="BG07Img" alt="使用者分享美好的體驗過程">
      <!-- <span id="userExperience-title">使用者分享美好的體驗過程</span> -->
      <!-- #region 使用者體驗照片輪播 -->
      <div class="userExperiencePic">
        <userExperienceSwiper />
      </div>
      <!-- #endregion -->
    </div>
    <!-- #endregion -->
    <!-- #region 師資 -->
    <div class="teacher">
      <img v-lazy="BG08Img" alt="我們的師資">
      <!-- <span id="teacher-title">我們的師資</span> -->
      <p id="teacher-info">好奇心是孩子與生俱來的寶物，我們引導孩子維持、延續好奇心，從創作中培養專注度。
        創造的過程中，有著無限變化的史萊姆，讓孩子從獨立的創造思維到邏輯的建構技巧。
      </p>
    </div>
    <!-- #endregion -->
    <!-- #region 最新消息 -->
    <div class="news">
      <img v-lazy="BG09Img" alt="最新消息">
      <!-- <span id="news-title">最新消息</span> -->
      <!-- #region 最新消息照片輪播 -->
      <div class="newsPic">
        <newsPicSwiper />
      </div>
      <!-- #endregion -->
    </div>
    <!-- #endregion -->
  </div>
</template>

<script>
import bannerSwiper from "../components/swiper/bannerSwiper.vue";
import classPicSwiper from "../components/swiper/classPicSwiper.vue";
import productSwiper from "../components/swiper/productSwiper.vue";
import userExperienceSwiper from "../components/swiper/userExperienceSwiper.vue";
import newsPicSwiper from "../components/swiper/newsPicSwiper.vue";
// import "../assets/css/style.css";
import { mapState } from 'vuex'
export default {
  components: {
    bannerSwiper,
    classPicSwiper,
    productSwiper,
    userExperienceSwiper,
    newsPicSwiper,
  },
  data() {
    return {
      bannerA3Img: '',
      bannerBImg: '',
      BG02Img: '',
      BG03Img: '',
      BG04Img: '',
      BG05Img: '',
      BG06Img: '',
      BG07Img: '',
      BG08Img: '',
      BG09Img: '',
      girlImg: ''
    }
  },
  created() {
    if (this.isMobile) {
      this.bannerA3Img = require('../assets/img/mobile/home/(P)bannerA3.png'),
        this.bannerBImg = require('../assets/img/mobile/home/(P)bannerB.png'),
        this.BG02Img = require('../assets/img/mobile/home/(P)BG-02.png'),
        this.BG03Img = require('../assets/img/mobile/home/(P)BG-03.png'),
        this.BG04Img = require('../assets/img/mobile/home/(P)BG-04.png'),
        this.BG05Img = require('../assets/img/mobile/home/(P)BG-05.png'),
        this.BG06Img = require('../assets/img/mobile/home/(P)BG-06.jpg'),
        this.BG07Img = require('../assets/img/mobile/home/(P)BG-07.png'),
        this.BG08Img = require('../assets/img/mobile/home/(P)BG-08.png'),
        this.BG09Img = require('../assets/img/mobile/home/(P)BG-09.png')
    }
    else {
      this.bannerA3Img = require('../assets/img/pc/home/bannerA.png'),
        this.bannerBImg = require('../assets/img/pc/home/bannerB.png'),
        this.BG02Img = require('../assets/img/pc/home/BG-02.png'),
        this.BG03Img = require('../assets/img/pc/home/BG-03.png'),
        this.BG04Img = require('../assets/img/pc/home/BG-04.png'),
        this.BG05Img = require('../assets/img/pc/home/BG-05.png'),
        this.BG06Img = require('../assets/img/pc/home/BG-06.jpg'),
        this.BG07Img = require('../assets/img/mobile/home/(P)BG-07.png'),
        this.BG08Img = require('../assets/img/mobile/home/(P)BG-08.png'),
        this.BG09Img = require('../assets/img/mobile/home/(P)BG-09.png'),
        this.girlImg = require('../assets/img/pc/home/girl.png')
    }
  },
  computed: {
    swiper() {
      return this.$refs.bannerSwiper.$swiper;
    },
    ...mapState({
      isMobile: state => state.isMobile,
    }),
  },
  methods: {

  }
}
</script>

<style scoped>
.topLoginOk {
  position: relative;
  top: -30px;
}

.topLoginOkMobile {
  position: relative;
  width: 100%;
}

.about {
  position: relative;
}

.about img {
  width: 100%;
}

.about-title {
  position: absolute;
  top: 46.4%;
  left: 52%;
  transform: translate(-46.4%, -52%);
  width: 230px;
  font-weight: bolder;
}

.about-info-group {
  position: absolute;
  top: 67%;
  left: 68%;
  transform: translate(-73%, -54%);
}

.about-info {
  width: 250px;
  font-size: 12.5px;
}

.about-info-title {
  color: #DF2059;
  font-size: 18px;
}

.about-info-sub {
  width: 250px;
  font-size: 16px;
  color: #F39C3F;
}

.about-footer {
  position: relative;
}

#about-footer-bannerA {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* top: 1050px;
  right: 0px; */
}

#about-footer-bannerA-text {
  color: #ffffff;
  position: absolute;
  /* top: 45%; */
  left: 50%;
  transform: translate(-50%, -15%);
  width: 250px;
  /* top: 1090px;
  right: 50px; */
}

.class {
  position: relative;
}

.class img {
  width: 100%;
}

#class-title {
  position: absolute;
  top: 36.5%;
  left: 20%;
  transform: translateY(15%);
  font-weight: bolder;
}

#class-info {
  position: absolute;
  font-size: 15px;
  top: 38%;
  left: 15%;
  width: 150px;
}

#register-now {
  padding: 5px 37px;
  background-color: #ffcf00;
  cursor: alias;
  position: absolute;
  top: 60%;
  left: 15%;
  font-weight: bolder;
}

.classPic {
  position: absolute;
  top: 67%;
}


.product {
  position: relative;
}

.product img {
  width: 100%;
  /* margin-top: 20px; */
}

#product-title {
  position: absolute;
  top: 44.6%;
  left: 33%;
  font-size: 18px;
  font-weight: bolder;
}

#product-info {
  position: absolute;
  font-size: 13px;
  top: 52%;
  left: 15%;
  width: 300px;
  line-height: 30px;
}

#product-slogan {
  position: absolute;
  font-size: 15px;
  top: 70%;
  left: 18%;
  width: 300px;
  color: var(--main-text-color);
}

#buy-product {
  padding: 5px 37px;
  background-color: #ffcf00;
  cursor: alias;
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translateX(-50%);
  font-weight: bolder;
}

.productPic {
  position: absolute;
  top: 82%;
}

.userExperience {
  position: relative;
}

.userExperience img {
  width: 100%;
}

#userExperience-banner {
  margin-top: 100px;
}

#userExperience-title {
  position: absolute;
  top: 60.5%;
  left: 52%;
  transform: translateX(-50%);
  font-size: 20px;
  width: 250px;
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff,
    1px 1px 0 #fff;
}

.userExperiencePic {
  position: absolute;
  top: 70%;
}

.teacher {
  position: relative;
}

.teacher img {
  width: 100%;
}

#teacher-title {
  position: absolute;
  top: 2%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 20px;
  font-weight: bolder;
}

#teacher-info {
  position: absolute;
  font-size: 13px;
  top: 12%;
  left: 50%;
  transform: translateX(-50%);
  width: 300px;
  line-height: 26px;
}

.news {
  position: relative;
}

.news img {
  width: 100%;
}

#news-title {
  position: absolute;
  top: 1.5%;
  left: 51%;
  transform: translateX(-50%);
  font-size: 20px;
  font-weight: bolder;
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff,
    1px 1px 0 #fff;
}

.newsPic {
  position: absolute;
  top: 16%;
}

.footerPic {
  position: absolute;
  top: 98%;
}

.footerPic img {
  /* width: 100vw; */
}

.routerLink {
  display: inline-block;
  text-decoration: none;
  color: black;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-title {
    font-size: 35px;
    left: 45%;
    white-space: nowrap;
  }

  .about-info-group {
    top: 69%;
    left: 15%;
  }

  .about-info-title {
    width: 450px;
    font-size: 28px;
  }

  .about-info-sub {
    font-size: 25px;
  }

  .about-info {
    width: 450px;
    font-size: 20px;
  }

  #about-footer-bannerA-text {
    font-size: 30px;
    white-space: nowrap;
    width: auto;
  }

  #class-title {
    font-size: 30px;
  }

  #class-info {
    width: 250px;
    font-size: 23px;
  }

  #register-now {
    font-size: 25px;
  }


  #product-title {
    font-size: 30px;
    left: 50%;
    transform: translateX(-50%);
  }

  #product-info {
    font-size: 23px;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
  }

  #product-slogan {
    font-size: 25px;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    white-space: nowrap;
  }

  .productPic {
    top: 79%;
  }

  #buy-product {
    font-size: 25px;
  }

  .userExperiencePic {
    top: 66%;
  }

  #teacher-info {
    font-size: 23px;
    width: auto;

  }
}
</style>

<style scoped>
@media (min-width: 992px) {
  .about-title {
    letter-spacing: 1.5px;
    top: 34%;
    left: 19%;
    width: 300px;
    transform: none;
    font-size: 1.5rem;
  }

  .about-info-group {
    top: 38%;
    left: 15%;
    transform: none;
  }

  .about-info-title {
    width: 450px;
    font-size: 28px;
  }

  .about-info-sub {
    font-size: 25px;
  }

  .about-info {
    width: 450px;
    font-size: 20px;
  }

  #about-footer-bannerA-text {
    font-size: 20px;
    width: 300px;
  }

  #class-title {
    letter-spacing: 1.5px;
    top: 18.5%;
    left: 19%;
    font-size: 1.5rem;
  }

  #class-info {
    font-size: 20px;
    top: 22%;
    left: 19%;
    width: 350px;
    line-height: 2.5rem;
  }

  #register-now {
    padding: 5px 65px;
    top: 37%;
    left: 19%;
    font-size: 20px;
  }

  .classPic {
    top: 47%;
    left: 10%;
  }

  #product-info {
    font-size: 20px;
    top: 26%;
    left: 59%;
    width: 400px;
    line-height: 2.5rem;
  }

  #product-slogan {
    font-size: 20px;
    top: 44%;
    left: 59%;
    width: 400px;
  }

  #buy-product {
    padding: 7px 65px;
    top: 52%;
    left: 66%;
  }

  .productPic {
    top: 66%;
  }

  .userExperiencePic {
    top: 58%;
  }

  #teacher-info {
    font-size: 24px;
    top: 13%;
    width: auto;
    line-height: 3rem;
  }
}
</style>