<template>
    <div>
        <swiper ref="bannerSwiper" :options="swiperOptions" @slideChange="handleBannerChange">
            <swiper-slide v-for="i in banner_list" :key="i.id">
                <img :src="i.url" />
            </swiper-slide>
            <!-- DESKTOP -->
            <div v-show="show_prev_btn" class="swiper-button-prev desktop-only" slot="button-prev"></div>
            <div v-show="show_next_btn" class="swiper-button-next desktop-only" slot="button-next"></div>
            <!-- MOBILE -->
            <div v-show="active_swipe_type" class="swiper-button-prev mobile-only" slot="button-prev"></div>
            <div v-show="active_swipe_type" class="swiper-button-next mobile-only" slot="button-next"></div>
            <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import { mapState } from 'vuex'
export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    created() {
        if (this.isMobile) this.banner_list = this.banner_list_mobile
        else this.banner_list = this.banner_list_pc
    },
    computed: {
        ...mapState({
            isMobile: state => state.isMobile,
        }),
    },
    data() {
        return {
            show_prev_btn: false,
            show_next_btn: false,
            active_swipe_type: true,
            banner_list: [],
            banner_list_mobile: [
                {
                    id: 7,
                    url: require("../../assets/img/mobile/home/banner7.jpg"),
                },
                {
                    id: 1,
                    url: require("../../assets/img/mobile/home/banner1.jpg"),
                },
                {
                    id: 2,
                    url: require("../../assets/img/mobile/home/banner2.jpg"),
                },
                {
                    id: 6,
                    url: require("../../assets/img/mobile/home/banner6.jpg"),
                },
                {
                    id: 3,
                    url: require("../../assets/img/mobile/home/banner3.jpg"),
                },
                {
                    id: 4,
                    url: require("../../assets/img/mobile/home/banner4.jpg"),
                },
                {
                    id: 5,
                    url: require("../../assets/img/mobile/home/banner5.jpg"),
                },
            ],
            banner_list_pc: [
                {
                    id: 7,
                    url: require("../../assets/img/pc/home/banner7.jpg"),
                },
                {
                    id: 1,
                    url: require("../../assets/img/pc/home/banner1.jpg"),
                },
                {
                    id: 2,
                    url: require("../../assets/img/pc/home/banner2.jpg"),
                },
                {
                    id: 3,
                    url: require("../../assets/img/pc/home/banner3.jpg"),
                },
                {
                    id: 4,
                    url: require("../../assets/img/pc/home/banner4.jpg"),
                },
                {
                    id: 5,
                    url: require("../../assets/img/pc/home/banner5.jpg"),
                },
            ],
            swiperOptions: {
                loop: true,
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                },
                autoplay: {
                    disableOnInteraction: false,
                },
            },
        }
    },
    methods: {
        handleBannerChange() {
            this.bannerBtn(this.show_swipe_btn);
        },
        bannerBtn(mouseEnter) {
            this.show_swipe_btn = mouseEnter;
            if (this.show_swipe_btn) {
                if (this.swiper.realIndex == 0) {
                    this.show_next_btn = true;
                    this.show_prev_btn = false;
                } else if (this.swiper.realIndex + 1 === this.banner_list_mobile.length) {
                    this.show_prev_btn = true;
                    this.show_next_btn = false;
                } else {
                    this.show_prev_btn = true;
                    this.show_next_btn = true;
                }
            } else {
                this.show_prev_btn = false;
                this.show_next_btn = false;
            }
        },
    }
}
</script>

<style scoped>
.swiper-container {
    --swiper-navigation-color: #909090;
    --swiper-navigation-size: 24px;
    width: 100vw;
}

.swiper-slide {
    width: 100% !important;
    height: 100%;
    cursor: pointer;

}

.swiper-slide img {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: block;
    object-fit: cover;
    position: center center;
}

.swiper-container-horizontal>.swiper-pagination-bullets {
    width: 60%;
}

.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
    background: white;
}


.swiper-button-next,
.swiper-button-prev {
    /* background: #cbab9b; */
    opacity: 0.9;
    border-radius: 999px !important;
    width: 64px !important;
    height: 64px !important;
    top: 50%;
    margin: 10px;
    transform: translateY(-50%);
    color: #ffffff;
}

@media (min-width: 992px) {
    .swiper-container-horizontal>.swiper-pagination-bullets {
        height: 12%;
    }
}
</style>