<template>
  <div id="body-bg" class="row justify-content-center">
    <b-card no-body class="card col-lg-3 col-xl-3 col-12">
      <div class="row">
        <!-- <div class="col-6 info-title">
                    <span>訂單編號</span><br />
                    <span>12345678</span>
                </div> -->
        <div class="col-6 info-title date">
          <span>日期</span><br />
          <span>{{ booking.TRANS_DATE }}</span>
        </div>
      </div>
      <div class="text-left row info d-flex justify-content-between">
        <div class="col-12">付款方式 : {{ booking.TRANS_PAY_NAME }}</div>
        <div class="col-5">人數 : {{ booking.NUM }}</div>
        <div class="col-12">訂金 : {{ booking.AMOUNT }}</div>
        <div class="col-12">狀態 : {{ booking.TRANS_STATUS_NAME }}</div>
        <div class="col-12">訊息 : {{ booking.MSG }}</div>
        <div class="col-6"><button @click="goHome()">回首頁</button></div>
        <div class="col-6"><button @click="goAppointment()">預約資訊</button></div>
        <!-- <div class="col-12">
                    <ul>
                        <li>每人預收100訂金,可全額扣抵課程金額。</li>
                        <li>會員可使用購物金支付。</li>
                        <li>於課程48小時前可免費取消,款項依原支付方式退回。</li>
                    </ul>
                </div> -->
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import AppOptions from "../config/AppOptions.vue";
import axios from "../api/axios.js";
export default {
  data() {
    return {
      selected: "",
      booking: {},
      trans_id: "",
      userPoint:0,
      THIRD_PARAM: {
        HOST: "",
        WEB_ID_CREDIT: "S1607299011",
        TRANS_PWD: "a12345678",
        WEB_ID_ATM: "S1607299037",
      },
    };
  },
  computed: {
    ...mapState(["bookingDetail", "loggedInUser"]),
    formattedDate() {
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      };
      const currentDate = new Date(this.booking.date);
      return (
        currentDate.toLocaleDateString("zh-TW", options).replace(/\//g, ".") +
        " (" +
        this.getWeekday(currentDate) +
        ")"
      );
    },
  },
  created() {    
    if (this.$route.query.msg != undefined) {
      this.booking.MSG = this.$route.query.msg;
    }
    if (this.$route.query.id != undefined) {
      axios
      .post("/Trans/GetTransResult", {
          TRANS_ID: this.$route.query.id,
        })
      .then((resp) => {
        if (resp.succ) {
          this.booking = resp.result[0];
        }
      });
      
      this.callApi(
        API_ROUTE.ESAFE.GetClass,
        { TRANS_ID: this.$route.query.id },
        {}
      );
      return;
    }
   
  },
  methods: {
    goHome() {
      this.$router.push({ name: 'home' })
    },
    goAppointment() {
      this.$router.push({ name: 'signIn' })
    },
    GetUserPoint(sno) {
        axios.post("/User/GetUserPoint", { Sno_user: sno }).then(resp => {
            if (resp.succ) {
                this.userPoint = resp.result;
            }
        });
    },
    nextPay() {},
    getWeekday(date) {
      const weekdays = ["日", "一", "二", "三", "四", "五", "六"];
      return weekdays[date.getDay()];
    },
    checkout() {
      let loader = this.$loading.show();
      if(this.bookingDetail.formData.pay_type.value=="POINT" && this.userPoint<this.booking.amount)
      {
        this.$swal("點數不足!");
        return;
      }
      var TransShoppingCart = [];
      TransShoppingCart.push({
        NAME: this.bookingDetail.formData.user_name.value,
        EMAIL: this.bookingDetail.formData.user_email.value,
        PHONE: this.bookingDetail.formData.user_phone.value,
        SEND_TYPE: "STORE",
        DELIVERY_NAME: this.bookingDetail.formData.user_name.value,
        DELIVERY_EMAIL: this.bookingDetail.formData.user_email.value,
        DELIVERY_PHONE: this.bookingDetail.formData.user_phone.value,
        DELIVERY_FEE:0,
        STORE: 0,
        NOTE: this.bookingDetail.formData.note.value,
        PAY_TYPE: this.bookingDetail.formData.pay_type.value,
      });
      let TransPays = [];
      if (this.bookingDetail.formData.pay_type.value=="POINT") {
        //
        TransPays.push({
          PAY_ID: "POINT",
          AMT: this.booking.amount,
        });        
      }
      else
      {
        TransPays.push({
          PAY_ID: this.bookingDetail.formData.pay_type.value,
          AMT: this.booking.amount,
          CARD_TYPE: "THIRD",
        });
      }

      let req = {
        SNO_USER: this.loggedInUser.sno,
        SNO_STORE: 0,
        TRANS_STATUS: this.bookingDetail.formData.pay_type.value=="POINT" ? "OK":"WAIT_PAY",
        TRANS_PAY_ID: this.bookingDetail.formData.pay_type.value,
        EINV_VEHICLE_NO1: this.bookingDetail.formData.einv_vehicle_no,
        BUYER_TAXID: this.bookingDetail.formData.buyer_taxid,
        BOOKING_DATE: this.booking.date,
        BOOKING_TIME: this.booking.time,
        TransItems: [
          {
            SNO_GROUP_CLASS: this.bookingDetail.id,
            QTY: this.booking.qty,
            AMT: this.booking.amount,
          },
        ],
        TransPays: TransPays,
        TransShoppingCart
      };

      let vm = this;
      axios
        .post("/Trans/TransCreate", req)
        .then((resp) => {
          loader.hide()
          if (resp.succ) {
            vm.trans_id=resp.result.TRANS_ID;
            if(vm.bookingDetail.formData.pay_type.value=="POINT")
            {
              axios
              .post("/Group/GroupClassBookingCreate", {
                trans_id:vm.trans_id
              })
              .then((resp) => {
                if (resp.succ) {
                  this.$swal(resp.result).then(() => {
                    this.$router.push({
                      name: "home",
                    });
                  });
                } else {
                  this.$swal(resp.msg);
                }
              });
            }
            else
            {
              axios.post("/System/CreateWebApiLog", {
                REQUEST_API_URL: this.THIRD_PARAM.HOST,
                KEY_TYPE: "ThirdPart_TRANS",
                KEY_VALUE: vm.trans_id,
                //TRANS_CONTENT: window.location.origin + window.location.pathname,
                TRANS_CONTENT:
                  window.location.origin,
              })
              .then((resp) => {
                if (resp.succ) {
                  let param = {};
                  param.THIRDPART_TYPE = "NEWEBPAY"
                  param.amount = vm.booking.amount;
                  param.WEB_ID_CREDIT = vm.THIRD_PARAM.WEB_ID_CREDIT;
                  param.TRANS_PWD = vm.THIRD_PARAM.TRANS_PWD;
                  param.HashIV = vm.THIRD_PARAM.HashIV;
                  param.HOST = vm.THIRD_PARAM.HOST;
                  param.LOCALHOST = vm.THIRD_PARAM.LOCALHOST
                  param.ordernumber = vm.trans_id;
                  param.full_mobile = vm.bookingDetail.formData.user_phone.value;
                  param.ItemName = "課程";
                  param.user_email = vm.bookingDetail.formData.user_email.value;
                  param.pay_type = vm.bookingDetail.formData.pay_type.value;
                  vm.$cf.thirdPartTrans(param);
                }
              })
            }
            
            
             
          } else {
            this.$swal(resp.msg);
          }
        });

      
    },
  },
};
</script>

<style scoped>
#body-bg {
  background-color: #f5f5f5;
  padding: 30px;
  margin: 0;
}

.card {
  margin: 10px;
  padding: 10px 30px;
}

.info-title {
  font-size: 18px;
}

.date {
  border-left: black 1.5px solid;
}

.info {
  margin: 20px 0;
}

.info .col-12,
.info .col-5 {
  padding: 10px 0;
}

.info button {
  width: 100%;
  background-color: #e91e63;
  color: white;
  border: 0;
  border-radius: 5px;
  line-height: 2em;
}

ul {
  padding-left: 1rem;
}
</style>