<script>
// Default Configs
let defaultConfigs = {
    version: "3.23.5.5",
    // apiUrl: "https://localhost:44316",
    // apiUrl: "http://localhost/IB_FRONT_API_II",
    // apiUrl: "https://www-api.gagamonster.co",
    // apiUrl: "http://ap.sjrlife.com:20298",
    apiUrl: "https://gagamonster-api-test.gagamonster.co/api",
    
    cookieTime: 7 * 60 * 60 * 24,
};

const appOptions = {
    param: defaultConfigs
}
export default appOptions;
</script>