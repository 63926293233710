import Vue from "vue";
import VueRouter from "vue-router";
import home from "../page/home.vue";
import onlineAppointment from "../page/onlineAppointment.vue";
import allProduct from "../page/allProduct.vue";
import news from "../page/news.vue";
import newsDetail from "../page/newsDetail.vue";
import course from "../page/course.vue";
import commonProblem from "../page/commonProblem.vue";
import allPlace from "../page/allPlace.vue";
import aboutUs from "../page/aboutUs.vue";
import signIn from "../page/signIn.vue";
import appointmentClass from "../page/appointmentClass.vue";
import appointmentClassPay from "../page/appointmentClassPay.vue";
import appointmentClassCheckOut from "../page/appointmentClassCheckOut.vue";
import productDetail from "../page/productDetail.vue";
import shoppingCart from "../page/shoppingCart.vue";
import shoppingCartPay from "../page/shoppingCartPay.vue";
import forgetPwd from "../page/forgetPwd.vue";
import setPwd from "../page/setPwd.vue";
import returnPolicy from "../page/returnPolicy.vue";
import privacyPolicy from "../page/privacyPolicy.vue";
import memberPolicy from "../page/memberPolicy.vue";
import error404 from "../page/error404.vue";
import store from "../store/index.js";
import ShoppingCartPayDatil from "../page/ShoppingCartPayDatil.vue"
import shareByUser from "../page/shareByUser.vue"

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: home,
    meta: {
      title: "首頁",
    },
  },
  {
    path: "/online-appointment",
    name: "onlineAppointment",
    component: onlineAppointment,
    meta: {
      title: "線上預約",
    },
  },
  {
    path: "/all-product",
    name: "allProduct",
    component: allProduct,
    meta: {
      title: "全部商品",
    },
  },
  {
    path: "/news",
    name: "news",
    component: news,
    meta: {
      title: "最新消息",
    },
  },
  {
    path: "/news/news-detail/:name?",
    name: "newsDetail",
    component: newsDetail,
    meta: {
      title: "最新消息",
    },
  },
  {
    path: "/news/share-by-user/:name?",
    name: "shareByUser",
    component: shareByUser,
    meta: {
      title: "部落客分享",
    },
  },
  {
    path: "/course",
    name: "course",
    component: course,
    beforeEnter() {
      window.location =
        "https://www.youtube.com/channel/UCxON1MpShc1N9t9wRI4V3jg";
    },
    meta: {
      title: "史萊姆教學",
    },
  },
  {
    path: "/common-problem",
    name: "commonProblem",
    component: commonProblem,
    meta: {
      title: "常見問題",
    },
  },
  {
    path: "/all-place",
    name: "allPlace",
    component: allPlace,
    meta: {
      title: "星球據點",
    },
  },
  {
    path: "/about-us",
    name: "aboutUs",
    component: aboutUs,
    meta: {
      title: "關於我們",
    },
  },
  {
    path: "/sign-in",
    name: "signIn",
    component: signIn,
    meta: {
      title: "登入",
    },
  },
  {
    path: "/online-appointment/appointment-class/:name/:id",
    name: "appointmentClass",
    component: appointmentClass,
    meta: {
      title: "線上預約",
    },
  },
  {
    path: "/online-appointment/appointment-class/:name/:id/pay",
    name: "appointmentClassPay",
    component: appointmentClassPay,
    meta: {
      title: "線上預約",
    },
  },
  {
    path: "/online-appointment/appointment-class/checkout",
    name: "appointmentClassCheckOut",
    component: appointmentClassCheckOut,
    meta: {
      title: "線上預約",
    },
  },
  {
    path: "/all-product/product-detail/:type/:id",
    name: "productDetail",
    component: productDetail,
    meta: {
      title: "商品",
    },
  },
  {
    path: "/shopping-cart",
    name: "shoppingCart",
    component: shoppingCart,
    meta: {
      title: "購物車",
    },
  },
  {
    path: "/shopping-cart/pay",
    name: "shoppingCartPay",
    component: shoppingCartPay,
    meta: {
      title: "結帳",
    },
  },
  {
    path: "/shopping-cart/PaySuccess",
    name: "shoppingCartPaySuccess",
    component: ShoppingCartPayDatil,
    meta: {
      title: "結帳",
    },
  },
  {
    path: "/forgetpwd",
    name: "forgetPwd",
    component: forgetPwd,
    meta: {
      title: "忘記密碼",
    },
  },
  {
    path: "/setpwd",
    name: "setPwd",
    component: setPwd,
    meta: {
      title: "重設密碼",
    },
  },
  {
    path: "/return-policy",
    name: "returnPolicy",
    component: returnPolicy,
    meta: {
      title: "退換貨政策",
    },
  },
  {
    path: "/privacy-policy",
    name: "privacyPolicy",
    component: privacyPolicy,
    meta: {
      title: "隱私權政策",
    },
  },
  {
    path: "/member-policy",
    name: "memberPolicy",
    component: memberPolicy,
    meta: {
      title: "會員制度",
    },
  },
  {
    path: "/404",
    name: "error404",
    component: error404,
    meta: {
      title: "404",
    },
  },
  {
    path: "*",
    redirect: '/404'
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

function getCookie(cookieName) {
  var name = cookieName + "=";
  var decodedCookie = document.cookie;
  var cookies = decodedCookie.split(";");
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i].trim();
    if (cookie.indexOf(name) === 0) {
      var letcookie = cookie.substring(name.length, cookie.length);
      var decodeCookie = decodeURIComponent(decodeURIComponent(letcookie));
      return JSON.parse(decodeCookie);
    }
  }
  return "";
}

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  const cookieUser = getCookie("user");
  if (cookieUser)
    store.dispatch("login", {
      userId: cookieUser.userId,
      userName: cookieUser.userName,
      sno: cookieUser.sno,
    });
  next();
});

export default router;
