<style scoped>
.input-err-border {
  border-color: var(--ft-error) !important;
  border: 1.5px solid;
}

input {
  height: 40px;
}
</style>

<template>
  <div>
    <VueFormElement :HeaderText="Options.HeaderText" :RemarkText="Options.RemarkText" :ErrorMessage="ErrorMessage"
      :ShowErrorMessage="Options.ShowErrorMessage" :required="Options.required">
      <template #custom-content>
        <input :type="type" v-model="parentValue" :placeholder="Placeholder" :disabled="Disabled"
          :class="[{ 'input-err-border': Options.ShowErrorMessage }]" class="w-100 h-48px" min="0" />
      </template>
    </VueFormElement>
  </div>
</template>

<script>
import VueFormElement from "./Form-element.vue";

export default {
  components: {
    VueFormElement,
  },
  props: {
    type: {
      default: 'text'
    },
    value: { require: true },
    Options: Object,
  },
  computed: {
    parentValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    ErrorMessage: {
      get() {
        if (this.Options.ErrorMessage === undefined) {
          return "未輸入";
        } else {
          return this.Options.ErrorMessage;
        }
      },
    },
    Placeholder: {
      get() {
        if (this.Options.Placeholder === undefined) {
          return "請輸入" + this.Options.HeaderText;
        } else {
          return this.Options.Placeholder;
        }
      },
    },
    Disabled: {
      get() {
        if (this.Options.disabled === undefined) {
          return false;
        } else {
          return this.Options.disabled;
        }
      },
    }
  },
};
</script>
