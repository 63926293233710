<template>
  <div>
    <div class="bg-card">

      <section id="card-section">
        <section class="row py-0">
          <!-- 門市 -->
          <div class="col-6 col-lg-2">
            <select class="form-select" v-model="select_date" @change="handleChangeTabEvent('select', select_date)">
              <option :value="3">三個月內</option>
              <option :value="0">當月</option>
              <option :value="1">上個月</option>
              <option :value="2">上上個月</option>
            </select>
          </div>
        </section>

        <!--課程訂單-->
        <template>
          <!-- v-if="class_order_list.length !== 0" -->
          <div class="row">
            <!-- web -->
            <!-- <section class="col table-responsive d-none d-xl-block"> -->
            <section class="col table-responsive">
              <table class="table" id="table">
                <thead>
                  <tr>
                    <th>訂單編號</th>
                    <th>日期</th>
                    <th>購買方式</th>
                    <th>訂單狀態</th>
                    <th>出貨狀態</th>
                    <th>總價</th>
                    <th>明細</th>
                  </tr>
                </thead>
                <tbody v-for="(data, index) in class_order_list" :key="index">
                  <tr :style="{ backgroundColor: data.open ? '' : '' }" @click="openDetail(data)">
                    <td>{{ data.number }}</td>
                    <td>{{ data.date }}</td>
                    <td>{{ checkData(data.purchase, data.purchase) }}</td>
                    <td v-if="data.type == '尚未付款'">
                      <a href="javascript:void(0);" @click="handleClickESAFE(data)"><span class="text-blue mx-8px">{{
                        data.type
                      }}</span></a>
                    </td>
                    <td v-else>{{ data.type }}</td>
                    <td>{{ data.STOCK_TYPE_NAME }}</td>
                    <td>
                      ${{ $cf.numProc.numberWithCommas(data.total_price) }}
                    </td>
                    <td>
                      <img width="12" height="8" class="icon-arrow-down" :class="data.open ? 'arrow-up' : ''" />
                    </td>
                  </tr>

                  <tr v-show="data.open" id="detail-table">
                    <td colspan="6" class="p-0">
                      <div class="detail-table-title fw-bold" style="background-color: #f1f1f1">
                        <div><span>品項</span></div>
                        <div class="d-flex justify-content-end detail-table-back">
                          <span style="width: 28px">數量</span><span style="width: 28px">堂數</span><span
                            style="width: 60px">原價</span><span style="width: 60px; text-align: right">優惠價</span>
                        </div>
                      </div>
                      <!-- 課堂Block1 -->
                      <section>
                        <div v-for="(class_data, index) in data.class" :key="index" class="detail-table-content">
                          <div class="d-flex flex-column">
                            <span class="fw-bold fs-16px mb-5px">{{
                              class_data.item
                            }}</span><span v-show="class_data.position !== '' &&
    class_data.position !== undefined
    ">【部位：{{ class_data.position }}】</span>
                          </div>
                          <div class="d-flex align-items-center detail-table-back">
                            <span style="width: 28px">{{
                              class_data.amount
                            }}</span><span style="width: 28px">{{
  class_data.count
}}</span><span style="width: 60px; font-size: 16px">${{
  $cf.numProc.numberWithCommas(class_data.oAmt)
}}
                              <p class="fs-10px" v-show="class_data.oAmt_count">
                                ({{
                                  $cf.numProc.numberWithCommas(
                                    class_data.oAmt_count
                                  )
                                }}/堂)
                              </p>
                            </span><span style="width: 60px; text-align: right">
                              ${{
                                $cf.numProc.numberWithCommas(class_data.amt)
                              }}</span>
                          </div>
                        </div>
                      </section>
                      <!-- 訂單資訊Block2 -->
                      <section>
                        <div class="detail-table-bottom align-items-start">
                          <div class="d-block">
                            <span class="fw-bold">發票號碼<a href="https://einvoice.ecpay.com.tw/SearchInvoice/Invoice"
                                target="_blank">
                                <p class="fw-normal mx-8px" style="color: #2e6acd">
                                  {{ data.bill }}
                                </p>
                              </a></span>
                            <span class="fw-bold">付款方式
                              <p class="fw-normal mx-8px">
                                {{ data.payment }}
                              </p>
                            </span>
                            <span class="fw-bold">購買門市
                              <p class="fw-normal mx-8px">
                                {{ data.store }}
                              </p>
                            </span>
                            <span class="fw-bold">消費方式
                              <p class="fw-normal mx-8px">
                                {{ data.SEND_TYPE_NAME }}
                              </p>
                            </span>
                            <span class="fw-bold" v-show="data.SEND_TYPE == 'STORE'">店取門市
                              <p class="fw-normal mx-8px">
                                {{ data.send_store }}
                              </p>
                            </span>
                          </div>
                          <div class="detail-table-back w-auto">
                            <span class="d-flex">
                              <p>總計</p>
                              <p>
                                ${{ $cf.numProc.numberWithCommas(data.total) }}
                              </p>
                            </span>
                            <span class="d-flex">
                              <p>購物金</p>
                              <p>-{{ data.point }}</p>
                            </span>
                            <span class="d-flex">
                              <p>優惠金</p>
                              <p>-{{ data.discount_cash }}</p>
                            </span>
                            <span class="d-flex">
                              <p>優惠碼</p>
                              <p>-{{ data.coupon_cash }}</p>
                            </span>
                            <span v-if="data.TRANS_PAY_ID === 'REGULAR'" class="d-flex">
                              <p>期數</p>
                              <p>{{ data.period_num }}</p>
                            </span>
                            <span v-if="data.TRANS_PAY_ID === 'REGULAR'" class="d-flex fw-bold fs-16px">
                              <p class="w-100px">每期金額</p>
                              <p>
                                ${{
                                  $cf.numProc.numberWithCommas(
                                    data.total_amount
                                  )
                                }}
                              </p>
                            </span>
                            <span v-if="data.TRANS_PAY_ID === 'REGULAR'" class="d-flex fw-bold fs-16px">
                              <p class="w-100px">消費總金額</p>
                              <p>
                                ${{
                                  $cf.numProc.numberWithCommas(
                                    data.total_amount * data.period_num
                                  )
                                }}
                              </p>
                            </span>
                            <span v-else class="d-flex fw-bold fs-16px">
                              <p class="w-100px">消費總金額</p>
                              <p>
                                ${{
                                  $cf.numProc.numberWithCommas(
                                    data.total_amount
                                  )
                                }}
                              </p>
                            </span>
                          </div>
                        </div>
                      </section>
                      <!-- 退費課堂Block3 -->
                      <section v-show="data.refund_class.length !== 0" style="background: #fff8f0">
                        <div v-for="(refund_class, index) in data.refund_class" :key="index" class="detail-table-content">
                          <div class="d-flex flex-column">
                            <span class="fw-bold fs-16px mb-5px">{{
                              refund_class.item
                            }}</span>
                            <span v-if="refund_class.refund_purchase === 'Y'">【原價 ${{
                              $cf.numProc.numberWithCommas(
                                refund_class.refund
                              )
                            }}/堂 退費】</span>
                            <span v-else>【原課程優惠價退費 ${{
                              $cf.numProc.numberWithCommas(
                                refund_class.refund
                              )
                            }}
                              <p v-show="refund_class.total_count !== ''">
                                /{{ refund_class.total_count }} 四捨五入 ${{
                                  $cf.numProc.numberWithCommas(
                                    refund_class.refund_once
                                  )
                                }}/堂
                              </p>
                              】
                            </span>
                          </div>
                          <div class="d-flex align-items-center detail-table-back">
                            <span style="width: 28px">{{
                              refund_class.count
                            }}</span><span style="width: 60px; font-size: 16px">-${{
  $cf.numProc.numberWithCommas(refund_class.oAmt)
}}
                              <p class="fs-10px">
                                ({{
                                  $cf.numProc.numberWithCommas(
                                    refund_class.oAmt_count
                                  )
                                }}/堂)
                              </p>
                            </span><span style="width: 60px; text-align: right">-${{
                              $cf.numProc.numberWithCommas(refund_class.amt)
                            }}</span>
                          </div>
                        </div>
                      </section>
                      <!-- 退費方式Block4 -->
                      <section v-show="data.mode !== ''" style="background: #fff8f0">
                        <div class="detail-table-bottom align-items-start">
                          <div class="d-block">
                            <span class="fw-bold" style="line-height: 25px">退費方式
                              <p class="fw-normal mx-8px">
                                {{ data.mode }}
                              </p>
                            </span>
                          </div>
                          <div class="detail-table-back w-auto">
                            <span class="d-flex" :class="{ hide: data.refund_total === '' }" style="line-height: 25px">
                              <p>總計</p>
                              <p>
                                -${{
                                  $cf.numProc.numberWithCommas(
                                    data.refund_total
                                  )
                                }}
                              </p>
                            </span>
                            <span class="d-flex fw-bold fs-16px">
                              <p class="w-100px">退費總金額</p>
                              <p>
                                ${{
                                  $cf.numProc.numberWithCommas(
                                    data.refund_total_price
                                  )
                                }}
                              </p>
                            </span>
                          </div>
                        </div>
                      </section>
                    </td>
                  </tr>
                </tbody>
              </table>
            </section>
            <!-- mobile -->
            <!-- 刪除 -->
          </div>
        </template>

        <!--沒有任何課程訂單、沒有任何購物金紀錄-->
        <template v-if="class_order_list.length === 0">
          <div class="no-data">
            <span>您目前尚未有任何資料</span>
          </div>
        </template>

        <section>
          <Pagination :totalPage="PageList.totalPage" :currentPage="PageList.currentPage" @change="handleChangePage" />
        </section>
      </section>
    </div>
  </div>
</template>

  
<script>
import Pagination from "../components/Pagination-element.vue";
import axios from "../api/axios.js";
export default {
  name: "ConsumptionHistory",
  components: {
    Pagination,
  },
  created() {
    //call
    this.login_user = this.$cookies.get("user");
    //   this.callApi(API_ROUTE.Store.GetStoreList, { addAllOption: false }, {});
    // this.callApi(
    //   API_ROUTE.ConsumptionHistory.GetTransHistoryList,
    //   {
    //     sno_user: this.login_user.sno_user,
    //     sno_store: this.active_store,
    //     month: this.select_date,
    //     type: "CLASS",
    //   },
    //   {}
    // );
    //if (this.$route.query.type === 1) {
    //  this.active_tab = 1;
    //  this.apiGetPointData();
    // } else {
    this.apiGetClassData();
    // }
  },
  data() {
    return {
      PageList: {
        totalPage: 1,
        currentPage: 1,
      },
      user_email: "",
      active_tab: 0,
      store_list: [],
      active_store: 0,
      select_date: 3,
      dialog_select_date: 0,
      all_list: [],
      user_reserve_list: [],
      class_order_list: [],
      point_record_list: [],
      THIRDPART_TYPE: "",
      type: "CLASS",
      login_user: [],
      THIRD_PARAM: {
        HOST: "",
        WEB_ID_CREDIT: "S1607299011",
        TRANS_PWD: "a12345678",
        WEB_ID_ATM: "S1607299037",
      },
    };
  },
  methods: {
    handleChangePage(item) {
      //call api
      if (this.active_tab === 0) {
        this.apiGetClassData(item);
      } else {
        this.apiGetPointData(item);
      }
    },
    handleClickESAFE(item) {
      let p_key = "THIRDPART_TYPE"
      if (item.TRANS_PAY_ID == "REGULAR") {
        p_key = "REGULAR_TYPE";
      }
      this.user_email = item.USER_EMAIL;
      ApiCommon.GetSysparam({
        p_key: p_key,
      }).then((resp) => {
        if (resp.stat.succ && resp.data != null) {
          this.THIRDPART_TYPE = resp.data.p_value;
          this.callApi(API_ROUTE.Trans.GetRetryTransID, { ORG_TRANS_ID: item.number, TRANS_DATE: item.date }, {});
        }
      });
    },
    apiGetPointData(intPage = 1) {
      intPage;
      ApiBase.callApi(
        "/api/main/TransHistory/GetPonintForHistory",
        {
          sno_user: this.login_user.sno_user,
          sno_store: this.active_store,
          month: this.select_date,
        },
        { currentPage: intPage }
      ).then((resp) => {
        if (!resp.stat.succ) {
          alert(resp.stat.err_msg);
          throw resp;
        }
        this.point_record_list = resp.data;
        this.PageList.currentPage = resp.currentPage;
        this.PageList.totalPage = resp.totalPage;
        //console.log(resp.data);
        //this.PageList.currentPage = resp.currentPage;
        //this.PageList.totalPage = resp.totalPage;

        this.handleListDetail();
      });
    },
    apiGetClassData(intPage = 1) {
      const user = this.$cookies.get("user")
      intPage;
      // ApiBase.callApi(
      //   "/api/main/TransHistory/GetClassForHistory",
      //   {
      //     sno_user: this.login_user.sno_user,
      //     sno_store: this.active_store,
      //     month: this.select_date,
      //   },
      //   { currentPage: intPage }
      // ).then((resp) => {
      //   if (!resp.stat.succ) {
      //     alert(resp.stat.err_msg);
      //     throw resp;
      //   }
      //   console.log(resp.data)
      //   this.class_order_list = resp.data;
      //   this.PageList.currentPage = resp.currentPage;
      //   this.PageList.totalPage = resp.totalPage;
      //   this.handleListDetail();
      // });
      let loader = this.$loading.show();
      axios.post("/TransHistory/GetClassForHistory", { sno_user: user.sno, month: this.select_date, currentPage: intPage }).then(resp => {
        if (resp.succ) {
          this.class_order_list = resp.result.data;
          this.PageList.currentPage = resp.result.currentPage;
          this.PageList.totalPage = resp.result.totalPage;
          this.handleListDetail();

        }
        loader.hide()
      });
    },

    // apiSuccess: function (apiRoute, respData) {
    //   switch (apiRoute) {
    //     case API_ROUTE.Store.GetStoreList:
    //       this.store_list = [
    //         {
    //           store_name: "全部門市",
    //           store_id: 0,
    //         },
    //       ];
    //       respData.data.store_list.forEach((item) => {
    //         this.store_list.push(item);
    //       });

    //       break;
    //     case API_ROUTE.ConsumptionHistory.GetTransHistoryList:
    //       if (respData.data.type == "CLASS") {
    //         this.class_order_list = JSON.parse(respData.data.jsonString);
    //       } else {
    //         this.point_record_list = JSON.parse(respData.data.jsonString);
    //       }
    //       this.handleListDetail();
    //       break;
    //     case API_ROUTE.Trans.GetRetryTransID:
    //       {
    //         this.THIRD_PARAM.HOST = "";
    //         let vm = this;  
    //         let respContent = respData.data[0];          
    //         ApiCommon.GetStoreParam({
    //           SNO_STORE: respData.data[0].SNO_STORE,
    //           PARAM_ID: this.THIRDPART_TYPE,
    //         }).then((resp) => {              
    //           if (resp.stat.succ && resp.data != null) {
    //             vm.THIRD_PARAM.HOST =
    //               resp.data.find((x) => x.PARAM_KEY == "HOST").VAL;
    //             vm.THIRD_PARAM.WEB_ID_CREDIT = resp.data.find(
    //               (x) => x.PARAM_KEY == "WEB_ID_CREDIT"
    //             ).VAL;
    //             vm.THIRD_PARAM.TRANS_PWD = resp.data.find(
    //               (x) => x.PARAM_KEY == "TRANS_PWD"
    //             ).VAL;
    //             vm.THIRD_PARAM.LOCALHOST = resp.data.find(
    //               (x) => x.PARAM_KEY == "LOCALHOST"
    //             ).VAL;
    //             vm.THIRD_PARAM.HashIV = resp.data.find(
    //               (x) => x.PARAM_KEY == "HashIV"
    //             )?.VAL;

    //             let param = {};
    //             param.THIRDPART_TYPE = vm.THIRDPART_TYPE
    //             param.is_installment = false;
    //             param.authday = "28";
    //             param.amount = respContent?.TOTAL_AMT;
    //             param.WEB_ID_CREDIT = vm.THIRD_PARAM.WEB_ID_CREDIT;
    //             param.TRANS_PWD = vm.THIRD_PARAM.TRANS_PWD;
    //             param.HashIV = vm.THIRD_PARAM.HashIV;
    //             param.HOST = vm.THIRD_PARAM.HOST;
    //             param.LOCALHOST = vm.THIRD_PARAM.LOCALHOST
    //             param.ordernumber = respContent?.trans_id;
    //             param.full_mobile = vm.login_user.full_mobile;
    //             param.ItemName = "商品";
    //             param.user_email = vm.user_email;
    //             param.user_name = vm.login_user.user_name;
    //             param.PERIOD_NUM = respContent?.PERIOD_NUM;
    //             param.pay_type = respContent?.TRANS_PAY_ID;
    //             if(respContent?.SEND_TYPE=="MARKET")
    //             {
    //               param.CVSCOM = respContent?.TRANS_PAY_ID =="MARKET" ? '2':'1';
    //             }
    //             this.$cf.thirdPartTrans(param);

    //           }
    //         });
    //         break;
    //       }


    //   }
    // },
    // apiError: function (apiRoute, errModel) {
    //   apiRoute;
    //   errModel;
    // },
    getMonth() {
      return new Date().getMonth() + 1;
    },
    checkData(item, value) {
      if (item === "" || item === null) {
        return "-";
      } else {
        return value;
      }
    },
    handleListDetail() {
      this.class_order_list.forEach((i) => {
        this.$set(i, "open", false);
      });
      this.point_record_list.forEach((i) => {
        this.$set(i, "open", false);
      });
    },
    openDetail(data) {
      console.log(data);
      data.open = !data.open;
    },
    /**
     *
     * @param type tab: 課程訂單/購物金紀錄 store: 門市 select: 日期範圍
     * @param selectedIItem
 
     */
    handleChangeTabEvent(type, selectedIItem) {
      switch (type) {
        case "tab":
          this.PageList.currentPage = 1;
          this.PageList.totalPage = 1;
          this.active_tab = selectedIItem.id;
          if (selectedIItem.id == "0") {
            this.type = "CLASS";
            this.apiGetClassData();
          } else {
            this.type = "POINT";
            this.apiGetPointData();
          }

          break;
        case "store":
          this.active_store = selectedIItem.store_id;
          // this.callApi(
          //   API_ROUTE.ConsumptionHistory.GetTransHistoryList,
          //   {
          //     sno_user: this.login_user.sno_user,
          //     sno_store: this.active_store,
          //     month: this.select_date,
          //     type: "CLASS",
          //     //type: this.type,
          //   },
          //   {}
          // );
          if (this.active_tab == "0") {
            this.apiGetClassData();
          } else {
            this.apiGetPointData();
          }

          break;
        case "select":
          // this.callApi(
          //   API_ROUTE.ConsumptionHistory.GetTransHistoryList,
          //   {
          //     sno_user: this.login_user.sno_user,
          //     sno_store: this.active_store,
          //     month: this.select_date,
          //     // type: this.type,
          //     type: "CLASS",
          //   },
          //   {}
          // );
          if (this.active_tab == "0") {
            this.apiGetClassData();
          } else {
            this.apiGetPointData();
          }

          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
figure {
  margin: 0;
}

.table-responsive {
  padding: auto;
}

.bg-pers {
  width: 100vw;
  max-width: 896px;
  min-height: 805px;
  padding: 0 !important;
}

.pers-title {
  padding: 24px 40px 14px;
}

.pers-title-name {
  font-size: 20px;
  color: var(--ft-pers-title-color);
}

.pers-title-more {
  font-size: 14px;
  font-weight: normal;
}

.nav-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 9px;
}

.title {
  font-weight: bold;
  font-size: 16px;
}

.tab-wrap.tab-w-sm {
  border-bottom: 1.5px solid #f1f1f1;
}

.detail-table-title,
.detail-table-content,
.detail-table-bottom {
  // background-color: #ffe5cc;
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding: 0 32px;
}

.detail-table-content {
  // margin: 16px 0;
  padding: 0px 32px;
  height: 72px;

  p {
    margin-bottom: 0;
  }
}

.detail-table-bottom {
  padding: 12px 32px 6px;
  height: auto;
}

.detail-table-content+.detail-table-content {
  padding-top: 0px;
}

.detail-table-title span {
  display: flex;
  color: #4b4b4b;
}

.detail-table-content span,
.detail-table-bottom span {
  display: flex;
  color: #6f6f6f;
}

.detail-table-back span {
  display: block;
  margin-left: 24px;
}

.detail-table-bottom span {
  margin-bottom: 4px;

  p {
    margin-bottom: 8px;
  }
}

.detail-table-bottom .detail-table-back span {
  justify-content: flex-end;
  margin-bottom: 4px;
}

.detail-table-bottom .detail-table-back span p {
  width: 60px;
  margin-left: 24px;
  text-align: right;
}

.form-select {
  margin: 15px 0;
  padding-top: 5px;
  padding-bottom: 5px;
}

#card-section {
  padding: 24px 40px 80px
}

.detail-table-bottom span p {
  margin-bottom: 8px;
}

.mx-8px {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

p {
  line-height: normal ;
}

@media (max-width: 1200px) {
  #card-section {
    padding: 0px
  }

  .table-responsive {
    padding: 0px;
  }

  .bg-pers {
    max-width: inherit;
  }

  div {
    font-size: 16px;
  }

  .pers-title {
    display: flex;
    flex-direction: column;
  }

  .pers-title-name {
    font-size: 36px;
  }

  .pers-title-more {
    font-size: 20px;
  }

  .tab-wrap .tab-item {
    width: 100%;
    border-radius: 15px 15px 0px 0px;
  }

  .tab-wrap .tab-item,
  .tag,
  .pills-tab {
    font-size: 24px;
  }

  .tag {
    max-width: 166px;
  }

  .title {
    font-size: 28px !important;
  }

  .detail-item-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    #detail:not(:first-child),
    .detail:not(:first-child) {
      margin-left: 40px;
    }
  }

  .form-select {
    margin: 10px 0;
  }
}

#detail-table section+section,
#detail-list section+section {
  border-top: 2px solid #f1f1f1;
}

a {
  text-decoration: none;
}

th,
tr {
  white-space: nowrap;
}
</style>