<script>
const storeDetail = [
    /* 一般課程 -信義 */
    // {
    //     id: '1', //團課編號
    //     bas_id: '1',
    //     sno: '1', //門市編號
    //     picture: [{
    //         id: 1,
    //         url: require("../assets/img/mobile/class/信誠.jpg"),
    //     },
    //     {
    //         id: 5,
    //         url: require("../assets/img/mobile/class/groupclass/信義誠品.jpg"),
    //     },
    //     {
    //         id: 4,
    //         url: require("../assets/img/mobile/class/Q萁星空怪獸預約.jpg"),
    //     },
    //     {
    //         id: 2,
    //         url: require("../assets/img/mobile/class/信誠時間.jpg"),
    //     },
    //     {
    //         id: 3,
    //         url: require("../assets/img/mobile/class/課程收費.jpg"),
    //     }],
    //     title: '誠品信義店5F',
    //     time: '890～1390－ 1 小時',
    //     info: 'GAGAmonster史萊姆怪獸星球一店，位於誠品信義店5樓。',
    //     name: '誠品信義店5F',
    //     address: '台北市信義區松高路11號5樓',
    //     type:'normal',
    //     tele:'(02) 7756-3935 #11',
    //     classPlaceImg:require("../assets/img/mobile/place/誠品信義.jpg"),
    //     infoImg:require("../assets/img/mobile/onlineAppointment/誠品信義-預約頁.jpg")
    // },
    /* 特殊課程2 -信義*/
    // {
    //     id: '10', //團課編號
    //     bas_id: '',
    //     sno: '1', //門市編號
    //     picture: [{
    //         id: 1,
    //         url: require("../assets/img/mobile/class/groupclass/信誠店.jpg"),
    //     },
    //     {
    //         id: 2,
    //         url: require("../assets/img/mobile/class/groupclass/時間1.jpg"),
    //     },
    //     {
    //         id: 3,
    //         url: require("../assets/img/mobile/class/groupclass/時間2.jpg"),
    //     }],
    //     title: '誠品信義店5F',
    //     time: '890～1390－ 1 小時',
    //     info: 'GAGAmonster史萊姆怪獸星球一店，位於誠品信義店5樓。',
    //     name: '誠品信義店5F',
    //     address: '台北市信義區松高路11號5樓',
    //     type:'groupClass2',
    //     tele:'(02) 7756-3935 #11'
    // },

    /*  - 信義威秀*/
    {
        id: '16',
        bas_id: '17',
        sno: '5',
        picture: [{
            id: 1,
            url: require("../assets/img/mobile/class/信義威秀.jpg"),
        },
        // {
        //     id: 2,
        //     url: require("../assets/img/mobile/class/Q萁星空怪獸預約.jpg"),
        // },
        {
            id: 3,
            url: require("../assets/img/mobile/class/威秀時間.jpg"),
        },
        {
            id: 4,
            url: require("../assets/img/mobile/class/課程收費.jpg"),
        },],
        title: '信義威秀店1F',
        time: '890～1390－ 1 小時',
        info: 'GAGAmonster史萊姆怪獸星球七店，位於信義威秀1樓。',
        name: '信義威秀店1F',
        address: '台北市信義區松壽路20號1樓',
        type:'normal',
        tele:'0983253668',
        classPlaceImg:'',
        infoImg:require("../assets/img/mobile/onlineAppointment/信義威秀-預約頁.jpg")
    },
    
    /* 一般課程 -天母*/
    {
        id: '2',
        bas_id: '2',
        sno: '3',
        picture: [{
            id: 1,
            url: require("../assets/img/mobile/class/天母.jpg"),
        },
        // {
        //     id: 4,
        //     url: require("../assets/img/mobile/class/Q萁星空怪獸預約.jpg"),
        // },
        {
            id: 2,
            url: require("../assets/img/mobile/class/天母時間.jpg"),
        },
        {
            id: 3,
            url: require("../assets/img/mobile/class/課程收費.jpg"),
        }],
        title: 'SOGO天母店7F',
        time: '890～1390－ 1 小時',
        info: 'GAGAmonster史萊姆怪獸星球二店，位於SOGO天母店7樓。',
        name: 'SOGO天母店7F',
        address: '台北市士林區中山北路六段77號7樓',
        type:'normal',
        tele:'(02) 7756-3934',
        classPlaceImg:require("../assets/img/mobile/place/天母店.jpg"),
        infoImg:require("../assets/img/mobile/onlineAppointment/天母sogo-預約頁.jpg")
    },
    
    /* 特殊課程2 -天母*/
    // {
    //     id: '11',
    //     bas_id: '',
    //     sno: '3',
    //     picture: [{
    //         id: 1,
    //         url: require("../assets/img/mobile/class/groupclass/天母店.jpg"),
    //     },
    //     {
    //         id: 2,
    //         url: require("../assets/img/mobile/class/groupclass/時間1.jpg"),
    //     },
    //     {
    //         id: 3,
    //         url: require("../assets/img/mobile/class/groupclass/時間2.jpg"),
    //     }],
    //     title: 'SOGO天母店7F',
    //     time: '890～1390－ 1 小時',
    //     info: 'GAGAmonster史萊姆怪獸星球二店，位於SOGO天母店7樓。',
    //     name: 'SOGO天母店7F',
    //     address: '台北市士林區中山北路六段77號7樓',
    //     type:'groupClass2',
    //     tele:'(02) 7756-3934'
    // },

    /*  - 新店裕隆城*/
    {
        id: '15',
        bas_id: '16',
        sno: '7',
        picture: [{
            id: 1,
            url: require("../assets/img/mobile/class/groupclass/裕隆城1.jpg"),
        },
        // {
        //     id: 4,
        //     url: require("../assets/img/mobile/class/Q萁星空怪獸預約.jpg"),
        // },
        {
            id: 3,
            url: require("../assets/img/mobile/class/groupclass/裕隆城4.jpg"),
        },
        {
            id: 4,
            url: require("../assets/img/mobile/class/課程收費.jpg"),
        },],
        title: '新店裕隆城店3F',
        time: '890～1390－ 1 小時',
        info: 'GAGAmonster史萊姆怪獸星球六店，位於新店裕隆城3樓。',
        name: '新店裕隆城店3F',
        address: '新北市新店區中興路三段70號3樓',
        type:'normal',
        tele:'0902037018',
        classPlaceImg:require("../assets/img/mobile/place/新店裕隆.jpg"),
        infoImg:require("../assets/img/mobile/onlineAppointment/裕隆城-預約頁.jpg")
    },

    
    /* 一般課程 -新竹*/
    {
        id: '4',
        bas_id: '4',
        sno: '4',
        picture: [{
            id: 1,
            url: require("../assets/img/mobile/class/新竹.jpg"),
        },
        // {
        //     id: 4,
        //     url: require("../assets/img/mobile/class/Q萁星空怪獸預約.jpg"),
        // },
        {
            id: 2,
            url: require("../assets/img/mobile/class/新竹時間.jpg"),
        },
        {
            id: 3,
            url: require("../assets/img/mobile/class/課程收費.jpg"),
        }],
        title: '新竹巨城店5F',
        time: '890～1390－ 1 小時',
        info: 'GAGAmonster史萊姆怪獸星球四店，位於新竹巨城5樓。',
        name: '新竹巨城店5F',
        address: '新竹市新竹市東區中央路229號5樓',
        type:'normal',
        tele:'0968678729',
        classPlaceImg:require("../assets/img/mobile/place/巨城.jpg"),
        infoImg:require("../assets/img/mobile/onlineAppointment/新竹巨城-預約頁.jpg")
    },
   
    /* 特殊課程2 -新竹*/
    // {
    //     id: '13',
    //     bas_id: '',
    //     sno: '4',
    //     picture: [{
    //         id: 1,
    //         url: require("../assets/img/mobile/class/groupclass/巨城店.jpg"),
    //     },
    //     {
    //         id: 2,
    //         url: require("../assets/img/mobile/class/groupclass/時間1.jpg"),
    //     },
    //     {
    //         id: 3,
    //         url: require("../assets/img/mobile/class/groupclass/時間2.jpg"),
    //     }],
    //     title: '新竹巨城店5F',
    //     time: '890～1390－ 1 小時',
    //     info: 'GAGAmonster史萊姆怪獸星球四店，位於新竹巨城5樓。',
    //     name: '新竹巨城店7F',
    //     address: '新竹市新竹市東區中央路229號5樓',
    //     type:'groupClass2',
    //     tele:'0968678729'
    // },
    
    /* 一般課程 -廣三*/
    {
        id: '3',
        bas_id: '3',
        sno: '2',
        picture: [{
            id: 1,
            url: require("../assets/img/mobile/class/廣三.jpg"),
        },
        // {
        //     id: 4,
        //     url: require("../assets/img/mobile/class/Q萁星空怪獸預約.jpg"),
        // },
        {
            id: 2,
            url: require("../assets/img/mobile/class/廣三時間.jpg"),
        },
        {
            id: 3,
            url: require("../assets/img/mobile/class/課程收費.jpg"),
        }],
        title: '台中廣三SOGO店8F',
        time: '890～1390－ 1 小時',
        info: 'GAGAmonster史萊姆怪獸星球三店，位於廣三SOGO店8樓。',
        name: '廣三SOGO店8F',
        address: '台中市西區台灣大道二段459號8樓',
        type:'normal',
        tele:'(04) 3700-5392',
        classPlaceImg:require("../assets/img/mobile/place/台中店.jpg"),
        infoImg:require("../assets/img/mobile/onlineAppointment/廣三sogo-預約頁.jpg")
    },
    
    /* 特殊課程2 -廣三*/
    // {
    //     id: '12',
    //     bas_id: '',
    //     sno: '2',
    //     picture: [{
    //         id: 1,
    //         url: require("../assets/img/mobile/class/groupclass/廣三店.jpg"),
    //     },
    //     {
    //         id: 2,
    //         url: require("../assets/img/mobile/class/groupclass/時間1.jpg"),
    //     },
    //     {
    //         id: 3,
    //         url: require("../assets/img/mobile/class/groupclass/時間2.jpg"),
    //     }],
    //     title: '台中廣三SOGO店8F',
    //     time: '890～1390－ 1 小時',
    //     info: 'GAGAmonster史萊姆怪獸星球三店，位於廣三SOGO店8樓。',
    //     name: '廣三SOGO店8F',
    //     address: '台中市西區台灣大道二段459號8樓',
    //     type:'groupClass2',
    //     tele:'(04) 3700-5392'
    // },
    /*  - 漢神巨蛋*/
    {
        id: '14',
        bas_id: '14',
        sno: '6',
        picture: [{
            id: 1,
            url: require("../assets/img/mobile/class/groupclass/漢神巨蛋店.jpg"),
        },
        // {
        //     id: 4,
        //     url: require("../assets/img/mobile/class/Q萁星空怪獸預約.jpg"),
        // },
        {
            id: 2,
            url: require("../assets/img/mobile/class/groupclass/漢神巨蛋店-2.jpg"),
        },
        {
            id: 3,
            url: require("../assets/img/mobile/class/課程收費.jpg"),
        },],
        title: '漢神巨蛋店6F',
        time: '890～1390－ 1 小時',
        info: 'GAGAmonster史萊姆怪獸星球五店，位於漢神巨蛋6樓。',
        name: '漢神巨蛋店6F',
        address: '高雄市左營區博愛二路777號6樓',
        type:'normal',
        tele:'0902037022',
        classPlaceImg:'',
        infoImg:require("../assets/img/mobile/onlineAppointment/漢神巨蛋-預約頁.jpg")
    },
    
    
]


export default storeDetail;
</script>