<template>
    <div v-if="isMobile">
        <!-- <div>
            <img :src="bgImg" alt="" class="body-bg">
            <div class="body-area1">
                <p class="title">女兒才是初始創辦人</p>
                <p class="sub-title">說到史萊姆，我們家的小朋友，她可是行家！</p>
                <div class="info">
                    <p>自從女兒開始接觸史萊姆，無限的熱情，從她小小的體內不斷湧出。
                        她可以展現驚人的專注力，好幾個小時安安靜靜地揉捏史萊姆；
                        她也曾經發揮探險精神，將家中想像得到的材料通通拿去做實驗，
                        讓爸爸臨時想刮鬍子都沒辦法。
                    </p>
                    <p>在她天馬行空的腦袋瓜裡，關於史萊姆的故事，更是源源不絕。
                        史萊姆怪獸星球 的輪廓，就這樣逐漸成形。
                    </p>
                    <p>身為 史萊姆怪獸星球 的地下總監，女兒對於史萊姆，可是很挑剔的。
                        小朋友最懂小朋友，經過這位小小「史萊姆校長」的親身把關，
                        您家寶貝也能安全又自由的快樂探險。
                    </p>
                </div>
            </div>
            <div class="body-area2">
                <p class="sub-title">「媽媽，我的手好癢！」</p>
                <div class="info">
                    <p>
                        在還沒創立品牌時，女兒迷上史萊姆沒多久，
                        手上卻突然冒出許多小小水泡。
                        我們驚訝地抓起小手仔細端詳，發現竟然連指紋也變淡了！
                    </p>
                    <p>這才親身體會，原來史萊姆的原料，
                        需要分外嚴格審視。
                    </p>
                    <p>在文具行購買來路不明的膠水，
                        專門設計給大人使用的刮鬍泡，
                        高濃度的化學香精，
                        都不是小朋友該輕易接觸的物品。
                    </p>
                </div>
            </div>
            <div class="body-area3">
                <p class="sub-title">於是，身為爸媽的我們，開始在台灣各地尋找最優質的材料，</p>
                <div class="info">
                    <p>
                        只為給予女兒最安心的遊玩體驗。
                        史萊姆怪獸星球 採用的史萊姆專用膠水、成型水等材料，
                        皆通過 SGS 安全檢測，百分百台灣製造。
                    </p>
                    <p>這也是為什麼，透過 史萊姆怪獸星球 ，
                        孩子的每次遊玩，家長都能夠百分百放心。
                    </p>
                    <p>你知道嗎？經常當作成型水添加的隱形眼鏡藥水裡，也含有硼砂！
                        我們敢保證， 史萊姆怪獸星球 的獨門配方，絕對不含硼砂，徹底杜絕硼砂致敏的可能性。
                    </p>
                    <p>
                        拒絕毒性與致敏成分，面對孩子，我們總是謹慎，謹慎，再謹慎。
                    </p>
                </div>
            </div>
            <div class="body-area4">
                <p class="sub-title">這是一個在星際間與怪獸共同探險的旅程⋯⋯</p>
                <div class="info">
                    <p>
                        好久好久以前的某一天，宇宙裡出現一場爆炸，史萊姆飛到不同星球上，成為不同的史萊姆怪獸。
                    </p>
                    <p>史萊姆怪獸有超能力，超能力包含千變萬化的無限可能性：
                        既可以閃爍金色光芒，替代星際獨角獸遺失的隕石角，
                        也可以延展得又薄又寬，成為彗星公主撒滿閃亮星星碎片的禮服裙擺。
                    </p>
                    <p>怪獸想要獲得更多超能力，都要靠「史萊姆探險家」來幫忙。
                        被稱為「探險家」的小朋友，擁有「進化」史萊姆怪獸的能力。
                        星際裡最厲害的探險家，將會得到「超新星探險家 Supernova 」的稱號！
                    </p>
                    <p>
                        為了成為超新星探險家，小朋友們踏上探險之旅。旅途中，探險家將會認識不同的夥伴、進化出不同的史萊姆怪獸，
                        更延伸出許多有趣、歡樂與感動的故事。
                    </p>
                </div>
            </div>
            <div class="body-area5" v-if="isMobile">
                <p class="sub-title">這是一個在星際間與怪獸共同探險的旅程⋯⋯</p>
                <div class="info">
                    <p>
                        好久好久以前的某一天，宇宙裡出現一場爆炸，史萊姆飛到不同星球上，成為不同的史萊姆怪獸。
                    </p>
                    <p>史萊姆怪獸有超能力，超能力包含千變萬化的無限可能性：
                        既可以閃爍金色光芒，替代星際獨角獸遺失的隕石角，
                        也可以延展得又薄又寬，成為彗星公主撒滿閃亮星星碎片的禮服裙擺。
                    </p>
                    <p>怪獸想要獲得更多超能力，都要靠「史萊姆探險家」來幫忙。
                        被稱為「探險家」的小朋友，擁有「進化」史萊姆怪獸的能力。
                        星際裡最厲害的探險家，將會得到「超新星探險家 Supernova 」的稱號！
                    </p>
                    <p>
                        為了成為超新星探險家，小朋友們踏上探險之旅。旅途中，探險家將會認識不同的夥伴、進化出不同的史萊姆怪獸，
                        更延伸出許多有趣、歡樂與感動的故事。
                    </p>
                </div>
            </div>
        </div> -->
        <div>
            <div>
                <img src="../assets/img/mobile/about/titleImg.png" alt="" style="width: 100%;">
            </div>
            <div class="row mobile-body">
                <div class="col-12 body-area">
                    <div style="margin-bottom: 65px;">
                        <img src="../assets/img/pc/about/girl.png" alt="" class="Img"
                            style="top: 0%;left: -2%;transform: translateY(-81%);">
                        <img src="../assets/img/mobile/about/bg1.png" alt="" class="bgImg Img"
                            style="top: 0%;left: -36%;transform: translateY(-15%);">
                        <!-- <img src="../assets/img/mobile/about/bg2.png" alt="" class="bgImg Img"
                            style="top: 20%;left: 78%;transform: translateY(-61%);"> -->
                    </div>
                    <p class="title">女兒才是初始創辦人</p>
                    <p class="sub-title">說到史萊姆，我們家的小朋友，她可是行家！</p>
                    <div class="info">
                        <p>自從女兒開始接觸史萊姆，無限的熱情，從她小小的體內不斷湧出。
                            她可以展現驚人的專注力，好幾個小時安安靜靜地揉捏史萊姆；
                            她也曾經發揮探險精神，將家中想像得到的材料通通拿去做實驗，
                            讓爸爸臨時想刮鬍子都沒辦法。
                        </p>
                        <p>在她天馬行空的腦袋瓜裡，關於史萊姆的故事，更是源源不絕。
                            史萊姆怪獸星球 的輪廓，就這樣逐漸成形。
                        </p>
                        <p>身為 史萊姆怪獸星球 的地下總監，女兒對於史萊姆，可是很挑剔的。
                            小朋友最懂小朋友，經過這位小小「史萊姆校長」的親身把關，
                            您家寶貝也能安全又自由的快樂探險。
                        </p>
                    </div>
                </div>
                <div class="col-12 text-center">
                    <img src="../assets/img/mobile/about/Img1.png" alt="" style="width: 80%;">
                </div>
            </div>
            <div style="position: relative;">
                <img src="../assets/img/mobile/about/sloganmodel.png" alt="" style="width: 100%;">

                <!-- <img src="../assets/img/mobile/about/bg3.png" alt="" class="bgImg Img"
                    style="top: -12%;left: 70%;transform: translateY(-81%);"> -->
            </div>
            <div class="row mobile-body">
                <div class="col-12 body-area">
                    <!-- <img src="../assets/img/mobile/about/bg4.png" alt="" class="bgImg Img"
                        style="top: 50%;left: 92%;transform: translate(-8%,17%);"> -->
                    <p class="sub-title">「媽媽，我的手好癢！」</p>
                    <div class="info">
                        <p>
                            在還沒創立品牌時，女兒迷上史萊姆沒多久，
                            手上卻突然冒出許多小小水泡。
                            我們驚訝地抓起小手仔細端詳，發現竟然連指紋也變淡了！
                        </p>
                        <p>這才親身體會，原來史萊姆的原料，
                            需要分外嚴格審視。
                        </p>
                        <p>在文具行購買來路不明的膠水，
                            專門設計給大人使用的刮鬍泡，
                            高濃度的化學香精，
                            都不是小朋友該輕易接觸的物品。
                        </p>
                    </div>
                </div>
                <div class="col-12 text-center">
                    <img src="../assets/img/mobile/about/Img2.png" alt="" style="width: 100%;">
                </div>
                <div>
                    <div class="body-area">
                    <img src="../assets/img/mobile/about/bg5.png" alt="" class="bgImg Img"
                        style="top: -30%;left: -22%;transform: translate(-34%, 0%);">
                        <p class="sub-title">於是，身為爸媽的我們，開始在台灣各地尋找最優質的材料，</p>
                        <div class="info">
                            <p>
                                只為給予女兒最安心的遊玩體驗。
                                史萊姆怪獸星球 採用的史萊姆專用膠水、成型水等材料，
                                皆通過 SGS 安全檢測，百分百台灣製造。
                            </p>
                            <p>這也是為什麼，透過 史萊姆怪獸星球 ，
                                孩子的每次遊玩，家長都能夠百分百放心。
                            </p>
                            <p>你知道嗎？經常當作成型水添加的隱形眼鏡藥水裡，也含有硼砂！
                                我們敢保證， 史萊姆怪獸星球 的獨門配方，絕對不含硼砂，徹底杜絕硼砂致敏的可能性。
                            </p>
                            <p>
                                拒絕毒性與致敏成分，面對孩子，我們總是謹慎，謹慎，再謹慎。
                            </p>
                        </div>
                        
                    <!-- <img src="../assets/img/mobile/about/bg6.png" alt="" class="bgImg Img"
                        style="top: 90%;left: 77%;transform: translate(14%, -10%);"> -->
                    </div>
                </div>
            </div>
            <div>
                <img src="../assets/img/mobile/about/middle1.png" alt="" style="width: 100%;">
            </div>
            <div class="row mobile-body">
                <div class="body-area">
                    <p class="sub-title">這是一個在星際間與怪獸共同探險的旅程⋯⋯</p>
                    <div class="info">
                        <p>
                            好久好久以前的某一天，宇宙裡出現一場爆炸，史萊姆飛到不同星球上，成為不同的史萊姆怪獸。
                        </p>
                        <p>史萊姆怪獸有超能力，超能力包含千變萬化的無限可能性：
                            既可以閃爍金色光芒，替代星際獨角獸遺失的隕石角，
                            也可以延展得又薄又寬，成為彗星公主撒滿閃亮星星碎片的禮服裙擺。
                        </p>
                        <p>怪獸想要獲得更多超能力，都要靠「史萊姆探險家」來幫忙。
                            被稱為「探險家」的小朋友，擁有「進化」史萊姆怪獸的能力。
                            星際裡最厲害的探險家，將會得到「超新星探險家 Supernova 」的稱號！
                        </p>
                        <p>
                            為了成為超新星探險家，小朋友們踏上探險之旅。旅途中，探險家將會認識不同的夥伴、進化出不同的史萊姆怪獸，
                            更延伸出許多有趣、歡樂與感動的故事。
                        </p>
                    </div>
                </div>
                <div class="col-12 text-center" style="position: relative;">
                    <img src="../assets/img/mobile/about/Img3.png" alt="" style="width: 100%;margin-bottom: 20px;">
                    <!-- <img src="../assets/img/mobile/about/bg7.png" alt="" class="bgImg Img"
                        style="top: 55%;left: 77%;transform: translate(14%, -10%);"> -->
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <div>
            <img src="../assets/img/pc/about/titleImg.png" alt="" style="width: 100%;">
        </div>
        <div class="pc-body">
            <div class="row pc-body-area1">
                <div class="col-6 img-area">
                    <img src="../assets/img/pc/about/girl.png" alt=""
                        style="position: absolute;top: -69%;left: 30%;width: 80%;">
                    <img src="../assets/img/pc/about/bgImg1.png" alt=""
                        style="position: absolute;top: 38%;width: 45%;z-index: -1;">
                </div>
                <div class="col-6 mt-5 text-area">
                    <p class="title">女兒才是初始創辦人</p>
                    <p class="sub-title">說到史萊姆，我們家的小朋友，她可是行家！</p>
                    <div class="info">
                        <p>自從女兒開始接觸史萊姆，無限的熱情，從她小小的體內不斷湧出。
                            她可以展現驚人的專注力，好幾個小時安安靜靜地揉捏史萊姆；
                            她也曾經發揮探險精神，將家中想像得到的材料通通拿去做實驗，
                            讓爸爸臨時想刮鬍子都沒辦法。
                        </p>
                        <p>在她天馬行空的腦袋瓜裡，關於史萊姆的故事，更是源源不絕。
                            史萊姆怪獸星球 的輪廓，就這樣逐漸成形。
                        </p>
                        <p>身為 史萊姆怪獸星球 的地下總監，女兒對於史萊姆，可是很挑剔的。
                            小朋友最懂小朋友，經過這位小小「史萊姆校長」的親身把關，
                            您家寶貝也能安全又自由的快樂探險。
                        </p>
                    </div>
                </div>
            </div>
            <div class="img-area">
                <img src="../assets/img/pc/about/slogan.png" alt="">
                <!-- <img src="../assets/img/pc/about/bgImg2.png" alt=""
                    style="position: absolute;top: -50%;right: -20%;width: 35%;z-index: -1;"> -->
            </div>
            <div class="row pc-body-area2 justify-content-center">
                <div class="col-6  text-area">
                    <p class="sub-title">「媽媽，我的手好癢！」</p>
                    <div class="info">
                        <p>
                            在還沒創立品牌時，女兒迷上史萊姆沒多久，
                            手上卻突然冒出許多小小水泡。
                            我們驚訝地抓起小手仔細端詳，發現竟然連指紋也變淡了！
                        </p>
                        <p>這才親身體會，原來史萊姆的原料，
                            需要分外嚴格審視。
                        </p>
                        <p>在文具行購買來路不明的膠水，
                            專門設計給大人使用的刮鬍泡，
                            高濃度的化學香精，
                            都不是小朋友該輕易接觸的物品。
                        </p>
                    </div>
                </div>
                <div class="col-4 text-center img-area">
                    <img src="../assets/img/pc/about/img1.png" alt="">
                    <img src="../assets/img/pc/about/bgImg3.png" alt=""
                        style="position: absolute;top: 45%;right: -25%;z-index: -1;">
                </div>
            </div>
            <div class="row pc-body-area3 justify-content-center">
                <div class="col-6 text-center img-area">
                    <img src="../assets/img/pc/about/img2.png" alt="">
                    <img src="../assets/img/pc/about/bgImg4.png" alt=""
                        style="position: absolute;top: 50%;left: -30%;z-index: -1;">
                </div>
                <div class="col-6 text-area">
                    <p class="sub-title">於是，身為爸媽的我們，開始在台灣各地尋找最優質的材料，</p>
                    <div class="info">
                        <p>
                            只為給予女兒最安心的遊玩體驗。
                            史萊姆怪獸星球 採用的史萊姆專用膠水、成型水等材料，
                            皆通過 SGS 安全檢測，百分百台灣製造。
                        </p>
                        <p>這也是為什麼，透過 史萊姆怪獸星球 ，
                            孩子的每次遊玩，家長都能夠百分百放心。
                        </p>
                        <p>你知道嗎？經常當作成型水添加的隱形眼鏡藥水裡，也含有硼砂！
                            我們敢保證， 史萊姆怪獸星球 的獨門配方，絕對不含硼砂，徹底杜絕硼砂致敏的可能性。
                        </p>
                        <p>
                            拒絕毒性與致敏成分，面對孩子，我們總是謹慎，謹慎，再謹慎。
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-5">
            <img src="../assets/img/pc/about/middle1.png" alt="" style="width: 100%;">
        </div>
        <div class="pc-body">
            <div class="row pc-body-area4 justify-content-center">
                <div class="col-6 text-area">
                    <!-- <p class="sub-title">於是，身為爸媽的我們，開始在台灣各地尋找最優質的材料，</p> -->
                    <p class="sub-title">這是一個在星際間與怪獸共同探險的旅程⋯⋯</p>
                    <div class="info">
                        <p>
                            好久好久以前的某一天，宇宙裡出現一場爆炸，史萊姆飛到不同星球上，成為不同的史萊姆怪獸。
                        </p>
                        <p>史萊姆怪獸有超能力，超能力包含千變萬化的無限可能性：
                            既可以閃爍金色光芒，替代星際獨角獸遺失的隕石角，
                            也可以延展得又薄又寬，成為彗星公主撒滿閃亮星星碎片的禮服裙擺。
                        </p>
                        <p>怪獸想要獲得更多超能力，都要靠「史萊姆探險家」來幫忙。
                            被稱為「探險家」的小朋友，擁有「進化」史萊姆怪獸的能力。
                            星際裡最厲害的探險家，將會得到「超新星探險家 Supernova 」的稱號！
                        </p>
                        <p>
                            為了成為超新星探險家，小朋友們踏上探險之旅。旅途中，探險家將會認識不同的夥伴、進化出不同的史萊姆怪獸，
                            更延伸出許多有趣、歡樂與感動的故事。
                        </p>
                    </div>
                </div>
                <div class="col-6 text-center">
                    <div class="row">
                        <div class="col-12 my-5 img-area">
                            <img src="../assets/img/pc/about/img3.png" alt="">
                            <!-- <img src="../assets/img/pc/about/bgImg5.png" alt=""
                                style="position: absolute;top: 50%;right: -30%;z-index: -1;"> -->
                        </div>
                        <!-- <div class="col-12 my-5" style="padding-left: 55px;">
                            <img src="../assets/img/pc/about/img4.png" alt="">
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    computed: {
        ...mapState({
            isMobile: state => state.isMobile,
        }),
    },
    data() {
        return {
            bgImg: ''
        }
    },
    created() {
        if (this.isMobile) this.bgImg = require('../assets/img/mobile/about/bg.png')
        else this.bgImg = require('../assets/img/pc/about/bg.png')
    }
}
</script>

<style scoped>
p {
    margin-bottom: 10px;
}

.body-bg {
    width: 100%;
    position: relative;
}

.body-area {
    position: relative;
    margin: 20px 0;
}

.mobile-body {
    padding: 0 60px;
    width: 100%;
}

.bgImg {
    z-index: -1;
    width: 50% !important;
}

.Img {
    position: absolute;
    width: 80%;
}


.title {
    color: var(--main-text-color);
    font-size: 18px;
}

.sub-title {
    font-weight: bold;
    font-size: 14px;
}

.body-area .info {
    font-size: 12px;
}


@media only screen and (min-width: 768px) and (max-width: 819px) {
    .title {
        font-size: 26px !important;
    }

    .sub-title {
        font-size: 24px !important;
        width: 500px !important;
    }

    .body-area .info,
    .body-area .info,
    .body-area .info,
    .body-area .info,
    .body-area .info {
        width: 500px;
    }
}

@media only screen and (min-width: 820px) and (max-width: 991px) {
    .title {
        font-size: 26px !important;
    }

    .sub-title {
        font-size: 24px !important;
        width: 500px !important;
    }

    .body-area .info,
    .body-area .info,
    .body-area.info,
    .body-area .info,
    .body-area .info {
        width: 500px;
    }
}
</style>

<style scoped>
.pc-body {
    padding: 0 60px;
}

.pc-body img {
    width: 100%;
}

.pc-body-area2,
.pc-body-area3,
.pc-body-area4 {
    margin-top: 5rem;
}

.pc-body-area2 img,
.pc-body-area3 img,
.pc-body-area4 img {
    width: 70%;
}

.img-area {
    position: relative;
}

.text-area {
    padding: 40px 80px;
}

.pc-body-area4 {
    margin-top: auto;
}

.pc-body-area4 .text-area {
    padding-top: 2rem;
}
</style>

<style scoped>
@media (min-width: 992px) {
    .body-bg {
        background-image: url('../assets/img/pc/about/bg.png');
        height: 4405px;
    }


    .body-area1 {
        top: 15.5%;
        left: 51%;
    }

    .title {
        font-size: 24px;
    }

    .sub-title {
        font-size: 22px;
    }

    .info {
        font-size: 18px !important;
        width: 500px !important;
    }

    p {
        margin-bottom: 20px;
        line-height: 2rem;
    }

    .body-area2 {
        top: 37%;
        left: 15%;
    }

    .body-area3 {
        top: 48.5%;
        left: 55%;
    }

    .body-area3 .sub-title {
        width: 300px;
    }

    .body-area4 {
        position: absolute;
        top: 79%;
        left: 15%;
    }

}
</style>