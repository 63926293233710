import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
  // 狀態屬性
  state: {
    isMobile: false,
    loggedInUser: null,
    bookingDetail: null,
    //購物車Menu
    showCart: false,
    //購物清單
    shoppingCart: [],
    shoppingInfo: {},
    //購物車結帳按鈕變化
    countClickShoppingCartBtn: 0,
  },
  // 狀態變更方法
  mutations: {
    setIsMobile(state, value) {
      state.isMobile = value;
    },
    setLoggedInUser(state, user) {
      state.loggedInUser = user;
    },
    clearLoggedInUser(state) {
      state.loggedInUser = null;
    },
    setbookingDetail(state, booking) {
      state.bookingDetail = booking;
    },
    clearbookingDetail(state) {
      state.bookingDetail = null;
      localStorage.removeItem("bookingDetail");
    },
    TOGGLE_SHOPPING_CART(state, val) {
      if (typeof val === "boolean") {
        state.showCart = val;
      } else {
        state.showCart = !state.showCart;
      }
    },
    SHOPPING_CART_BTN_COUNT(state) {
      state.countClickShoppingCartBtn += 1;
    },
    SET_SHOP_INFO(state, info) {
      state.shoppingInfo = info;
    },
  },
  // 非同步方法
  actions: {
    checkIsMobile({ commit }) {
      const isMobile =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        ) ||
        (window.innerWidth <= 768 && window.innerHeight <= 1024);
      commit("setIsMobile", isMobile);
    },
    login({ commit }, user) {
      // 處理登入邏輯，例如發送API請求進行身份驗證
      // 成功後，將登入資訊傳遞給mutation進行更新
      commit("setLoggedInUser", user);
    },
    logout({ commit }) {
      // 處理登出邏輯，例如清除本地存儲的登入資訊
      // 完成後，通過mutation清除登入資訊
      commit("clearLoggedInUser");
    },
    setBooking({ commit }, booking) {
      commit("setbookingDetail", booking);
    },
    clearBooking({ commit }) {
      commit("clearbookingDetail");
    },
    toggleShoppingCart({ commit }, val) {
      commit("TOGGLE_SHOPPING_CART", val);
    },
    setShopInfo({ commit }, info) {
      commit("SET_SHOP_INFO", info);
    },
  },
  // 計算屬性
  getters: {
    showCart: (state) => state.showCart,
    shoppingInfo: (state) => state.shoppingInfo,
    shoppingCart: (state) => state.shoppingCart,
    getCountClickShoppingCartBtn: state => state.countClickShoppingCartBtn,
  },
});

export default store;
