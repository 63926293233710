<style lang="scss" scoped>
.swiper-container {
    max-width: 420px;

    .img-size {
        width: 420px;
        height: 420px;
    }
}

.bg-card {
    padding: 80px;
    min-height: inherit !important;
}

h3 {
    font-size: 28px;
    font-weight: 700;
    color: var(--ft-main);
}

.subtitle {
    color: var(--btn-main);
}

.remark {
    font-size: 12px;
}

hr {
    background-color: var(--color-gray1);
    height: 1.5px;
}

.btn-pink {
    border-radius: 8px !important;
    height: 38px;
    font-size: 16px !important;
    background-color: var(--main-text-color) !important;
    color: white;
}

.d-row {
    display: grid;
    grid-template-columns: 56px 5fr;
    gap: 8px;
    align-items: center;
}

.thumbnail {
    margin: 20px 18px 0;
    display: grid;
    gap: 12.5px;
    grid-template-columns: repeat(4, 1fr);
    //overflow: scroll;

    img {
        cursor: pointer;
    }

    .img-size-min {
        width: 74px;
        height: 74px;
    }
}

.item-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: -8px 0 0 -8px;
}

.item-wrap>* {
    margin: 10px 0 0 8px;
}

.item {
    border: 1.5px solid var(--color-gray1);
    box-sizing: border-box;
    border-radius: 6px;
    padding: 5px 20px;
    max-width: max-content;
    max-height: 90px;
    cursor: pointer;

    &:hover,
    &.active {
        background-color: #ffecde;
    }
}

.info {
    display: inline;

    span {
        font-size: 15px;
        font-weight: 800;
        color: #ffffff;
        background-color: var(--btn-info);
        padding: 1px 10px;
        border-radius: 8px;
        margin-right: 5px;
    }
}

.info+.info {
    margin-left: 5px;
}

.time-wrap {
    border-left: 1.5px solid var(--btn-main);
}

.time-grid {
    display: grid;
    grid-template-columns: repeat(4, 100px);
    gap: 8px 12px;
    justify-content: center;

    .time-item {
        cursor: pointer;
        padding: 10px 20px;
        border-radius: 12px;
        text-align: center;
        font-size: 14px;
        background: #ffffff;
        color: #6f6f6f;
        border: 1px solid #dddddd;
    }

    .candidate {
        background: #eeeeee;
        color: #6f6f6f;
        border: 1px solid #dddddd;
    }

    .active {
        background: var(--btn-time-active);
        box-shadow: 2px 2px 5px #ebebeb;
        color: #ffffff;
        font-weight: 700;
    }
}

.time-info-text {
    font-size: 10px;
}

.candidate-box {
    margin: 10px 0;
}

.classesbox {
    padding: 8px 0 16px;
    color: var(--ft-label-classes);
}

.classesbox+.classesbox {
    padding: 16px 0;
    border-top: 1.5px solid var(--color-gray1);
}

.label-checkbox {
    width: auto;
    line-height: 22.5px;
    padding: 8px;
    padding-left: 3px;
}

.form-group .label-pink {
    padding: 0;
}

.title-course {
    width: auto;
    padding-left: 35px;
    font-size: 16px;
    font-weight: bold;
}

.last {
    color: var(--color-orange1);
    font-size: 14px;
    font-weight: bold;
}

.label-checkbox input[type="checkbox"]+span {
    padding-left: 22px;
}

.label-classes input[type="checkbox"]+span,
.label-classes input[type="checkbox"]:checked+span {
    margin: 0;
    line-height: 22.5px;
}

.store-info {
    width: 20px;
    height: 20px;
}

.bg-pers.top {
    margin: 0;
}

@media (max-width: 1200px) {
    .swiper-container {
        max-width: 85vw;

        .img-size {
            width: 77vw;
            height: 77vw;
        }
    }

    .bg-card {
        padding: 35px;
    }

    h4 {
        font-size: 40px;
    }

    .d-row {
        grid-template-columns: 72px 5fr;
    }

    .item {
        padding: 15px 38px;
        border-radius: 10px;
    }

    .desc {
        margin-top: 32px;
    }

    .item-wrap>* {
        margin: 16px 0 0 8px;
    }

    .btn {
        // min-height: 90px;
    }

    .btn-pink {
        font-size: 18px !important;
    }

    .thumbnail {
        margin: 25px 2vw 0;
        gap: 3vw;

        .img-size-min {
            width: 14.5vw;
            height: 14.5vw;
        }
    }

    .info {
        span {
            font-size: 24px;
            padding: 3px 15px;
            margin-right: 10px;
        }
    }

    .info+.info {
        margin-left: 10px;
    }

    .time-grid {
        grid-template-columns: repeat(4, 1fr);
        gap: 20px;

        .time-item {
            font-size: 28px;
        }
    }

    .time-wrap {
        border-top: 1.5px solid var(--btn-main);
        border-left: none;
        padding: 30px 20px 0;
    }

    .circle {
        width: 22px;
        height: 22px;
    }

    .time-info-text {
        font-size: 26px;
    }

    .candidate-box {
        margin: 10px 0 30px;
    }

    .bg-pers.top {
        margin: 30px 0 20px;
    }

    .title-course,
    .last {
        font-size: 24px;
    }

    .classesbox {
        padding: 18px 0 26px;
    }

    .classesbox+.classesbox {
        padding: 40px 0 26px;
        border-top: 1.5px solid var(--color-gray1);
    }

    .label-checkbox {
        padding: 24px 8px 12px;
        padding-left: 0;
    }

    input,
    select,
    textarea,
    textarea:focus,
    #dateTimeInput {
        -webkit-border-radius: 12px !important;
        border-radius: 12px !important;
        font-size: 26px !important;
        padding: 20px;
    }

    .label-checkbox input[type="checkbox"]+span {
        display: flex;
        flex-direction: column;
    }

    .label-checkbox input[type="checkbox"]+span,
    .label-checkbox input[type="checkbox"]:checked+span,
    input[type="radio"]+span,
    input[type="radio"]:disabled+span,
    input[type="radio"]:checked+span,
    input[type="radio"]:checked:disabled+span,
    .label-classes input[type="checkbox"]+span,
    .label-classes input[type="checkbox"]:checked+span {
        font-size: 26px;
        padding-left: 50px;
        line-height: 38px;
        background-size: 38px 38px;
        background-position: left center;
    }

    .store-info {
        width: 30px;
        height: 30px;
    }

    hr {
        margin: 24px auto;
        height: 2px;
        width: 100%;
        background-color: transparent;
        border-top: solid 2px #dadada;
    }
}
</style>
<template>
    <div class="store-main">
        <!-- BEGIN breadcrumb -->
        <!-- <ol class="breadcrumb desktop-only">
      <li class="breadcrumb-item active">{{ $route.query.breadcrumb }}</li>
      <li class="breadcrumb-item active">{{ detail.item_name }}</li>
    </ol> -->

        <!-- top content -->
        <section class="bg-card" style="min-height: unset; text-align: unset;">
            <div class="row">
                <div class="col-xl-5 col-12">
                    <!-- 大圖 -->
                    <swiper ref="picture" :options="swiperOptions">
                        <swiper-slide v-for="i in detail.picture_list" :key="i.id">
                            <img :src="i.url" class="img-size" />
                        </swiper-slide>
                    </swiper>
                    <!-- 小圖 -->
                    <div v-show="detail.picture_list.length > 1" class="thumbnail">
                        <img :src="i.url" v-for="i in detail.picture_list" :key="i.id" class="img-size-min"
                            @click="handleImage(i)" />
                    </div>
                </div>
                <div class="col-xl-7 col-12 desc p-10px">
                    <span style="font-size: 24px; color: var(--ft-main)" class="col-xl-7 col-12 desc m-text-40 fw-bold">{{
                        detail.item_name }}</span>
                    <figure class="mt-10px" v-if="ec_sales == true && (detail.amtenable == null || detail.amtenable)">
                        <span style="font-size: 18px; padding-right: 10px" class="m-text-35">
                            NT${{ $cf.numProc.numberWithCommas(detail.discount_price) }}</span>
                        <span
                            v-show="detail.original_price !== detail.discount_price && detail.original_price > detail.discount_price && detail.original_price != 0"
                            style="
                text-decoration: line-through;
                font-size: 14px;
                color: #a1a1a1;
              " class="m-text-28">
                            NT${{ $cf.numProc.numberWithCommas(detail.original_price) }}</span>
                    </figure>
                    <figure v-else>
                        <h2>
                            <span style="font-size: 24px; color: red">
                                {{ detail.ecenablename }}</span>
                        </h2>
                    </figure>
                    <hr />
                    <figure class="mt-10px">
                    </figure>


                    <div v-if="ec_sales" class="d-flex m-text-24" style="margin-top: 24px; flex-direction: column">
                        <!-- 非團課數量 -->
                        <template>
                            <div v-show="detail.amtenable == null || detail.amtenable" style="margin-bottom: 4px"
                                class="m-text-24 w-100">
                                <span>請選擇數量</span>
                            </div>
                            <div v-show="detail.amtenable == null || detail.amtenable" class="item-wrap">
                                <InputNumber :qty.sync="detail.qty" :min="1"> </InputNumber>
                            </div>
                        </template>
                        <!-- 團課數量 -->
                        <!-- <template v-else>
              <section
                v-for="input in detail.group_class_link_type"
                :key="input.ROW_ID"
              >
                <div style="margin-bottom: 4px" class="m-text-24 w-100">
                  <span>{{ input.COURSE_NUMBER_TYPE_NAME }} {{ input.NUMBER }}:{{ input.NUMBER_PROPORTION }} - 請選擇數量</span>
                </div>
                <div class="item-wrap">
                  <InputNumber :qty.sync="input.qty" :min="0"> </InputNumber>
                </div>
              </section>
            </template> -->

                        <div v-show="detail.amtenable == null || detail.amtenable" class="item-wrap row"
                            style="margin-top:10px">
                            <button v-show="show_cart_btn" class="btn btn-pink col-xs-6 col-md-4 col-6"
                                @click="AddShoppingCart">
                                <img class="icon-cart" />
                                加入購物車
                            </button>
                        </div>
                    </div>

                    <hr />

                    <div v-for="(i, index) in detail.events" :key="index" class="d-flex flex-row m-text-28"
                        style="margin-top: 12px">
                        <div>
                            <i class="fas fa-bullhorn m-text-24" style="
                  font-size: 12px;
                  margin-right: 5px;
                  position: relative;
                  top: -1.5px;
                " />{{ i.content }}
                        </div>
                        <a v-show="i.url !== '' && i.url !== null" :href="i.url" target="_blank"
                            style="text-decoration: underline; margin-left: 3px">詳情</a>
                    </div>
                </div>
            </div>
        </section>

        <!-- second content -->

        <!-- main content -->
        <section class="bg-card">
            <h3 class="m-text-35" style="margin-bottom: 24px;text-align: center">
                {{ detail.title }}
            </h3>

            <div class="subtitle m-text-24" style="margin-bottom: 24px">
                {{ detail.subtitle }}
            </div>

            <div v-for="(i, index) in detail.content" :key="index" style="margin-bottom: 24px" class="m-text-24">
                <span v-show="i.type === 'content'" v-html="handleTextBreak(i.value)"></span>
                <img v-show="i.type === 'image'" :src="i.value"
                    style="max-width: 1000px; width: 100%;margin: 0 auto;display: block" />
            </div>

            <hr />
            <span class="remark m-text-24" v-html="handleTextBreak(detail.remark)"></span>
        </section>
    </div>
</template>
<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import InputNumber from "../components/common/InputNumber";
import axios from "../api/axios.js";
import { mapState } from "vuex";

export default {

    inject: ["callCartCount"],
    components: {
        Swiper,
        SwiperSlide,
        InputNumber,
    },
    async created() {
        // this.callApi(API_ROUTE.System.sysparam, { p_key: "Client_Mall_Buy" }, {});
        // this.projectMode = await this.getProjectMode();
        this.id = this.$route.params.id
        axios.post("/Course/GetCommodityDetail2", { id: this.id, source_type: 'BAS_GOODS' }).then(resp => {
            if (resp.succ) {
                this.detail = resp.result.detail;
                this.id = resp.result.id;
            }
        });
    },
    data() {
        return {
            id: 0,
            group_class: {
                date_list: [
                    {
                        date: "11/30",
                        time: "11:00",
                        active: true,
                    },
                    {
                        date: "11/30",
                        time: "11:30",
                        active: false,
                    },
                    {
                        date: "12/30",
                        active: false,
                        time: "11:00",
                    },
                ],
            },
            show_cart_btn: null,
            button_type: 2,
            breadcrumb: "",
            source_type: "",
            ec_sales: true,
            projectMode: "1",
            swiperOptions: {
                width: window.innerWidth,
            },
            shopping_cart: {
                id: "",
                source_sno: "",
                item_name: "",
                original_price: 0,
                discount_price: 0,
                pre_price: 0,
                qty: 1,
                image: "",
                tag_list: [],
                breadcrumb: "",
            },
            shopping_info: {
                brand: "",
                store_id: 0,
                store_name: "",
                type: 1, //商品類型 1:一般課程 2:組合課程 3:商品
                voucher: true,
                disc: true,
                point: true,
                sno: 0,
            },
            detail: {
                //圖片list
                picture_list: [
                    {
                        id: 1,
                        url: "https://",
                    },
                ],
                //非組合課程list
                store_list: [
                    {
                        row_id: "1",
                        classification: "",
                        position: "",
                        content: "",
                        tag_list: [
                            {
                                id: 1,
                                tag_name: "",
                                active: false,
                            },
                        ],
                    },
                ],
                //組合課程list
                group_store_list: [
                    {
                        row_id: "1",
                        classification: "",
                        position: "",
                        content: "",
                        tag_list: [
                            {
                                id: 1,
                                tag_name: "",
                                active: false,
                            },
                        ],
                    },
                    {
                        row_id: "2",
                        classification: "",
                        position: "",
                        content: "",
                        tag_list: [
                            {
                                id: 1,
                                tag_name: "",
                                active: false,
                            },
                        ],
                    },
                ],
                qty: 1,
                TARGET_AMT: 0,
                //活動list
                events: [
                    {
                        content: "",
                        url: "https://",
                    },
                ],
                //商品名稱
                item_name: "",
                original_price: 0,
                discount_price: 0,
                pre_price: 0,
                title: "",
                subtitle: "",
                content: [
                    {
                        type: "",
                        value: "",
                    },
                ],
                remark: "",
                //團課課程
                group_class_up_link_group_number: [],
                TIME_YN: true,
                ALL_YN: true,
                RESERVE_YN: true,
            },
        };
    },
    mounted() {
        this.show_cart_btn = true;
        //after call api
        this.parseMarkdown();
    },
    computed: {
        ...mapState({
            isMobile: state => state.isMobile,
        }),
        groupClassTitle() {
            if (this.detail.ALL_YN) {
                return `日期及時間
        <div>
          目前人數：${this.detail.group_class_up_link_group_number[0].NUMBER_NOW} / 上限人數：${this.detail.group_class_up_link_group_number[0].NUMBER_MAX}
        </div>`;
            } else {
                if (
                    this.detail.group_class_up_link_group_number.every(
                        (x) => x.NUMBER_NOW >= x.NUMBER_MAX
                    )
                ) {
                    return "請選擇您想要的日期及時間（人數已達上限）";
                } else {
                    return `請選擇您想要的日期及時間`;
                }
            }
        },
    },
    methods: {
        // ...mapActions(["setCart", "setShopInfo"]),
        parseMarkdown() {
            this.detail.content.forEach((i) => {
                if (i.type === "content") {
                    /* eslint-disable no-useless-escape */
                    i.value = this.$cf.markdown.parseMarkdown(i.value);
                }
            });

            this.detail.remark = this.$cf.markdown.parseMarkdown(this.detail.remark);
        },
        handleImage(img) {
            let index = this.detail.picture_list.findIndex((i) => i.id === img.id);
            this.$refs.picture.$swiper.slideTo(index);
        },
        AddShoppingCart() {
            const user = this.$cookies.get("user")
            if (!user) {
                this.$swal("請先登入！");
                this.$router.push({
                    name: 'signIn'
                });
                return
            }
            axios.post("/Course/ShoppingCartCreate", { uid: user.sno, id: this.id, source_type: 'BAS_GOODS', qty: this.detail.qty }).then(resp => {
                if (resp.succ) {
                    this.$swal("已成功加入購物車!");
                    this.callCartCount();
                }
            });
        },
        handleTextBreak(val) {
            return val.replace(/\n/g, "<br>");
        },
    },
};
</script>
