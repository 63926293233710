<template>
    <!-- <img v-lazy="../../assets/img/mobile/home/(P)BG-10.png" alt=""> -->
    <div class="footer-backcolor">
        <div v-if="isMobile">
            <img v-lazy="ImgGroup.logo" alt="" id="footer-logo">
            <div style="padding: 0 10px;">
                <div style="text-align: center;">
                    <div class="row" id="footer-row-title">
                        <img v-lazy="ImgGroup.master" alt="">
                    </div>
                    <div class="footer-info">
                        <p>
                        隨著科技的進步、AI人工智慧的突破， <br>
                        有「想法」，變得前所未有的重要。 <br>
                        孩子們的未來，將由創意力來主導！ <br>

                        如何一邊磨練邏輯思考，又能解放想像、盡情創造呢？ <br>

                        GAGA monster史萊姆怪獸星球 <br>
                        和你的孩子一起，從思考到創造，開發潛藏的創意力。</p>
                    </div>
                </div>

                <div class="footer-connection">
                    <!-- <div class="row" v-for="(item, index) in shhop_connection_list" :key="index" style="margin-top: 15px;">
                <div class="col-12">{{ item.text }}</div>
            </div> -->
                    <div class="row">
                        <!-- <div class="col-12 mt-2">客服電話 : (02) 7756-3931</div>
                        <div class="col-12 mt-2"><a
                                href="https://www.google.com/maps/place/110%E5%8F%B0%E5%8C%97%E5%B8%82%E4%BF%A1%E7%BE%A9%E5%8D%80%E6%9D%BE%E9%AB%98%E8%B7%AF11%E8%99%9F/@25.0397197,121.5636852,17z/data=!3m1!4b1!4m6!3m5!1s0x3442abbbd49806cb:0x29f411e16d99476f!8m2!3d25.0397197!4d121.5658739!16s%2Fg%2F11c2btn6w5"
                                target="_blank" rel="noopener noreferrer">台北市信義區松高路11號</a></div>
                        <div class="col-12 mt-2">營業時間 : 11:00 - 22:00</div> -->
                        <div class="col-12 mt-2"><router-link class="routerLink"
                                :to="{ name: 'allPlace' }">店鋪資訊</router-link></div>
                        <div class="col-12 mt-2"><router-link class="routerLink"
                                :to="{ name: 'memberPolicy' }">會員制度</router-link></div>
                        <div class="col-12 mt-2"><router-link class="routerLink"
                                :to="{ name: 'returnPolicy' }">退換貨政策</router-link></div>
                        <div class="col-12 mt-2"><router-link class="routerLink"
                                :to="{ name: 'privacyPolicy' }">隱私權政策</router-link></div>
                    </div>
                </div>

                <div class="footer-social-icon">
                    <a href="https://line.me/R/ti/p/@gagamonster?from=page&liff.referrer=https%3A%2F%2Fgagamonster.kinsta.cloud%2F&accountId=gagamonster"
                        target="_blank"><img v-lazy="ImgGroup.line" alt=""></a>
                    <a href="https://www.facebook.com/gagamonster.co/" target="_blank"><img v-lazy="ImgGroup.fb" alt=""></a>
                    <a href="https://www.instagram.com/gagamonster.co/" target="_blank"><img v-lazy="ImgGroup.ig"
                            alt=""></a>
                    <a href="https://www.youtube.com/channel/UCxON1MpShc1N9t9wRI4V3jg" target="_blank"><img
                            v-lazy="ImgGroup.yt" alt=""></a>
                </div>
            </div>
        </div>
        <div v-if="!isMobile">
            <div class="row" style="padding: 20px 10px;color: white;">
                <div class="col-3"><img v-lazy="ImgGroup.logo" alt="" id="footer-logo"></div>
                <div class="col-5" style="text-align: left;padding-top: 10px;">
                    <div style="display: flex;align-items: center;">
                        <img v-lazy="ImgGroup.master" alt=""  style="width: 100%;">
                    </div>
                    <hr>
                    <p style="width: 400px;line-height: 2rem;">
                        隨著科技的進步、AI人工智慧的突破， <br>
                        有「想法」，變得前所未有的重要。 <br>
                        孩子們的未來，將由創意力來主導！ <br>

                        如何一邊磨練邏輯思考，又能解放想像、盡情創造呢？ <br>

                        GAGA monster史萊姆怪獸星球 <br>
                        和你的孩子一起，從思考到創造，開發潛藏的創意力。</p>
                    <div class="footer-social-icon">
                        <a href="https://line.me/R/ti/p/@gagamonster?from=page&liff.referrer=https%3A%2F%2Fgagamonster.kinsta.cloud%2F&accountId=gagamonster"
                            target="_blank"><img v-lazy="ImgGroup.line" alt=""></a>
                        <a href="https://www.facebook.com/gagamonster.co/" target="_blank"><img v-lazy="ImgGroup.fb"
                                alt=""></a>
                        <a href="https://www.instagram.com/gagamonster.co/" target="_blank"><img v-lazy="ImgGroup.ig"
                                alt=""></a>
                        <a href="https://www.youtube.com/channel/UCxON1MpShc1N9t9wRI4V3jg" target="_blank"><img
                                v-lazy="ImgGroup.yt" alt=""></a>
                    </div>
                </div>
                <div class="col-4" style="text-align: left;padding-top: 10px;">
                    <div class="footer-connection">
                        <div class="row">
                            <!-- <div class="col-12">聯絡方式</div>
                            <div class="col-12 mt-4">客服電話 : (02) 7756-3931</div>
                            <div class="col-12 mt-2"><a
                                    href="https://www.google.com/maps/place/110%E5%8F%B0%E5%8C%97%E5%B8%82%E4%BF%A1%E7%BE%A9%E5%8D%80%E6%9D%BE%E9%AB%98%E8%B7%AF11%E8%99%9F/@25.0397197,121.5636852,17z/data=!3m1!4b1!4m6!3m5!1s0x3442abbbd49806cb:0x29f411e16d99476f!8m2!3d25.0397197!4d121.5658739!16s%2Fg%2F11c2btn6w5"
                                    target="_blank" rel="noopener noreferrer">台北市信義區松高路11號</a></div>
                            <div class="col-12 mt-2">營業時間 : 11:00 - 22:00</div> -->

                            <div class="col-12 mt-2"><router-link class="routerLink footer-link"
                                    :to="{ name: 'allPlace' }">店鋪資訊</router-link></div>
                            <div class="col-12 mt-2"><router-link class="routerLink footer-link"
                                    :to="{ name: 'memberPolicy' }">會員制度</router-link></div>
                            <div class="col-12 mt-2"><router-link class="routerLink footer-link"
                                    :to="{ name: 'returnPolicy' }">退換貨政策</router-link></div>
                            <div class="col-12 mt-2"><router-link class="routerLink footer-link"
                                    :to="{ name: 'privacyPolicy' }">隱私權政策</router-link></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr class="footer-hr" />
        <div class="copyright">
            <span>© 2023GAGA Monster 怪獸星球有限公司</span>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    computed: {
        ...mapState({
            isMobile: state => state.isMobile,
        }),
    },
    data() {
        return {
            connection_list: [
                {
                    icon: require("../../assets/img/mobile/home/BG-10(2)-1.png"),
                    info: '地址：新北市新店區民權路88-4號9樓'
                },
                {
                    icon: require("../../assets/img/mobile/home/BG-10(2)-2.png"),
                    info: '電話：02-7756-3935#11'
                },
                {
                    icon: require("../../assets/img/mobile/home/BG-10(2)-3.png"),
                    info: '客服：＠gagamonster'
                },
                {
                    icon: require("../../assets/img/mobile/home/BG-10(2)-4.png"),
                    info: '快速預約： https://gagamonster.co/booking/'
                }
            ],
            shhop_connection_list: [
                {
                    text: '客服電話 : (02) 7756-3931',
                }, {
                    text: '台北市信義區松高路11號',
                }, {
                    text: '營業時間 : 11:00 - 22:00',
                }
            ],
            ImgGroup: {
                fb: require('../../assets/img/mobile/home/BG-10(2)-FB.png'),
                ig: require('../../assets/img/mobile/home/BG-10(2)-IG.png'),
                yt: require('../../assets/img/mobile/home/BG-10(2)-YT.png'),
                line: require('../../assets/img/mobile/home/line.png'),
                logo: require('../../assets/img/mobile/home/BG-10(2)-logo.png'),
                master: require('../../assets/img/mobile/home/BG-10(2)GAGA Monster.png')
            },

        }
    }
}
</script>

<style scoped>
.footer-backcolor {
    background-color: var(--main-text-color);
    width: 100%;
    text-align: center;
    padding: 0 50px;
}

#footer-logo {
    width: 25vw;
    margin-top: 20px;
}

#footer-row-title {
    margin-right: 0;
    text-align: center;
    display: flex;
    align-items: end;
}

#footer-row-title img {
    width: 100%;
}

#footer-title {
    color: white;
    font-size: 14px;
    display: flex;
    align-items: center;
    letter-spacing: 2px;
    text-align: left;
    white-space: nowrap;
}

.footer-info {
    margin-top: 20px;
    color: white;
    font-size: 12px;
    letter-spacing: 2px;
    text-align: left;
}

.footer-social-icon {
    text-align: start;
    margin-bottom: 35px;
}

.footer-social-icon img {
    width: 35px;
    margin-right: 20px;
}

.footer-connection {
    margin-top: 40px;
    margin-bottom: 30px;
    font-size: 12px;
    color: white;
    text-align: left;
    letter-spacing: 1px;
}

.footer-connection img {
    width: 15px;
}

.footer-connection a {
    color: white;
    text-decoration: none;
}

.footer-hr {
    border: 0;
    height: 1px;
    background-color: #fff;
}

.copyright {
    text-align: center;
    font-size: 12px;
    color: white;
    padding: 20px 0;
    letter-spacing: 1px;
}

@media (min-width: 992px) {
    #footer-logo {
        width: 20vw;
        margin: 0;
    }

    .footer-connection {
        margin: 0;
        font-size: 16px;
    }
}
</style>