<template>
    <div style="padding: 20px 20px">
        <div v-if="isMobile" style="text-align: center;font-size: 20px;color: var(--main-text-color); margin-bottom: 10px;">
            購物車</div>
        <div class="title" v-else>
            <span class="title-now">購物車</span><span class="title-next-icon">&nbsp;>&nbsp;</span>
            <span class="title-next">結帳</span><span class="title-next-icon">&nbsp;>&nbsp;</span>
            <span class="title-next">訂單完成</span>
        </div>
        <div class="row">
            <!-- <span style="color: #7d8791;">結帳後，您將獲得3點數!</span> -->

            <div class="col-xl-6 col-md-6 col-12">
                <table>
                    <thead style="color: #776e73;">
                        <tr>
                            <th colspan="3">商品</th>
                            <th v-if="!isMobile">價格</th>
                            <th style="text-align: right;">數量</th>
                            <th v-if="!isMobile" style="text-align: right;">小計</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="product-remove">
                                <a class="remove" aria-label="移除這項商品">×</a>
                            </td>
                            <td class="product-thumbnail">
                                <img src="../assets/img/mobile/home/(P)BG-05(S01).png" alt="">
                            </td>
                            <td>
                                <div>QQ怪獸膠-500ml 1xNT$399</div>
                            </td>
                            <td v-if="!isMobile">
                                <div>NT$399</div>
                            </td>
                            <td class="product-quantity" data-title="數量">
                                <InputNumber :qty.sync="qty" :min="1" :resize="false" @AddClick="handleUpdate()"
                                    @MinusClick="handleUpdate()">
                                </InputNumber>
                            </td>
                            <td v-if="!isMobile">
                                <div>NT$399</div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div style="margin-bottom: 40px;">
                    <input type="button" value="← 繼續選購" id="keep-shopping">
                    <input type="button" value="更新購物車" id="update-shopping-cart">
                </div>
            </div>
            <div class="col-xl-6 col-md-6 col-12">
                <div class="cart-sidebar col-inner">
                    <div class="cart_totals ">
                        <table cellspacing="0">
                            <thead>
                                <tr>
                                    <th class="product-name" colspan="2" style="border-style: solid;">購物車總計</th>
                                </tr>
                            </thead>
                        </table>
                        <h2 style="color: #776e73;">購物車總計</h2>
                        <table cellspacing="0" class="shop_table shop_table_responsive">
                            <tbody>
                                <tr class="cart-subtotal">
                                    <th>小計</th>
                                    <td data-title="小計"><span class="price-amount amount"><bdi><span
                                                    class="price-currencySymbol">NT$</span>399</bdi></span></td>
                                </tr>
                                <tr class="shipping-totals shipping ">
                                    <td class="shipping__inner" colspan="2">
                                        <table class="shipping__table ">
                                            <tbody>
                                                <tr>
                                                    <th>運送方式</th>
                                                    <td data-title="運送方式">
                                                        <ul id="shipping_method" class="shipping__list shipping-methods">
                                                            <li class="shipping__list_item">
                                                                <input type="hidden" name="shipping_method[0]"
                                                                    data-index="0" id="shipping_method_0_flat_rate1"
                                                                    value="flat_rate:1" class="shipping_method"><label
                                                                    class="shipping__list_label"
                                                                    for="shipping_method_0_flat_rate1">宅配: <span
                                                                        class="price-amount amount"><bdi><span
                                                                                class="price-currencySymbol">NT$</span>100</bdi></span></label>
                                                            </li>
                                                        </ul>
                                                        <p class="shipping-destination">
                                                            運送選項會在結帳時更新。 </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr class="order-total">
                                    <th>總計</th>
                                    <td data-title="總計"><strong><span class="price-amount amount"><bdi><span
                                                        class="price-currencySymbol">NT$</span>499</bdi></span></strong>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="wc-proceed-to-checkout">
                            <router-link :to="{ name: 'shoppingCartPay' }">
                                <input type="button" value="前往結帳" class="checkout-button button alt wc-forward">
                            </router-link>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="coupon">
                        <h3 class="widget-title"><b-icon icon="tag-fill"></b-icon>折價券</h3>
                        <input type="text" name="coupon_code" class="input-text" id="coupon_code" value="" placeholder="折價券"
                            style="margin-bottom: 10px;">
                        <input type="submit" class="is-form expand" name="apply_coupon" value="使用折價券"
                            style="margin-bottom: 10px;">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import InputNumber from "../components/common/InputNumber";
import { mapState } from 'vuex'
export default {
    components: {
        InputNumber,
    },
    computed: {
        ...mapState({
            isMobile: state => state.isMobile,
        }),
    },
    data() {
        return {
            item: 0,
            qty: 1,
        }
    },
    methods: {
        handleUpdate() {

        }
    }
}
</script>

<style src="../assets/css/shoppingCart.css" scoped></style>
<style src="../assets/css/cart.css" scoped></style>
<style scoped>
* {
    font-size: 12px;
}

.title {
    text-align: center;
    margin-bottom: 30px;
}

.title span {
    font-size: 20px;
}

.title-now {
    color: var(--main-text-color);
}

.title-next {
    color: #666
}

.title-next-icon {
    color: var(--main-text-color);
}

table {
    width: 100%;
}

.product-thumbnail img {
    width: 50%;
}

#keep-shopping {
    border: 2px solid var(--main-text-color);
    background-color: white;
    color: var(--main-text-color);
    padding: 3px 10px;
    margin-right: 20px;
}

#update-shopping-cart {
    background-color: var(--main-text-color);
    color: white;
    border: 0;
    padding: 5px 10px;
}

@media (min-width: 992px) {
    .product-thumbnail img {
        width: 80%;
    }
}
</style>