<template>
    <div id="body-bg" class="row justify-content-center">
        <div class="col-lg-3 col-xl-3 col-12">
            <b-card no-body class="card col-12">
                <div>
                    <div class="row">
                        <!-- <div class="col-6 info-title">
                                <span>訂單編號</span><br />
                                <span>12345678</span>
                            </div> -->
                        <div class="col-6 title date">
                            <span>預約資訊</span>
                        </div>
                    </div>
                    <div class="text-left row info d-flex justify-content-between">
                        <div class="col-12">{{ booking.store }} {{ formattedDate }}</div>
                        <div class="col-12">開始時間: {{ booking.time }}</div>
                        <div class="col-12">課程時間: 60分</div>
                        <div class="col-12" v-show="formData.pay_type.value == 'POINT'">剩餘點數 : {{ userPoint }}</div>
                        <div class="col-5">人數 : {{ booking.qty }}</div>
                        <div class="col-12">訂金 : {{ booking.amount }}</div>
                    </div>
                    <div>
                        <div class="text-left title">
                            <span>付款方式</span>
                        </div>
                        <div class="payType">
                            <b-form-radio-group id="radio-group-1" v-model="formData.pay_type.value"
                                :options="formData.pay_type.options" name="radio-options"></b-form-radio-group>
                        </div>
                        <div class="payType">剩餘點數 : {{ userPoint }}</div>
                    </div>
                </div>
                <div>
                    <div class="text-left title">
                        <span>購買人資料</span>
                    </div>
                    <div class="text-left row info">
                        <span class="col-12">姓名<abbr class="required" title="必要欄位">*</abbr></span>
                        <input type="text" name="name" id="name" v-model="formData.user_name.value" class="col-12">
                        <span class="col-12">電子郵件地址<abbr class="required" title="必要欄位">*</abbr></span>
                        <input type="text" name="email" id="email" v-model="formData.user_email.value" class="col-12">
                        <span class="col-12">聯絡電話<abbr class="required" title="必要欄位">*</abbr></span>
                        <input type="text" name="phone" id="phone" v-model="formData.user_phone.value" class="col-12">
                        <span style="font-size: 18px;margin-bottom: 10px;font-weight: bold;">額外資訊</span>
                        <span class="col-12">訂單備註(選項)</span>
                        <textarea name="comments" id="comments" v-model="formData.note.value"
                            placeholder="您的訂單的備註，例如: 運送時的特別註記。" rows="2" cols="5" class="input-text"></textarea>
                        <span style="font-size: 18px;margin-bottom: 10px;font-weight: bold;">發票資訊</span>
                        <span class="col-12">發票開立<abbr class="required" title="必要欄位">*</abbr></span>
                        <select class="form-select" v-model="formData.buyer" aria-label="Default select example">
                            <option value="0" selected>個人</option>
                            <option value="1">公司</option>
                        </select>
                        <span class="col-12" v-show="formData.buyer == '1'">統一編號<abbr class="required"
                                title="必要欄位">*</abbr></span>
                        <input type="text" name="buyer_taxid" id="buyer_taxid" v-show="formData.buyer == '1'"
                            v-model="formData.buyer_taxid" class="col-12">
                        <span class="col-12">載具類別<abbr class="required" title="必要欄位">*</abbr></span>
                        <select class="form-select" v-model="formData.einv_vehicle" aria-label="Default select example">
                            <option value="0" selected>無載具 (將寄送電子發票到您的信箱)</option>
                            <option value="1">手機條碼</option>
                            <!-- <option value="1">自然人憑證</option> -->
                        </select>
                        <span class="col-12" v-show="formData.einv_vehicle == '1'">手機載具<abbr class="required"
                                title="必要欄位">*</abbr></span>
                        <input type="text" name="buyer_taxid" id="buyer_taxid" v-show="formData.einv_vehicle == '1'"
                            v-model="formData.einv_vehicle_no" class="col-12">
                    </div>
                </div>
            </b-card>
            <div class="text-center row">
                <div class="col-6">
                    <button @click="Previous">上一步</button>
                </div>
                <div class="col-6">
                    <button @click="nextPay">確認結帳</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "../api/axios.js";
import { mapState, mapMutations, mapActions } from 'vuex'
export default {
    computed: {
        ...mapState(["bookingDetail", 'loggedInUser']),
        formattedDate() {
            const options = {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            };
            const currentDate = new Date(this.booking.date);
            return (
                currentDate.toLocaleDateString("zh-TW", options).replace(/\//g, ".") +
                " (" +
                this.getWeekday(currentDate) +
                ")"
            );
        },
    },
    created() {
        const storedData = JSON.parse(localStorage.getItem('bookingDetail'));
        if (storedData) {
            const currentTime = new Date().getTime();
            const storedTime = storedData.timestamp;
            const expirationTime = 10 * 60 * 1000; //10分鐘

            if (currentTime - storedTime <= expirationTime) {
                //未過期
                this.setbookingDetail(storedData.value)
            } else {
                //過期
                console.log(storedData.value);
                this.$swal('請重新預約時間').then(() => {
                    this.$router.push({
                        path: `/online-appointment/appointment-class/${storedData.value.store}/${storedData.value.id}`
                    });
                });

                localStorage.removeItem('bookingDetail');
                return;
            }
        }

        console.log('this.bookingDetail--', this.bookingDetail);
        this.booking = {
            store: this.bookingDetail.store,
            date: this.bookingDetail.reserve_date ?? this.bookingDetail.date,
            time: this.bookingDetail.reserve_time ?? this.bookingDetail.time,
            qty: this.bookingDetail.qty,
            id: this.bookingDetail.id,
            bas_id: this.bookingDetail.bas_id,
            sno: this.bookingDetail.sno,
            amount: this.bookingDetail.amount * this.bookingDetail.qty,
        };

        //存localStorage
        localStorage.setItem('bookingDetail', JSON.stringify({
            value: this.bookingDetail,
            timestamp: new Date().getTime() // 當前時間
        }));

        if (!this.loggedInUser) {
            this.$swal("請先登入帳號。");
            this.$router.push({
                name: 'signIn'
            });
            return
        }
        this.GetUserPoint(this.loggedInUser.sno);
        axios.post("User/GetUserInfo", { USER_ACCOUNT: this.loggedInUser.userId }).then(resp => {
            if (resp.succ) {
                this.formData.user_name.value = resp.result.user_name;
                this.formData.user_email.value = resp.result.email;
                this.formData.user_phone.value = resp.result.phone;
                //alert(resp.result);
            } else {
                this.$swal(resp.msg);
                //alert(resp.msg);
            }
        });
        
        const bookingDate = new Date(this.booking.date);
        //抓目前日期不抓時間
        let currentDate = new Date(new Date().getFullYear()+"/"+(new Date().getMonth()+1)+"/"+ new Date().getDate());

        // 比较年、月和日是否相等
        if (bookingDate.getTime()==currentDate.getTime())         
            this.formData.pay_type.options.filter(x => x.value == "ATM")[0].disabled = true;

        
    },
    data() {
        return {
            selected: '',
            form: {
                reserve_date: this.$route.query.reserve_date,
                reserve_time: this.$route.query.reserve_time,
            },
            userPoint: 0,
            booking: {},
            THIRDPART_TYPE:"",
            formData: {
                buyer: "0",
                einv_vehicle: "0",
                pay_type: {
                    value: "CREDIT",
                    options: [
                        { text: '信用卡', value: 'CREDIT' },
                        { text: 'Atm', value: 'ATM' },
                        { text: 'LINEPAY', value: 'LINEPAY' },
                        { text: '點數', value: 'POINT',disabled:true },
                    ]
                },
                user_name: {
                    value: "",
                    HeaderText: "姓名",
                    ErrorMessage: "未填寫",
                    required: true,
                    ShowErrorMessage: false,
                },
                user_email: {
                    value: "",
                    HeaderText: "電子郵件",
                    ErrorMessage: "未填寫",
                    required: true,
                    ShowErrorMessage: false,
                },
                user_phone: {
                    value: "",
                    HeaderText: "手機號碼",
                    ErrorMessage: "未填寫",
                    required: true,
                    ShowErrorMessage: false,
                },
                note: {
                    value: "",
                    HeaderText: "備註",
                    ErrorMessage: "未填寫",
                },
                period: {
                    value: "",
                    HeaderText: "期數",
                    ErrorMessage: "未選擇",
                    required: true,
                    ShowErrorMessage: false,
                },
                store: {
                    value: "",
                    HeaderText: "門市",
                    ErrorMessage: "未選擇",
                    required: true,
                    ShowErrorMessage: false,
                },
                reserve_date: this.$route.query.reserve_date,
                reserve_time: this.$route.query.reserve_time,
            },
            THIRD_PARAM: {
                HOST: "",
                WEB_ID_CREDIT: "S1607299011",
                TRANS_PWD: "a12345678",
                WEB_ID_ATM: "S1607299037",
            },
        }
    },
    methods: {
        ...mapMutations(['setbookingDetail', 'clearbookingDetail']),
        ...mapActions(['setBooking', 'clearBooking']),
        GetUserPoint(sno) {
            axios.post("/User/GetUserPoint", { Sno_user: sno }).then(resp => {
                if (resp.succ) {
                    this.userPoint = resp.result;
                    this.formData.pay_type.options.filter(x => x.value == "POINT")[0].disabled = (this.userPoint < this.bookingDetail.amount);
                }
            });
        },
        Previous() {
            this.$router.push({ name: 'appointmentClass' })
        },
        async nextPay() {
            let loader = this.$loading.show();
            if (this.formData.pay_type.value == "POINT" && this.userPoint < this.booking.amount) {
                this.$swal("點數不足!");
                return;
            }
            var TransShoppingCart = [];
            TransShoppingCart.push({
                NAME: this.formData.user_name.value,
                EMAIL: this.formData.user_email.value,
                PHONE: this.formData.user_phone.value,
                SEND_TYPE: "STORE",
                DELIVERY_NAME: this.formData.user_name.value,
                DELIVERY_EMAIL: this.formData.user_email.value,
                DELIVERY_PHONE: this.formData.user_phone.value,
                DELIVERY_FEE: 0,
                STORE: 0,
                NOTE: this.formData.note.value,
                PAY_TYPE: this.formData.pay_type.value,
            });
            let TransPays = [];
            if (this.formData.pay_type.value == "POINT") {
                //
                TransPays.push({
                    PAY_ID: "POINT",
                    AMT: this.booking.amount,
                });
            }
            else {
                TransPays.push({
                    PAY_ID: this.formData.pay_type.value,
                    AMT: this.booking.amount,
                    CARD_TYPE: "THIRD",
                });
            }

            let req = {
                SNO_USER: this.loggedInUser.sno,
                SNO_STORE: 0,
                TRANS_STATUS: this.formData.pay_type.value == "POINT" ? "OK" : "WAIT_PAY",
                TRANS_PAY_ID: this.formData.pay_type.value,
                EINV_VEHICLE_NO1: this.formData.einv_vehicle_no,
                BUYER_TAXID: this.formData.buyer_taxid,
                BOOKING_DATE: this.booking.date,
                BOOKING_TIME: this.booking.time,
                TransItems: [
                    {
                        SNO_GROUP_CLASS_UP: this.bookingDetail.id,
                        SNO_GROUP_CLASS: this.bookingDetail.bas_id,
                        QTY: this.booking.qty,
                        AMT: this.booking.amount,
                    },
                ],
                TransPays: TransPays,
                TransShoppingCart
            };
            let vm = this;
            if (vm.formData.pay_type.value != "POINT") {
                if(vm.formData.pay_type.value=="LINEPAY")
                {
                    vm.THIRDPART_TYPE = "LINEPAY";
                }
                else
                {
                    await axios
                    .post("/System/SysparamList", {
                        p_key: "THIRDPART_TYPE",
                    }).then((resp) => {                                
                        vm.THIRDPART_TYPE = resp.result[0]?.p_value;

                    })
                }
                await axios
                .post("/System/STORE_PARAM", {
                    SNO_STORE: 0,
                    PARAM_ID: vm.THIRDPART_TYPE,
                })
                .then((resp) => {
                    if (resp.succ) {
                        vm.THIRD_PARAM.HOST = resp.result.find(
                        (x) => x.PARAM_KEY == "HOST"
                        ).VAL;
                        vm.THIRD_PARAM.WEB_ID_CREDIT = resp.result.find(
                        (x) => x.PARAM_KEY == "WEB_ID_CREDIT"
                        ).VAL;
                        vm.THIRD_PARAM.TRANS_PWD = resp.result.find(
                        (x) => x.PARAM_KEY == "TRANS_PWD"
                        ).VAL;
                        vm.THIRD_PARAM.LOCALHOST = resp.result.find(
                        (x) => x.PARAM_KEY == "LOCALHOST"
                        ).VAL;
                        vm.THIRD_PARAM.HashIV = resp.result.find(
                        (x) => x.PARAM_KEY == "HashIV"
                        )?.VAL;
                    }
                });
            }
            
            axios
                .post("/Trans/TransCreate", req)
                .then((resp) => {
                    loader.hide()
                    if (resp.succ) {
                        vm.trans_id = resp.result.TRANS_ID;
                        if (vm.formData.pay_type.value == "POINT") {
                            axios
                                .post("/Group/GroupClassBookingCreate", {
                                    trans_id: vm.trans_id
                                })
                                .then((resp) => {
                                    if (resp.succ) {
                                        this.$swal(resp.result).then(() => {
                                            this.$router.push({
                                                name: "appointmentClassCheckOut",
                                                query: {
                                                    id: vm.trans_id
                                                }
                                            });
                                        });
                                    } else {
                                        this.$swal(resp.msg);
                                    }
                                });
                        }
                        else {
                            axios.post("/System/CreateWebApiLog", {
                                REQUEST_API_URL: this.THIRD_PARAM.HOST,
                                KEY_TYPE: "ThirdPart_TRANS",
                                KEY_VALUE: vm.trans_id,
                                //TRANS_CONTENT: window.location.origin + window.location.pathname,
                                TRANS_CONTENT:
                                window.location.origin+'/online-appointment/appointment-class/checkout',
                            })
                                .then((resp) => {
                                if (resp.succ) {
                                    let param = {};
                                    param.THIRDPART_TYPE = vm.THIRDPART_TYPE;
                                    param.amount = vm.booking.amount;
                                    param.WEB_ID_CREDIT = vm.THIRD_PARAM.WEB_ID_CREDIT;
                                    param.TRANS_PWD = vm.THIRD_PARAM.TRANS_PWD;
                                    param.HashIV = vm.THIRD_PARAM.HashIV;
                                    param.HOST = vm.THIRD_PARAM.HOST;
                                    param.LOCALHOST = vm.THIRD_PARAM.LOCALHOST
                                    param.ordernumber = vm.trans_id;
                                    param.full_mobile = vm.formData.user_phone.value;
                                    param.ItemName = "訂金";
                                    param.user_email = vm.formData.user_email.value;
                                    param.user_name = vm.formData.user_name.value;
                                    param.pay_type = vm.formData.pay_type.value;
                                    vm.$cf.thirdPartTrans(param);
                                }
                            })
                        }
                    } else {
                        this.$swal(resp.msg);
                    }
                });
            // this.formData.pay_type.text = this.formData.pay_type.options.filter(x => x.value == this.formData.pay_type.value)[0]?.text;
            // this.setBooking({
            //     ...this.bookingDetail,
            //     formData: this.formData
            // })
            // this.$router.push({ name: 'appointmentClassCheckOut' })
        },
        getWeekday(date) {
            const weekdays = ["日", "一", "二", "三", "四", "五", "六"];
            return weekdays[date.getDay()];
        },
    }
}
</script>

<style scoped>
#body-bg {
    background-color: #f5f5f5;
    padding: 30px;
    margin: 0;
}

.card {
    margin: 10px;
}

.info-title {
    font-size: 18px;
}

.date {
    border-left: black 1.5px solid;
}

.info {
    margin: 20px 0;
}

.title {
    margin: 10px 0 0 20px;
    font-size: 20px;
    font-weight: bold;
}

.payType {
    margin: 20px 30px;
}

.payType label {
    margin-left: 10px;
}

.info {
    margin: 0;
    padding: 10px 30px 30px;
}

.info span {
    padding: 0;
}

.info .row {
    margin: 0;
    padding: 0;
}

select,
input {
    margin-bottom: 10px;
}

#comments {
    margin-bottom: 10px;
}

.required {
    color: red;
    font-weight: 700;
    border: 0 !important;
    text-decoration: none;
}

button {
    width: 80%;
    background-color: #E91E63;
    color: white;
    border: 0;
    border-radius: 5px;
    line-height: 2em;
}
</style>