<template>
  <div class="row" style="margin: 12px -30px 0;border-radius: 10px;">
    <figure class="col-xl-6 col-xs-12 col-12 candidate-box" id="inline-datepicker">
      <!-- <datepicker :inline="true" class="inline" :class="{ gray: dateIsCandidate }" v-model="getSelectedDate"
        format="yyyy/MM/dd" :disabledDates="options" :language="zh"></datepicker> -->
      <v-date-picker v-if="isDatePickerReady" v-model="getSelectedDate" :available-dates="formattedAvailableDates" color="orange" is-required/>
    </figure>

    <figure class="col-xl-6 col-12 time-wrap">
      <div v-if="reserve_time.length < 1" class="no-data p-0" style="margin: 120px 0">
        目前尚無可選取的時段
      </div>
      <div v-else class="time-grid">
        <div class="time-item" :class="[
          t.is_candidate ? 'candidate' : '',
          time === t.time ? 'active' : '',
        ]" v-for="(t, index) in reserve_time" :key="index" @click="handleSelectTime(t)">
          {{ t.time }}
        </div>
      </div>
      <div style="margin-top: 10%;">
        此場次剩餘人數為: {{ RemainingNumber }}
      </div>
    </figure>
  </div>
</template>
<script>
import "../../assets/css/datePicker.css"
import { zh } from 'vuejs-datepicker/dist/locale'
import axios from "../../api/axios.js";
export default {
  name: "CustomDateTimePicker",
  props: {
    reserve_date: {
      type: Array,
      require: true,
    },
    reserve_time: {
      type: Array,
      require: true,
    },
    time: {
      type: String,
      require: true,
    },
    timeIsCandidate: {
      type: Boolean,
      require: false,
      default: false,
    },
    iRemainingNumber: {
      type: String,
      require: false,
      default: "0",
    },
    isCandidate: {
      type: Boolean,
      require: false,
      default: false,
    },
    date: {
      require: true,
      default: new Date(),
    },
    options: {
      type: Object,
      require: false,
      default: function () {
        return {
          to: new Date(new Date().setDate(new Date().getDate() - 1)),
        };
      },
    },
    SnoGroupClass: {
      type: String,
      require: true,
    },
    availableDates: {
      type: Array,
    }
  },
  data() {
    return {
      dateIsCandidate: false,
      zh: zh,
      RemainingNumber: "尚未選擇場次",
      formattedAvailableDates: [],
    };
  },
  watch: {
    reserve_date() {
      this.setActiveClass(this.date);
    },
    availableDates: {
      immediate: true,
      handler(newAvailableDates) {
        this.formattedAvailableDates = this.formatAvailableDates(newAvailableDates);
      },
    },
  },
  computed: {
    getSelectedDate: {
      get() {
        return this.date;
      },
      set(val) {
        let date = this.formatDate(val);

        //check日期是否為候補
        this.setActiveClass(date);
        this.$emit("update:date", date);
      },
    },
    isDatePickerReady() {
      return this.date.length > 0;
    },
  },
  methods: {
    formatAvailableDates(availableDates_list) {
      const dateList = availableDates_list.map((a) => {
        const [year, month, day] = a.split('/')
        return new Date(year, month - 1, day)
      });
      return dateList
    },
    formatDate(date) {
      if (typeof date !== "string") {
        let y = date.getFullYear();
        let m = ("0" + (date.getMonth() + 1)).substr(-2);
        let d = String(date.getDate()).padStart(2, "0");

        return `${y}/${m}/${d}`;
      } else {
        return date;
      }
    },
    setActiveClass(date) {
      let find = this.reserve_date.some((i) => i === date);
      if (find) {
        this.dateIsCandidate = true;
      } else {
        this.dateIsCandidate = false;
      }
      this.$emit("update:isCandidate", this.dateIsCandidate);
    },
    handleSelectTime(t) {
      this.$emit("update:time", t.time);
      this.$emit(
        "update:isCandidate",
        this.dateIsCandidate || t.is_candidate ? true : false
      );
      this.$emit("update:timeIsCandidate", t.is_candidate);

      let loader = this.$loading.show();
      axios.post("/Group/GetRemainingNumber", { Sno_group_class: this.SnoGroupClass, Booking_date: this.date, Booking_time: t.time }).then(resp => {
        if (resp.succ) {
          this.RemainingNumber = resp.result[0].RemainingNumber;
          this.$emit("update:iRemainingNumber", this.RemainingNumber + "");
        }

        loader.hide()
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 4px;
}

.time-wrap {
  border-left: 1.5px solid var(--btn-main);
}

.time-grid {
  display: grid;
  grid-template-columns: repeat(4, 80px);
  gap: 8px 12px;
  justify-content: center;

  .time-item {
    cursor: pointer;
    padding: 10px 20px;
    border-radius: 12px;
    text-align: center;
    font-size: 14px;
    background: #ffffff;
    color: #6f6f6f;
    border: 1px solid #dddddd;
  }

  .candidate {
    background: #eeeeee;
    color: #6f6f6f;
    border: 1px solid #dddddd;
  }

  .active {
    background: #e67737;
    box-shadow: 2px 2px 5px #ebebeb;
    color: #ffffff;
    font-weight: 700;
  }
}

.time-info-text {
  font-size: 10px;
}

.candidate-box {
  margin: 0;
}

@media (max-width: 1200px) {
  .time-grid {
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;

    .time-item {
      padding: 5px 15px;
      // font-size: 28px;
    }
  }

  .time-wrap {
    // border-top: 1.5px solid var(--btn-main);
    border-left: none;
    padding: 0px 20px;
    margin-left: 32px;
  }

  .circle {
    width: 22px;
    height: 22px;
  }

  .time-info-text {
    font-size: 26px;
  }

  .candidate-box {
    margin: 10px 0 30px;
  }
}

#inline-datepicker>.vdp-datepicker>.vdp-datepicker__calendar {
  width: 100% !important;
}

@media (min-width: 992px) {

  .time-wrap {
    padding-left: 80px;
  }
}
</style>
