<template>
    <div class="page-wrapper">
        <div class="row row-large row-divided">
            <div class="large-12 col" style="padding-left:0;padding-right:0;">
                <div class="entry-header">
                    <h1 class="entry-title">親子家庭一同體驗BMW臺北汎德 與GAGA monster 不一樣的週末展間時光</h1>
                </div>
            </div>
            <div class="post-time">
                <span><img src="../../../assets/img/mobile/news/time.png" alt="">2022-11-20</span>
            </div>
            <div class="content col-12">
                <p>GAGA monster 參與BMW 小小車主周末活動，共同推動 ⌜以寓教於樂⌟ 的課程與趣味互動的教學方式。GAGA
                    monster發揮獨特創意，大車主們不僅可以至展間觀賞車子之外，更可以與孩子進行周末展間之旅，度過充滿活力與創意的史萊姆歡樂時光。
                </p>
                <div class="img-group">
                    <img v-lazy="Img1" alt="">
                    <img v-lazy="Img2" alt="">
                </div>
                <p>GAGA monster將生動的史萊姆怪獸星球故事與帶動跳舞蹈，開啟孩子的好奇心! 被稱為「探險家」的小朋友，擁有進化史萊姆怪獸的能力並踏上探險之旅，進化出不同的史萊姆怪獸。GAGA
                    monster延伸出許多無限創造與想像力的課程，從過程中誘發孩子培養 「獨立、好奇、專注」的能力，運用不同的道具與教
                    材讓孩子自由發揮動手做和勇敢嘗試的精神，透過老師的引導來連結孩子從想到做、從玩中學，並投入全心全意的專注力。</p>

                <div class="img-group">
                    <img v-lazy="Img3" alt="">
                    <p>史萊姆怪獸星球故事與帶動跳舞蹈現場</p>
                    <img v-lazy="Img4" alt="">
                    <p>親子一起製作史萊姆</p>
                    <img v-lazy="Img5" alt="">
                    <p>體驗巨大史萊姆</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'newsdetail20221120',
    data() {
        return {
            Img1: require('../../../assets/img/mobile/news/20221120/1.jpg'),
            Img2: require('../../../assets/img/mobile/news/20221120/2.jpg'),
            Img3: require('../../../assets/img/mobile/news/20221120/3.jpg'),
            Img4: require('../../../assets/img/mobile/news/20221120/4.jpg'),
            Img5: require('../../../assets/img/mobile/news/20221120/5.jpg'),
        }
    },
}
</script>