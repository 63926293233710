import { render, staticRenderFns } from "./memberPolicy.vue?vue&type=template&id=acd4ef70&scoped=true"
var script = {}
import style0 from "./memberPolicy.vue?vue&type=style&index=0&id=acd4ef70&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "acd4ef70",
  null
  
)

export default component.exports