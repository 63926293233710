<template>
    <div>
        <swiper ref="userExperienceSwiper" :options="userExperienceSwiperOption">
            <swiper-slide v-for="i in userExperience_list" :key="i.id" v-if="isMobile">
                <div>
                    <img :src="i.url" />
                    <div id="userExperience-group">
                        <span class="userExperience-group-title">{{ i.title }}</span>
                        <br>
                        <span class="userExperience-group-info">{{ i.info }}</span>
                    </div>
                </div>
                <div v-if="!isMobile" style="margin-top: 30px;">
                    <img :src="i.subUrl" />
                    <div id="userExperience-group-sec">
                        <span class="userExperience-group-title-sec">{{ i.subTitle }}</span>
                        <br>
                        <span class="userExperience-group-info-sec">{{ i.subInfo }}</span>
                    </div>
                </div>
            </swiper-slide>
            <swiper-slide v-for="i in pc_userExperience_list" :key="i.id" v-if="!isMobile">
                <div>
                    <img :src="i.url" />
                    <div id="userExperience-group">
                        <span class="userExperience-group-title">{{ i.title }}</span>
                        <br>
                        <span class="userExperience-group-info">{{ i.info }}</span>
                    </div>
                </div>
                <div style="margin-top: 30px;">
                    <img :src="i.subUrl" />
                    <div id="userExperience-group-sec">
                        <span class="userExperience-group-title-sec">{{ i.subTitle }}</span>
                        <br>
                        <span class="userExperience-group-info-sec">{{ i.subInfo }}</span>
                    </div>
                </div>
            </swiper-slide>
        </swiper>
        <!-- <div class="row" v-if="!isMobile">
            <div class="col-6" v-for="i in userExperience_list" :key="i.id">
                <img :src="i.url" class="userExperienceImg" />
                <div id="">
                    <span class="">{{ i.title }}</span>
                    <br>
                    <span class="">{{ i.info }}</span>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import { mapState } from 'vuex'

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    computed: {
        ...mapState({
            isMobile: state => state.isMobile,
        }),
    },
    data() {
        return {
            userExperience_list: [
                {
                    id: 1,
                    url: require("../../assets/img/mobile/home/(P)BG-07(P01).png"),
                    title: '炫妞的奇幻冒險',
                    info: '炫妞上完課已經喊著下次要體驗其他怪獸了！來上這堂課完全是小孩開心大人也放鬆的歡樂行程呀！趕快來預約體驗吧',
                }, {
                    id: 2,
                    url: require("../../assets/img/mobile/home/(P)BG-07(P02).png"),
                    title: 'Nina 瑄寶',
                    info: '炎炎夏日或是下大雨的日子不知道要去哪遛小孩? 推薦你們一個適合親子的室內景點!! 絕對包君滿意!...',
                }, {
                    id: 3,
                    url: require("../../assets/img/mobile/home/(P)BG-07(P03).png"),
                    title: '暖男哥哥，傲嬌妹妹',
                    info: '整堂課兄妹都超認真也超開心的,史萊姆姊姊還教他們如何製作大泡泡玩到超忘我，下課了還捨不得回家...',
                }, {
                    id: 4,
                    url: require("../../assets/img/mobile/home/(P)BG-07(P04).png"),
                    title: '兔寶蹦蹦跳',
                    info: '相信每個爸媽跟寶貝對史萊姆都不陌生,擁有讓孩子一秒安靜的神奇魔力,史萊姆透過不同質地搭配,變身成為專屬的史萊姆怪獸...'
                }, {
                    id: 5,
                    url: require("../../assets/img/mobile/home/(P)BG-07(P05).png"),
                    title: '我愛人妻少女心',
                    info: '史萊姆在幼童世界裡,是延伸無限想像力的柔軟小星球,這次特別預約媽媽我也非常期待的"@GAGAmonster史萊姆怪獸星球"體驗...',
                }, {
                    id: 6,
                    url: require("../../assets/img/mobile/home/(P)BG-07(P06).png"),
                    title: '鋼鐵兄妹&mom 親子｜旅遊｜育兒生活',
                    info: '現場都有專業又溫柔的老師帶著孩子們從認識各種史萊姆、觸摸、秤重、攪拌一步步細心講解操作。...'
                }, {
                    id: 7,
                    url: require("../../assets/img/mobile/home/(P)BG-07(P07).png"),
                    title: '崔崔包包去哪兒',
                    info: '小孩們超愛的史萊姆,現在有超有趣的實體課程啦,在史萊姆怪獸星球,不只有單單一種史萊姆...',
                }, {
                    id: 8,
                    url: require("../../assets/img/mobile/home/(P)BG-07(P08).png"),
                    title: '語妳們一起菲',
                    info: '大家應該都知道小朋友超愛史萊姆,在我還沒接觸過史萊姆之前,對它的印象停留在黏呼呼的很可怕,而且萬一小孩黏到頭髮衣服就完蛋了...'
                },
            ],
            pc_userExperience_list: [
                {
                    id: 1,
                    url: require("../../assets/img/mobile/home/(P)BG-07(P01).png"),
                    subUrl: require("../../assets/img/mobile/home/(P)BG-07(P03).png"),
                    title: '炫妞的奇幻冒險',
                    subTitle: '暖男哥哥，傲嬌妹妹',
                    info: '炫妞上完課已經喊著下次要體驗其他怪獸了！來上這堂課完全是小孩開心大人也放鬆的歡樂行程呀！趕快來預約體驗吧',
                    subInfo: '整堂課兄妹都超認真也超開心的,史萊姆姊姊還教他們如何製作大泡泡玩到超忘我，下課了還捨不得回家...'
                },
                {
                    id: 2,
                    url: require("../../assets/img/mobile/home/(P)BG-07(P02).png"),
                    subUrl: require("../../assets/img/mobile/home/(P)BG-07(P04).png"),
                    title: 'Nina 瑄寶',
                    subTitle: '兔寶蹦蹦跳',
                    info: '炎炎夏日或是下大雨的日子不知道要去哪遛小孩? 推薦你們一個適合親子的室內景點!! 絕對包君滿意!...',
                    subInfo: '相信每個爸媽跟寶貝對史萊姆都不陌生,擁有讓孩子一秒安靜的神奇魔力,史萊姆透過不同質地搭配,變身成為專屬的史萊姆怪獸...'
                },
                {
                    id: 3,
                    url: require("../../assets/img/mobile/home/(P)BG-07(P05).png"),
                    subUrl: require("../../assets/img/mobile/home/(P)BG-07(P06).png"),
                    title: '我愛人妻少女心',
                    subTitle: '鋼鐵兄妹&mom 親子｜旅遊｜育兒生活',
                    info: '史萊姆在幼童世界裡,是延伸無限想像力的柔軟小星球,這次特別預約媽媽我也非常期待的"@GAGAmonster史萊姆怪獸星球"體驗...',
                    subInfo: '現場都有專業又溫柔的老師帶著孩子們從認識各種史萊姆、觸摸、秤重、攪拌一步步細心講解操作。...'
                },
                {
                    id: 4,
                    url: require("../../assets/img/mobile/home/(P)BG-07(P07).png"),
                    subUrl: require("../../assets/img/mobile/home/(P)BG-07(P08).png"),
                    title: '崔崔包包去哪兒',
                    subTitle: '語妳們一起菲',
                    info: '小孩們超愛的史萊姆,現在有超有趣的實體課程啦,在史萊姆怪獸星球,不只有單單一種史萊姆...',
                    subInfo: '大家應該都知道小朋友超愛史萊姆,在我還沒接觸過史萊姆之前,對它的印象停留在黏呼呼的很可怕,而且萬一小孩黏到頭髮衣服就完蛋了...'
                },
            ],
            userExperienceSwiperOption: {},
        }
    },
    created() {
        if (this.isMobile) this.userExperienceSwiperOption = {
            loop: true,
            slidesPerView: 1,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            autoplay: {
                // delay: 5 * 1000,
                disableOnInteraction: false,
            },
        }
        else this.userExperienceSwiperOption = {
            loop: true,
            slidesPerView: 2,
            spaceBetween: -15,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            autoplay: {
                disableOnInteraction: false,
            },
        }
    }
}
</script>

<style scoped>
.swiper-container {
    width: 100vw;
    height: 80vh;
}

.swiper-slide {
    text-align: center;
}

.swiper-slide img {
    width: 80vw;
}

#userExperience-group {
    text-align: left;
    position: absolute;
    left: 47%;
    top: 4%;
    width: 160px;
    line-height: 20px;
}

.userExperience-group-title {
    font-size: 16px;
    color: var(--main-text-color);
    margin-bottom: 10px;
    font-weight: bold;
}

.userExperience-group-info {
    font-size: 10px;
}

#userExperience-group-sec {
    text-align: left;
    position: absolute;
    left: 47%;
    top: 61%;
    width: 250px;
    line-height: 30px;
}

.userExperience-group-title-sec {
    font-size: 22px;
    color: var(--main-text-color);
    margin-bottom: 10px;
    font-weight: bold;
}

.userExperience-group-info-sec {
    font-size: 16px;
}

.userExperienceImg {
    width: 100%;
}


@media only screen and (min-width: 768px) and (max-width: 991px) {
    #userExperience-group {
        width: 280px;
        line-height: 40px;
    }

    .userExperience-group-title {
        font-size: 30px;
    }

    .userExperience-group-info {
        font-size: 20px;
    }
}

@media (min-width: 992px) {
    .swiper-container {
        height: 100%;
    }

    .swiper-slide img {
        width: 40vw;
    }

    #userExperience-group {
        left: 47%;
        top: 6%;
        width: 250px;
        line-height: 30px;
    }

    .userExperience-group-title {
        font-size: 22px;
    }

    .userExperience-group-info {
        font-size: 16px;
    }
}
</style>