<template>
    <div class="wrap main-content">
        <!-- 聯絡資訊 -->
        <section class="card-round row form mobile-zoom">
            <h4>聯絡資訊</h4>
            <!-- 姓名 -->
            <VueFormElementText v-model="formData.user_name.value" :Options="formData.user_name"></VueFormElementText>
            <!-- 電子郵件 -->
            <VueFormElementText v-model="formData.user_email.value" :Options="formData.user_email"></VueFormElementText>
            <!-- 手機號碼 -->
            <VueFormElementText v-model="formData.user_phone.value" :Options="formData.user_phone"></VueFormElementText>

            <label class="col-12" v-for="item in send_type_list" :key="item.id">
                <div class="border-btn" v-show="item.show">
                    <input type="radio" name="send_type" v-model="formData.send_type.value" :disabled="item.disabled"
                        :id="item.id" :key="item.id" :value="item.id" @change="GetDeliveryFee_Sendtype(item.id)" /><span
                        class="m-text-28">{{ item.label }}</span>
                </div>
            </label>

            <!-- 收件地址 -->
            <template v-if="formData.send_type.value === 'DELIVERY'">
                <h4>
                    收件地址
                </h4>
                <!-- 姓名 -->
                <VueFormElementText v-model="formData.delivery_name.value" :Options="formData.delivery_name">
                </VueFormElementText>
                <!-- 電子郵件 -->
                <VueFormElementText v-model="formData.delivery_email.value" :Options="formData.delivery_email">
                </VueFormElementText>
                <!-- 手機號碼 -->
                <VueFormElementText v-model="formData.delivery_phone.value" :Options="formData.delivery_phone">
                </VueFormElementText>
                <!-- 縣市 -->
                <VueFormElementSelect class="col-6" v-model="formData.delivery_city.value" :Options="formData.delivery_city"
                    :selectOption="optionList.city" @change="onChange_add1($event, '')">
                    <option value="">請選擇縣市</option>
                </VueFormElementSelect>
                <!-- 鄉鎮市區 -->
                <VueFormElementSelect class="col-6" v-model="formData.delivery_district.value"
                    :Options="formData.delivery_district" :selectOption="optionList.district">
                    <option value="">請選擇鄉鎮市區</option>
                </VueFormElementSelect>
                <!-- 地址 -->
                <VueFormElementText v-model="formData.delivery_addr.value" :Options="formData.delivery_addr">
                </VueFormElementText>
            </template>

            <!-- 收件方式 -->
            <!-- <h4>收件方式</h4>
            <div class="mb-15px ">
                {{ formData.pay_type === "DELIVERY" ? "宅配" : "店取" }}
            </div> -->

            <VueFormElementTextarea v-model="formData.note.value" :Options="formData.note"></VueFormElementTextarea>

            <!-- 付款方式 -->
            <h4>付款方式</h4>
            <template v-if="is_installment">
                <label class="col-12">
                    <div class="border-btn">
                        <input type="radio" name="pay_type" v-model="pay_type" id="pay_type4" value="REGULAR" /><span
                            class="m-text-28">定期定額</span>
                    </div>
                </label>

                <!-- 期數 -->
                <VueFormElementSelect class="col-6 mt-15px" v-model="formData.period.value" :Options="formData.period"
                    :selectOption="optionList.period">
                    <option value="">請選擇期數</option>
                </VueFormElementSelect>
            </template>

            <template v-else>
                <label class="col-12" v-show="point_use && formData.send_type.value != 'MARKET'">
                    <div class="border-btn">
                        <input type="radio" name="pay_type" v-model="pay_type" id="pay_type1" value="POINT"
                            @click="pay_type_setting()" />
                        <span class="m-text-28">
                            購物金
                            <span style="font-size: 12px; margin-left: 4px" class="m-text-24" :style="{
                                color: calcTotal > balance ? 'var(--main-text-color)' : '',
                            }">/ 餘額{{ balance }}
                            </span>
                        </span>
                    </div>
                </label>
                <label class="col-12 mt-15px">
                    <div class="border-btn">
                        <input type="radio" name="pay_type" v-model="pay_type" id="pay_type0" value="CREDIT" /><span
                            class="m-text-28">信用卡</span>
                    </div>
                </label>
                <label class="col-12 mt-15px">
                    <div class="border-btn">
                        <input type="radio" name="pay_type" v-model="pay_type" id="pay_type2" value="ATM" /><span
                            class="m-text-28">ATM</span>
                    </div>
                </label>
                <label class="col-12 mt-15px">
                    <div class="border-btn">
                        <input type="radio" name="pay_type" v-model="pay_type" id="pay_type3" value="LINEPAY" /><span
                            class="m-text-28">LINE PAY</span>
                    </div>
                </label>

                <div style="padding-top: 20px;" v-show="pay_type == 'CREDIT' || pay_type == 'ATM' || pay_type == 'LINEPAY'">
                    <VueFormElementText v-model="formData.buyer_taxid.value" :Options="formData.buyer_taxid">
                    </VueFormElementText>

                    <VueFormElementText v-model="formData.einv_vehicle_no1.value" :Options="formData.einv_vehicle_no1">
                    </VueFormElementText>
                </div>
            </template>


            <button class="btn btn-pink pay-btn m-text-40 mt-20px" style="border-radius: 4px !important;color: #fff;"
                @click="handlePay()" :disabled="pay_btn_disable">
                <b-spinner small v-if="pay_btn_disable"></b-spinner>
                <span v-if="!pay_btn_disable">付款</span>
            </button>
        </section>

        <!-- 購物車 -->
        <section class="card-round d-grid desktop-fix">
            <section class="list">
                <figure v-for="item in cart.cart_list" :key="item.id" class="cart-item">
                    <div class="d-flex align-items-center justify-content-between w-100">
                        <img :src="item.image" class="cart-img" />

                        <div class="d-flex flex-column justify-content-between w-100">
                            <div class="name m-text-28">{{ item.name }}</div>
                            <div class="tag-wrap m-text-24">
                                <div v-for="tag in item.position" :key="tag.id">
                                    {{ tag.tag_name }}
                                </div>
                            </div>

                            <div class="m-text-24">${{ item.amount }}</div>
                            <!-- 團課 -->
                            <template v-if="shoppingCart.source_type === 'GROUP_CLASS_UP'">
                                <div v-for="x in item.group_class_link_type" :key="x.sno" style="font-size: 14px"
                                    class="m-text-24">
                                    {{ x.COURSE_NUMBER_TYPE_NAME }} - 數量：{{ x.qty }}
                                </div>
                            </template>

                            <div v-else style="font-size: 14px" class="m-text-24">
                                數量：{{ item.qty }}
                            </div>
                        </div>

                        <figure class="mt-10px">
                            <span style="font-size: 18px; padding-right: 10px" class="m-text-35">
                                NT${{ numberWithCommas(item.discount_price * item.qty)
                                }}</span>
                            <span v-show="item.original_price !== item.discount_price" style="text-decoration: line-through;
                  font-size: 14px;
                  color: #a1a1a1;
                " class="m-text-28">
                                NT${{ numberWithCommas(item.original_price * item.qty)
                                }}</span>
                        </figure>
                    </div>
                </figure>
                <!-- <figure style="margin:8px 0 16px 0">
                    <div class="m-text-30" style="color: #909090; font-size: 14px; margin-bottom: 8px">
                        已享用之優惠
                    </div>
                    <div class="m-text-30" v-show="event">
                        {{ event.length > 0 ? event[event.length - 1].content : "" }}
                    </div>
                </figure> -->
            </section>

            <div>
                

                <figure class="d-flex justify-content-between m-text-35" style="font-weight: 700; font-size: 18px">
                    合計
                    <span>${{ numberWithCommas(calcTotal) }}</span>
                </figure>

                <figure class="d-flex justify-content-between m-text-35" style="font-weight: 700; font-size: 18px">
                    運費
                    <span>${{ numberWithCommas(delivery_fee) }}</span>
                </figure>

                <hr />

                <figure class="d-flex justify-content-between m-text-35" style="font-weight: 700; font-size: 18px">
                    總金額
                    <span>${{ numberWithCommas(realTotal) }}</span>
                </figure>
            </div>
        </section>

        <form ref="form" v-bind:action="THIRD_PARAM.HOST" method="post" enctype="multipart/form-data">
            <div>
                <input v-for="(fieldName, pos) in Object.keys(thirdForm)" :key="pos" v-model="thirdForm[fieldName]"
                    type="hidden" :name="fieldName" />
                <input v-model="thirdForm.Send_Type" type="hidden" name="test" />

                <button ref="submit" style="display: none">Submit</button>
            </div>
        </form>
    </div>
</template>

<script>
import VueFormElementText from "../components/Form/Form-element-text.vue";
import VueFormElementSelect from "../components/Form/Form-element-select.vue";
import VueFormElementTextarea from "../components/Form/Form-element-textarea.vue";
import { mapActions, mapGetters,mapState } from "vuex";
import axios from "../api/axios.js";
import Vue from "vue";
import {
  isGuiNumberValid, // 統一編號
  //isNationalIdentificationNumberValid, // 身分證字號
  //isResidentCertificateNumberValid, // 居留證編號
  //isNewResidentCertificateNumberValid, // 新式居留證編號
  //isOriginalResidentCertificateNumberValid, // 舊式居留證編號
  //isCitizenDigitalCertificateNumberValid, // 自然人憑證
  isEInvoiceCellPhoneBarcodeValid, // 手機條碼
  //isEInvoiceDonateCodeValid, // 捐贈碼
  //isCreditCardNumberValid // 信用卡
} from 'taiwan-id-validator'

export default {
    components: {
        VueFormElementText,
        VueFormElementSelect,
        VueFormElementTextarea
    },
    data() {
        return {
            is_installment: !!this.$route.query.installment, //定期定額
            is_email_empty: false,
            cash_use: false, //前台開啟優惠金使用
            booking: false, //前台開放預約
            point_use: true, //前台購物金使用
            coupon_use: true, //前台優惠碼使用
            other_use: true, //其他顯示
            other_name: "", //其他的名稱
            cash_on_delivery_yn: false, //是否使用貨到付款
            EC_PRIORITY_USE_POINT: false, //前台結帳優先使用購物金
            user_email: "", //email
            coupon_status: {},
            cash_err: "",
            cash_err_msg: "",
            coupon_err: "",
            coupon_err_msg: "",
            coupon_code: "", //優惠碼
            point_err: "",
            point_err_msg: "",
            delivery_fee: 0, //運費
            free_fee: 0, //免運金額
            //---自己本身剩餘的購物金與優惠金
            balance: 0, //餘額購物金
            coupon_amount: 0, //優惠碼金額
            useable_cash: 0, //優惠金金額
            //---商品可以抵用的購物金與優惠金
            use_balance: 0, //可抵用購物金
            use_coupon_amount: 0, //可抵用優惠碼金額
            use_useable_cash: 0, //可抵用優惠金金額
            //---商品可抵用的購物金與優惠金(只含有其中一邊)
            use_balance_uncash: 0, //可抵用購物金裡不含有的優惠金
            //use_coupon_amount: 0, //可抵用優惠碼金額
            use_useable_cash_unbalance: 0, //可抵用優惠金金額裡不含有的購物金
            uncash: 0, //扣可以抵用的優惠金
            unbalance: 0, //扣可以抵用的購物金
            cash: 0, //優惠金
            discamt: 0,//滿額折扣
            useable_point: 0,
            point: 0, //購物金
            brand: "",
            store_name: "", //門市名稱
            store_id: 0, //門市
            type: 1,
            sno: 0,
            addr1: "",
            addr2: "",
            trans_id:"",
            cart: {
                //購物車課程、商品內容
                id: null,
                cart_list: [],
            },
            optionList: {
                city: [],
                district: [],
                period: [],
                store_list: [],
            },
            event: [
                //{ content: "10/1滿額300送100" }
            ],
            THIRDPART_TYPE: "",
            send_type_list: [],
            pay_btn_disable: false,
            coupon_show: false, //優惠碼 顯示
            point_show: false, //購物金 顯示
            disc_show: false, //優惠金顯示
            coupon_calc_available: false, //優惠碼 套用
            cash_calc_available: false, //優惠金 套用
            point_calc_available: false, //購物金 套用
            discount_type: "", //DISCOUNT:折扣 CASH:現金折抵
            discount: 1,
            login_user: {}, //會員資料
            user_level: 0,
            pay_type: "CREDIT", //付款方式
            thirdForm: {},
            Countys: [{}],
            Citys: [{}],
            formObj: {
                web: "S1607299011",
                MN: "8999",
                Td: "Test00001",
                sna: "Neil",
                sdt: "0912345678",
                Card_Type: "",
                DueDate: "",
                ProductName1: "",
                ProductPrice1: "",
                ProductQuantity1: "",
                AgencyType: "",
                AgencyBank: "",
                note2: "",
                ChkValue: "5B97DB2994099769C5FB224A2C7E8E6E7A020C30",
            },
            THIRD_PARAM: {
                HOST: "",
                WEB_ID_CREDIT: "S1607299011",
                TRANS_PWD: "a12345678",
                WEB_ID_ATM: "S1607299037",
            },
            formData: {
                user_name: {
                    value: "",
                    HeaderText: "姓名",
                    ErrorMessage: "未填寫",
                    required: true,
                    ShowErrorMessage: false,
                },
                user_email: {
                    value: "",
                    HeaderText: "電子郵件",
                    ErrorMessage: "未填寫",
                    required: true,
                    ShowErrorMessage: false,
                },
                user_phone: {
                    value: "",
                    HeaderText: "手機號碼",
                    ErrorMessage: "未填寫",
                    required: true,
                    ShowErrorMessage: false,
                },
                buyer_taxid: {
                    value: "",
                    HeaderText: "統一編號",
                    ErrorMessage: "未填寫",
                    required: false,
                    ShowErrorMessage: false,
                },
                einv_vehicle_no1: {
                    value: "",
                    HeaderText: "手機載具",
                    ErrorMessage: "未填寫",
                    required: false,
                    ShowErrorMessage: false,
                },

                send_type: { value: "DELIVERY" }, //寄送方式 宅配or店取
                delivery_name: {
                    value: "",
                    HeaderText: "姓名",
                    ErrorMessage: "未填寫",
                    required: true,
                    ShowErrorMessage: false,
                },
                delivery_email: {
                    value: "",
                    HeaderText: "電子郵件",
                    ErrorMessage: "未填寫",
                    required: true,
                    ShowErrorMessage: false,
                },
                delivery_phone: {
                    value: "",
                    HeaderText: "手機號碼",
                    ErrorMessage: "未填寫",
                    required: true,
                    ShowErrorMessage: false,
                },
                delivery_addr: {
                    value: "",
                    HeaderText: "地址",
                    ErrorMessage: "未填寫",
                    required: true,
                    ShowErrorMessage: false,
                },
                delivery_city: {
                    value: "",
                    HeaderText: "縣市",
                    ErrorMessage: "未選擇",
                    required: true,
                    ShowErrorMessage: false,
                },
                delivery_district: {
                    value: "",
                    HeaderText: "鄉鎮市區",
                    ErrorMessage: "未選擇",
                    required: true,
                    ShowErrorMessage: false,
                },
                note: {
                    value: "",
                    HeaderText: "備註",
                    ErrorMessage: "未填寫",
                },
                period: {
                    value: "",
                    HeaderText: "期數",
                    ErrorMessage: "未選擇",
                    required: true,
                    ShowErrorMessage: false,
                },
                store: {
                    value: "",
                    HeaderText: "門市",
                    ErrorMessage: "未選擇",
                    required: true,
                    ShowErrorMessage: false,
                },
            },
        };
    },
    created() {
        axios.post("/Course/GetShoppingCart", { uid : this.$cookies.get("user").sno}).then(resp => {
        if (resp.succ) {
            this.cart.cart_list = resp.result.cart_list;
            if(this.cart.cart_list.length == 0 ) return this.$router.push({name: 'home'})
            }
        });
        // this.callApi(API_ROUTE.System.sysparam, { p_key: "Client_Cash" }, {});
        this.login_user = this.getUserCookie();
        axios.post("/User/GetUserPoint", { sno_user : this.$cookies.get("user").sno}).then(resp => {
        if (resp.succ) {
          this.balance = resp.result;
          this.useable_point = resp.result;
            }
        });
        if (!this.loggedInUser) {
            this.$swal("請先登入帳號。");
            this.$router.push({
                name: 'signIn'
            });
            return
        }
        axios.post("User/GetUserInfo", { USER_ACCOUNT: this.$cookies.get("user").userId }).then(resp => {
            if (resp.succ) {
                this.formData.user_name.value = resp.result.user_name;
                this.formData.user_email.value = resp.result.email;
                this.formData.user_phone.value = resp.result.phone;
                this.user_email = resp.result.email;
                this.formData.delivery_name.value = resp.result.user_name;
                this.formData.delivery_email.value = resp.result.email;
                this.formData.delivery_phone.value = resp.result.phone;
                this.addr1 = resp.result.addr1;
                this.addr2 = resp.result.addr2;
                this.formData.delivery_addr.value = resp.result.addr3;
                this.getAddress();
                //alert(resp.result);
            } else {
                this.$swal(resp.msg);
                //alert(resp.msg);
            }
        });
        axios.post("System/SysparamList", { p_group: "delivery_type" }).then(resp => {
            if (resp.succ) {
                this.send_type_list = [];
                let vm = this;
                resp.result.forEach((item) => {
                    if(item.p_key != 'STORE') { 
                        let _disabled = false;
                        let _show = true;
                        //console.log(this.cart.cart_list.some(item => item.item_type != "3"));
                        if (this.shoppingCart.source_type === 'GROUP_CLASS_UP' && (item.p_key == 'DELIVERY' || item.p_key == 'MARKET')) _disabled = true;
                        if (this.is_installment && (item.p_key == 'STORE' || item.p_key == 'MARKET')) _disabled = true;
                        if (item.p_key == 'MARKET' && (vm.THIRDPART_TYPE != 'NEWEBPAY' || !vm.shouldShowInput))
                            _show = false;

                        this.send_type_list.push({
                            id: item.p_key,
                            label: item.p_name,
                            disabled: _disabled,
                            show: _show
                            });
                    }
                });
            } else {
                this.$swal(resp.msg);
                //alert(resp.msg);
            }
        });
        this.GetDeliveryFee_Sendtype("DELIVERY");
        // this.callApi(API_ROUTE.User.GetUserInfo, {}, {});
        // this.callApi(API_ROUTE.System.sysparam, { p_key: "Client_Booking" }, {});
        // this.callApi(API_ROUTE.System.sysparam, { p_key: "EC_TRANS_PAY_OTHER_YN" }, {});
        // this.callApi(API_ROUTE.System.sysparam, { p_key: "EC_TRANS_PAY_OTHER_NAME" }, {});
        // this.callApi(API_ROUTE.System.sysparam, { p_key: "EC_CASH_ON_DELIVERY_YN" }, {});
        // this.callApi(API_ROUTE.System.sysparam, { p_key: "Client_Point_Buy" }, {}); //EC前台點數販賣

        // this.callApi(API_ROUTE.Store.GetStoreList2, { addAllOption: false, allStore: true }, {});
    },
    mounted() {
        this.toggleShoppingCart(false);
    },
    computed: {
        ...mapGetters(["shoppingCart", "shoppingInfo", "getCountClickShoppingCartBtn"]),
        ...mapState(['loggedInUser']),
        calcTotal() {
            let total = this.cart.cart_list.reduce((acc, curr) => {
                return acc + curr.amount * curr.qty;
            }, 0);
            return total;
        },
        realTotal() {
            let total = this.cart.cart_list.reduce((acc, curr) => {
                return acc + curr.amount * curr.qty;
            }, 0);
            let amount = this.coupon_calc_available ? this.calDiscount() : 0;
            let cash = this.cash_calc_available ? this.cash : 0;
            let point = this.point_calc_available ? this.point : 0;
            let calc = total - amount - cash - point - this.discamt + parseInt(this.delivery_fee);
            return calc < 0 ? 0 : calc;
            //return total + this.delivery_fee;
        },
        shouldShowInput() {
            return !this.cart.cart_list.some(item => item.item_type != "3");
        }
    },
    watch: {
        //監聽是否有按下結帳，重新call api
        getCountClickShoppingCartBtn() {
            axios.post("/Course/GetShoppingCart", { uid : this.$cookies.get("user").sno}).then(resp => {
            if (resp.succ) {
                this.cart.cart_list = resp.result.cart_list;
                }
            });
        },
    },
    methods: {
        ...mapActions(["toggleShoppingCart"]),
        getUserCookie() {
            return Vue.$cookies.get("user");
        },
        swalForInputConfirm(val, tag) {
            switch (tag) {
                default:
                    if (val != "") {
                        this.user_email = val;
                        this.handlePay();
                    }
                    break;
            }
        },
        calDiscount() {
            if (this.discount_type === "DISCOUNT") {
                this.coupon_amount =
                    this.use_coupon_amount +
                    this.delivery_fee -
                    Math.round(
                        this.$cf.numProc.multiply(
                            this.use_coupon_amount + this.delivery_fee,
                            this.discount
                        )
                    );
            } else if (this.discount_type === "CASH") {
                this.coupon_amount = this.discount;
            }

            // console.log(this.cart.coupon_amount);
            return this.coupon_amount;
        },
        //優惠金使用條件
        _cash_use(type) {
            if (!this.EC_PRIORITY_USE_POINT) {
                return true;
            }

            if (type === "text" && this.balance < this.realTotal) {
                return true;
            }
            else if (this.cash_calc_available) {
                return true;
            }

            return false;
        },
        //輸入框條件
        _cash_use_input() {
            if (this.cash_calc_available || (this.EC_PRIORITY_USE_POINT && this.balance >= this.realTotal)) {
                return true;
            }
            return false;
        },
        pay_type_setting() {
            this.coupon_calc_available = false;
            this.coupon_amount = 0;
            this.coupon_code = "";
            this.cash_calc_available = false;
            this.cash = 0;
            this.uncash = 0;
            this.point_calc_available = false;
            this.point = 0;
            this.unbalance = 0;
        },
        checkcoupon() {
            if (AppOptions.param.demoMode) {
                alert("checkcoupon");
                this.coupon_status = true;
            } else {
                if (!this.coupon_calc_available) {
                    this.coupon_amount = 0;
                    this.coupon_status = false;
                    if (
                        this.realTotal > 0 &&
                        this.use_coupon_amount + this.delivery_fee > 0
                    ) {
                        // this.callApi(
                        //     API_ROUTE.Disc.RedeemDiscForShop,
                        //     { DISC_NO: this.coupon_code, sno_store: this.store_id },
                        //     {}
                        // );
                    } else {
                        this.coupon_err_msg = "已無商品可用優惠碼折抵!";
                        this.coupon_err = true;
                    }
                } else {
                    this.coupon_calc_available = false;
                    this.coupon_code = null;
                    this.coupon_amount = 0;
                    this.coupon_status = false;
                }
            }
            //call api

            // after call api
            //優惠券有效：this.coupon_status = true this.coupon_amount = 100
            //優惠券無效：this.coupon_status = false
        },
        checkcash() {
            if (!this.cash_calc_available) {
                if (this.cash > this.useable_cash) {
                    this.cash_err = true;
                    this.cash_err_msg = "您輸入的金額大於目前可用餘額，請重新輸入";
                    this.cash_calc_available = false;
                } else {
                    this.cash_err = false;
                    this.cash_err_msg = "";
                    //先判斷 商品可抵用的優惠金(總和) - 已被購物金扣裡面包含的優惠金 < 輸入的優惠金
                    if (
                        this.use_useable_cash - this.unbalance + this.delivery_fee <
                        this.cash
                    ) {
                        this.cash =
                            this.use_useable_cash + this.delivery_fee - this.unbalance;
                    }
                    //商品裡面可抵用優惠金(不包含購物金) < 輸入的優惠金
                    if (this.use_useable_cash_unbalance < this.cash) {
                        this.uncash = this.cash - this.use_useable_cash_unbalance;
                    }
                    //總金無 < 輸入的優惠金
                    if (this.realTotal < this.cash) {
                        this.cash = this.realTotal;
                    }
                    this.cash_calc_available = true;
                }
            } else {
                this.cash_calc_available = false;
                this.cash = 0;
                this.uncash = 0;
            }
        },
        checkpoint() {
            if (!this.point_calc_available) {
                if (this.point > this.balance) {
                    this.point_err = true;
                    this.point_err_msg = "您輸入的金額大於目前可用餘額，請重新輸入";
                    this.point_calc_available = false;
                } else {
                    this.point_err = false;
                    this.point_err_msg = "";
                    //先判斷 商品可抵用的購物金(總和) - 已被優惠金扣裡面包含的購物金 < 輸入的購物金
                    if (this.use_balance - this.uncash + this.delivery_fee < this.point) {
                        this.point = this.use_balance + this.delivery_fee - this.uncash;
                    }
                    //商品裡面可抵用購物金(不包含優惠金) < 輸入的購物金
                    if (this.use_balance_uncash < this.point) {
                        this.unbalance = this.point - this.use_balance_uncash;
                    }
                    //總金無 < 輸入的購物金
                    if (this.realTotal < this.point) {
                        this.point = this.realTotal;
                    }
                    this.point_calc_available = true;
                }
            } else {
                this.point_calc_available = false;
                this.point = 0;
                this.unbalance = 0;
            }
        },
        handleInsufficientPoint() {
            const { href } = this.$router.resolve({
                name: "R.6.2.0",
                query: { tabid: 4, typeid: 0, jump: 1 },
            });
            window.open(href, "_blank");
        },
        getTopN: function (arr, prop, n) {
            // clone before sorting, to preserve the original array
            var clone = arr.slice(0);

            // sort descending
            clone.sort(function (x, y) {
                if (x[prop] == y[prop]) return 0;
                else if (parseInt(x[prop]) < parseInt(y[prop])) return 1;
                else return -1;
            });

            return clone.slice(0, n || 1);
        },
        apiGiftSetRemark() {
            //撈單筆滿額
            ApiGiftSet.GetNowUse({
                SNO_STORE: this.store_id,
                TARGET_AMT: this.calcTotal,
                TARGET_TYPE: "SINGLE",
                SNO_USER: this.login_user.sno_user,
            }).then((resp) => {
                if (resp.stat.succ) {
                    let arrContent = [];
                    if (resp.data != null) {
                        let respData = resp.data;

                        respData.forEach((a) => {
                            //撈單筆滿額(一般會員)折扣
                            if (a.GIFT_TYPE == "DISC_NORMAL" && this.user_level == "0") {
                                var topScorers = this.getTopN(resp.data.filter(x => x.GIFT_TYPE == "DISC_NORMAL" && this.user_level == "0"), "GIFT_NUM", 1);
                                topScorers.forEach(function (item, index) {
                                    console.log("#" + (index + 1) + ": " + item.player);
                                });
                                respData = topScorers;
                                this.discamt = respData[0]?.GIFT_NUM;
                            }

                        });
                    }
                    this.event = arrContent;
                }
            });

            //撈所有滿額贈活動名稱
            ApiGiftSet.GetNowUse({
                SNO_STORE: this.store_id,
                TARGET_AMT: this.calcTotal,
                SNO_USER: this.login_user.sno_user,
            }).then((resp) => {
                if (resp.stat.succ) {
                    let arrContent = [];
                    if (resp.data != null) {
                        let respData = resp.data;

                        respData.forEach((a) => {
                            //let giftOne = {};
                            if (a.TARGET_TYPE == "SINGLE" && this.user_level != "0") {
                                return;
                            }
                            let letDateSplitB = a.ACTION_DATE_B.split("-");
                            let letDateSplitE = a.ACTION_DATE_E.split("-");

                            let letDateMMDD_B = letDateSplitB
                                ? letDateSplitB[1] + "/" + letDateSplitB[2]
                                : "";
                            let letDateMMDD_E = letDateSplitB
                                ? letDateSplitE[1] + "/" + letDateSplitE[2]
                                : "";

                            let letContent =
                                letDateMMDD_B + " - " + letDateMMDD_E + " | " + a.GIFT_NAME;
                            arrContent.push({ content: letContent, url: "" });
                        });
                    }
                    this.event = arrContent;
                }
            });
        },
        getAddress() {
            axios.post("System/SysparamList", { p_group: "City" }).then(resp => {
                if (resp.succ) {
                    this.optionList.city = resp.result.map(x => {
                        return {
                            id: x.p_key,
                            label: x.p_name
                        }
                    });
                    let a = this.optionList.city.filter((item) => {
                        return item.label == this.addr1;
                    });
                    this.formData.delivery_city.value = a[0]?.id;
                    
                    this.onChange_add1("", this.formData.delivery_city.value);
                    //alert(resp.result)
                } else {
                    this.$swal(resp.msg);
                    //alert(resp.msg);
                }
            });
        },
        onChange_add1: function (event, name) {
            let temp_name = "";
            if (name === "") {
                temp_name = this.formData.delivery_city.value;
                this.formData.delivery_district.value = null;
            } else {
                temp_name = name;
            }
            var a = this.optionList.city.filter((item) => {
                return item.id == temp_name;
            });

            let p_key = a[0]?.id;
            if (p_key != undefined) {
                axios.post("System/SysparamList", { p_group: p_key }).then(resp => {
                    if (resp.succ) {
                        this.optionList.district = resp.result.map(x => {
                            return {
                                id: x.p_key,
                                label: x.p_name
                            }
                        });
                        let district = this.optionList.district.filter((item) => {
                            return item.label == this.addr2;
                        });

                        this.formData.delivery_district.value = district[0]?.id;
                    } else {
                        this.$swal(resp.msg);
                        //alert(resp.msg);
                    }
                });
            }

        },
        getApiStoreParam() {
            this.THIRD_PARAM.HOST = "";
            let vm = this;
            // this.callApi(API_ROUTE.System.SysparamList, { p_group: "delivery_type" }, {});
            // ApiCommon.GetStoreParam({
            //     SNO_STORE: this.store_id,
            //     PARAM_ID: this.THIRDPART_TYPE,
            // }).then((resp) => {
            //     if (resp.stat.succ && resp.data != null) {
            //         vm.THIRD_PARAM.HOST = resp.data.find(
            //             (x) => x.PARAM_KEY == "HOST"
            //         ).VAL;
            //         vm.THIRD_PARAM.WEB_ID_CREDIT = resp.data.find(
            //             (x) => x.PARAM_KEY == "WEB_ID_CREDIT"
            //         ).VAL;
            //         vm.THIRD_PARAM.TRANS_PWD = resp.data.find(
            //             (x) => x.PARAM_KEY == "TRANS_PWD"
            //         ).VAL;
            //         vm.THIRD_PARAM.LOCALHOST = resp.data.find(
            //             (x) => x.PARAM_KEY == "LOCALHOST"
            //         ).VAL;
            //         vm.THIRD_PARAM.HashIV = resp.data.find(
            //             (x) => x.PARAM_KEY == "HashIV"
            //         )?.VAL;
            //     }
            // });
        },
        handleContinue() {
            if (
                (this.user_email == "" || this.user_email == undefined) &&
                this.pay_type != "POINT"
            ) {
                this.showSwalForInput("請輸入Email", "結帳Email");
            } else {
                this.handlePay();
            }
        },
        async handlePay() {
            this.pay_btn_disable = true;

            if (this.verify()) {
                if (this.is_email_empty) {
                    // this.callApi(API_ROUTE.User.InsertUserEmail, {
                    //     SNO: this.login_user.sno_user,
                    //     email: this.formData.user_email.value,
                    // });
                }
                if (this.calcTotal > this.balance && this.pay_type === "POINT" && !this.EC_PRIORITY_USE_POINT) {
                    this.showPointMsg();
                } else {
                    if (this.EC_PRIORITY_USE_POINT && this.pay_type === "POINT" && parseInt(this.cash) == 0 && this.realTotal > parseInt(this.balance)) {
                        this.showPointMsg();
                    }
                    else if (this.EC_PRIORITY_USE_POINT && this.pay_type === "POINT" && parseInt(this.cash) != 0
                        && this.calcTotal + parseInt(this.delivery_fee) != parseInt(this.balance) + parseInt(this.cash)) {
                        this.showPointMsg();
                    }
                    else {
                        let TransItems = [];
                        let GroupClassLinkType = [];
                        let GroupClassUpLinkGroupNumber = [];
                        this.cart.cart_list.forEach((x) => {
                            // if (x.item_type == 1) {
                            //     x.position.forEach((c) => {
                            //         TransItems.push({
                            //             SNO_PRODUCT_LOC: c.id,
                            //             QTY: x.qty,
                            //             AMT: x.discount_price * x.qty,
                            //             SNO_PRODUCT_ACTION: x.SNO_PRODUCT_ACTION,
                            //         });
                            //     });
                            // }
                            // if (x.item_type == 2) {
                            //     let combinItem = [];
                            //     x.position.forEach((c) => {
                            //         combinItem.push(c.id);
                            //     });
                            //     TransItems.push({
                            //         SNO_COMBIN: x.source_sno,
                            //         COMBIN_SNO_PRODUCT_LOC: combinItem,
                            //         QTY: x.qty,
                            //         AMT: x.discount_price * x.qty,
                            //         SNO_PRODUCT_ACTION: x.SNO_PRODUCT_ACTION,
                            //     });
                            // }
                            if (x.item_type == "BAS_GOODS") {
                                TransItems.push({
                                    SNO_GOODS: x.source_sno,
                                    QTY: x.qty,
                                    AMT: x.discount_price * x.qty,
                                });
                            }
                            // if (x.item_type == 5) {
                            //     TransItems.push({
                            //         SNO_RENTAL_GOODS: x.source_sno,
                            //         QTY: x.qty,
                            //         AMT: x.discount_price * x.qty,
                            //     });
                            // }
                            // if (x.item_type == 6) {
                            //     TransItems.push({
                            //         SNO_GROUP_CLASS: x.source_sno,
                            //         QTY: x.qty,
                            //         AMT: x.discount_price * x.qty,
                            //     });
                            //     x.group_class_link_type.forEach((c) => {
                            //         if (c.qty != 0) {
                            //             GroupClassLinkType.push({
                            //                 COURSE_NUMBER_TYPE_NAME: c.COURSE_NUMBER_TYPE_NAME,
                            //                 SNO_GROUP_CLASS_LINK_TYPE: c.SNO,
                            //                 SNO_COURSE_NUMBER_TYPE: c.SNO_COURSE_NUMBER_TYPE,
                            //                 SNO_GROUP_CLASS: c.SNO_GROUP_CLASS,
                            //                 qty: c.qty,
                            //                 NUMBER: c.NUMBER,
                            //                 NUMBER_PROPORTION: c.NUMBER_PROPORTION,
                            //             });
                            //         }
                            //     });
                            //     x.group_class_up_link_group_number.forEach((c) => {
                            //         if (c.selected || x.ALL_YN) {
                            //             var GroupClassUpLinkDate = [];
                            //             c.group_class_up_link_date.forEach((z) => {
                            //                 if (c.SNO == z.SNO_GROUP_CLASS_UP_LINK_NUMBER) {
                            //                     GroupClassUpLinkDate.push({
                            //                         SNO_GROUP_CLASS_LINK_DATE: z.SNO,
                            //                         SNO_GROUP_CLASS_UP: z.SNO_GROUP_CLASS_UP,
                            //                         SNO_GROUP_CLASS_UP_LINK_GROUP_NUMBER: z.SNO_GROUP_CLASS_UP_LINK_NUMBER,
                            //                         GROUP_CLASS_DATE: z._GROUP_CLASS_DATE,
                            //                         GROUP_CLASS_TIME: z._GROUP_CLASS_TIME,
                            //                     });
                            //                 }
                            //             });
                            //             GroupClassUpLinkGroupNumber.push({
                            //                 SNO_GROUP_CLASS_UP_LINK_GROUP_NUMBER: c.SNO,
                            //                 SNO_GROUP_CLASS_UP: c.SNO_GROUP_CLASS_UP,
                            //                 GROUP_CLASS_UP_LINK_DATE: GroupClassUpLinkDate,
                            //             });
                            //         }
                            //     });
                            // }
                        });
                        let TransPays = [];
                        if (this.pay_type == "POINT") {
                            //
                            TransPays.push({
                                PAY_ID: "POINT",
                                AMT: this.realTotal,
                            });
                        }
                        else {
                            TransPays.push({
                                PAY_ID: this.pay_type,
                                AMT: this.realTotal,
                                CARD_TYPE: "THIRD",
                            });
                        }
                        var TransShoppingCart = [];
                        TransShoppingCart.push({
                            NAME: this.formData.user_name.value,
                            EMAIL: this.formData.user_email.value,
                            PHONE: this.formData.user_phone.value,
                            SEND_TYPE: this.formData.send_type.value,
                            DELIVERY_NAME: this.formData.delivery_name.value,
                            DELIVERY_EMAIL: this.formData.delivery_email.value,
                            DELIVERY_PHONE: this.formData.delivery_phone.value,
                            DELIVERY_ADDR: this.formData.delivery_addr.value,
                            DELIVERY_CITY: this.formData.delivery_city.value,
                            DELIVERY_DISTRICT: this.formData.delivery_district.value,
                            STORE: 0,
                            NOTE: this.formData.note.value,
                            PAY_TYPE: this.pay_type,
                            DELIVERY_FEE: this.delivery_fee,
                            CASH_ON_DELIVERY_YN: this.cash_on_delivery_yn ? "Y" : "N",
                        });

                        let total_disc_item = {};
                        if (this.discamt != 0) {
                            total_disc_item = {
                                MARKUP_PERC: this.discamt,
                                CTM_AMT: this.realTotal,
                            };
                        }
                        total_disc_item;
                        let req = {
                            SNO_USER: this.loggedInUser.sno,
                            SNO_STORE: 0,
                            TRANS_STATUS: this.pay_type == "POINT" ? "OK" : "WAIT_PAY",
                            TRANS_PAY_ID: this.pay_type,
                            TRANS_JSON: JSON.stringify(this.cart),
                            EINV_VEHICLE_NO1: this.formData.einv_vehicle_no,
                            BUYER_TAXID: this.formData.buyer_taxid.value,
                            BOOKING_DATE: this.booking.date,
                            BOOKING_TIME: this.booking.time,
                            TransItems: TransItems,
                            TransPays: TransPays,
                            GroupClassLinkType,
                            GroupClassUpLinkGroupNumber,
                            TransShoppingCart
                        };
                        let vm = this;
                        if (vm.pay_type != "POINT") {
                            if(vm.pay_type=="LINEPAY")
                            {
                                vm.THIRDPART_TYPE = "LINEPAY";
                            }
                            else
                            {
                                await axios
                                .post("/System/SysparamList", {
                                    p_key: "THIRDPART_TYPE",
                                }).then((resp) => {                                
                                    vm.THIRDPART_TYPE = resp.result[0]?.p_value;

                                })
                            }
                            
                            await axios
                            .post("/System/STORE_PARAM", {
                                SNO_STORE: 0,
                                PARAM_ID: vm.THIRDPART_TYPE,
                            })
                            .then((resp) => {
                                if (resp.succ) {
                                    vm.THIRD_PARAM.HOST = resp.result.find(
                                    (x) => x.PARAM_KEY == "HOST"
                                    ).VAL;
                                    vm.THIRD_PARAM.WEB_ID_CREDIT = resp.result.find(
                                    (x) => x.PARAM_KEY == "WEB_ID_CREDIT"
                                    ).VAL;
                                    vm.THIRD_PARAM.TRANS_PWD = resp.result.find(
                                    (x) => x.PARAM_KEY == "TRANS_PWD"
                                    ).VAL;
                                    vm.THIRD_PARAM.LOCALHOST = resp.result.find(
                                    (x) => x.PARAM_KEY == "LOCALHOST"
                                    ).VAL;
                                    vm.THIRD_PARAM.HashIV = resp.result.find(
                                    (x) => x.PARAM_KEY == "HashIV"
                                    )?.VAL;
                                }
                            });
                        }
                        console.log(req);

                        axios
                            .post("/Trans/TransCreate", req)
                            .then((resp) => {
                                // loader.hide()
                                if (resp.succ) {
                                    vm.trans_id = resp.result.TRANS_ID;
                                    if (vm.pay_type == "POINT") {
                                        if (resp.succ) {
                                            this.$router.push({
                                                    name: "shoppingCartPaySuccess",
                                                    query: {
                                                        id: vm.trans_id
                                                    }
                                                });
                                        } else {
                                            this.$swal(resp.msg);
                                        }
                                    }
                                    else {
                                        axios.post("/System/CreateWebApiLog", {
                                            REQUEST_API_URL: this.THIRD_PARAM.HOST,
                                            KEY_TYPE: "ThirdPart_TRANS",
                                            KEY_VALUE: vm.trans_id,
                                            //TRANS_CONTENT: window.location.origin + window.location.pathname,
                                            TRANS_CONTENT:
                                            window.location.origin+'/shopping-cart/PaySuccess',
                                        })
                                            .then((resp) => {
                                            if (resp.succ) {
                                                let param = {};
                                                param.THIRDPART_TYPE = vm.THIRDPART_TYPE;
                                                param.amount = vm.realTotal;
                                                param.WEB_ID_CREDIT = vm.THIRD_PARAM.WEB_ID_CREDIT;
                                                param.TRANS_PWD = vm.THIRD_PARAM.TRANS_PWD;
                                                param.HashIV = vm.THIRD_PARAM.HashIV;
                                                param.HOST = vm.THIRD_PARAM.HOST;
                                                param.LOCALHOST = vm.THIRD_PARAM.LOCALHOST
                                                param.ordernumber = vm.trans_id;
                                                param.full_mobile = vm.formData.user_phone.value;
                                                param.ItemName = "商品";
                                                param.user_email = vm.formData.user_email.value;
                                                param.pay_type = vm.pay_type;
                                                param.user_name = vm.formData.user_name.value;
                                                vm.$cf.thirdPartTrans(param);
                                            }
                                        })
                                    }
                                } else {
                                    this.$swal(resp.msg);
                                }
                            });
                        // this.callApi(
                        //     API_ROUTE.Trans.TransCreate,
                        //     {
                        //         BUYER_TAXID: this.formData.buyer_taxid.value,
                        //         EINV_VEHICLE_NO1: this.formData.einv_vehicle_no1.value,
                        //         SNO_STORE: this.store_id,
                        //         TRANS_STATUS:
                        //             (this.pay_type == "POINT" || this.pay_type == "OTHER" || this.realTotal == 0) && this.formData.send_type.value != 'MARKET'
                        //                 ? "OK"
                        //                 : "WAIT_PAY",
                        //         TRANS_PAY_ID: this.pay_type,
                        //         TRANS_JSON: JSON.stringify(this.cart),
                        //         TransItems,
                        //         TransPays: [
                        //             {
                        //                 PAY_ID: "CREDIT",
                        //                 AMT: this.pay_type === "CREDIT" ? this.realTotal : 0,
                        //                 CARD_TYPE:
                        //                     this.pay_type === "CREDIT" || this.pay_type === "ATM"
                        //                         ? "THIRD"
                        //                         : "",
                        //             },
                        //             {
                        //                 PAY_ID: "DISCCODE",
                        //                 AMT: this.coupon_calc_available ? this.coupon_amount : 0,
                        //                 VOUCHER_NO: this.coupon_code,
                        //             },
                        //             {
                        //                 PAY_ID: "DISCAMT",
                        //                 AMT: this.cash_calc_available ? this.cash : 0,
                        //             },
                        //             {
                        //                 PAY_ID: "POINT",
                        //                 AMT:
                        //                     this.pay_type === "CREDIT" ||
                        //                         this.pay_type === "ATM" ||
                        //                         this.pay_type === "REGULAR" ||
                        //                         this.pay_type === "MARKET" ||
                        //                         this.pay_type === "OTHER"
                        //                         ? this.point_calc_available
                        //                             ? this.point
                        //                             : 0
                        //                         : this.realTotal,
                        //             },
                        //             {
                        //                 PAY_ID: "ATM",
                        //                 AMT: this.pay_type === "ATM" ? this.realTotal : 0,
                        //                 CARD_TYPE:
                        //                     this.pay_type === "CREDIT" || this.pay_type === "ATM"
                        //                         ? "THIRD"
                        //                         : "",
                        //             },
                        //             {
                        //                 PAY_ID: "MARKET",
                        //                 AMT: this.pay_type === "MARKET" ? this.realTotal : 0,
                        //                 CARD_TYPE:
                        //                     this.pay_type === "CREDIT" || this.pay_type === "ATM" || this.pay_type === "MARKET"
                        //                         ? "THIRD"
                        //                         : "",
                        //             },
                        //             {
                        //                 PAY_ID: "REGULAR",
                        //                 AMT: this.pay_type === "REGULAR" ? this.realTotal : 0,
                        //                 PERIOD:
                        //                     this.pay_type === "REGULAR"
                        //                         ? this.formData.period.value
                        //                         : "",
                        //             },
                        //             {
                        //                 PAY_ID: "OTHER",
                        //                 AMT: this.pay_type === "OTHER" ? this.realTotal : 0,
                        //                 REMARK: this.other_name,
                        //             },
                        //         ],
                        //         TransShoppingCart,
                        //         GroupClassLinkType,
                        //         GroupClassUpLinkGroupNumber,
                        //         AMT_DISC: total_disc_item ?? null,
                        //     },
                        //     {}
                        // );
                    
                    }
                }
            } else {
                this.pay_btn_disable = false;
            }
        },

        strippadding(string) {
            const slast = string.charCodeAt(string.length - 1);
            const slastc = String.fromCharCode(slast);
            // const pcheck = string.substr(-slast);
            const regex = new RegExp(`${slastc}{${slast}}`);
            if (regex.test(string)) {
                string = string.substr(0, string.length - slast);
                return string;
            } else {
                return false;
            }
        },
        showPointMsg() {
            this.pay_btn_disable = false;
            this.$swal
                .fire({
                    title: "購物金不足",
                    html: `<span>您的購物金餘額不足折抵訂單金額，不足之餘額需以刷卡或購買購物金支付完成訂單，謝謝</span>`,
                    confirmButtonText: "確認",
                    showCancelButton: false,
                    cancelButtonText: "關閉",
                    confirmButtonClass: "btn-pink",
                    cancelButtonClass: "btn-white",
                    reverseButtons: true,
                })
                


        },
        verify() {
            var bool = true;
            this.formData.user_name.ShowErrorMessage
                = this.formData.user_email.ShowErrorMessage
                = this.formData.user_phone.ShowErrorMessage
                = this.formData.delivery_name.ShowErrorMessage
                = this.formData.delivery_email.ShowErrorMessage
                = this.formData.delivery_phone.ShowErrorMessage
                = this.formData.delivery_addr.ShowErrorMessage
                = this.formData.delivery_city.ShowErrorMessage
                = this.formData.delivery_district.ShowErrorMessage
                = false;

            //檢核統編及手機條碼
            if (this.pay_type == 'CREDIT' || this.pay_type == 'ATM' || this.pay_type == 'LINEPAY') {
                if (this.formData.buyer_taxid.value && !isGuiNumberValid(this.formData.buyer_taxid.value)) {
                    this.$swal.fire("統編錯誤!");
                    return false;

                }
                if (this.formData.einv_vehicle_no1.value && !isEInvoiceCellPhoneBarcodeValid(this.formData.einv_vehicle_no1.value)) {
                    this.$swal.fire("手機載具錯誤!");
                    bool = false;
                    return false;
                }
            } else {
                this.formData.buyer_taxid.value = "";
                this.formData.einv_vehicle_no1.value = "";
            }

            if (
                this.formData.user_name.value == "" ||
                this.formData.user_name.value == null
            ) {
                this.formData.user_name.ShowErrorMessage = true;
                bool = false;
            }
            if (
                this.formData.user_email.value == "" ||
                this.formData.user_email.value == null
            ) {
                this.formData.user_email.ShowErrorMessage = true;
                bool = false;
            }
            if (
                this.formData.user_phone.value == "" ||
                this.formData.user_phone.value == null
            ) {
                this.formData.user_phone.ShowErrorMessage = true;
                bool = false;
            }
            if (this.formData.send_type.value == "DELIVERY") {
                if (
                    this.formData.delivery_name.value == "" ||
                    this.formData.delivery_name.value == null
                ) {
                    this.formData.delivery_name.ShowErrorMessage = true;
                    bool = false;
                }
                if (
                    this.formData.delivery_email.value == "" ||
                    this.formData.delivery_email.value == null
                ) {
                    this.formData.delivery_email.ShowErrorMessage = true;
                    bool = false;
                }
                if (
                    this.formData.delivery_phone.value == "" ||
                    this.formData.delivery_phone.value == null
                ) {
                    this.formData.delivery_phone.ShowErrorMessage = true;
                    bool = false;
                }
                if (
                  this.formData.delivery_addr.value == "" ||
                  this.formData.delivery_addr.value == null
                ) {
                  this.formData.delivery_addr.ShowErrorMessage = true;
                  bool = false;
                }
                if (
                  this.formData.delivery_city.value == "" ||
                  this.formData.delivery_city.value == null
                ) {
                  this.formData.delivery_city.ShowErrorMessage = true;
                  bool = false;
                }
                if (
                  this.formData.delivery_district.value == "" ||
                  this.formData.delivery_district.value == null
                ) {
                  this.formData.delivery_district.ShowErrorMessage = true;
                  bool = false;
                }
            }

            return bool;
        },
        handleCity() {
            // this.callApi(
            //     API_ROUTE.Account.Register_City,
            //     { p_key: this.formData.delivery_city.value },
            //     {}
            // );
        },
        GetDeliveryFee_Sendtype(send_type) {
            this.coupon_calc_available = false;
            this.coupon_amount = 0;
            this.coupon_code = "";
            this.cash_calc_available = false;
            this.cash = 0;
            this.uncash = 0;
            this.point_calc_available = false;
            this.point = 0;
            this.unbalance = 0;
            if (send_type == "STORE") {
                this.delivery_fee = 0;
            } else if (send_type == "DELIVERY") {
                axios.post("System/SysparamList", { p_key: "Delivery_Fee" }).then(resp => {
                    if (resp.succ) {
                        this.delivery_fee = parseInt(resp.result[0].p_value);
                        axios.post("System/SysparamList", { p_key: "Free_Fee" }).then(resp => {
                            if (resp.succ) {
                                this.free_fee = resp.result[0].p_value;
                                console.log()
                                if (this.calcTotal >= this.free_fee) {
                                this.delivery_fee = 0;
                                }
                            } else {
                                this.$swal(resp.msg);
                                //alert(resp.msg);
                            }
                        });
                    } else {
                        this.$swal(resp.msg);
                        //alert(resp.msg);
                    }
                });
                // this.callApi(API_ROUTE.System.sysparam, { p_key: "Delivery_Fee" }, {});
            }

        },
        // 千分位
        numberWithCommas(x) {
            return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        // 乘法
        multiply(a, b) {
            undefined;
            return $math.multiply($math.bignumber(a), $math.bignumber(b));
        },
        thirdPartTrans: function (param) {
            let thirdForm = {};
            switch (param.THIRDPART_TYPE) {
                case "YIPAY":
                    {
                        thirdForm.merchantId = param.WEB_ID_CREDIT;
                        thirdForm.amount = param.amount + "";
                        thirdForm.orderNo = param.ordernumber;
                        thirdForm.returnURL = param.LOCALHOST + "/EC/ThirdPart/YiPayResp"; //當交易完成後，系統會由付款⾴⾯返回⾄商家的⾴⾯，並⼀併將結果以 POST ⽅式回傳⾄該網址
                        thirdForm.cancelURL = ""; //若填寫此參數時，於付款⾴⾯上會顯⽰「取消付款返回商家網站」的連結，當消費者按下後會導回商家指定網址，並不帶入任何參數
                        thirdForm.backgroundURL = param.LOCALHOST + "/EC/ThirdPart/YiPayCallback"; //當交易完成後，系統會將交易結果以背景⽅式 POST ⾄該網址
                        var jsonString = JSON.stringify(thirdForm);
                        // jsonString = "{\"merchantId\":\"1604000006\",\"amount\":\"1500\",\"orderNo\":\"YP2016111503353\",\"returnURL\":\"https://gateway-test.yipay.com.tw/demo/return\",\"cancelURL\":\"https://gateway-test.yipay.com.tw/demo/cancel\",\"backgroundURL\":\"https://gateway-test.yipay.com.tw/demo/background\"}";
                        // 3. 去除各組 key/value 間的空格，並確認所有 key/value 皆有雙引號
                        var formattedJsonString = jsonString.replace(/"([^"]+)"\s*:/g, "\"$1\":").replace(/\s*,\s*/g, ",");
                        var aesString = this.encryptAES256(formattedJsonString, param.TRANS_PWD, param.HashIV);
                        const sha1Hash = this.sha1Hash(aesString, 'hex');

                        thirdForm.checkCode = sha1Hash;
                        thirdForm.orderDescription = param.ItemName;
                        thirdForm.notificationEmail = param.user_email;


                        switch (param.pay_type) {
                            case "CREDIT":
                                // this.formObj.Td = "Test00001";
                                thirdForm.type = "1";

                                break;
                            case "ATM": {
                                thirdForm.type = "4";
                                // this.thirdForm.bankid =this.THIRD_PARAM.BANKID;
                                break;
                            }
                        }
                        break;
                    }
                case "1177PAY":
                    {
                        if (param.is_installment) {
                            thirdForm.authday = param.authday;
                            thirdForm.isregularcharge = "1";
                            thirdForm.mode = "i";
                            thirdForm.regular = "m";
                            thirdForm.regularcount = param.PERIOD_NUM;
                        }
                        thirdForm.amount = param.amount;
                        thirdForm.currency = "TWD";
                        thirdForm.merchantnumber = param.WEB_ID_CREDIT;
                        thirdForm.ordernumber = param.ordernumber;
                        thirdForm.paymenttype = "creditcard";
                        thirdForm.payphone = param.full_mobile; //消費者手機(數字;不可全形) ;未帶將自動轉入系統預設付款頁面
                        thirdForm.paytitle = param.ItemName;
                        switch (param.pay_type) {
                            case "CREDIT":
                                // this.formObj.Td = "Test00001";
                                thirdForm.paymenttype = "creditcard";

                                break;
                            case "ATM": {
                                thirdForm.paymenttype = "atm";
                                // this.thirdForm.bankid =this.THIRD_PARAM.BANKID;
                                break;
                            }
                        }
                        thirdForm.timestamp = moment(new Date()).format("YYYYMMDDHHmmss");
                        let calculatedArray = []
                        for (const [key, value] of Object.entries(thirdForm)) {
                            calculatedArray.push({
                                id: key,
                                val: value
                            })
                        }

                        thirdForm = {};
                        this.sortedData(calculatedArray).forEach(x => {
                            thirdForm[x.id] = x.val;
                        })
                        let s = this.serializeObj(thirdForm);
                        s = sha256(s + param.TRANS_PWD).toUpperCase();
                        thirdForm.checksum = s;

                        break;
                    }
                case "GOMYPAY":
                    thirdForm.Pay_Mode_No = "2";
                    thirdForm.CustomerId = param.WEB_ID_CREDIT;
                    thirdForm.Order_No = param.ordernumber;
                    thirdForm.Amount = param.amount;
                    thirdForm.TransCode = "00"; //交易類別請填 00(授權)
                    thirdForm.Buyer_Name = param.user_name; //消費者姓名;未帶將自動轉入系統預設付款頁面
                    thirdForm.Buyer_Telm = param.full_mobile; //消費者手機(數字;不可全形) ;未帶將自動轉入系統預設付款頁面
                    thirdForm.Buyer_Memo = param.ItemName; //消費備註(交易內容) ;未帶將自動轉入系統預設付款頁面
                    thirdForm.Buyer_Mail = param.user_email;
                    // this.thirdForm.CardNo= ""; //信用卡號;如無將自動轉入系統預設付款頁面
                    // this.thirdForm.ExpireDate= ""; //卡片有效日期(YYMM) ;如無將自動轉入系統預設付款頁面
                    // this.thirdForm.CVV= ""; //卡片認證碼;如無將自動轉入系統預設付款頁面
                    thirdForm.TransMode = "1"; //交易模式一般請填(1)、分期請填(2)
                    thirdForm.Installment = "0"; //期數;無期數請填 0
                    switch (param.pay_type) {
                        case "CREDIT":
                            // this.formObj.Td = "Test00001";
                            thirdForm.Send_Type = "0";
                            thirdForm.Return_url =
                                param.LOCALHOST + "/EC/ThirdPart/GomypayResp"; //授權結果回傳網址=如無則自動轉入系統預設授權頁面
                            break;
                        case "ATM": {
                            thirdForm.Send_Type = "4";
                            thirdForm.Callback_Url =
                                param.LOCALHOST + "/EC/ThirdPart/GomypayCallback"; //授權結果回傳網址=如無則自動轉入系統預設授權頁面
                            break;
                        }
                    }
                    break;
                case "ECPAY":
                    {
                        switch (param.pay_type) {
                            case "CREDIT":
                                thirdForm.ChoosePayment = "Credit";
                                thirdForm.OrderResultURL = param.LOCALHOST + "/EC/ThirdPart/EcpayResp"; //授權結果回傳網址=如無則自動轉入系統預設授權頁面
                                break;
                            case "ATM":
                                thirdForm.ChoosePayment = "ATM";
                                thirdForm.ReturnURL = param.LOCALHOST + "/EC/ThirdPart/ECpayCallback"; //授權結果回傳網址=如無則自動轉入系統預設授權頁面
                                break;
                        }
                        thirdForm.EncryptType = "1"; //商品名稱
                        thirdForm.ItemName = param.ItemName; //商品名稱
                        thirdForm.MerchantID = param.WEB_ID_CREDIT;
                        thirdForm.MerchantTradeDate = moment(new Date()).format("YYYY/MM/DD HH:mm:ss");
                        thirdForm.MerchantTradeNo = param.ordernumber;
                        thirdForm.OrderResultURL = param.LOCALHOST + "/EC/ThirdPart/EcpayResp"; //授權結果回傳網址=如無則自動轉入系統預設授權頁面
                        thirdForm.PaymentType = "aio"; //交易類型        
                        thirdForm.TotalAmount = param.amount;
                        thirdForm.TradeDesc = param.ItemName; //交易描述
                        let calculatedArray = []
                        for (const [key, value] of Object.entries(thirdForm)) {
                            calculatedArray.push({
                                id: key,
                                val: value
                            })
                        }

                        thirdForm = {};
                        this.sortedData(calculatedArray).forEach(x => {
                            thirdForm[x.id] = x.val;
                        })
                        let s = encodeURIComponent("HashKey=" + param.TRANS_PWD + "&" + this.serializeObj(thirdForm) + "&HashIV=" + param.HashIV);
                        s = sha256(this.netUrlEncode(s).toLowerCase()).toUpperCase();
                        thirdForm.CheckMacValue = s;

                        break;
                    }
                case "NEWEBPAY": {
                    let data1 = `MerchantID=${param.WEB_ID_CREDIT}&TimeStamp=${Math.floor(Date.now() / 1000)}&Version=2.0&CVSCOM=${param.CVSCOM}&RespondType=JSON&MerchantOrderNo=${param.ordernumber}&Amt=${param.amount}&ItemDesc=${param.ItemName}&Email=${param.user_email}`;

                    switch (param.pay_type) {
                        case "CREDIT":
                            data1 += "&CREDIT=1";
                            data1 += `&ReturnURL=${param.LOCALHOST + "/EC/ThirdPart/NewebpayResp"}`;
                            data1 += `&NotifyURL=${param.LOCALHOST + "/EC/ThirdPart/NewebpayCallback"}`;
                            break;
                        case "ATM":
                            data1 += "&VACC=1";
                            data1 += `&NotifyURL=${param.LOCALHOST + "/EC/ThirdPart/NewebpayCallback"}`;
                            break;
                        default:
                            // data1 += `&ReturnURL=${param.LOCALHOST + "/EC/ThirdPart/NewebpayResp"}`;
                            data1 += `&NotifyURL=${param.LOCALHOST + "/EC/ThirdPart/NewebpayCallback"}`;
                            break;
                    }
                    let edata1 = this.encryptionAES(data1, param.TRANS_PWD, param.HashIV);
                    let TradeSha = sha256(`HashKey=${param.TRANS_PWD}&${edata1}&HashIV=${param.HashIV}`).toUpperCase();
                    console.log(edata1);
                    thirdForm.MerchantID = param.WEB_ID_CREDIT;
                    thirdForm.TradeInfo = edata1;
                    thirdForm.TradeSha = TradeSha;
                    thirdForm.Version = "2.0";
                    break;
                }
                case "NEWEBPAY_REGULAR": {
                    let data1 = "";
                    let edata1 = "";
                    let PeriodPoint = moment(new Date()).format("DD");
                    data1 = `MerchantID=${param.WEB_ID_CREDIT}&TimeStamp=${Math.floor(Date.now() / 1000)}&Version=1.5&RespondType=JSON&MerOrderNo=${param.ordernumber}&PeriodAmt=${param.amount}&ProdDesc=${param.ItemName}&PeriodType=M&PeriodPoint=${PeriodPoint}&PeriodStartType=2&PeriodTimes=${param.PERIOD_NUM}=&PayerEmail=${param.user_email}&PaymentInfo=N&OrderInfo=N`;
                    data1 += `&ReturnURL=${param.LOCALHOST + "/EC/ThirdPart/NewebPayPeriodResp"}`;
                    data1 += `&NotifyURL=${param.LOCALHOST + "/EC/ThirdPart/NewebPayPeriodCallBack"}`;
                    edata1 = this.encryptionAES(data1, param.TRANS_PWD, param.HashIV);
                    thirdForm.MerchantID_ = param.WEB_ID_CREDIT;
                    thirdForm.PostData_ = edata1;
                    break;

                }
            }
            this.formpost(param.HOST, thirdForm);

        },
    },
}
</script>

<style lang="scss" scoped>
h4 {
    margin-bottom: 16px;
}

.row {
    margin: 0;
}

.wrap {
    display: grid;
    grid-template-columns: 6fr 4fr;
    gap: 8px;
}

.wrap-one {
    grid-template-columns: 1fr;
}

.card-round {
    background-color: #fff;
    box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.3);
    border-radius: 16px;
    padding: 16px;
    margin: 10PX;
}

.pers-title {
    margin: 0 40px 24px 40px !important;
}

hr {
    background: #4b4b4b;
    height: 1.5px;
    margin: 20px 0;
}

.amount {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    margin-top: 4px;
    justify-items: end;
    white-space: nowrap;
}

.main-content {
    min-width: 1200px;
}

.m-checkout {
    display: grid;
    grid-template-columns: 144px 1fr;
    align-items: center;
}

.pay-btn {
    background-color: var(--main-text-color) !important;
}

.cart-item {
    padding: 10px;
    border-bottom: 2px solid #e9ecef;
    width: 100%;
    display: flex;
    flex-direction: column;

    .name {
        color: #2d353c;
        font-weight: 600;
        font-size: 18px;
    }
}

.cart-img {
    width: 100px;
    height: 100px;
    margin-right: 10px;
}

.tag-wrap {
    font-size: 14px;
    display: flex;
    flex-direction: row;
    max-width: 150px;
    overflow: hidden;

    div {
        white-space: nowrap;
    }

    div+div {
        margin-left: 5px;
    }
}

.amount {
    font-weight: 600;
    color: #2d353c;
    font-size: 18px;
}

.disc-btn {
    background: #dadada;
    border-radius: 6px;
    width: 160px;
    font-size: 16px;
    color: #4b4b4b;
    height: 47px;
    margin-left: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
        background: #d0d0d0;
    }
}

@media (max-width: 1200px) {
    .mobile-zoom {

        input,
        textarea {
            font-size: 20px;
        }

        h4 {
            font-size: 28px;
        }
    }

    .main-content {
        min-width: unset;
    }

    .wrap {
        grid-template-columns: 1fr;
        margin: 0;
    }

    .pay-btn {
        min-height: 50px;
    }

    .border-btn {
        border: 2px solid var(--btn-main);
        border-radius: 16px;
        box-sizing: border-box;
        padding: 40px;
    }

    .m-flex-column {
        flex-direction: column !important;
    }

    .amount {
        display: flex-end;
    }

    .m-btn {
        height: 90px;
        width: 50%;
        font-size: 28px !important;
    }

    .form {
        order: 2;
        padding: 16px !important;
    }

    .border-btn {
        padding: 15px;
    }
}

@media (min-width: 1200px) {
    .desktop-fix {
        width: 95%;
        height: 80%;

        .list {
            height: calc(100% - 30px);
            overflow: auto;
        }
    }
}
</style>