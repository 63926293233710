<template>
    <div class="page-wrapper">
        <div class="row row-large row-divided">
            <div class="large-12 col">
                <div class="entry-header">
                    <h1 class="entry-title">照亮偏鄉小學！GAGA monster獨家史萊姆手作教學公益活動！
                        點亮瑞里國小學生的創造力，探索史萊姆新世界！
                    </h1>
                </div>
            </div>
            <div class="post-time">
                <span><img src="../../../assets/img/mobile/news/time.png" alt="">2023-06-05</span>
            </div>
            <div class="content col-12">
                <div class="img-group">
                    <img v-lazy="Img1" alt="">
                    <p>GAGA monster史萊姆公益活動，出發──嘉義縣梅山鄉瑞里國小</p>
                </div>
                <p>位於嘉義縣梅山鄉的瑞里國小，是一個自然環境豐富的地方，被群山環繞，景色優美。在這裡的居民們大多從事農務業相關工作，生活節奏相對較慢，充滿了悠閒與寧靜的氛圍。在山上的瑞里國小，或許因地理環境的限制，沒有文具店或是百貨公司史萊姆手作專櫃等地方，因此學生們較難有機會親身體驗史萊姆的樂趣！
                </p>
                <div class="img-group">
                    <img v-lazy="Img2" alt="">
                </div>
                <p>因市面上有許多來路不明的史萊姆，原物料膠水等可能都是使用工業或文書專用，還會添加不明的化學物質！如果讓小朋友遊玩會對皮膚造成傷害。 GAGA
                    monster為瑞里國小帶來了一場安全無毒的公益活動，提供GAGA史萊姆專用怪獸膠水、成型水等，材料且皆通過SGS安全檢測，採用台灣原料減低碳排!為了就是讓所有小朋友有最安全的遊玩，可以將史萊姆從零到有的製作。
                </p>
                <div class="img-group">
                    <img v-lazy="Img3" alt="">
                </div>
                <div style="text-align: center;font-size: 16px;">
                    <p><b>GAGA monster與瑞里國小的史萊姆探險即將展開！</b></p>
                </div>
                <p>⌜哇!!!好神奇喔~~~原來加入怪獸膠跟魔法藥水就會變出史萊姆!!!!!⌟
                    沒摸過史萊姆的小朋友 ⌜原來史萊姆冰冰涼涼的! 好特別喔!!!⌟
                    在孩子們眼中，史萊姆不僅是一種有趣的玩具，更是一個能夠發揮無限創造與想像手工藝品，他們可以自由發揮史萊姆各種色彩與形狀，用怪獸膠調配揉捏出各種手感，享受著探索和創造的樂趣。
                </p>
                <div class="img-group">
                    <img v-lazy="Img4" alt="">
                    <img v-lazy="Img5" alt="">
                </div>
                <p>GAGA monster認為探索史萊姆這個樂趣不分地區更不分你我他，每個小孩都該擁有豐富又有趣的史萊姆童年!所有孩子能盡情發揮創意，創造出屬於自己的作品!</p>
                <div class="img-group">
                    <img v-lazy="Img6" alt="">
                </div>
                <p>⌜老師!!! 我們想要蓋大泡泡~⌟ 瑞里國小師生們同心協力挑戰史萊姆大泡泡，蓋史萊姆大泡泡是一項需要團隊合作的有趣活動，每個成員都是一位重要的角色，彼此合作才能成功創造出巨大的泡泡！GAGA
                    monster看見瑞里國小的學生們共同努力所帶來的成就感和快樂，同時也看見所有孩子在遊戲中獲得了一份難忘的回憶。</p>
                <div class="img-group">
                    <img v-lazy="Img7" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'newsdetail20230605',
    data() {
        return {
            Img1: require('../../../assets/img/mobile/news/20230605/官網1.jpg'),
            Img2: require('../../../assets/img/mobile/news/20230605/官網2.jpg'),
            Img3: require('../../../assets/img/mobile/news/20230605/官網3.jpg'),
            Img4: require('../../../assets/img/mobile/news/20230605/官網4.jpg'),
            Img5: require('../../../assets/img/mobile/news/20230605/官網5.jpg'),
            Img6: require('../../../assets/img/mobile/news/20230605/官網6.jpg'),
            Img7: require('../../../assets/img/mobile/news/20230605/官網7.jpg'),
        }
    },
}
</script>