var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-wrapper"},[_c('div',{staticClass:"row row-large row-divided"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"content col-12"},[_c('div',{staticClass:"img-group"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.Img1),expression:"Img1"}],attrs:{"alt":""}}),_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.Img2),expression:"Img2"}],attrs:{"alt":""}})]),_vm._m(2)]),_c('p',[_vm._v("慶祝GAGAmonster1歲生日!!!以創意料理聞名的廚師長，想要製作出慶祝派對甜點，前往「奶酪星球」探險時，好多牛奶凍散落在星球上，特級廚師長使出看家本領，邀請小小探險家共同研發，製作出Q彈冰涼又綿密的❰奶凍怪獸❱")]),_c('div',{staticClass:"content col-12"},[_c('div',{staticClass:"img-group"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.Img4),expression:"Img4"}],attrs:{"alt":""}})]),_vm._m(3),_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.Img5),expression:"Img5"}],attrs:{"alt":""}}),_vm._m(4),_vm._m(5),_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.Img6),expression:"Img6"}],attrs:{"alt":""}}),_vm._m(6),_vm._m(7),_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.Img7),expression:"Img7"}],attrs:{"alt":""}}),_vm._m(8),_vm._m(9),_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.Img8),expression:"Img8"}],attrs:{"alt":""}}),_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.Img9),expression:"Img9"}],attrs:{"alt":""}}),_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.Img10),expression:"Img10"}],attrs:{"alt":""}}),_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.Img11),expression:"Img11"}],attrs:{"alt":""}})])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"large-12 col",staticStyle:{"padding-left":"0","padding-right":"0"}},[_c('div',{staticClass:"entry-header"},[_c('h1',{staticClass:"entry-title"},[_vm._v("GAGA monster盛大一週年慶典"),_c('br'),_vm._v(" 🎉歡 / 慶 / 週 / 年 / 慶🎉"),_c('br'),_vm._v(" 活動期間：3/24 - 4/9"),_c('br'),_vm._v(" 場場加贈驚喜活動 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"post-time"},[_c('span',[_c('img',{attrs:{"src":require("../../../assets/img/mobile/news/time.png"),"alt":""}}),_vm._v("2023-03-24")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"text-align":"center","font-size":"16px"}},[_c('p',[_c('b',[_vm._v("❮新課程上市❯")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"text-align":"center","font-size":"16px"}},[_c('p',[_c('b',[_vm._v("❮新品上市❯")])]),_c('p',[_vm._v("五彩繽紛的史萊姆在宇宙星空中閃耀著彩虹般的繽紛色彩。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"text-align":"center","font-size":"16px"}},[_c('p',[_c('b',[_vm._v("❮期間小活動❯")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" 噗噗！是誰在偷放屁？與屁屁怪獸一起展開星球攻擊！"),_c('br'),_vm._v(" 於活動期間參與課程，製作史萊姆怪獸後加贈「屁屁怪獸」")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"text-align":"center","font-size":"16px"}},[_c('p',[_c('b',[_vm._v("❮全年最划算❯")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("凡是於周年慶期間2023/3/24-4/9儲值會員最低下殺7折起"),_c('br'),_vm._v(" 再加贈【品牌帆布袋】會員扣點再贈驚喜小禮!"),_c('br'),_vm._v(" 週年慶期間商品組合豪華重量箱 超值優惠價 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"text-align":"center","font-size":"16px"}},[_c('p',[_c('b',[_vm._v("❮來店裡換來店禮❯")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("上課即贈限定徽章，只送不賣!!!!!"),_c('br'),_vm._v(" 追蹤打卡分享，換更多限定徽章+驚喜小禮 ")])
}]

export { render, staticRenderFns }