<script>
import Vue from "vue";
import VueCookies from "vue-cookies";
import CryptoJS from 'crypto-js';
import { sha256 } from "js-sha256";
import moment from "moment";
import axios from "../api/axios.js";
const $math = require("mathjs");

// 底層共用功能
export default {
  created() {
    Vue.use(VueCookies);
    Vue.use($math);
  },
  //數值處理
  numProc: {
    // 千分位
    numberWithCommas(x) {
      return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    //加法
    add(a, b) {
      undefined;
      return $math.add($math.bignumber(a), $math.bignumber(b));
    },
    //减法
    subtract(a, b) {
      undefined;
      return $math.subtract($math.bignumber(a), $math.bignumber(b));
    },
    // 乘法
    multiply(a, b) {
      undefined;
      return $math.multiply($math.bignumber(a), $math.bignumber(b));
    },
    // 除法
    divide(a, b) {
      undefined;
      return $math.divide($math.bignumber(a), $math.bignumber(b));
    },
  },
  cookie: {
    getUserCookie() {
      return Vue.$cookies.get("user");
    },
  },
  //超連結處理
  markdown: {
    parseMarkdown(value) {
      /* eslint-disable no-useless-escape */
      return value.replace(
        /[\[]{1}([^\]]+)[\]]{1}[\(]{1}([^\)\"]+)(\"(.+)\")?[\)]{1}/g,
        '<a href="$2" target="_blank">$1</a>'
      );
    },
  },

  
  // 取得今天日期(格式：yyyy/mm/dd)
  getToday() {
    let Today = new Date();
    return (
      Today.getFullYear() +
      "/" +
      String(Today.getMonth() + 1).padStart(2, "0") +
      "/" +
      String(Today.getDate()).padStart(2, "0")
    );
  },
  fixModal: {
    // call this before showing SweetAlert:
    fixBootstrapModal() {
      var modalNode = document.querySelector('.modal-content[tabindex="-1"]');
      if (!modalNode) return;

      modalNode.removeAttribute("tabindex");
      modalNode.classList.add("js-swal-fixed");
      // console.log(modalNode);
    },
    // call this before hiding SweetAlert (inside done callback):
    restoreBootstrapModal() {
      var modalNode = document.querySelector(".modal-content.js-swal-fixed");
      if (!modalNode) return;

      modalNode.setAttribute("tabindex", "-1");
      modalNode.classList.remove("js-swal-fixed");
    },
  },
  
  //日期處理
  dateProc: {
    ConvertToYYYYMMDD: function (d){
      let mm = d.getMonth() + 1; // getMonth() is zero-based
      let dd = d.getDate();
      return [d.getFullYear(),
              (mm>9 ? '' : '0') + mm,
              (dd>9 ? '' : '0') + dd
            ].join('');
    },
  },
  //字串處理
  strProc: {
    padLeft : function(str, len, char) {
      if (char === undefined) {
          char = "0";
      }
      str = '' + str;
      if (str.length >= len) {
          return str;
      } else {
          return this.padLeft(char + str, len);
      }
    },
    //36進位
    decimal36: function(intValue){
      let strValue = "0";
      let strArr = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ"
      let strlen = strArr.length;
      while (intValue != 0){
        let intRem = 0;
        intRem = parseInt(intValue % strlen);
        strValue = strArr.substring(intRem, intRem+1) + strValue;
        intValue = parseInt(intValue / strlen);
      }
      return strValue;
    },
    //36進位隨機
    rnd36: function(rndlen){
      let strValue = "";
      let strArr = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      let strlen = strArr.length;
      while (rndlen > 0){
        let rnd = Math.floor(Math.random()*strlen);
        strValue = strArr.substring(rnd, rnd+1) + strValue;
        rndlen--;
      }     
      return strValue; 
    }
  },
  serializeObj : function(obj) {
    var str = [];
    for (var p in obj)
      if (Object.prototype.hasOwnProperty.call(obj, p)) {
        str.push(p + "=" + obj[p]);
      }
    return str.join("&");
  },
  formpost: function(path, params, method='post') {
    const form = document.createElement('form');
    form.method = method;
    form.action = path;
    form.enctype="application/x-www-form-urlencoded";
    
    for (const key in params) {
      if (Object.prototype.hasOwnProperty.call(params,key)) {
        const hiddenField = document.createElement('input');
        hiddenField.type = 'hidden';
        hiddenField.name = key;
        hiddenField.value = params[key];

        form.appendChild(hiddenField);
      }
    }
    document.body.appendChild(form);
    form.submit();
  },
  thirdPartTrans: function(param)
  {
    let thirdForm={};
    switch(param.THIRDPART_TYPE){
      case "LINEPAY":
        axios.post("/ThirdPart/LinePayRequest", 
        {
          ...param
        })
          .then(response => {            
            window.location = response.result.Info.PaymentUrl.Web;
          })
          .catch(error => {
            console.error('錯誤:', error.message);
            // 在這裡處理錯誤
          });
        break;
      case "1177PAY":
        {
          if (param.is_installment)
          {
            thirdForm.authday = param.authday;
            thirdForm.isregularcharge = "1";
            thirdForm.mode = "i";
            thirdForm.regular = "m";
            thirdForm.regularcount = param.PERIOD_NUM;
          } 
          thirdForm.amount = param.amount;
          thirdForm.currency = "TWD";
          thirdForm.merchantnumber = param.WEB_ID_CREDIT;
          thirdForm.ordernumber = param.ordernumber;
          thirdForm.paymenttype = "creditcard";
          thirdForm.payphone = param.full_mobile; //消費者手機(數字;不可全形) ;未帶將自動轉入系統預設付款頁面
          thirdForm.paytitle = param.ItemName;
          switch (param.pay_type) {
            case "CREDIT":
              // this.formObj.Td = "Test00001";
              thirdForm.paymenttype = "creditcard";
              
              break;
            case "ATM": {
              thirdForm.paymenttype = "atm";
              // this.thirdForm.bankid =this.THIRD_PARAM.BANKID;
              break;
            }
          }
          thirdForm.timestamp = moment(new Date()).format("YYYYMMDDHHmmss");    
          let calculatedArray = []
          for (const [key, value] of Object.entries(thirdForm)) {
            calculatedArray.push({
              id: key,
              val: value
            })
          }
          
          thirdForm={};      
          this.sortedData(calculatedArray).forEach(x=>{
            thirdForm[x.id] = x.val;
          })            
          let s = this.serializeObj(thirdForm);
          s=sha256(s+param.TRANS_PWD).toUpperCase();
          thirdForm.checksum = s;

          this.formpost(param.HOST,thirdForm);
          break;
        }
      case "GOMYPAY":
        thirdForm.Pay_Mode_No = "2";
        thirdForm.CustomerId = param.WEB_ID_CREDIT;
        thirdForm.Order_No = param.ordernumber;
        thirdForm.Amount = param.amount;
        thirdForm.TransCode = "00"; //交易類別請填 00(授權)
        thirdForm.Buyer_Name = param.user_name; //消費者姓名;未帶將自動轉入系統預設付款頁面
        thirdForm.Buyer_Telm = param.full_mobile; //消費者手機(數字;不可全形) ;未帶將自動轉入系統預設付款頁面
        thirdForm.Buyer_Memo = param.ItemName; //消費備註(交易內容) ;未帶將自動轉入系統預設付款頁面
        thirdForm.Buyer_Mail = param.user_email;
        // this.thirdForm.CardNo= ""; //信用卡號;如無將自動轉入系統預設付款頁面
        // this.thirdForm.ExpireDate= ""; //卡片有效日期(YYMM) ;如無將自動轉入系統預設付款頁面
        // this.thirdForm.CVV= ""; //卡片認證碼;如無將自動轉入系統預設付款頁面
        thirdForm.TransMode = "1"; //交易模式一般請填(1)、分期請填(2)
        thirdForm.Installment = "0"; //期數;無期數請填 0
        switch (param.pay_type) {
          case "CREDIT":
            // this.formObj.Td = "Test00001";
            thirdForm.Send_Type = "0";
            thirdForm.Return_url =
              param.LOCALHOST + "/ThirdPart/GomypayResp"; //授權結果回傳網址=如無則自動轉入系統預設授權頁面
            thirdForm.Callback_Url =
              param.LOCALHOST + "/ThirdPart/GomypayCallback"; //授權結果回傳網址=如無則自動轉入系統預設授權頁面
            break;
          case "ATM": {
            thirdForm.Send_Type = "4";
            thirdForm.Callback_Url =
              param.LOCALHOST + "/ThirdPart/GomypayCallback"; //授權結果回傳網址=如無則自動轉入系統預設授權頁面
            break;
          }
        }
        this.formpost(param.HOST,thirdForm);
        break;  
      case "ECPAY":
      {
        switch (param.pay_type) {
          case "CREDIT":
            thirdForm.ChoosePayment = "Credit";
            thirdForm.OrderResultURL = param.LOCALHOST + "/ThirdPart/EcpayResp"; //授權結果回傳網址=如無則自動轉入系統預設授權頁面
            break;
          case "ATM": 
            thirdForm.ChoosePayment = "ATM";
            thirdForm.ReturnURL = param.LOCALHOST + "/ThirdPart/ECpayCallback"; //授權結果回傳網址=如無則自動轉入系統預設授權頁面
            break;
        }
        thirdForm.EncryptType  = "1"; //商品名稱
        thirdForm.ItemName = param.ItemName; //商品名稱
        thirdForm.MerchantID = param.WEB_ID_CREDIT;
        thirdForm.MerchantTradeDate = moment(new Date()).format("YYYY/MM/DD HH:mm:ss"); 
        thirdForm.MerchantTradeNo = param.ordernumber;
        thirdForm.OrderResultURL = param.LOCALHOST + "/ThirdPart/EcpayResp"; //授權結果回傳網址=如無則自動轉入系統預設授權頁面
        thirdForm.PaymentType = "aio"; //交易類型        
        thirdForm.TotalAmount = param.amount;
        thirdForm.TradeDesc = param.ItemName; //交易描述
        let calculatedArray = []
        for (const [key, value] of Object.entries(thirdForm)) {
          calculatedArray.push({
            id: key,
            val: value
          })
        }
        
        thirdForm={};      
        this.sortedData(calculatedArray).forEach(x=>{
          thirdForm[x.id] = x.val;
        })            
        let s = encodeURIComponent("HashKey="+param.TRANS_PWD + "&" +this.serializeObj(thirdForm)+ "&HashIV="+param.HashIV);
        s=sha256(this.netUrlEncode(s).toLowerCase()).toUpperCase();
        thirdForm.CheckMacValue = s;

        this.formpost(param.HOST,thirdForm);
        break;
      }  
      case "NEWEBPAY": {
        if (param.is_installment)
        {
          thirdForm.authday = param.authday;
          thirdForm.isregularcharge = "1";
          thirdForm.mode = "i";
          thirdForm.regular = "m";
          thirdForm.regularcount = param.PERIOD_NUM;

          let data1 = `TimeStamp=${Math.floor(Date.now() / 1000)}&Version=1.5&RespondType=JSON&MerOrderNo=${param.ordernumber}&PeriodAmt=${param.amount}&ProdDesc=${param.ItemName}&PayerEmail=${param.user_email}`;
          data1+=`&PeriodType=M&PeriodPoint=${param.authday}&PeriodStartType=2&PeriodTimes=${param.PERIOD_NUM}&ReturnURL=${param.LOCALHOST + "/ThirdPart/NewebpayResp"}`
          
          let edata1 = this.encryptionAES(data1, param.TRANS_PWD, param.HashIV);
          // let TradeSha = sha256(`HashKey=${param.TRANS_PWD}&${edata1}&HashIV=${param.HashIV}`).toUpperCase();
          console.log(edata1);
          thirdForm.MerchantID_ = param.WEB_ID_CREDIT;
          thirdForm.PostData_ = edata1;
        } 
        else
        {
          let currentDate = new Date().getFullYear()+"-"+(new Date().getMonth()+1)+"-"+ (new Date().getDate()+1);
          let data1 = `MerchantID=${param.WEB_ID_CREDIT}&TimeStamp=${Math.floor(Date.now() / 1000)}&Version=2.0&RespondType=JSON&MerchantOrderNo=${param.ordernumber}&Amt=${param.amount}&ItemDesc=${param.ItemName}&Email=${param.user_email}`;
          switch (param.pay_type) {
            case "CREDIT":
              data1 += "&CREDIT=1";
              data1 += `&ReturnURL=${param.LOCALHOST + "/ThirdPart/NewebpayResp"}`;
              data1 += `&NotifyURL=${param.LOCALHOST + "/ThirdPart/NewebpayCallback"}`;
              break;
            case "ATM":
              data1 += `&VACC=1&ExpireDate=${currentDate}`;
              //data1 += `&ReturnURL=${param.LOCALHOST + "/ThirdPart/NewebpayCallback"}`;
              data1 += `&NotifyURL=${param.LOCALHOST + "/ThirdPart/NewebpayCallback"}`;
              break;
          }
          let edata1 = this.encryptionAES(data1, param.TRANS_PWD, param.HashIV);
          let TradeSha = sha256(`HashKey=${param.TRANS_PWD}&${edata1}&HashIV=${param.HashIV}`).toUpperCase();
          
          thirdForm.MerchantID = param.WEB_ID_CREDIT;
          thirdForm.TradeInfo = edata1;
          thirdForm.TradeSha = TradeSha;
          thirdForm.Version = "2.0";
          console.log(new URLSearchParams(thirdForm).toString());
        }
        

        // this.thirdForm.CheckMacValue = s;
        this.formpost(param.HOST,thirdForm);

        break;
      }
    }
   
    //this.formpost(param.HOST,thirdForm);

  },
  sortedData : function(calculatedArray) {
    if(calculatedArray) {
      return calculatedArray.sort((t1,t2) => {return (t1.id>t2.id)-0.5});
    } else {
      return []
    }
  },
  netUrlEncode : function(url){
		let netUrlEncode = url.replaceAll("%21", "\\!").replaceAll("%28", "\\(").replaceAll("%29", "\\)").replaceAll("%20", "+");
		return netUrlEncode;
	},
  // TUID :
  // 建立時間序UID(3碼隨機), yyyymmdd+rnd3+36進位毫秒(6碼) = 17碼
  // bUTC = true or false
  // ex.
  // 20201231ABC123456
  // 20201231 (2021/12/31)
  // ABC (隨機3碼)
  // 123456 (當日毫秒轉36進位)
  createTUID: function(bUTC){    
    //取得當天的毫秒
    let dd = new Date();
    let d = new Date();    
    if (bUTC){      
      let now_utc = Date.UTC(dd.getUTCFullYear(), dd.getUTCMonth(), dd.getUTCDate(), dd.getUTCHours(), dd.getUTCMinutes(), dd.getUTCSeconds(), dd.getUTCMilliseconds());
      dd = new Date(now_utc);
      d = new Date(dd.getUTCFullYear(), dd.getUTCMonth(), dd.getUTCDate());      
    }
    else{
      dd = new Date();
      d = new Date(dd.getFullYear(),dd.getMonth(),dd.getDate());
    }
    let df = dd - d;  //毫秒
    //進位顯示毫秒
    let strValue = this.strProc.padLeft(this.strProc.decimal36(df),6);
    //加日期, 加隨機3碼
    strValue = this.dateProc.ConvertToYYYYMMDD(d) + this.strProc.rnd36(3) + strValue;
    return strValue;
  },
  encryptionAES(pwd, key, iv) {
    pwd=CryptoJS.enc.Utf8.parse(pwd)//解析文
    key = CryptoJS.enc.Utf8.parse(key)//解析密钥
    iv = CryptoJS.enc.Utf8.parse(iv)
    const encrypted = CryptoJS.AES.encrypt(pwd, key, {
      mode: CryptoJS.mode.CBC,//加密模式
      padding:CryptoJS.pad.Pkcs7,//填充方式
      iv// 向量
    })
    return encrypted.ciphertext.toString()//加密后的结果是对象,要转为文本
  },
  generateHmacSha256Signature(secret, data) {
    const hmac = CryptoJS.HmacSHA256(data, secret);
    return CryptoJS.enc.Base64.stringify(hmac);
  }
};
</script>
