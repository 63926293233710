<template>
    <div v-if="isMobile">
        <div class="row justify-content-end" style="display: flex;align-items: center;margin: 0;">
            <div class="col-6">
                <select class="form-select" aria-label="Default select example" @change="handleSortChange">
                    <option selected value="0">預設排序</option>
                    <option value="1">依最新項目排序</option>
                    <option value="2">依價格排序:低至高</option>
                    <option value="3">依價格排序:高至低</option>
                </select>
            </div>
            <div class="col-2 dropdown" id="filter-product" @click="isDropdown()">
                <img v-lazy="filterImg" alt="">
                <span style="padding-left:5px ;">篩選</span>
                <div class="dropdown-content" :style="{ display: dropdownVisible ? 'block' : 'none' }">
                    <ul>
                        <li v-for="item in type_list" :key="item.id">
                            <span @click="toggleItem(item)">
                                {{ item.name }}
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="row justify-content-center" style="margin: 0;">
            <div class="col-6" v-for="item in product_list" :key="item.id" style="padding: 30px;text-align: center;">
                <router-link class="routerLink" :to="{ name: 'productDetail',  params: { type: item.goods_class, id: item.id } }">
                    <img v-lazy="item.img" alt="" style="width: 100%;">
                    <div id="product-group">
                        <span class="product-group-name">{{ item.class_name }}</span>
                        <br>
                        <span class="product-group-originPrice" v-if="item.original_price">NT${{ item.original_price
                        }}</span>
                        <span class="product-group-discountPrice">NT${{ item.discount_price }}</span>
                    </div>
                </router-link>
            </div>
        </div>
        <div>
            <Pagination :totalPage="PageList.totalPage" :currentPage="PageList.currentPage" @change="handleChangePage" />
        </div>
    </div>
    <div v-else style="padding: 0 30px;">
        <div class="row justify-content-end" style="display: flex;align-items: center;margin: 0;">
            <div class="col-3">
                <select class="form-select" aria-label="sortSelect" @change="handleSortChange">
                    <option selected value="0">預設排序</option>
                    <option value="1">依最新項目排序</option>
                    <option value="2">依價格排序:低至高</option>
                    <option value="3">依價格排序:高至低</option>
                </select>
            </div>
        </div>
        <div style="padding: 0 30px;">
            <div class="row">
                <div class="col-4 product-classification">
                    <div style="background-color: #e3e3e3;width: 180px;padding: 5px 20px;">
                        <span>商品分類</span>
                    </div>
                    <ul>
                        <li v-for="item in type_list" :key="item.id" @click="toggleItem(item)" style="cursor: pointer;">
                            <span>
                                {{ item.name }}
                                <span v-if="item.children"><img src="../assets/img//element/expand.png" alt=""
                                        style="width: 14%;"></span>
                            </span>
                            <ul v-if="item.expanded" class="child-ul">
                                <li v-for="child in item.children" :key="child.text" class="child-li">{{ child.text }}</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div class="col-8">
                    <div class="row">
                        <div class="col-4" v-for="item in product_list" :key="item.id"
                            style="padding: 30px;text-align: center;">
                            <router-link class="routerLink" :to="{ name: 'productDetail',  params: { type: item.goods_class, id: item.id } }">
                                <img v-lazy="item.img" alt="" style="width: 100%;">
                                <div id="product-group">
                                    <span class="product-group-name">{{ item.class_name }}</span>
                                    <br>
                                    <span class="product-group-originPrice" v-if="item.original_price">NT${{
                                        item.original_price
                                    }}</span>
                                    <span class="product-group-discountPrice">NT${{ item.discount_price }}</span>
                                </div>
                            </router-link>
                        </div>
                    </div>
                    <div class="pagination-selector">
                        <Pagination :totalPage="PageList.totalPage" :currentPage="PageList.currentPage"
                            @change="handleChangePage" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Pagination from "../components/Pagination-element";
import { mapState } from 'vuex'
import axios from "../api/axios.js";
export default {
    components: {
        Pagination,
    },
    computed: {
        ...mapState({
            isMobile: state => state.isMobile,
        }),
    },
    data() {
        return {
            select_sort: 0,
            type_id: '',
            type_list: [
            ],
            product_list: [
            ],
            PageList: {
                totalPage: 3,
                currentPage: 1,
            },
            filterImg: require('../assets/img/mobile/product/03-C03.png'),
            dropdownVisible: false
        }
    },
    created() {
        let loader = this.$loading.show();
        axios.post("/Course/GetCommodity", { type: '2', sort: this.select_sort, type_id: this.type_id, currentPage: 1 }).then(resp => {
            if (resp.succ) {
                this.product_list = resp.result.data;
                this.PageList.totalPage = resp.result.totalPage;
                this.PageList.currentPage = resp.result.currentPage;

            }
            loader.hide()
        });
        axios.post("/Course/TypeMenu").then(resp => {
            if (resp.succ) {
                this.type_list = resp.result;
            }

        });
    },
    methods: {
        handleChangePage(item) {
            let loader = this.$loading.show();
            axios.post("/Course/GetCommodity", { type: '2', sort: this.select_sort, type_id: this.type_id, currentPage: item }).then(resp => {
                if (resp.succ) {
                    this.product_list = resp.result.data;
                    this.PageList.totalPage = resp.result.totalPage;
                    this.PageList.currentPage = resp.result.currentPage;

                }
                loader.hide()
            });
        },
        handleSortChange(event) {
            this.select_sort = event.target.value;
            //console.log("选中的值：" + selectedValue)
            let loader = this.$loading.show();;
            axios.post("/Course/GetCommodity", { type: '2', sort: this.select_sort, type_id: this.type_id, currentPage: this.PageList.currentPage }).then(resp => {
                if (resp.succ) {
                    this.product_list = resp.result.data;
                    this.PageList.totalPage = resp.result.totalPage;
                    this.PageList.currentPage = resp.result.currentPage;

                }
                loader.hide()
            });
        },
        toggleItem(item) {
            this.type_id = item.id;
            let loader = this.$loading.show();;
            axios.post("/Course/GetCommodity", { type: '2', sort: this.select_sort, type_id: this.type_id, currentPage: 1 }).then(resp => {
                if (resp.succ) {
                    this.product_list = resp.result.data;
                    this.PageList.totalPage = resp.result.totalPage;
                    this.PageList.currentPage = resp.result.currentPage;

                }
                loader.hide()
            });
            this.dropdownVisible = false
        },
        isDropdown() {
            this.dropdownVisible = !this.dropdownVisible;
        },
    }
}
</script>

<style scoped>
.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    width: 150px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
    z-index: 1;
    right: 20px;
    top: 30px;
}

.dropdown ul {
    padding-left: 0;
    list-style-type: none;
}

.dropdown li {
    padding: 0 5px;
    font-size: 24px;
}

/* .dropdown:hover .dropdown-content {
    display: block;
} */

#filter-product {
    padding: 0;
    font-size: 12px;
    color: var(--main-text-color);
}

#filter-product img {
    width: 30%;
}

#product-group {
    text-align: left;
    line-height: 100%;
}

.product-group-title {
    font-size: 6px;
    color: gray;
}

.product-group-name {
    font-size: 12px;
    color: rgb(0, 153, 255);
}

.product-group-originPrice {
    font-size: 13px;
    color: gray;
    margin-right: 5px;
    text-decoration: line-through;
}

.product-group-discountPrice {
    font-size: 13px;
}

.routerLink {
    display: inline-block;
    text-decoration: none;
    color: black;
}

.product-classification {
    padding: 5px 20px;
}


.product-classification ul {
    list-style-type: none;
    padding-left: 0;
}

.product-classification li {
    padding: 10px;
    border-bottom: gray 1px solid;
    border-left: none;
    border-right: none;
    width: 180px;
}

.pagination-selector {
    padding: 20px 0;
}

.child-li {
    padding: 10px 5px;
    width: 170px !important;
}

.child-ul li:last-child {
    border-bottom: none;
    padding-bottom: 0px;
}
</style>