<template>
    <swiper ref="newsPicSwiper" :options="newsPicSwiperOption">
        <swiper-slide v-for="i in news_list" :key="i.id">
            <div class="bg">
                <img :src="i.url" />
                <br>
                <div class="newsPic-group" style="height: 40px;">
                    <span class="newsPic-group-title">{{ i.title }}</span>
                    <br>
                </div>
                <div class="newsPic-group">
                    <p class="newsPic-group-info">{{ i.info }}</p>
                </div>
                <div style="text-align: end;padding: 0px 30px 10px;">
                    <input type="button" value="更多內容" class="more-info rounded-pill" @click="goNews(i.name)">
                </div>
            </div>
        </swiper-slide>
    </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { mapState } from 'vuex'
export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    computed: {
        ...mapState({
            isMobile: state => state.isMobile,
        }),
    },
    data() {
        return {
            news_list: [
                {
                    id: 1,
                    url: require("../../assets/img/mobile/news/new1.jpg"),
                    title: '照亮偏鄉小學！GAGA monster獨家史萊姆手作教學公益活動！',
                    info: 'GAGA monster史萊姆公益活動，出發──嘉義縣梅山鄉......',
                    name: '偏鄉小學公益活動',
                },
                {
                    id: 2,
                    url: require("../../assets/img/mobile/news/new2.jpg"),
                    title: '【粽夏YA!】DIY史萊姆 粽粽怪獸+冰心粽 包出夏日的趣味!',
                    info: '端午節到GAGA monster包史萊姆粽子啦➡ #粽粽怪獸 + 浸涼.....',
                    name: '粽夏YA!DIY史萊姆',
                },
                {
                    id: 3,
                    url: require("../../assets/img/mobile/news/new3.jpg"),
                    title: '隱藏怪獸【爆米花怪獸】出沒✨',
                    info: '棉花糖星球的彗星公主，最喜歡下午茶了，吃過各式各樣甜點的......',
                    name: '爆米花怪獸出沒',
                },
                {
                    id: 4,
                    url: require("../../assets/img/mobile/news/new4.jpg"),
                    title: '獻給媽咪史萊姆 ⌜康乃馨🌹⌟ 限定徽章與隱藏怪獸出沒!!!',
                    info: '≣ 活動期間：5/13、5/14  ≣只有兩天，趕快報名卡位唷!......',
                    name: '母親節活動',
                },
                {
                    id: 5,
                    url: require("../../assets/img/mobile/news/new5.jpg"),
                    title: 'GAGA monster史萊姆多元感官體驗公益活動!',
                    info: '「臺北市立啟明學校」來到 GAGA monster天母店體驗......',
                    name: '史萊姆多元感官體驗公益活動',
                },
            ],
            newsPicSwiperOption: {},
        }
    },
    created() {
        if (this.isMobile) this.newsPicSwiperOption = {
            loop: true,
            slidesPerView: 1.5,
            spaceBetween: 15,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            autoplay: {
                // delay: 5 * 1000,
                disableOnInteraction: false,
            },
        }
        else this.newsPicSwiperOption = {
            loop: true,
            slidesPerView: 4,
            spaceBetween: 15,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            autoplay: {
                disableOnInteraction: false,
            },
        }
    },
    methods: {
        goNews(routerName) {
            this.$router.push({ name: 'newsDetail', params: { name: routerName } })
        }
    }
}
</script>

<style scoped>
.swiper-container {
    width: 100vw;
    height: 80vh;
}

.swiper-slide {
    text-align: center;
    /* position: relative; */
}

/* .swiper-slide img {
    width: 49vw;
} */

.newsPic-group {
    text-align: left;
    line-height: 100%;
    padding: 10px 20px 0px;
}

.newsPic-group-title {
    font-size: 16px;
    color: var(--main-text-color);
    /* white-space: nowrap; */
}

.newsPic-group-info {
    font-size: 14px;
    padding-top: 10px;
}

.bg {
    width: 250px;
    height: 420px;
    background-color: white;
    border-radius: 20px;
}

.bg img {
    width: 250px;
    height: 250px;
    border-radius: 20px;
}

.more-info {
    padding: 5px 20px;
    background-color: #ffcf00;
    font-size: 14px;
    border: none;
}

@media screen and (max-width: 380px)and (max-height:700px) {
    .newsPic-group {
        padding: 5px 15px;
    }

    .newsPic-group-title {
        font-size: 13px;
        color: var(--main-text-color);
    }

    .newsPic-group-info {
        font-size: 12px;
        width: auto;
    }

    .bg {
        width: 100%;
        height: 350px;
    }
}


@media only screen and (min-width: 768px) and (max-width: 991px) {
    .bg {
        width: 400px;
        height: 600px;
    }

    .newsPic-group-title {
        font-size: 23px;
    }

    .newsPic-group-info {
        font-size: 20px;
        width: auto;
        line-height: 1.5;
    }
}

@media (min-width: 992px) {
    .bg {
        width: 300px;
        height: 500px;
    }

    .bg img {
        width: 300px;
        height: 300px;
    }

    .swiper-container {
        height: 103vh;
    }
}
</style>