<script>
var HEADER = {
  uid: "",
  schema_no: "",
};

export default {
  data() {
    return {
      header: HEADER,
      data: Object,
      currentPage: 0,
      totalPage: 0,
    };
  },
};
</script>>