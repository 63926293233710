<template>
    <swiper ref="productBannerSwiper" :options="productSwiperOption">
        <swiper-slide v-for="i in product_list" :key="i.id">
            <img :src="i.url" />
            <br>
            <div id="product-group">
                <span class="product-group-title">{{ i.title }}</span>
                <br>
                <span class="product-group-name">{{ i.name }}</span>
                <br>
                <span class="product-group-originPrice" v-if="i.originPrice">{{ i.originPrice }}</span>
                <span class="product-group-discountPrice">{{ i.discountPrice }}</span>
            </div>
        </swiper-slide>
    </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import { mapState } from 'vuex'

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    computed: {
        ...mapState({
            isMobile: state => state.isMobile,
        }),
    },
    data() {
        return {
            product_list: [
                {
                    id: 1,
                    url: require("../../assets/img/mobile/home/(P)BG-05(S01).png"),
                    title: '新星品報到',
                    name: '白白怪獸膠',
                    originPrice: '',
                    discountPrice: 'NT$349 – NT$489'
                },
                {
                    id: 2,
                    url: require("../../assets/img/mobile/home/(P)BG-05(S02).png"),
                    title: '主推組合',
                    name: '史萊姆豪華重量箱',
                    originPrice: 'NT$3,350',
                    discountPrice: 'NT$2,680'
                },
                {
                    id: 3,
                    url: require("../../assets/img/mobile/home/(P)BG-05(S03).png"),
                    title: '新星品報到',
                    name: '小蘇打水',
                    originPrice: 'NT$109',
                    discountPrice: 'NT$79'
                },
            ],
            productSwiperOption: {}
        }
    },
    created() {
        if (this.isMobile) this.productSwiperOption = {
            loop: true,
            slidesPerView: 2,
            spaceBetween: -30,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            autoplay: {
                disableOnInteraction: false,
            },
        }
        else this.productSwiperOption = {
            loop: true,
            slidesPerView: 4,
            spaceBetween: 15,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            autoplay: {
                disableOnInteraction: false,
            },
        }

    }
}
</script>

<style scoped>
.swiper-container {
    width: 100vw;
    height: 80vh;
}

.swiper-slide {
    text-align: center;
    position: relative;
}

.swiper-slide img {
    width: 40vw;
}

#product-group {
    text-align: left;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    line-height: 100%;
}

.product-group-title {
    font-size: 12px;
    color: gray;
}

.product-group-name {
    font-size: 12px;
    color: rgb(0, 153, 255);
}

.product-group-originPrice {
    font-size: 13px;
    color: gray;
    margin-right: 5px;
    text-decoration: line-through;
}

.product-group-discountPrice {
    font-size: 13px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .product-group-title,
    .product-group-name,
    .product-group-originPrice , 
    .product-group-discountPrice{
        font-size: 18px;
    }
}

@media (min-width: 992px) {
    .swiper-slide img {
        width: 20vw;
    }
}
</style>