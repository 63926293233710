<template>
    <div class="page-wrapper">
        <div class="row row-large row-divided">
            <div class="large-12 col" style="padding-left:0;padding-right:0;">
                <div class="entry-header">
                    <h1 class="entry-title">史萊姆怪獸星球來了！台北寒舍艾美「探索童趣」兒童節住房與餐飲活動起跑</h1>
                </div>
            </div>
            <div class="post-time">
                <span><img src="../../../assets/img/mobile/news/time.png" alt="">2023-03-29</span>
            </div>
            <div class="content col-12">

                <p>搶攻兒童節商機，台北寒舍艾美酒店攜手親子界超夯的「GAGA
                    monster史萊姆怪獸星球」合作，推出一系列的住房及餐飲活動。4月1日至4月30日凡入住「北歐探險小旅人」住房專案，主管套房內享有童趣帳篷佈置，並贈送加床服務及翌日早餐，再限量贈「GAGA課程免費體驗券」，每房每晚15,999元+15.5％。另外，4月1日至4月4日探索廚房自助餐廳加碼推出繽紛甜甜圈、太妃糖香蕉麵包布丁及香緹奶油杯子蛋糕等精緻甜品，並祭出孩童免費的限定優惠，消費滿額再送「GAGA實驗豪禮組」，同時寒舍食譜中餐廳用餐滿額則贈「GAGA課程八五折優惠券」乙張。
                </p>
                <div class="img-group">
                    <img v-lazy="Img1" alt="">
                </div>
                <div style="text-align: center;font-size: 16px;">
                    <p><b>親子住宿規劃幸福假期 DIY課程體驗無限樂趣</b></p>
                </div>
                <p>來趟精彩的旅程，就是給孩子最棒的兒童節禮物。帶家中小寶貝入住台北寒舍艾美酒店「北歐探險小旅人」住房專案，享受18坪寬敞的主管套房，房內布置孩子熱愛的童趣小帳篷增添遊戲空間，若有需求還可免費贈送加床一張，讓小朋友也享有獨立的舒適睡床。入住期間，大小朋友亦可以體驗艾美會館中的室內溫水游泳池，全家感受獨一無二的艾美假期。4月兒童月，限量加碼贈史萊姆怪獸星球的「課程免費體驗券」乙張，史萊姆手作塑形度極高，歡樂變化出獨一無二的小怪獸，激發孩子無限創造力。
                </p>
                <div class="img-group">
                    <img v-lazy="Img2" alt="">
                    <img v-lazy="Img3" alt="">
                </div>
                <div style="text-align: center;font-size: 16px;">
                    <p><b>加碼料理品味多元驚喜 豐富好禮增添美好回憶</b></p>
                </div>
                <p>4月1日至4月4日兒童節連假期間，探索廚房自助餐廳午餐、下午茶與晚餐餐期，凡一位成人攜帶一位兒童用餐，即可享兒童免費之優惠，每位成人1,290元+10％起，而且消費滿12,000元再贈好玩又安全的「GAGA史萊姆實驗豪禮組」，數量有限，送完為止。餐檯更加碼提供多樣期間限定的美味，包含「蜂蜜蒜味薯條」、「香蕉棉花糖巧克力蘭戈斯餅」、「香緹奶油杯子蛋糕」、「繽紛甜甜圈」、「太妃糖香蕉麵包布丁」、「日式銅鑼燒」與「動物造型馬林糖」等精緻甜點。同時，寒舍食譜中餐廳凡用餐發票金額單筆達6,000元，則贈「史萊姆怪獸課程85折優惠券」乙張，可使用於品牌旗下的誠品信義店、SOGO天母店與台中廣三SOGO店。超值優惠、美味佳餚、童趣禮贈一次擁有，大人小孩一同歡慶愉快兒童節。
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'newsdetail20230329',
    data() {
        return {
            Img1: require('../../../assets/img/mobile/news/20230329/1.jpg'),
            Img2: require('../../../assets/img/mobile/news/20230329/2.jpg'),
            Img3: require('../../../assets/img/mobile/news/20230329/3.jpg'),
        }
    },
}
</script>