var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-wrapper"},[_c('div',{staticClass:"row row-large row-divided"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"content col-12"},[_c('div',{staticClass:"img-group"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.Img1),expression:"Img1"}],attrs:{"alt":""}})]),_vm._m(2),_vm._m(3),_c('div',{staticClass:"img-group"},[_c('p',[_vm._v(" 使用GAGA怪獸膠基底+魔法藥水調配💧")]),_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.Img2),expression:"Img2"}],attrs:{"alt":""}}),_c('p',[_vm._v("用魔法棒攪一攪就變出史萊姆啦~~~")]),_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.Img3),expression:"Img3"}],attrs:{"alt":""}}),_c('p',[_vm._v("再來變身調色盤大師~調配出專屬的顏色❤️🧡💛")]),_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.Img4),expression:"Img4"}],attrs:{"alt":""}}),_vm._m(4),_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.Img5),expression:"Img5"}],attrs:{"alt":""}}),_c('p',[_vm._v("一樣可以蓋大泡泡喔~~~")]),_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.Img6),expression:"Img6"}],attrs:{"alt":""}}),_c('p',[_vm._v("再來淋上GAGA特製的甜滋滋果醬史萊姆~~")]),_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.Img7),expression:"Img7"}],attrs:{"alt":""}}),_c('p',[_vm._v("準備扮在一起啦~~~還有香香的味道~~")]),_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.Img8),expression:"Img8"}],attrs:{"alt":""}}),_vm._m(5),_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.Img9),expression:"Img9"}],attrs:{"alt":""}})]),_vm._m(6)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"large-12 col",staticStyle:{"padding-left":"0","padding-right":"0"}},[_c('div',{staticClass:"entry-header"},[_c('h1',{staticClass:"entry-title"},[_vm._v("隱藏怪獸【爆米花怪獸】出沒✨"),_c('br')]),_c('h1',{staticClass:"entry-title"},[_vm._v("≡期間限定┊5/13-5/31≡")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"post-time"},[_c('span',[_c('img',{attrs:{"src":require("../../../assets/img/mobile/news/time.png"),"alt":""}}),_vm._v("2023-05-13")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("棉花糖星球的彗星公主，最喜歡下午茶了，"),_c('br'),_vm._v(" 吃過各式各樣甜點的彗星公主說了："),_c('br'),_vm._v(" 「啊～真想嘗試更多不一樣的特別點心！」"),_c('br'),_vm._v(" 希望受邀出席的貴賓能帶不一樣的下午茶甜點過來!"),_c('br'),_vm._v(" 收到彗星公主下午茶邀請函的GAGA小小探險家"),_c('br'),_vm._v(" 決定帶上【爆米花怪獸】找彗星公主~ ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"text-align":"center","font-size":"16px"}},[_c('p',[_c('b',[_vm._v("【爆米花怪獸】製作步驟： ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("登登登~史萊姆基底完成啦~~~~"),_c('br'),_vm._v(" 可以拉的好長好長!!! ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("最後再配上七彩的爆米花球球來當裝飾品"),_c('br'),_vm._v(" 五顏六色的爆米花球球拌在史萊姆裡會變出意想不到的顏色變化~ ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" 撲鼻而來的甜滋滋果醬再配上七彩的爆米花完成! "),_c('br'),_vm._v(" 快讓彗星公主揭發觸覺與嗅覺和視覺的三重享受❣ "),_c('br'),_vm._v(" 爆米花怪獸小點心的有效期限：5/13-5/31 "),_c('br'),_vm._v(" 獨家秘方需到GAGAmonster櫃上體驗喔!!! ")])
}]

export { render, staticRenderFns }