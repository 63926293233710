<template>
    <div>
        <ol class="breadcrumb">
            <li @click="goHome()">首頁&nbsp;/&nbsp;</li>
            <li @click="goSecPage()" :style="{ color: params ? 'black' : 'gray' }">{{ pageTypeStr }}</li>
            <li v-if="params" :style="{ color: isCheckOut ? 'black' : 'gray' }" @click="goThirdPage()"> / {{ params }}</li>
            <li v-if="isCheckOut" style="color: gray;"> / 結帳</li>
        </ol>
    </div>
</template>

<script>
export default {
    name: "BreadCrumb",
    props: {
        pageType: {
            type: String,
        },
        pageTypeStr: {
            type: String,
        },
        params: {
            type: String,
        },
        isCheckOut: {
            type: Boolean,
        },
    },
    methods: {
        goHome() {
            this.$router.push({ name: 'home' })
        },
        goSecPage() {
            const path = this.$route.path.split('/')
            if (!this.params) return
            else this.$router.push({ path: `/${path[1]}` })
        },
        goThirdPage() {
            const path = this.$route.path.split('/')
            if (!this.isCheckOut) return
            
            else this.$router.push({ path: `/online-appointment/appointment-class/${path[3]}/${path[4]}` })
        }
    }
}
</script>

<style scoped>
.breadcrumb {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0 0;
    margin-bottom: 1rem;
    list-style: none;
}

.breadcrumb .breadcrumb-item {
    color: #909090;
    line-height: inherit;
    font-size: 12px;
}
</style>