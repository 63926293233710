<!-- <style lang="scss" scoped>
input[type="number"] {
  border: none;
  padding: 0;
  height: 100%;
  width: 100%;
  text-align: center;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.number-wrap {
  display: grid;
  grid-template-columns: 44px 1fr 44px;
  align-items: center;
  justify-items: center;
  border: 1.5px solid gray;
  box-sizing: border-box;
  border-radius: 8px;
  // height: 38px;
  width: 150px;
  font-weight: 700;
}

.minus {
  height: 37px;
  width: 45px;
  position: relative;
  border-right: solid 1.5px gray;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;

  &::after {
    content: "_";
    width: 12px;
    position: absolute;
    top: -12px;
    left: 15px;
    color: gray;
    font-size: 24px;
  }

  &:hover {
    // background: gray;

    &::after {
      color: #5c5c5c;
    }
  }
}

.plus {
  height: 37px;
  width: 45px;
  border-left: solid 1.5px gray;
  position: relative;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;

  &::after {
    content: "+";
    width: 12px;
    color: gray;
    position: absolute;
    top: 49%;
    left: 15px;
    transform: translateY(-50%);
    font-size: 24px;
  }

  &:hover {
    // background: gray;

    &::after {
      color: #5c5c5c;
    }
  }
}

.minus-round {
  height: 20px;
  width: 20px;
  position: relative;
  border: solid 1.5px rgb(88, 84, 84);
  border-radius: 9999px;

  &::after {
    content: "_";
    border-radius: 9999px;
    position: absolute;
    top: -35%;
    left: 50%;
    transform: translate(-50%, -35%);
    color: rgb(88, 84, 84);
    font-size: 20px;
  }

  &:hover {
    // background: #ffb98a;

    &::after {
      color: rgb(88, 84, 84);
    }
  }
}

.plus-round {
  height: 20px;
  width: 20px;
  border: solid 1.5px rgb(88, 84, 84);
  position: relative;
  border-radius: 9999px;

  &::after {
    content: "+";
    color: rgb(88, 84, 84);
    border-radius: 9999px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
  }

  &:hover {
    // background: #fc7f4f;

    &::after {
      color: rgb(88, 84, 84);
    }
  }
}

.round-qty {
  color: #5c5c5c;
  font-size: 16px;
  font-weight: normal;
  min-width: 40px !important;
}

@media (max-width: 1200px) {
  .zoom {
    input[type="number"] {
      font-size: 30px !important;
    }

    .number-wrap {
      height: 75px;
      width: 370px;
      height: 90px;
      grid-template-columns: 80px 1fr 80px;
    }

    .plus {
      height: 74px;
      width: 80px;

      &::after {
        width: 23px;
        left: 24px;
        font-size: 44px;
      }
    }

    .minus {
      height: 74px;
      width: 80px;

      &::after {
        width: 23px;
        left: 28px;
        font-size: 44px;
        top: -18px;
      }
    }

    .plus-round,
    .minus-round {
      width: 30px;
      height: 30px;

      &::after {
        font-size: 20px;
      }
    }

    .minus-round::after {
      top: 0;
    }

    .round-qty {
      font-size: 30px;
      min-width: 40px !important;
    }
  }
}
</style> -->

<style scoped>
.quantity {
  opacity: 1;
  display: inline-block;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1em;
  white-space: nowrap;
  vertical-align: top;
}

.text-center .button+.quantity,
.button+.quantity {
  margin-right: 0;
}

.quantity+.button {
  margin-right: 0;
  font-size: 1em;
}

.quantity .button.minus {
  border-right: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.quantity .button.plus {
  border-left: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.quantity .minus,
.quantity .plus {
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.quantity input {
  padding-left: 0;
  padding-right: 0;
  display: inline-block;
  vertical-align: top;
  margin: 0;
}

.quantity input[type="number"] {
  max-width: 2.5em;
  width: 2.5em;
  text-align: center;
  border-radius: 0 !important;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  font-size: 1em;
  background-color: #f9f9f9;
  text-shadow: 1px 1px 1px #fff;
  border: 1px solid #ddd;

}

@media (max-width: 549px) {
  .quantity input[type="number"] {
    width: 2em;
  }
}

.quantity input[type="number"]::-webkit-outer-spin-button,
.quantity input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.is-form,
button.is-form,
input[type="submit"].is-form,
input[type="reset"].is-form,
input[type="button"].is-form {
  overflow: hidden;
  position: relative;
  background-color: #f9f9f9;
  text-shadow: 1px 1px 1px #fff;
  color: #666;
  border: 1px solid #ddd;
  text-transform: none;
  font-weight: normal;
}

.button.is-form:hover {
  color: #000;
  background-color: #f1f1f1;
}
</style>

<template>
  <div :class="{ zoom: resize }">
    <div v-if="type === 'input'" class="number-wrap">
      <!-- <button type="button" class="btn btn-primary btn-sm " @click="minus">-</button> -->
      <!-- <span class="round-qty" style="min-width: 24px; text-align: center">{{
                            qty
                          }}</span> -->
      <!-- <input type="number" pattern="[0-9]*" class="round-qty" v-model.number="childQty"
                @blur="childQty = !childQty ? 1 : childQty" />
              <button type="button" class="btn btn-primary btn-sm " @click="add">+</button> -->
      <div class="quantity buttons_added" style="white-space: nowrap;">
        <span style="margin-right: 10px;">{{ typeName }}</span>
        <input type="button" value="-" class="minus button is-form" @click="minus">
        <input type="number" class="input-text qty text" title="數量" size="4" placeholder="" inputmode="numeric"
          pattern="[0-9]*" v-model.number="childQty" @blur="childQty = !childQty ? 1 : childQty" />
        <input type="button" value="+" class="plus button is-form" @click="add">
      </div>
    </div>

    <div v-if="type === 'round'" class="d-flex flex-row align-items-center">
      <!-- <button type="button" class="btn btn-primary btn-sm " @click="minus">-</button>
              <input type="number" pattern="[0-9]*" class="round-qty"
                style="margin: 16px 12px; min-width: 24px; text-align: center" v-model.number="childQty"
                @blur="childQty = !childQty ? 1 : childQty" />
              <button type="button" class="btn btn-primary btn-sm " @click="add">+</button> -->
      <div class="quantity buttons_added" style="white-space: nowrap;">
        <input type="button" value="-" class="minus button is-form" @click="minus">
        <input type="number" class="input-text qty text" title="數量" size="4" placeholder="" inputmode="numeric"
          pattern="[0-9]*" v-model.number="childQty" @blur="childQty = !childQty ? 1 : childQty"
          style="margin: 16px 12px; min-width: 24px; text-align: center" />
        <input type="button" value="+" class="plus button is-form" @click="add">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "InputNumber",
  props: {
    qty: {
      type: Number,
      require: true,
    },
    min: {
      default: 0,
    },
    type: {
      require: false,
      default: "input",
    },
    resize: {
      type: Boolean,
      default: true,
    },
    typeName: {
      type: String,
      default: "",
    }
  },
  data() {
    return {
      childQty: this.qty,
    };
  },
  watch: {
    qty(val) {
      this.childQty = val;
    },
    childQty() {
      this.$emit("update:qty", this.childQty);
    },
  },
  methods: {
    add() {
      this.childQty = parseInt(this.childQty) + 1;
      this.$emit("update:qty", this.childQty);
      this.$emit("AddClick");
    },
    minus() {
      this.childQty =
        parseInt(this.childQty) - 1 <= this.min
          ? this.min
          : parseInt(this.childQty) - 1;
      this.$emit("update:qty", this.childQty);
      this.$emit("MinusClick");
    },
  },
};
</script>
